import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { EventService } from '../event.service';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  inputs: ['isVisible', 'url'],
})
export class ReadMoreComponent implements OnInit {
  @Output() onClosing = new EventEmitter();

  isVisible;
  url;

  sendUrl;
  constructor(public event: EventService) {
    this.sendUrl = this.event.sendUrl.subscribe((res) => {
      this.url = res;
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.sendUrl.unsubscribe();
  }

  visibleChange = (e) => {
    if (!this.isVisible) this.onClosing.emit(true);
  };
}
