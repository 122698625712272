import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'wapro-loader',
  templateUrl: './wapro-loader.component.html',
  styleUrls: ['./wapro-loader.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaproLoaderComponent {
  @Input() mode: 'primary' | 'secondary' = 'secondary';
  @Input() message: string = 'Loading...';
  spinnerSize: number = 30;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.mode?.currentValue === 'primary') {
      this.spinnerSize = 74;
    }
  }
}
