import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule  } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { routing } from './app-routing.module';
import { AppServicesPayment } from './app-services.service'
import { PaymentFailComponent } from './payment-fail/payment-fail.component'
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { TpayBankComponent } from './tpay-bank/tpay-bank.component'

import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box'
import { EventService } from '../event.service';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { PointReplacerPipe } from '../pipes/pipe';
import { PipesModule } from '../pipes/pipes.module';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    DashboardComponent,
    PaymentFailComponent,
    PaymentSuccessComponent,
    TpayBankComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DxCheckBoxModule,
    PipesModule,
    routing
  ],
  providers: [AppServicesPayment],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PaymentsModule { }
