<form [formGroup]="formCodesActivation">
  <div class="row">
    <div class="col-md-12">
      <h4 style="padding-bottom: 20px">Kod aktywacji</h4>

      <div class="row">
        <div class="col-md-6 col-12 order-box">
          <div class="form-group">
            <label for="registrationCode"
              >Kod rejestracyjny <strong>*</strong></label
            >
            <dx-text-box
              [ngClass]="{
                'input-required': submitted && formError.registrationCode
              }"
              name="registrationCode"
              placeholder=""
              id="registrationCode"
              formControlName="registrationCode"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              class="text-box"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="submitted && formError.registrationCode"
            >
              {{ formError.registrationCode }}
            </small>
          </div>
          <div class="form-group">
            <label for="registrationCode"
              >Numer licencji <strong>*</strong></label
            >
            <dx-text-box
              [ngClass]="{
                'input-required': submitted && formError.licenseNumber
              }"
              name="licenseNumber"
              placeholder=""
              id="licenseNumber"
              formControlName="licenseNumber"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              class="text-box"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="submitted && formError.licenseNumber"
            >
              {{ formError.licenseNumber }}
            </small>
          </div>
          <div class="form-group">
            <label for="vatNumber">NIP firmy</label>
            <dx-text-box
              [ngClass]="{ 'input-required': submitted && formError.vatNumber }"
              name="vatNumber"
              placeholder=""
              id="vatNumber"
              formControlName="vatNumber"
              [showClearButton]="true"
              [maxLength]="10"
              [inputAttr]="{ autocomplete: 'off' }"
              class="text-box"
            >
            </dx-text-box>
            <small class="text-danger" *ngIf="submitted && formError.vatNumber">
              {{ formError.vatNumber }}
            </small>
          </div>
        </div>
        <div class="col-md-6 col-12" style="padding-left: 15px">
          <div class="form-group">
            <label for="serialNumber">Numer seryjny <strong>*</strong></label>
            <dx-text-box
              [ngClass]="{
                'input-required': submitted && formError.serialNumber
              }"
              name="serialNumber"
              placeholder=""
              id="serialNumber"
              formControlName="serialNumber"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              class="text-box"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="submitted && formError.serialNumber"
            >
              {{ formError.serialNumber }}
            </small>
          </div>
          <div class="form-group">
            <label for="secretNumber">Numer tajny <strong>*</strong></label>
            <dx-text-box
              [ngClass]="{
                'input-required': submitted && formError.secretNumber
              }"
              name="secretNumber"
              placeholder=""
              id="secretNumber"
              formControlName="secretNumber"
              [showClearButton]="true"
              [maxLength]="4"
              [inputAttr]="{ autocomplete: 'off', maxLength: 4 }"
              class="text-box"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="submitted && formError.secretNumber"
            >
              {{ formError.secretNumber }}
            </small>
          </div>
          <div class="form-group">
            <img
              src="../../../assets/img/icon_alert_black.svg"
              alt="wapro-erp"
              style="margin-top: -25px"
            />
            <p class="p-info-wapro-erp">
              Jeżeli masz wątpliwości jak aktywować<br />program przeczytaj
              <span (click)="downloadInstruction()">instrukcję</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- <div class="col-md-12" style="margin-top: 0px; padding-top: 20px; margin-bottom: 30px; border-top: 1px solid rgba(218, 222, 225, 0.5);">
            <div class="panel-re-captcha">
                <re-captcha formControlName="recaptchaReactive" (resolved)="resolved($event)"></re-captcha>
            </div>
        </div>  -->
    <div class="col-md-12 text-center">
      <dx-button
        class="btn-danger"
        text="Generuj kod aktywacji"
        width="232"
        height="40"
        style="margin-right: 5px"
        (onClick)="onSubmit(formCodesActivation.value)"
      >
      </dx-button>
    </div>

    <div
      class="col-md-12 text-Center active-code-panel"
      *ngIf="activeCode !== ''"
    >
      <p>Kod aktywacji: {{ activeCode }}</p>
    </div>
  </div>
</form>
