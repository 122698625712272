<div id="employee" *ngIf="loadingVisible"></div>
<div class="row">
  <div class="col-md-12" style="padding-bottom: 39px">
    <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->

    <h1>
      Zawartość<strong> Twojego<br />koszyka</strong>
    </h1>
  </div>
</div>

<div class="row" *ngIf="!isUnauthorized">
  <div class="col-md-12">
    <a style="cursor: pointer" (click)="backToOfert()"
      ><i class="icon absui-icon--arrow-back"></i>
    </a>
    <h5 style="display: inline-block">Koszyk</h5>

    <dx-check-box
      text="grupuj po Kliencie"
      style="
        width: 150px;
        height: 26px;
        display: inline-block;
        margin-left: 50px;
        position: relative;
        top: -7px;
      "
      (onValueChanged)="onGroupChange()"
      [(value)]="groupMode"
      *ngIf="UserRole === 'partner' || UserRole === 'subpartner'"
    ></dx-check-box>
  </div>
</div>

<div class="row">
  <div class="col-md-12" *ngIf="groupBasket.length !== 0; else noData">
    <dx-accordion
      #accordion
      [dataSource]="groupBasket"
      [collapsible]="true"
      [multiple]="true"
      [animationDuration]="300"
      [selectedItems]="groupBasket"
    >
      <div *dxTemplate="let item of 'title'">
        <div
          class="row"
          style="padding-top: 35px; box-sizing: border-box; width: 100%"
          *ngIf="!groupMode"
        >
          <div class="col-12 col-md-8">
            <div class="flex-container">
              <div>
                <div class="hexagon-red">
                  <p
                    [ngClass]="{
                      'p-smaler-name-shortcut':
                        item.productNameShortcut.length === 3
                    }"
                  >
                    {{ item.productNameShortcut }}
                  </p>
                </div>
              </div>
              <div>
                <h2
                  class="product-description-wapro-erp"
                  style="text-align: left"
                >
                  {{ item.productNameDescription }}
                </h2>
                <h4 class="h1-product-name-wapro-erp">
                  WAPRO <strong>{{ item.name }}</strong>
                </h4>
              </div>

              <div>
                <i
                  class="icon"
                  [ngClass]="{
                    'absui-icon--expand-more': item.showDescription,
                    'absui-icon--is-greateee': !item.showDescription
                  }"
                  style="
                    margin-left: 25px;
                    margin-top: 10px;
                    cursor: pointer;
                    border: none;
                  "
                  (click)="
                    expandItem(item);
                    $event.stopPropagation();
                    item.showDescription = !item.showDescription
                  "
                ></i>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 text-right">
            <dx-button
              class="icon-border"
              (onClick)="deleteAllProductLicense(item)"
              [hidden]="item.list[0].maxDateOfExpiration"
            >
              <i
                class="icon absui-icon--delete"
                aria-hidden="true"
                title="Usuń produkt"
              ></i>
            </dx-button>
          </div>

          <div class="col-12" *ngIf="item.showDescription">
            <div>
              <p class="description">{{ item.list[0].descriptionProduct }}</p>
              <a
                style="color: #dc1e28; cursor: pointer; font-size: 11px"
                (click)="$event.stopPropagation(); showDescription(item)"
                >Czytaj więcej</a
              >
            </div>
          </div>
        </div>

        <div
          class="row"
          style="padding-top: 35px; box-sizing: border-box"
          *ngIf="groupMode"
        >
          <div class="col-12 col-md-12">
            <p class="p-client-name-wapro-erp">{{ item.name }}</p>
            <p
              style="margin-top: -20px; font-weight: bold; font-size: 13px"
              *ngIf="item.list[0].customerVATNumber"
            >
              NIP: {{ item.list[0].customerVATNumber }}
            </p>
          </div>
        </div>
      </div>

      <div *dxTemplate="let item of 'item'">
        <div class="row">
          <div class="col-md-12">
            <dx-data-grid
              [dataSource]="item.list"
              [allowColumnReordering]="false"
              [showBorders]="true"
              [columnAutoWidth]="true"
              wordWrapEnabled="true"
              [showBorders]="false"
              [showRowLines]="true"
              [showColumnLines]="false"
              [hoverStateEnabled]="true"
              [height]="!isMobile ? 'auto' : item.height + 100"
              (onCellClick)="onAdaptiveDetailRowPreparing($event, item)"
              style="overflow: hidden"
            >
              <dxi-column
                dataField="licenseNumber"
                caption="Licencja"
                [allowSorting]="false"
                width="100"
                *ngIf="!isUnauthorized"
              >
              </dxi-column>
              <dxi-column
                dataField="description"
                caption="Produkt"
                [allowSorting]="false"
                [hidingPriority]="3"
                cellTemplate="productCell"
              ></dxi-column>
              <dxi-column
                dataField="company"
                caption="Klient"
                [allowSorting]="false"
                [hidingPriority]="4"
                width="250"
                cellTemplate="companyCell"
                *ngIf="!isUnauthorized"
              ></dxi-column>
              <dxi-column
                dataField="quantity"
                caption="Stanowiska"
                [allowSorting]="false"
                alignment="right"
                width="100"
                [hidingPriority]="0"
              >
              </dxi-column>
              <dxi-column
                dataField="grossPrice"
                caption="Kwota (brutto)"
                [allowSorting]="false"
                cellTemplate="grossPriceCell"
                width="120"
                alignment="right"
              >
              </dxi-column>
              <dxi-column
                caption=""
                [allowSorting]="false"
                width="120"
                cssClass="vertical-align"
                cellTemplate="editCell"
                [hidingPriority]="1"
              ></dxi-column>
              <!-- <dxi-column caption="" [allowSorting]="false" width="55" cssClass="vertical-align"
                                cellTemplate="deleteCell" [hidingPriority]="2"></dxi-column> -->

              <dxo-load-panel [enabled]="true"></dxo-load-panel>

              <dxo-scrolling
                mode="virtual"
                showScrollbar="never"
              ></dxo-scrolling>
              <dxo-sorting mode="none"></dxo-sorting>

              <dxo-grouping
                [autoExpandAll]="true"
                [allowCollapsing]="false"
              ></dxo-grouping>

              <div
                *dxTemplate="let data of 'grossPriceCell'"
                class="vertical-align"
                style="font-family: 'InterMedium'"
              >
                {{
                  data.data.amount.toFixed(2) | currency : " " | pointReplacer
                }}
              </div>

              <div
                *dxTemplate="let data of 'editCell'"
                class="cart-single-icon"
              >
                <dx-button
                  class="icon-border-row-table"
                  (onClick)="onModifyClick(data.data)"
                >
                  <i
                    class="icon icon-pencile icon-row"
                    aria-hidden="true"
                    title="Edytuj"
                  ></i>
                </dx-button>
                <dx-button
                  class="icon-border-row-table"
                  (onClick)="
                    deleteProduct(
                      data.data.licenseId,
                      data.data.productId,
                      data.data.unicate
                    )
                  "
                  [disabled]="data.data.maxDateOfExpiration"
                >
                  <i
                    class="icon absui-icon--delete icon-row"
                    aria-hidden="true"
                    title="Usuń"
                  ></i>
                </dx-button>
              </div>

              <div
                *dxTemplate="let data of 'productCell'"
                style="min-width: 300px"
              >
                <p class="p-product-name-wapro-erp">
                  {{ data.data.description }}
                </p>
                <p
                  class="p-lineDescription-wapro-erp d-none-mobile"
                  *ngIf="data.data.remarks"
                >
                  {{
                    data.data.remarks.length > 40
                      ? (data.data.remarks | slice : 0 : 40) + ".."
                      : data.data.remarks
                  }}
                </p>
              </div>

              <div *dxTemplate="let data of 'companyCell'">
                <p *ngIf="data.data.company">
                  {{
                    data.data.company.length > 50
                      ? (data.data.company | slice : 0 : 50) + ".."
                      : data.data.company
                  }}
                </p>
              </div>
              <!--
                            <div *dxTemplate="let data of 'deleteCell'" class="cart-single-icon">

                            </div> -->
            </dx-data-grid>
          </div>
        </div>
      </div>
    </dx-accordion>
  </div>
</div>

<br />

<div class="row" *ngIf="basket.length > 0" style="padding: 0 15px">
  <div class="col-md-6 col-12">
    <dx-button class="icon-border" (onClick)="popVisible = true">
      <i
        class="icon absui-icon--delete"
        style="color: #ec5f5f"
        aria-hidden="true"
        title="Usuń wszystkie"
      ></i>
    </dx-button>

    <dx-button class="icon-border" (onClick)="copyToClipboard()">
      <i
        class="icon absui-icon--files"
        aria-hidden="true"
        title="Kopiuj do schowka"
      ></i>
    </dx-button>

    <dx-button
      class="icon-border"
      (onClick)="saveBaskett()"
      *ngIf="!isUnauthorized"
    >
      <i
        class="icon absui-icon--save"
        aria-hidden="true"
        title="Zapisz koszyk"
      ></i>
    </dx-button>
  </div>
  <div class="col-md-6 col-12 panel-to-price-wapro-erp">
    <p class="p-price-wapro-erp">
      Do zapłaty
      <strong>{{
        countToPrices.toFixed(2) | currency : " " | pointReplacer
      }}</strong>
      PLN
    </p>
    <p class="p-price-netto-wapro-erp">
      {{ countNetPrice | currency : " " | pointReplacer }} netto
    </p>
    <dx-button
      class="btn-danger"
      id="sendOrder"
      text="Złóż zamówienie"
      (onClick)="sendYourOrder()"
      width="190"
      height="40"
      style="margin-top: 29px"
      [disabled]="event.eventMSG.typeOfLock === 3"
    ></dx-button>
  </div>

  <div class="col-md-12 info-variant" *ngIf="showInfoVariant && dateToday">
    <p style="margin-bottom: 0px">
      Uwaga! Informujemy, że od dnia 01.01.2021 r. wycofane zostały warianty
      START oraz BIZNES w linii standard (licencje bezterminowe) i zastąpione
      wariantami 365 START oraz 365 BIZNES z linii produktów 365 (licencje
      roczne abonamentowe). Twoja kalkulacja zawiera cenę przejścia z linii
      standard do linii 365 produktów Wapro.
    </p>
  </div>

  <!-- <div class="col-md-12">
        <img src="../../assets/img/icon_alert_black.svg" alt="wapro erp" class="img-info-mobile">
        <p class="p-info-wapro-erp">

            Procent wartości nowego oprogramowania. Wartość ta może ulec zmianie po
            weryfikacji przez dział handlowy.</p>

    </div> -->
</div>

<!-- <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
  [height]="100"
>
</dx-load-panel> -->
<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>

<ng-template #noData>
  <div class="col-md-12 text-center about-license-wapro-erp">
    <img src="./assets/img/ico_empty_basket.svg" alt="wapro-erp asseco" />
    <p class="no-data-wapro-erp">
      Obecnie nie masz w koszyku<br />żadnych produktów
    </p>
  </div>
</ng-template>

<app-read-more
  [isVisible]="showMoreVisible"
  (onClosing)="showMoreVisible = false; cd.detectChanges()"
></app-read-more>

<dx-popup
  [width]="330"
  [height]="200"
  [showTitle]="true"
  title="Potwierdź usunięcie"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="popVisible"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12">
        <p>Czy chcesz usunąć wszystkie produkty?</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right" style="margin-top: 10px">
        <dx-button
          class="dialog-accept-btn"
          text="Tak"
          (onClick)="deleteAllProduct(); popVisible = false"
          style="width: 120px; height: 45px"
        ></dx-button>
        <dx-button
          class="btn-danger"
          text="Nie"
          (onClick)="popVisible = false"
          style="
            width: 120px;
            margin-right: 15px;
            margin-left: 12px;
            height: 45px;
          "
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="330"
  [height]="230"
  [showTitle]="true"
  title="Zapisz koszyk"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="saveBasket"
  (visibleChange)="visibleChange()"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12">
        <p>Nazwa koszyka</p>
        <dx-text-box
          [(ngModel)]="basketName"
          style="height: 45px"
          #nameBasket
          valueChangeEvent="input"
        ></dx-text-box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right" style="margin-top: 10px">
        <dx-button
          class="btn-danger"
          text="Tak"
          (onClick)="saveBasketName(); popVisible = false"
          style="width: 120px; height: 45px"
        ></dx-button>
        <dx-button
          class="dialog-accept-btn"
          text="Nie"
          (onClick)="saveBasket = false"
          style="
            width: 120px;
            margin-right: 15px;
            margin-left: 12px;
            height: 45px;
          "
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>
