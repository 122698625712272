import { Component } from '@angular/core';
import { EventService } from '../event.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-service-break',
  templateUrl: './service-break.component.html',
  styleUrls: ['./service-break.component.scss'],
})
export class ServiceBreakComponent {
  dateFrom: string = '';
  dateTo: string = '';

  constructor(
    public event: EventService,
    private route: ActivatedRoute,
    public _route: Router
  ) {}

  ngOnInit() {}

  routerNavigate(url) {
    this._route.navigate([url]);
  }
}
