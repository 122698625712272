<div class="row" *ngIf="isInfoPanelShow">
  <div class="col-md-12">
    <a style="cursor: pointer" (click)="backToOrder()"
      ><i class="icon absui-icon--arrow-back"></i>
    </a>
    <h5>Szczegóły zamówienia</h5>
  </div>
</div>

<div class="row" *ngIf="isInfoPanelShow">
  <div class="col-md-12">
    <div class="about-license-wapro-erp">
      <div class="col-md-12 d-flex flex-wrap">
        <div>
          <p class="p-oreder-number-wapro-erp">{{ infoOrder.orderNumber }}</p>
        </div>
        <div style="margin-right: 60px">
          <label>Data zakupu</label>
          <p class="p-data-order-wapro-erp">
            {{ infoOrder.orderDate | date : "yyyy-MM-dd" }}
          </p>
        </div>
        <div>
          <label>Status</label>
          <p class="p-data-order-wapro-erp">{{ infoOrder.orderState }}</p>
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div style="width: 270px; margin-right: 60px">
          <label>Klient</label>
          <p class="p-data-order-wapro-erp">{{ customerInfoOrder.company }}</p>
        </div>
        <div style="margin-right: 60px">
          <label>NIP</label>
          <p class="p-data-order-wapro-erp">
            {{ customerInfoOrder.vatNumber }}
          </p>
        </div>
        <div style="margin-right: 60px">
          <label>Kod pocztowy</label>
          <p class="p-data-order-wapro-erp">
            {{ customerInfoOrder.postalCode }}
          </p>
        </div>
        <div style="margin-right: 60px">
          <label>Miejscowość</label>
          <p class="p-data-order-wapro-erp">{{ customerInfoOrder.city }}</p>
        </div>
        <div style="margin-right: 60px">
          <label>Ulica</label>
          <p class="p-data-order-wapro-erp">{{ customerInfoOrder.street }}</p>
        </div>

        <div
          style="width: 100%; margin-right: 60px"
          *ngIf="customerInfoOrder.phone != ''"
        >
          <label>Telefon</label>
          <p class="p-data-order-wapro-erp">{{ customerInfoOrder.phone }}</p>
        </div>

        <div style="margin-right: 60px" *ngIf="customerInfoOrder.email != ''">
          <label>Email</label>
          <p class="p-data-order-wapro-erp">{{ customerInfoOrder.email }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="orderInfo.length !== 0; else noData">
  <div class="col-md-12">
    <div class="about-license-wapro-erp" style="padding: 10px 25px 0px 25px">
      <dx-data-grid
        id="gridContainer"
        [dataSource]="orderInfo"
        [allowColumnReordering]="false"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        wordWrapEnabled="true"
        [columnHidingEnabled]="true"
        [height]="400"
        [showBorders]="false"
        [showColumnLines]="false"
        style="margin-top: 35px"
        (onCellClick)="onAdaptiveDetailRowPreparing($event)"
        [(selectedRowKeys)]="selectedRows"
        #gridClass
      >
        <dxo-selection
          selectAllMode="allPages"
          mode="multiple"
          showCheckBoxesMode="always"
          *ngIf="showCheckBox"
        >
        </dxo-selection>

        <dxi-column
          dataField="licenseNumber"
          caption="Licencja"
          [allowSorting]="false"
          width="80"
          [hidingPriority]="3"
          alignment="left"
          cellTemplate="licenseNumber"
        ></dxi-column>

        <dxi-column
          dataField="description"
          caption="Produkt"
          [allowSorting]="false"
          alignment="left"
          cellTemplate="description"
          [width]="!isCustomerName ? 450 : 200"
        ></dxi-column>

        <dxi-column
          dataField="customerName"
          caption="Klient"
          [allowSorting]="false"
          alignment="left"
          width="250"
          cellTemplate="customerName"
          *ngIf="isCustomerName"
        ></dxi-column>

        <dxi-column
          dataField="quantity"
          caption="Stanowiska"
          [allowSorting]="false"
          cellTemplate="quantity"
          alignment="right"
          width="70"
          [hidingPriority]="0"
        ></dxi-column>

        <dxi-column
          caption="Cena netto za szt"
          width="170"
          [allowSorting]="false"
          alignment="center"
          [hidingPriority]="1"
          cellTemplate="netPriceCell"
          alignment="right"
        >
        </dxi-column>
        <dxi-column
          caption="Cena brutto za szt"
          [allowSorting]="false"
          width="120"
          [hidingPriority]="2"
          cellTemplate="grossCell"
          alignment="right"
        >
        </dxi-column>
        <dxi-column
          caption="Kwota (brutto)"
          width="120"
          [allowSorting]="false"
          cellTemplate="amountCell"
          alignment="right"
          [hidingPriority]="4"
        ></dxi-column>

        <dxo-load-panel [enabled]="true"></dxo-load-panel>

        <dxo-paging [pageSize]="10000"> </dxo-paging>

        <div *dxTemplate="let data of 'licenseNumber'">
          <p class="text-license">{{ data.data.licenseNumber }}</p>
        </div>

        <div *dxTemplate="let data of 'customerName'">
          <p class="text-license">{{ data.data.customerName }}</p>
        </div>

        <div *dxTemplate="let data of 'description'">
          <p class="tex-row">{{ data.data.description }}</p>
          <p class="p-lineDescription-wapro-erp">{{ data.data.remarks }}</p>
        </div>

        <div *dxTemplate="let data of 'quantity'">
          <p class="tex-row">{{ data.data.quantity }}</p>
        </div>

        <div *dxTemplate="let data of 'netPriceCell'">
          <p class="netPrice">
            {{ data.data.netPrice | currency : " " | pointReplacer }}
          </p>
        </div>

        <div *dxTemplate="let data of 'grossCell'">
          <p class="netPrice">
            {{ data.data.grossPrice | currency : " " | pointReplacer }}
          </p>
        </div>

        <div *dxTemplate="let data of 'amountCell'">
          <p class="amount">
            {{ data.data.amount | currency : " " | pointReplacer }}
          </p>
        </div>
      </dx-data-grid>
      <br /><br />
    </div>
  </div>
</div>
<div class="row" style="margin-top: 50px; padding: 0 15px">
  <div class="col-md-6">
    <div id="addToBasket" style="width: 40px">
      <dx-button
        class="icon-border icon-header"
        (onClick)="addSelectedToBasket()"
        *ngIf="showCheckBox"
        [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
        [disabled]="selectedRows.length === 0"
      >
        <i class="icon absui-icon--shopping-basket" aria-hidden="true"></i>
      </dx-button>
    </div>

    <dx-tooltip
      [target]="'#addToBasket'"
      showEvent="dxhoverstart"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="{ name: 'mouseover' }"
    >
      <div *dxTemplate="let data of 'content'">
        Przenieś zaznaczone do bieżącego zamóweinia
      </div>
    </dx-tooltip>

    <br /><br /><br />

    <label style="margin-left: 14px" *ngIf="isInfoPanelShow"
      >Uwagi do zamówienia</label
    >
    <div
      class="remarks-input-wapro-erp"
      style="
        width: 100%;
        height: 105px;
        box-sizing: border-box;
        padding: 10px 10px;
      "
      *ngIf="isInfoPanelShow"
    >
      {{ infoOrder.remarks }}
    </div>
  </div>
  <div class="col-md-6 text-right" style="margin-top: -30px">
    <p class="p-price-wapro-erp">
      Do zapłaty:
      <strong>{{ countToPrices | currency : " " | pointReplacer }} </strong> PLN
    </p>
    <p class="p-price-netto-wapro-erp">
      {{ netAmount | currency : " " | pointReplacer }} netto
    </p>

    <br />
    <dx-button
      icon="icon absui-icon--payment icon-row"
      text="Zapłać online"
      class="btn-pay"
      *ngIf="
        infoOrder
          ? infoOrder.hasPaymentRegistered &&
            infoOrder.orderState !== 'zaakceptowane'
          : false
      "
      (onClick)="payOnline(orderInfo)"
      style="margin-top: 20px"
    >
    </dx-button>
  </div>
</div>

<ng-template #noData>
  <div class="col-md-12 text-center" style="margin-top: 20px">
    <app-no-data></app-no-data>
  </div>
</ng-template>
