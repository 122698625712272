import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  ElementRef,
  HostBinding,
  HostListener,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';

const helper = new JwtHelperService();

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  showMyDate = false;
  hideNavbarText: string = '';

  isShowCountBasket: boolean = false; //zmienna do wyswietlania po najechaniu myszka na koszyk ilosci produktów w koszyku
  UserRole: string = '';
  shopingCount: number = 0;
  basket = [];
  userInfo = { email: '', login: '' };

  regestrationMenu: boolean = false;

  myDataActive: boolean = false;
  routerSubscription: Subscription;
  settingsTooltipVisible: boolean = false;
  logoutTooltipVisible: boolean = false;

  @HostBinding('class.show') get opened() {
    return this.showMyDate;
  }

  @HostListener('document:click', ['$event.target']) close(targetElement) {
    let inside: boolean = this._el.nativeElement.contains(targetElement);

    if (!inside) {
      this.showMyDate = false;
    }
  }

  hideNavbar: boolean = true;

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    if (e.target['scrollingElement'].scrollTop === 0) {
      document.getElementById('header').style.background =
        'rgba(253, 254, 255, 0.85)';
    } else {
      document.getElementById('header').style.background =
        'rgba(253, 254, 255, 1)';
    }
  }

  constructor(
    private _el: ElementRef,
    private appServices: AppServices,
    public event: EventService,
    private _route: Router,
    private renderer: Renderer2,
    private appService: AppServices
  ) {
    this.event.maxDateOfExpiration.subscribe((response) => {
      if (response) this.deleteAllProductWithMaxDataExpiration();
    });

    this.event.showNavbar.subscribe(() => {
      if (localStorage.getItem('wapro-erp-token'))
        this.UserRole = helper.decodeToken(
          localStorage.getItem('wapro-erp-token')
        ).UserRole;
    });

    this.event.baskedShop.subscribe((shop) => {
      if (shop === null) this.basket = [];
      else {
        shop.forEach((element) => {
          this.deleteUnicateProduct(element.unicate);
          this.deletekProductIsBasket(element.licenseId, element.productId);
        });

        shop.forEach((product) => {
          this.basket.push(product);
        });
        localStorage.setItem(
          'basket-wapro-erp',
          this.event.encryptString(this.basket)
        );
        this.event.baskedProduct.next(this.basket);
      }
      this.shopingCount = this.checkUnique();
    });

    this.event.showNavbar.subscribe((response) => {
      this.hideNavbar = !response;
      this.decryptInformationAboutUser();
      this.appServices.headers = this.appServices.headers.set(
        'Authorization',
        'Bearer ' + localStorage.getItem('wapro-erp-token')
      );
    });

    this.event.deleteFromBasket.subscribe((licenseId) => {
      this.deletekProductIsBasket(
        JSON.parse(licenseId).licenseId,
        JSON.parse(licenseId).productId
      );
      localStorage.setItem(
        'basket-wapro-erp',
        this.event.encryptString(this.basket)
      );
      this.event.baskedProduct.next(this.basket);
      this.shopingCount = this.checkUnique();
    });

    this.event.logOnRegistration.subscribe((response) => {
      this.regestrationMenu = response;
    });

    this.event.deleteAllProductLicense.subscribe((productId) => {
      this.deleteAllProductLicense(productId);
      localStorage.setItem(
        'basket-wapro-erp',
        this.event.encryptString(this.basket)
      );
      this.event.baskedProduct.next(this.basket);
      this.shopingCount = this.checkUnique();
    });

    this.event.welcomeMessage.subscribe((e) => {
      this.hideNavbarText = e;
    });
  }
  ngOnDestroy(): void {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }

  ngOnInit() {
    this._route.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        //sprawdzam czy w session sotrage znajduja sie produkty w koszyku ,jesli tak to dodaje je do koszyka
        this.basket = [];
        if (localStorage.getItem('basket-wapro-erp')) {
          let basketSession = JSON.parse(
            this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
          );
          basketSession.forEach((field) => {
            this.basket.push(field);
          });
        }
        this.event.baskedProduct.next(this.basket);
        this.shopingCount = this.checkUnique();
      }
    });
    this.routerSubscription = this._route.events.subscribe(
      (navigationEvent) => {
        if (navigationEvent instanceof NavigationEnd) {
          this.myDataActive =
            navigationEvent.url === '/moje-dane' ? true : false;
        }
      }
    );

    this.shopingCount = this.checkUnique();
  }

  deleteAllProductLicense = (product) => {
    for (let i = this.basket.length - 1; i >= 0; i--) {
      if (this.basket[i].productId === product) this.basket.splice(i, 1);
    }
  };

  deletekProductIsBasket(licenseId, productId) {
    for (var i = this.basket.length - 1; i >= 0; --i) {
      if (
        this.basket[i].licenseId === licenseId &&
        licenseId !== 0 &&
        licenseId !== -1 &&
        this.basket[i].productId === productId
      ) {
        this.basket.splice(i, 1);
      }
    }
  }

  deleteUnicateProduct(unicate) {
    for (var i = this.basket.length - 1; i >= 0; --i) {
      if (this.basket[i].unicate === unicate) {
        this.basket.splice(i, 1);
      }
    }
  }

  deleteAllProductWithMaxDataExpiration = () => {
    for (let i = this.basket.length - 1; i >= 0; i--) {
      if (this.basket[i].maxDateOfExpiration) this.basket.splice(i, 1);
    }
  };

  onMenuClick = () => {
    let element: HTMLElement = document.getElementsByClassName(
      'navbar-toggler'
    )[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
    this.showMyDate = false;
  };

  navigateToMyDate = () => {
    this._route.navigateByUrl('moje-dane');
  };

  navigate = () => {
    if (this.hideNavbarText === 'Kontynuuj bez logowania') {
      this._route.navigate(['/']);
      this.event.welcomeMessage.next('');
    }
    if (this.hideNavbarText === 'Powrót do ekranu logowania')
      this._route.navigate(['/logowanie']);
  };

  loadingVisible = false;
  onShown() {
    this.loadingVisible = true;
  }

  onHidden() {
    this.loadingVisible = false;
  }

  logout() {
    this.onShown();
    this.appServices.headers = this.appServices.headers.delete('DataRowCount');
    this.appServices.headers = this.appServices.headers.delete('FilterColumn');
    this.appServices.headers = this.appServices.headers.delete('FilterColumn2');
    this.appServices.headers =
      this.appServices.headers.delete('FilterCriteria');
    this.appServices.headers =
      this.appServices.headers.delete('FilterCriteria2');
    localStorage.removeItem('basket-wapro-erp');

    this.event.baskedProduct.next([]);
    this.event.baskedShop.next(null);

    this.appServices.deleteAuth('Authorization/Logout').subscribe(
      (response) => {
        localStorage.removeItem('wapro-erp-token');
        localStorage.removeItem('wapro-erp-login-user');
        localStorage.removeItem('user-info-wapro-erp');
        this._route.navigateByUrl('/');
        sessionStorage.clear();
        this.event.baskedProduct.next(null);
        this.event.showNavbar.next(false);
        this.onHidden();
      },
      (error) => {
        this.event.showNotification('error', error);
        this.onHidden();
      }
    );
  }

  mouseenter = () => {
    this.isShowCountBasket = true;
  };

  mousedown = () => {
    this.isShowCountBasket = false;
  };

  checkUnique() {
    const counts = Object.create(null);
    let count = 1;
    this.basket.forEach((value) => {
      counts[value.unicate] = 1 + (counts[value.unicate] || 0);
    });
    return Object.keys(counts).length;
  }

  navigateToMySetting = () => {
    sessionStorage.setItem('myAccountGenericCode', 'true');
  };

  decryptInformationAboutUser = () => {
    try {
      this.userInfo = JSON.parse(
        this.event.decryptString(localStorage.getItem('user-info-wapro-erp'))
      );
    } catch {}
  };

  toggleSettingsTooltip() {
    this.settingsTooltipVisible = !this.settingsTooltipVisible;
  }

  toggleLogoutTooltip() {
    this.logoutTooltipVisible = !this.logoutTooltipVisible;
  }
}
