<div class="row">
    <div class="col-md-12">
        <h4 style="padding-bottom: 20px;">Dane do wysyłki</h4>
        <div class="row">
            <div class="col-md-6 col-12 order-box">
                <div class="form-group">
                    <label for="vatNumber">NIP</label><br>
                    <dx-number-box [(ngModel)]="addresses.receiverAddress.vatNumber" readOnly="true" class="text-box">
                    </dx-number-box>
                </div>
                <div class="form-group">
                    <label for="company">Nazwa firmy</label><br>
                    <dx-text-area [height]="65" [(ngModel)]="addresses.receiverAddress.company" readOnly="true" class="text-box">
                    </dx-text-area>
                </div>
                <div class="form-group">
                    <label for="vatNumber">Nazwisko</label><br>
                    <dx-text-box [(ngModel)]="addresses.receiverAddress.name" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label for="street">Ulica</label><br>
                    <dx-text-box [(ngModel)]="addresses.receiverAddress.street" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
            </div>

            <div class="col-md-6 col-12" style="padding-left: 15px">
                <div class="form-group">
                    <label for="city">Miejscowość</label><br>
                    <dx-text-box [(ngModel)]="addresses.receiverAddress.city" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label for="postalCode">Kod pocztowy</label><br>
                    <dx-text-box [(ngModel)]="addresses.receiverAddress.postalCode" readOnly="true" class="no-box-shadow"
                        style="width: 163px"></dx-text-box>
                </div>
                <div class="form-group" style="margin-top:40px;">
                    <label for="email">Email</label><br>
                    <dx-text-box [(ngModel)]="addresses.receiverAddress.email" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label for="phone">Telefon</label><br>
                    <dx-text-box [(ngModel)]="addresses.receiverAddress.phone" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-md-12">
        <h4 style="padding-bottom: 20px;">Dane do faktury</h4>
        <div class="row">
            <div class="col-md-6 col-12 order-box">
                <div class="form-group">
                    <label for="vatNumber">NIP</label><br>
                    <dx-number-box [(ngModel)]="addresses.invoiceAddress.vatNumber" readOnly="true" class="text-box">
                    </dx-number-box>
                </div>
                <div class="form-group">
                    <label for="company">Nazwa firmy</label><br>
                    <dx-text-area [height]="65" [(ngModel)]="addresses.invoiceAddress.company" readOnly="true" class="text-box">
                    </dx-text-area>
                </div>
            
                <div class="form-group">
                    <label for="street">Ulica</label><br>
                    <dx-text-box [(ngModel)]="addresses.invoiceAddress.street" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
            </div>
            <div class="col-md-6 col-12" style="padding-left: 15px">
                <div class="form-group">
                    <label for="city">Miejscowość</label><br>
                    <dx-text-box [(ngModel)]="addresses.invoiceAddress.city" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label for="postalCode">Kod pocztowy</label><br>
                    <dx-text-box [(ngModel)]="addresses.invoiceAddress.postalCode" readOnly="true" class="no-box-shadow" style="width: 163px">
                    </dx-text-box>
                </div>
                <div class="form-group" style="margin-top:40px;">
                    <label for="email">Email</label><br>
                    <dx-text-box [(ngModel)]="addresses.invoiceAddress.email" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label for="phone">Telefon</label><br>
                    <dx-text-box [(ngModel)]="addresses.invoiceAddress.phone" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row"  *ngIf="UserRole !== 'partner' && UserRole !== 'subpartner'">
    <div class="col-md-12">
        <h4>Partner</h4>
        <div class="row">
            <div class="col-md-6 col-12 order-box">
                <div class="form-group">
                    <label for="vatNumber">NIP</label><br>
                    <dx-number-box [(ngModel)]="addresses.partnerInfo.vatNumber" readOnly="true" class="text-box">
                    </dx-number-box>
                </div>
                <div class="form-group">
                    <label for="company">Nazwa partnera</label><br>
                    <dx-text-box [(ngModel)]="addresses.partnerInfo.partnerName" readOnly="true" class="text-box">
                    </dx-text-box>
                </div>
            </div>
            <div class="col-md-6 col-12" style="padding-left: 15px">
                <div class="form-group">
                    <label for="vatNumber">Miasto</label><br>
                    <dx-text-box [(ngModel)]="addresses.partnerInfo.city" readOnly="true" class="text-box"></dx-text-box>
                </div>
                <div class="form-group">
                    <label for="company">www</label><br>
                    <dx-text-box [(ngModel)]="addresses.partnerInfo.www" readOnly="true" class="text-box"></dx-text-box>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-md-12">
        <img src="../../../assets/img/icon_alert_black.svg" alt="wapro-erp" class="img-adress">
        <p class="p-info-wapro-erp">Dane adresowe można zaktualizować w trakcie składania zamówienia.</p>
    </div>
</div>