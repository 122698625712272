import {
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppServices } from '../app-services.service';

import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../event.service';
import * as sha512 from 'js-sha512';

@Component({
  selector: 'app-log-on',
  templateUrl: './log-on.component.html',
  styleUrls: ['./log-on.component.scss'],
})
export class LogOnComponent implements OnInit, OnDestroy {
  logForm;
  mode: string = 'password';
  // jwtHelper: JwtHelper = new JwtHelper();

  formError = {
    login: '',
    password: '',
  };

  validationMessages = {
    login: {
      required: 'Login jest wymagany',
    },
    password: {
      required: 'Hasło jest wymagane',
    },
  };

  hideRegisterButton: boolean = false;

  submitted: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 430) {
      document.body.style.background =
        "url('./assets/img/kv_mobile.png') no-repeat 185px 0px";
      document.body.style.setProperty(
        'background-color',
        '#ffffff',
        'important'
      );
    } else
      document.body.style.background =
        "url('./assets/img/top_small.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
  }

  constructor(
    public formBuilder: FormBuilder,
    private appService: AppServices,
    private route: ActivatedRoute,
    public _route: Router,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.event.logOnNavbar.next(false);

    this.event.hideRegisterButton.subscribe((e) => {
      this.hideRegisterButton = e;
    });

    this.onCreateForm();
  }

  ngOnInit() {
    if (window.innerWidth < 430) {
      document.body.style.background =
        "url('./assets/img/kv_mobile.png') no-repeat 185px 0px";
      document.body.style.setProperty(
        'background-color',
        '#ffffff',
        'important'
      );
    } else
      document.body.style.background =
        "url('./assets/img/top_small.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";

    this.logForm.valueChanges.subscribe((value) => {
      this.event.onControlValueChanged(
        this.logForm,
        this.formError,
        this.validationMessages
      );
    });

    this.event.welcomeMessage.next('Kontynuuj bez logowania');
  }

  ngOnDestroy() {
    this.event.logOnNavbar.next(true);
    this.event.welcomeMessage.next('');
    document.body.style.background = 'none';
  }

  onCreateForm() {
    this.logForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  clearValue = (controls) => {
    if (controls === 'password') this.logForm.controls.password.setValue('');
    else this.logForm.controls.login.setValue('');
  };

  showPopUp: boolean = false;
  textMsg: string = '';
  urlMsg: string = '';
  agreementId: number = 0;
  typeOfLock: number = 0; //1 - blokada logowania, 0 - mozna logowac
  agreementList: any = [];
  checkAgreementToAccept() {
    return new Promise((resolve) => {
      this.appService
        .getAuth('authorization/CheckAgreementToAccept')
        .subscribe((response) => {
          if (response != null && response.length > 0) {
            this.agreementList = response;

            this.textMsg = response[0].agreementDesc;
            this.urlMsg = response[0].agreementUrl;
            this.agreementId = response[0].agreementId;
            this.typeOfLock = response[0].typeOfLock;
            this.showPopUp = true;
          }

          resolve(true);
        });
    });
  }

  logOut() {
    this.showPopUp = false;
    if (this.typeOfLock == 1) {
      this.appService.headers = this.appService.headers.delete('DataRowCount');
      this.appService.headers = this.appService.headers.delete('FilterColumn');
      this.appService.headers = this.appService.headers.delete('FilterColumn2');
      this.appService.headers =
        this.appService.headers.delete('FilterCriteria');
      this.appService.headers =
        this.appService.headers.delete('FilterCriteria2');
      // localStorage.removeItem('basket-wapro-erp');

      this.event.baskedProduct.next([]);
      this.event.baskedShop.next(null);

      this.appService.deleteAuth('Authorization/Logout').subscribe(
        (response) => {
          localStorage.removeItem('wapro-erp-token');
          localStorage.removeItem('wapro-erp-login-user');
          localStorage.removeItem('user-info-wapro-erp');
          this._route.navigateByUrl('/');
          // sessionStorage.clear();
          // this.event.baskedProduct.next(null);
          this.event.showNavbar.next(false);
        },
        (error) => {
          this.event.showNotification('error', error);
        }
      );
      return;
    }

    this.agreementList.shift();

    if (this.agreementList.length > 0) {
      this.textMsg = this.agreementList[0].agreementDesc;
      this.urlMsg = this.agreementList[0].agreementUrl;
      this.agreementId = this.agreementList[0].agreementId;
      this.typeOfLock = this.agreementList[0].typeOfLock;
      setTimeout(() => {
        this.showPopUp = true;
      }, 0);
    }

    if (this.agreementList.length == 0) {
      if (localStorage.getItem('orderWapro')) {
        this._route.navigate(['zamowienie']);
        localStorage.removeItem('orderWapro');
      } else this._route.navigate(['oferta']);
    }
  }

  accept() {
    this.showPopUp = false;

    // this._route.navigate(['oferta']);
    this.mode = 'password';
    this.submitted = false;

    this.agreementList.shift();

    this.appService
      .postAuth(`authorization/AgreementToAccept/${this.agreementId}`, null)
      .subscribe((res) => {
        if (this.agreementList.length > 0) {
          this.textMsg = this.agreementList[0].agreementDesc;
          this.urlMsg = this.agreementList[0].agreementUrl;
          this.agreementId = this.agreementList[0].agreementId;
          this.typeOfLock = this.agreementList[0].typeOfLock;
          setTimeout(() => {
            this.showPopUp = true;
          }, 0);
        }

        if (this.agreementList.length == 0) {
          if (localStorage.getItem('orderWapro')) {
            this._route.navigate(['zamowienie']);
            localStorage.removeItem('orderWapro');
          } else this._route.navigate(['oferta']);
        }
      });
    this.askForUpdate();
  }

  onSubmitForm(event) {
    if (this.event.eventMSG.typeOfLock === 2) return;

    localStorage.removeItem('wapro-erp-token');
    sessionStorage.clear();
    this.submitted = true;
    if (this.logForm.invalid) {
      this.event.onControlValueChanged(
        this.logForm,
        this.formError,
        this.validationMessages
      );
      return false;
    } else {
      //koduje hasło najpierw do sha512, następni edo base64
      event.password = sha512.sha512.hex(event.password);
      event.password = this.hexToBase64(event.password);

      this.appService
        .post('Authorization/LoginWithCredentials', event)
        .subscribe(
          (response) => {
            localStorage.setItem('wapro-erp-token', response.token);
            localStorage.setItem(
              'wapro-erp-login-user',
              this.logForm.get('login').value
            );

            this.event.showNavbar.next(true);
            this.sessionInformationAboutUser();
            this.checkLoginIsEmali();
            this.checkAgreementToAccept().then((res) => {
              if (this.showPopUp) return;

              if (localStorage.getItem('orderWapro')) {
                this._route.navigate(['zamowienie']);
                localStorage.removeItem('orderWapro');
              } else this._route.navigate(['oferta']);
              // this._route.navigate(['oferta']);
              this.mode = 'password';
              this.submitted = false;

              this.askForUpdate();

              this.logForm.reset();
            });

            return true;
          },
          (error) => {
            this.event.showNotification('error', 'Błędny login lub haslo');
            return false;
          }
        );
    }
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function (arg) {});
  }

  hexToBase64(str) {
    return btoa(
      String.fromCharCode.apply(
        null,
        str
          .replace(/\r|\n/g, '')
          .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
          .replace(/ +$/, '')
          .split(' ')
      )
    );
  }

  //odpytujemy api czy uzytkownik powinien zaktualizować dane adresowe
  askForUpdate = () => {
    this.appService.headers = this.appService.headers.set(
      'Authorization',
      'Bearer ' + localStorage.getItem('wapro-erp-token')
    );
    this.appService.getAuth(`customer/Contacts`).subscribe((response) => {
      if (response.askForUpdate) this.event.askForUpdate.next(true);
    });
  };

  //pobieram dane o zalogowanym użytkowniku i zapisuje w localStorage
  sessionInformationAboutUser = () => {
    this.appService.getAuth(`customer/Addresses`).subscribe(
      (response) => {
        let userInfo = this.event.encryptString({
          email: response.invoiceAddress.email,
          login: this.logForm.get('login').value,
        });
        localStorage.setItem('user-info-wapro-erp', userInfo);
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).message);
      }
    );
  };

  checkLoginIsEmali() {
    // Wyrażenie regularne do sprawdzenia adresu e-mail
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!pattern.test(this.logForm.value.login)) {
      this.event.isLoginToEmail.next(this.logForm.value.login);
    }
  }
}
