import { Component, OnInit, ViewChild } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { FormControl } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Md5 } from 'ts-md5/dist/md5';

import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

const md5 = new Md5();
const salt = '%CxR$%j$i9^2:9_0*2Q!230xs.+';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  @ViewChild('accordion') accordion;
  userOrderForm;

  rules: any = { X: /[02-9]/ };
  invoiceAddress: boolean = false;

  submitted: boolean = false;
  UserRole: string = '';

  realizatonOnPartner: boolean = false;
  partnerList: any = [];
  partner;
  countToPrices: Number = 0;

  countNetPrice = 0;
  loadingVisible = false;
  blockBtn: boolean = false;

  groupList = [{ title: 'Dane do wysyłki' }, { title: 'Dane do faktury' }];

  radioButton = [
    {
      idp: 'firstRadio',
      value:
        'Nie chcę przesyłki kurierskiej z nośnikiem programu i papierowym certyfikatem',
      display:
        'Nie chcę przesyłki kurierskiej z nośnikiem programu i papierowym certyfikatem',
      tooltip:
        'Jeśli nie wybierasz przesyłki kurierskiej, program możesz pobrać bezpośrednio ze strony https://wapro.pl a w sekcji Moje produkty znajdziesz certyfikat autentyczności w wersji PDF.<br>Bądź eko - nie marnuj papieru',
    },
    {
      idp: 'secondRadio',
      value:
        'Chcę otrzymać przesyłkę z nośnikiem programu oraz papierową wersją certyfikatu (płatne)',
      display:
        'Chcę otrzymać przesyłkę z nośnikiem programu oraz papierową wersją certyfikatu (płatne)',
      tooltip:
        'Po dokonaniu zakupu otrzymasz płytę DVD z programem przesyłką kurierską oraz wersję papierową certyfikatu autentyczności.<br>Pamiętaj, że nie potrzebujesz płyty DVD do instalacji, najnowsze wersje zawsze możesz pobrać ze strony https://wapro.pl a certyfikat do każdego zakupu znajdziesz w sekcji Moje produkty',
    },
  ];

  radioButtonValue =
    'Nie chcę przesyłki kurierskiej z nośnikiem programu i papierowym certyfikatem';

  selectedIndex = [this.groupList[1]];

  constructor(
    private appServices: AppServices,
    private event: EventService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _route: Router
  ) {
    this.event.showNavbar.next(true);
    if (localStorage.getItem('wapro-erp-token'))
      this.UserRole = helper.decodeToken(
        localStorage.getItem('wapro-erp-token')
      ).UserRole;

    // this.event.sendCountToPay.subscribe(count => {
    //   this.countToPrices = count;
    // })
  }

  ngOnInit(): void {
    if (this.UserRole !== 'partner' && this.UserRole !== 'subpartner') {
      this.getPartnerList();
    }

    this.onCreateForm();
    this.getAdress();
    this.countToPay();
  }

  onValueChanged = (e) => {
    if (
      e.value ==
      'Chcę otrzymać przesyłkę z nośnikiem programu oraz papierową wersją certyfikatu (płatne)'
    )
      this.selectedIndex = [this.groupList[0], this.groupList[1]];
    else this.selectedIndex = [this.groupList[1]];

    this.accordion.selectedItems = this.selectedIndex;
  };

  stop(e) {
    e.event.stopPropagation();
  }

  backToBasket = () => {
    this._route.navigate(['koszyk']);
  };

  onShown() {
    this.loadingVisible = true;
  }

  onHidden() {
    this.loadingVisible = false;
  }

  countToPay = () => {
    this.countToPrices = 0;
    this.countNetPrice = 0;
    setTimeout(() => {
      let basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
      basket.forEach((field) => {
        this.countNetPrice += field.netAmount;
        this.countToPrices += field.amount;
        try {
          field.productName = field.productName.replace('WAPRO ', '');
        } catch {}
      });
    }, 500);
  };

  getAdress() {
    return this.appServices
      .getAuth(`Customer/Addresses`)
      .subscribe((response) => {
        let basket = JSON.parse(
          this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
        );
        this.userOrderForm.patchValue(response);
        if (response.partnerInfo.partnerId !== null) {
          this.realizatonOnPartner = true;
          this.partner = response.partnerInfo.partnerId;
        }

        try {
          let email = this.userOrderForm.value.invoiceAddress.email.replace(
            / /,
            ''
          );
          this.userOrderForm.controls.invoiceAddress.controls.email.setValue(
            email
          );

          email = this.userOrderForm.value.receiverAddress.email.replace(
            / /,
            ''
          );
          this.userOrderForm.controls.receiverAddress.controls.email.setValue(
            email
          );
        } catch {}

        basket = this.event.removeDuplicates(basket, 'unicate');
        basket.forEach((product) => {
          if (product.customerId === null) product.customerId = 0;
          this.userOrderForm
            .get('myProductOrders')
            .push(this.formBuilder.group(product));
        });
      });
  }

  onCreateForm() {
    this.userOrderForm = this.formBuilder.group({
      remarks: [''],
      invoiceAddress: this.formBuilder.group({
        city: ['', Validators.required],
        company: ['', Validators.required],
        email: ['', [Validators.email]],
        phone: ['', Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/)],
        postalCode: [
          '',
          [Validators.required, Validators.pattern(/[0-9]{2}-[0-9]{3}/)],
        ],
        street: ['', Validators.required],
        vatNumber: ['', Validators.required],
      }),
      partnerInfo: this.formBuilder.group({
        city: [''],
        partnerId: [''],
        partnerName: [''],
        rowNumber: [''],
        vatNumber: [''],
        www: [
          '',
          Validators.pattern(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
          ),
        ],
      }),
      receiverAddress: this.formBuilder.group({
        city: [''],
        company: [''],
        email: [''],
        name: [''],
        phone: [''],
        postalCode: [''],
        street: [''],
        vatNumber: [''],
      }),
      myProductOrders: this.formBuilder.array([]),
    });
  }

  getDateFromGus = (nip, adress) => {
    if (nip === null) this.event.showNotification('info', 'Podaj nip');
    else {
      this.appServices.getAuth(`Customer/InvoiceAddress/${nip}`).subscribe(
        (response) => {
          // response = JSON.stringify(response).replace(/\\(.)/g, '$1');
          // response = JSON.parse(response);
          this.userOrderForm.get(adress).patchValue(response);
        },
        (error) => {
          this.event.showNotification('error', 'Błąd pobierania danych z GUS');
        }
      );
    }
  };

  onSelectionChanged = (event) => {
    this.userOrderForm.get('partnerInfo').patchValue(event.selectedItem);
  };

  completeDataFromInvoice = () => {
    this.userOrderForm
      .get('receiverAddress')
      .patchValue(this.userOrderForm.get('invoiceAddress').value);
  };

  sendOrder = (values) => {
    this.submitted = true;
    if (
      this.radioButtonValue ==
      'Nie chcę przesyłki kurierskiej z nośnikiem programu i papierowym certyfikatem'
    ) {
      this.userOrderForm.controls.receiverAddress.controls.city.setValue('');
      this.userOrderForm.controls.receiverAddress.controls.company.setValue('');
      this.userOrderForm.controls.receiverAddress.controls.email.setValue('');
      this.userOrderForm.controls.receiverAddress.controls.name.setValue('');
      this.userOrderForm.controls.receiverAddress.controls.phone.setValue('');
      this.userOrderForm.controls.receiverAddress.controls.postalCode.setValue(
        ''
      );
      this.userOrderForm.controls.receiverAddress.controls.street.setValue('');
      this.userOrderForm.controls.receiverAddress.controls.vatNumber.setValue(
        null
      );

      this.userOrderForm.controls.receiverAddress.controls.city.setValidators(
        null
      );
      this.userOrderForm.controls.receiverAddress.controls.city.enable();

      this.userOrderForm.controls.receiverAddress.controls.email.setValidators(
        null
      );
      this.userOrderForm.controls.receiverAddress.controls.email.enable();

      this.userOrderForm.controls.receiverAddress.controls.company.setValidators(
        null
      );
      this.userOrderForm.controls.receiverAddress.controls.company.enable();

      this.userOrderForm.controls.receiverAddress.controls.phone.setValidators(
        null
      );
      this.userOrderForm.controls.receiverAddress.controls.phone.enable();

      this.userOrderForm.controls.receiverAddress.controls.postalCode.setValidators(
        null
      );
      this.userOrderForm.controls.receiverAddress.controls.postalCode.enable();

      this.userOrderForm.controls.receiverAddress.controls.street.setValidators(
        null
      );
      this.userOrderForm.controls.receiverAddress.controls.street.enable();

      this.userOrderForm.controls.invoiceAddress.controls.email.setValidators([
        Validators.required,
        Validators.email,
      ]);
      this.userOrderForm.controls.invoiceAddress.controls.email.enable();

      this.userOrderForm.controls.invoiceAddress.controls.phone.setValidators([
        Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/),
      ]);
      this.userOrderForm.controls.invoiceAddress.controls.phone.enable();

      this.userOrderForm.updateValueAndValidity();
    } else {
      this.userOrderForm.controls.receiverAddress.controls.city.setValidators(
        Validators.required
      );
      this.userOrderForm.controls.receiverAddress.controls.city.enable();

      this.userOrderForm.controls.receiverAddress.controls.email.setValidators(
        Validators.email
      );
      this.userOrderForm.controls.receiverAddress.controls.email.enable();

      this.userOrderForm.controls.receiverAddress.controls.company.setValidators(
        Validators.required
      );
      this.userOrderForm.controls.receiverAddress.controls.company.enable();

      this.userOrderForm.controls.receiverAddress.controls.phone.setValidators([
        Validators.required,
        Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/),
      ]);
      this.userOrderForm.controls.receiverAddress.controls.phone.enable();

      this.userOrderForm.controls.receiverAddress.controls.postalCode.setValidators(
        [Validators.required, Validators.pattern(/[0-9]{2}-[0-9]{3}/)]
      );
      this.userOrderForm.controls.receiverAddress.controls.postalCode.enable();

      this.userOrderForm.controls.receiverAddress.controls.street.setValidators(
        Validators.required
      );
      this.userOrderForm.controls.receiverAddress.controls.street.enable();

      this.userOrderForm.controls.invoiceAddress.controls.email.setValidators([
        Validators.email,
      ]);
      this.userOrderForm.controls.invoiceAddress.controls.email.enable();

      this.userOrderForm.controls.invoiceAddress.controls.phone.setValidators([
        Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/),
      ]);
      this.userOrderForm.controls.invoiceAddress.controls.phone.enable();

      this.userOrderForm.updateValueAndValidity();
    }

    let isValid = true;

    if (
      !this.userOrderForm.controls.invoiceAddress.controls.city.valid ||
      !this.userOrderForm.controls.invoiceAddress.controls.company.valid ||
      !this.userOrderForm.controls.invoiceAddress.controls.email.valid ||
      !this.userOrderForm.controls.invoiceAddress.controls.phone.valid ||
      !this.userOrderForm.controls.invoiceAddress.controls.postalCode.valid ||
      !this.userOrderForm.controls.invoiceAddress.controls.street.valid ||
      !this.userOrderForm.controls.invoiceAddress.controls.vatNumber.valid
    ) {
      this.invoiceAddress = true;
    }

    if (this.userOrderForm.invalid) {
      this.event.showNotification('error', 'Uzupełnij wszystkie wymagane pola');
      return;
    }
    this.blockBtn = true;
    this.onShown();
    let value = this.userOrderForm.getRawValue();
    if (this.UserRole === 'partner' || this.UserRole === 'subpartner') {
      value.partnerInfo = null;
    }

    value.myProductOrders.forEach((products) => {
      if (products.licenseId === 0) {
        products.packetId = 0;
        products.variantId = 0;
        products.numberOfUsers = 0;
        products.versionId = 0;
      }
    });

    this.appServices.postAuth(`Orders`, value).subscribe(
      (response) => {
        // response = JSON.stringify(response).replace(/\\(.)/g, '$1');
        // response = JSON.parse(response);
        if (response.result) {
          if (response.isPaymentOnlineAvailable) {
            this.appServices
              .postAuth(
                `Orders/${response.orderId}/AddPayment`,
                JSON.stringify({ orderId: response.orderId })
              )
              .subscribe(
                (response) => {
                  this.event.newPayment(response);
                  // let parameters = this.createParameterString(response);
                  // this.postForm(response.paymentServiceUrl, parameters, 'POST');
                },
                (error) => {
                  this.event.showNotification(
                    'error',
                    JSON.parse(error).message
                  );
                }
              );
          }

          let msg = '';

          if (values.partnerInfo !== null) {
            msg = `Złożono zamówienie: ${response.orderNumber} kwota do zapłaty: ${response.amountToPay} zł<br>Oczekuj na kontakt działu handlowego lub partnera`;
          } else {
            msg = `Złożono zamówienie: ${response.orderNumber} kwota do zapłaty: ${response.amountToPay} zł<br>Oczekuj na kontakt działu handlowego lub partnera`;
          }

          if (response.isPaymentOnlineAvailable) {
            msg = `Złożono zamówienie: ${response.orderNumber} kwota do zapłaty: ${response.amountToPay} zł<br>Zostaniesz automatycznie przekierowany do strony płatności.`;
          }

          this.event.showNotification('success', msg);
          this.userOrderForm.reset();
          this._route.navigate(['podsumowanie']);

          sessionStorage.setItem(
            'orderInformation',
            this.event.encryptString({
              orderNumber: response.orderNumber,
              amountToPay: response.amountToPay,
            })
          );

          setTimeout(() => {
            this.event.informationAboutOrder.next({
              orderNumber: response.orderNumber,
              amountToPay: response.amountToPay,
            });
          }, 0);

          let basket = JSON.parse(
            this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
          );

          this.event.baskedProduct.next([]);
          this.event.baskedShop.next(null);
          localStorage.removeItem('basket-wapro-erp');

          this.event.measurePurchases(response, basket);
          this.submitted = false;
          this.blockBtn = false;
          this.onHidden();
        } else {
          let msg =
            response.errorDescription != null
              ? response.errorDescription
              : 'Błąd składania zamówienia';
          this.event.showNotification('error', msg);
          this.submitted = false;
          this.blockBtn = false;
          this.onHidden();
        }
      },
      (error) => {
        this.event.showNotification(
          'error',
          `Code: ${JSON.parse(error).error} ${JSON.parse(error).message}`
        );
        this.submitted = false;
        this.blockBtn = false;
        this.onHidden();
      }
    );
  };

  createParameterString = (checkSum) => {
    let payment = '';
    payment += checkSum.transactionId;
    payment += checkSum.firstname;
    payment += checkSum.lastname;
    payment += checkSum.desc;
    payment += checkSum.lang;
    payment += checkSum.mail;
    payment += checkSum.accountId;
    payment += checkSum.returnUrl;
    payment += checkSum.notificationUrl;
    payment += checkSum.priceNet;
    payment += checkSum.priceGross;
    payment += checkSum.priceVat;
    payment += checkSum.sendToErp;

    let dataNow = new DatePipe('en-US').transform(new Date(), 'yyyyMMdd');

    let string = Md5.hashStr(payment);
    string = string + '/' + dataNow + '/' + salt;
    string = Md5.hashStr(string);

    checkSum.checkSum = string;

    return checkSum;
  };

  postForm(path, params, method) {
    method = method || 'post';

    var form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }

  realizatonOnPartnerChanged = (e) => {
    let partner = {
      city: null,
      partnerId: null,
      partnerName: null,
      rowNumber: 1,
      vatNumber: null,
      www: null,
    };

    if (!e.value) {
      partner = {
        city: null,
        partnerId: null,
        partnerName: null,
        rowNumber: 1,
        vatNumber: null,
        www: null,
      };
    }

    if (e.value) {
      this.partnerList.forEach((field) => {
        if (field.partnerId == this.partner) {
          partner.city = field.city;
          partner.partnerId = field.partnerId;
          partner.partnerName = field.partnerName;
          partner.rowNumber = field.rowNumber;
          partner.vatNumber = field.vatNumber;
          partner.www = field.www;
        }
      });
    }

    this.userOrderForm.controls.partnerInfo.patchValue(partner);
  };

  getPartnerList = () => {
    this.appServices.getAuth(`orders/GetPartners`).subscribe((response) => {
      // response = JSON.stringify(response).replace(/\\(.)/g, '$1');
      // response = JSON.parse(response);
      this.partnerList = response.partners;
      return this.partnerList;
    });
  };

  historyBack = () => {
    history.back();
  };
}
