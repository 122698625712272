<div class="payment-container payment-status success">
  <div class="text-center title">
    <i class="icon absui-icon--status-ok-green"></i>
  </div>
  <div class="status-info text-center">Płatność została zarejestrowana</div>
  <div class="status-desc text-center">
    Dziękujemy za korzystanie z naszych usług
  </div>

  <div class="text-center btns">
    <div class="payment-btn btn-stand" style="width: 190px; margin: 0 auto">
      Wróć do aplikacji
    </div>
  </div>
  <hr class="payment-hr" />

  <div class="extra-info">
    <i
      class="icon icon-info"
      style="
        font-size: 21px;
        width: 21px;
        height: 28px;
        position: absolute;
        top: 3px;
        left: 0;
      "
    ></i>
    <div>Informacje dodatkowe</div>
    <div class="payment-description">
      Jeżeli masz jakiekolwiek wątpliwości dotyczące faktury lub systemu
      płatności zadzwoń na numer Działu Sprzedaży Asseco WAPRO
      <strong>22 702 89 02</strong> lub wyślij numer na adres
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        href="mailto:sprzedaz.wapro@assecobs.pl"
        >sprzedaz.wapro&#64;assecobs.pl</a
      >
    </div>
    <div class="payment-description">
      Więcej informacji na witrynach
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        href="https://poland.payu.pl"
        >PayU</a
      >
      oraz
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        href="https://asseco.pl"
        >Asseco BS</a
      >
    </div>
  </div>
</div>
