import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import { Router } from '@angular/router';
import { custom } from 'devextreme/ui/dialog';
import { elementAt, timestamp } from 'rxjs/operators';
import { HostListener } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-shoping-cart',
  templateUrl: './shoping-cart.component.html',
  styleUrls: ['./shoping-cart.component.scss'],
})
export class ShopingCartComponent implements OnInit {
  @ViewChild('accordion') accordion;
  @ViewChild('nameBasket') nameBasket;

  basket: Array<any> = [];
  countToPrices: Number = 0;

  groupBasket = [];

  myDialog;
  widthPopUp: number = 500;
  popVisible: boolean = false;
  countNetPrice = 0;

  customHeight = 170;
  isUnauthorized: boolean = true;
  saveBasket: boolean = false;
  basketName: string = '';
  showInfoVariant: boolean = false;
  dateToday: boolean = false;
  textToCopy = '';
  loadingVisible: boolean = false;

  groupMode: boolean = false;
  UserRole;
  heightGrid: any = 'auto';
  showMoreVisible: boolean = false;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.saveBasket && event.charCode == 13) {
      this.saveBasketName();
    }
  }

  constructor(
    private appServices: AppServices,
    public cd: ChangeDetectorRef,
    private event: EventService,
    private _route: Router
  ) {
    this.event.baskedProduct.subscribe((basket) => {
      if (basket !== null) this.basket = basket;
    });

    if (
      window.localStorage.getItem('wapro-erp-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('wapro-erp-token')).exp * 1000
    ) {
      this.event.showNavbar.next(true);
      this.isUnauthorized = false;
    }
  }

  ngOnInit(): void {
    if (
      new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd') >= '2021-10-01'
    )
      this.dateToday = true;

    try {
      if (
        window.localStorage.getItem('wapro-erp-token') &&
        new Date().getTime() <=
          helper.decodeToken(localStorage.getItem('wapro-erp-token')).exp * 1000
      ) {
        this.UserRole = helper.decodeToken(
          localStorage.getItem('wapro-erp-token')
        ).UserRole;
        if (
          localStorage.getItem('groupShopingCardWapro') &&
          (this.UserRole === 'partner' || this.UserRole === 'subpartner')
        ) {
          this.groupMode =
            localStorage.getItem('groupShopingCardWapro') == 'false'
              ? false
              : true;
        }
      }
    } catch {}

    this.basket.forEach((field) => {
      if (field.company == null && field.customerName !== null)
        field.company = field.customerName;
    });
    this.countToPrice();
    if (window.innerWidth <= 375) {
      this.widthPopUp = 350;
    }

    this.checkVariantBefore();
  }

  myDialogShow() {
    this.myDialog = custom({
      title: 'Potwierdź usunięcie',
      messageHtml: `Czy chcesz usunąć wszystkie produkty?`,
      buttons: [
        {
          text: 'Tak',
          onClick: () => {
            this.deleteAllProduct();
          },
        },
        {
          text: 'Nie',
        },
      ],
    });

    this.myDialog.show().done();
  }

  onGroupChange = () => {
    localStorage.setItem('groupShopingCardWapro', String(this.groupMode));

    if (this.groupMode) {
      if (this.basket.length > 0)
        this.groupBasket = this.event.grouipngList(
          'company',
          this.basket,
          false,
          50
        );
      else this.groupBasket = [];
    } else {
      if (this.basket.length > 0)
        this.groupBasket = this.event.grouipngList(
          'productName',
          this.basket,
          false,
          50
        );
      else this.groupBasket = [];
    }
  };

  backToOfert = () => {
    this._route.navigate(['panel/oferta']);
  };

  saveBasketName = () => {
    window.scrollTo(0, 0);
    this.onShown();
    let basket = this.event.removeDuplicates(
      JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      ),
      'unicate'
    );
    let basketS = {
      remarks: '',
      basketName: this.basketName,
      receiverAddress: {
        company: '',
        name: '',
        street: '',
        postalCode: '',
        city: '',
        phone: '',
        email: '',
        vatNumber: '',
      },
      invoiceAddress: {
        company: '',
        name: '',
        street: '',
        postalCode: '',
        city: '',
        phone: '',
        email: '',
        vatNumber: '',
      },
      partnerInfo: {
        rowNumber: 0,
        partnerId: 0,
        partnerName: '',
        city: '',
        www: '',
        vatNumber: '',
      },
      myProductOrders: basket,
    };

    basketS.myProductOrders.forEach((element) => {
      if (element.customerId === null) element.customerId = 0;
    });

    this.appServices.postAuth(`orders/SaveOrder`, basketS).subscribe(
      (response) => {
        this.event.showNotification(
          'success',
          `Koszyk został zapisany. Numer zamówienia: ${response.orderNumber}`
        );
        this.deleteAllProduct();
        this.onHidden();
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error.message));
        this.onHidden();
      }
    );
    this.saveBasket = false;
  };

  visibleChange = () => {
    this.basketName = '';
  };

  countToPrice() {
    this.countToPrices = 0;
    this.countNetPrice = 0;
    this.basket.forEach((field) => {
      this.countNetPrice += field.netAmount;
      this.countToPrices += field.amount;
      try {
        field.productName = field.productName.replace('WAPRO ', '');
      } catch {}
    });

    if (this.groupMode) {
      if (this.basket.length > 0)
        this.groupBasket = this.event.grouipngList(
          'company',
          this.basket,
          false,
          50
        );
      else this.groupBasket = [];
    } else {
      if (this.basket.length > 0)
        this.groupBasket = this.event.grouipngList(
          'productName',
          this.basket,
          false,
          50
        );
      else this.groupBasket = [];
    }
    //this.cd.detectChanges();
  }

  onAdaptiveDetailRowPreparing = (event, height) => {
    if (window.innerWidth < 1100) {
      height.height = height.list.length * 50;
      this.basket.forEach((el) => {
        if (event.component.isAdaptiveDetailRowExpanded(el))
          height.height += 250;
      });
    }
  };

  deleteProduct(licenseId, productId, unicate) {
    let item = [];
    for (var i = this.basket.length - 1; i >= 0; --i) {
      if (
        this.basket[i].licenseId === licenseId &&
        this.basket[i].productId === productId &&
        this.basket[i].unicate === unicate
      ) {
        item.push(this.basket[i]);
        this.basket.splice(i, 1);
      }
    }
    this.event.removeProductFromShoppingCart(item);
    this.event.deleteFromBasket.next(
      JSON.stringify({ licenseId: licenseId, productId: productId })
    );
    this.countToPrice();
    this.checkVariantBefore();
  }

  checkVariantBefore = () => {
    this.showInfoVariant = false;
    this.basket.forEach((field) => {
      if (field.variantName == 'START' || field.variantName == 'BIZNES') {
        this.showInfoVariant = true;
      }
    });
  };

  deleteAllProduct = () => {
    this.basket = [];
    this.countToPrices = 0;
    this.event.baskedProduct.next([]);
    this.event.baskedShop.next(null);
    this.groupBasket = [];
    localStorage.removeItem('basket-wapro-erp');
    this.showInfoVariant = false;
  };

  onModifyClick = (product) => {
    sessionStorage.setItem(
      'data-product-wapro-erp',
      this.event.encryptString(product)
    );
    if (this.isUnauthorized)
      this._route.navigate(['modyfikuj', product.productId]);
    else {
      this._route.navigate(['modyfikuj', product.productId]);
    }
  };

  sendYourOrder() {
    if (this.isUnauthorized) {
      this._route.navigate(['logowanie']);
      localStorage.setItem('orderWapro', 'true');
    } else {
      this.event.checkActualBasket();
      this.countToPrice();
      this._route.navigate(['zamowienie']);

      setTimeout(() => {
        this.event.sendCountToPay.next(this.countToPrices);
      }, 0);
    }
  }

  deleteAllProductLicense = (product) => {
    let item = [];
    for (let i = this.basket.length - 1; i >= 0; i--) {
      if (this.basket[i].productId === product.list[0].productId) {
        item.push(this.basket[i]);
        this.basket.splice(i, 1);
      }
    }
    this.event.removeProductFromShoppingCart(item);
    this.countToPrice();
    this.event.deleteAllProductLicense.next(product.list[0].productId);
    this.checkVariantBefore();
  };

  onShown() {
    this.loadingVisible = true;
  }

  onHidden() {
    this.loadingVisible = false;
  }

  copyToClipboard = () => {
    let lengthProductName = this.basket[0].description.length;
    let lengthClientName = !this.basket[0].company
      ? 0
      : this.basket[0].company.length;

    this.basket.forEach((product) => {
      if (lengthProductName < product.description.length)
        lengthProductName = product.description.length;
      if (!product.company) product.company = '';
      if (lengthClientName < product.company.length)
        lengthClientName = product.company.length;
    });

    let product = 'Produkt';
    while (product.length < lengthProductName) {
      product += ' ';
    }

    let client = 'Klient';
    while (client.length < lengthClientName) {
      client += ' ';
    }

    this.basket.forEach((field) => {
      while (field.company.length < lengthClientName) {
        field.company += ' ';
      }
    });

    this.basket.forEach((field) => {
      while (field.description.length < lengthProductName) {
        field.description += ' ';
      }
    });

    let textToCopy = `Licencja\t${product}\t${client}\tStanowiska\tKwota (netto)\tKwota (brutto)\n`;
    this.basket.forEach((product) => {
      let priceNet = product.netAmount.toFixed(2);
      priceNet = priceNet.replace('.', ',');

      let price = product.amount.toFixed(2);
      price = price.replace('.', ',');
      let quantity = product.quantity.toString();

      while (quantity.length < 10) {
        quantity += ' ';
      }
      textToCopy +=
        product.licenseNumber +
        '\t' +
        product.description.replace(/\r?\n|\r/g, ' ') +
        '\t' +
        product.company.replace(/\r?\n|\r/g, ' ') +
        '\t' +
        quantity +
        '\t' +
        priceNet +
        '\t' +
        price +
        '\n';
    });

    let countToPrice = this.countToPrices.toFixed(2);
    let countNetToPrice = this.countNetPrice.toFixed(2);

    countToPrice = countToPrice.replace('.', ',');
    countNetToPrice = countNetToPrice.replace('.', ',');
    textToCopy += 'Razem do zapłaty NETTO\t' + countNetToPrice + '\n';
    textToCopy += 'Razem do zapłaty BRUTTO\t' + countToPrice;

    let textarea = document.createElement('textarea');
    textarea.textContent = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  expandItem(i) {
    this.accordion.instance.expandItem(i.index);
  }

  saveBaskett = () => {
    this.saveBasket = true;

    setTimeout(() => {
      this.nameBasket.instance.focus();
    }, 500);
  };

  showDescription = (item) => {
    this.appServices
      .get(`products/${item.list[0].productId}`)
      .subscribe((res) => {
        this.event.sendUrl.next(res.productUrl);
        this.showMoreVisible = this.event.openReadMore(res.productUrl);
      });
  };
}
