<header *ngIf="!hideNavbar; else logON" id="header">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a
        (click)="onMenuClick()"
        class="logo-menu"
        [routerLink]="['/oferta']"
        [ngClass]="{ marginTopLogo: event.eventMSG.typeOfLock == 1 }"
      >
        moje konto <strong>wapro</strong>
        <!-- <img src="./assets/img/logo-new.png" alt="wapro erp" class="logo-dashboard-wapro-erp"
          [routerLink]="'panel/oferta'"> -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <button
        class="navbar-toggler cart-button"
        type="button"
        aria-expanded="false"
        aria-label="Cart"
        [routerLink]="['/koszyk']"
        routerLinkActive=""
        (click)="onMenuClick()"
      >
        <span class="icon shopping-cart"></span>
        <div class="circle circle-mobile" *ngIf="basket.length > 0">
          {{ shopingCount }}
        </div>
      </button>
      <div class="d-none background-mobile"></div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav" *ngIf="event.eventMSG.typeOfLock !== 1">
          <li class="nav-item shadow-mobile">
            <a
              class="nav-link"
              [routerLink]="['/oferta']"
              routerLinkActive="active"
              (click)="onMenuClick()"
              >Oferta</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/moje-produkty']"
              routerLinkActive="active"
              (click)="onMenuClick()"
              >Moje produkty</a
            >
          </li>
          <li
            class="nav-item"
            *ngIf="UserRole === 'partner' || UserRole === 'subpartner'"
          >
            <a
              class="nav-link"
              [routerLink]="['/produkty-klientow']"
              routerLinkActive="active"
              (click)="onMenuClick()"
              >Produkty klientów</a
            >
          </li>

          <li
            class="nav-item"
            *ngIf="UserRole === 'partner' || UserRole === 'subpartner'"
          >
            <a
              class="nav-link"
              [routerLink]="['/moi-klienci']"
              routerLinkActive="active"
              (click)="onMenuClick()"
              >Moi klienci</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/historia-zamowien']"
              routerLinkActive="active"
              (click)="onMenuClick()"
              >Zamówienia</a
            >
          </li>
          <li class="nav-item hide-mobile">
            <a
              class="nav-link cart-link"
              [routerLink]="['/koszyk']"
              routerLinkActive="active"
              (click)="onMenuClick()"
            >
              <i
                class="icon shopping-cart"
                aria-hidden="true"
                style="margin-top: -5px"
              ></i>
              Koszyk
              <div class="circle" *ngIf="basket.length > 0">
                {{ shopingCount }}
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              style="margin-right: 0px"
              style="margin-right: -150px"
              style="cursor: pointer; padding-top: 6px"
              id="navbarDropdown"
              role="button"
              is-open="status.isopen"
              on-toggle="toggled(open)"
              [ngClass]="{ active: myDataActive }"
              (click)="showMyDate = !showMyDate"
              data-toggle="dropdown"
              aria-haspopup="false"
              aria-expanded="false"
              >Moje konto
              <i
                class="icon"
                style="position: relative; top: 4px"
                [ngClass]="{
                  'absui-icon--expand-less': showMyDate,
                  'icon--keyboard-arrow-left': !showMyDate
                }"
              ></i>
            </a>
            <div
              class="my-date-container"
              *ngIf="showMyDate"
              [ngClass]="{
                'my-data-left':
                  UserRole === 'partner' || UserRole === 'subpartner',
                'not-partner':
                  UserRole !== 'partner' && UserRole !== 'subpartner'
              }"
            >
              <div class="row">
                <div class="col-md-12">
                  <!-- <img src="../../assets/img/about-us.png" alt="wapro-erp"> -->
                  <p class="p-user-wapro-erp">{{ userInfo.login }}</p>
                  <p class="p-email-wapro-erp">{{ userInfo.email }}</p>
                </div>
              </div>
              <div class="row" style="position: absolute; bottom: 15px">
                <div class="col-md-12" style="padding-left: 150px">
                  <div class="d-flex flex-row">
                    <div>
                      <a
                        [routerLink]="['/moje-dane']"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                        (click)="
                          showMyDate = !showMyDate; navigateToMySetting()
                        "
                        style="border: none !important; box-shadow: none"
                      >
                        <button class="icon-border" hint="Kod aktywacji">
                          <i
                            class="icon icon-key"
                            aria-hidden="true"
                            title="Kod aktywacji"
                          ></i>
                          <span class="btn-menu">Kod aktywacji</span>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a
                        routerLinkActive="active"
                        [routerLink]="['/moje-dane']"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                        (click)="showMyDate = !showMyDate"
                        style="border: none !important; box-shadow: none"
                      >
                        <button
                          class="icon-border"
                          hint="Ustawienia"
                          id="btn-settings"
                        >
                          <i
                            class="icon icon--settings"
                            aria-hidden="true"
                            title="Ustawienia konta"
                          ></i>
                          <span class="btn-menu">Ustawienia konta</span>
                        </button>
                      </a>
                    </div>
                    <div>
                      <button
                        class="icon-border"
                        id="btn-logout"
                        (click)="logout()"
                        hint="Wyloguj"
                      >
                        <i
                          class="icon icon-sign-out"
                          aria-hidden="true"
                          title="Wyloguj się"
                        ></i>
                        <span class="btn-menu">Wyloguj się</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<ng-template #logON>
  <header id="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light nav-lg">
        <a
          class="navbar-brand"
          (click)="onMenuClick()"
          class="logo-menu"
          [ngClass]="{ marginTopLogo: event.eventMSG.typeOfLock == 1 }"
          [routerLink]="['/']"
        >
          moje konto <strong>wapro</strong>
          <!-- <img src="./assets/img/logo-new.png" alt="wapro erp" class="logo-dashboard-wapro-erp" [routerLink]="''"> -->
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <button
          class="navbar-toggler cart-button"
          type="button"
          aria-expanded="false"
          aria-label="Cart"
          [routerLink]="['/koszyk']"
          routerLinkActive=""
          (click)="onMenuClick()"
        >
          <span class="icon shopping-cart"></span>
          <div class="circle circle-mobile" *ngIf="basket.length > 0">
            {{ shopingCount }}
          </div>
        </button>

        <div class="d-none background-mobile"></div>
        <div
          class="collapse navbar-collapse"
          id="navbarSupportedContent"
          *ngIf="hideNavbarText == ''; else menu"
        >
          <ul class="navbar-nav" *ngIf="event.eventMSG.typeOfLock !== 1">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/oferta']"
                routerLinkActive="active"
                (click)="onMenuClick()"
                >Oferta</a
              >
            </li>

            <li class="nav-item hide-mobile">
              <a
                class="nav-link cart-link"
                [routerLink]="['/koszyk']"
                routerLinkActive="active"
                (click)="onMenuClick()"
              >
                <i
                  class="icon shopping-cart"
                  aria-hidden="true"
                  style="margin-top: -5px"
                ></i>
                Koszyk
                <div class="circle circle-mobile" *ngIf="basket.length > 0">
                  {{ shopingCount }}
                </div>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link nav-log"
                [routerLink]="['/logowanie']"
                style="width: 110px"
                routerLinkActive="active"
                (click)="onMenuClick()"
                *ngIf="event.eventMSG.typeOfLock !== 2"
              >
                Zaloguj się
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</ng-template>

<ng-template #menu>
  <div
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
    *ngIf="!regestrationMenu"
  >
    <ul class="navbar-nav single-menu">
      <li>
        <a (click)="navigate(); onMenuClick()" style="cursor: pointer">
          {{ hideNavbarText }}<i class="icon absui-icon--is-greate"></i>
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<!-- <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#body' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
  [height]="100"
>
</dx-load-panel> -->

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#body' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>
