<dx-popup
  [showTitle]="true"
  title="Wymagana zmiana loginu"
  [dragEnabled]="false"
  class="my-customer-popup"
  [width]="620"
  [height]="770"
  [maxWidth]="'100%'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [(visible)]="isVisible"
  (onInitialized)="onInit($event)"
  (visibleChange)="onVisibleChange($event)"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12 text-center">
        <img
          src="../../assets/img/warning.svg"
          alt="Asseco Business Solutions"
        />

        <p class="p-header" style="margin-bottom: 6px">
          <strong>Zmieniamy sposób logowania do Moje konto Wapro ERP</strong>
        </p>
        <p class="p-header">
          Należy ustawić nowy login w formie adresu e-mail.
        </p>

        <p class="p_info">
          Podaj nowy login i zapisz zmiany. Po zapisaniu, zaloguj sie ponownie
          do sklepu używając nowego loginu.
          <strong>Twoje hasło się nie zmieni.</strong>
        </p>
      </div>
    </div>
    <div class="row" style="margin-top: 15px">
      <form [formGroup]="form" style="margin-bottom: 50px">
        <div class="col-md-12 text-left">
          <div class="form-group">
            <label for="subpartnersList"
              >Stary login <span style="color: #dc1e28">*</span></label
            >
            <dx-text-box
              placeholder=""
              valueChangeEvent="input"
              style="height: 40px"
              [showClearButton]="true"
              class="no-box-shadow nip-enter"
              formControlName="login"
              [readOnly]="true"
            >
            </dx-text-box>
          </div>

          <div class="form-group">
            <label for="subpartnersList"
              >Nowy login <span style="color: #dc1e28">*</span></label
            >
            <dx-text-box
              placeholder=""
              valueChangeEvent="input"
              style="height: 40px"
              [showClearButton]="true"
              class="no-box-shadow nip-enter"
              formControlName="newLogin"
              [maxLength]="50"
              [ngClass]="{
                'input-required': submitted && form.controls.newLogin.invalid
              }"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="
                form.controls.newLogin.invalid &&
                formError.newLogin &&
                submitted
              "
            >
              <p style="margin-bottom: 0px; margin-left: 15px">
                {{ formError.newLogin }}
              </p>
            </small>
          </div>

          <div class="form-group">
            <label for="subpartnersList"
              >Nowy login (powtórz) <span style="color: #dc1e28">*</span></label
            >
            <dx-text-box
              placeholder=""
              valueChangeEvent="input"
              style="height: 40px"
              [showClearButton]="true"
              class="no-box-shadow nip-enter"
              formControlName="newLoginRepeat"
              [elementAttr]="{ onpaste: 'return false;' }"
              [maxLength]="50"
              [ngClass]="{
                'input-required':
                  submitted && form.controls.newLoginRepeat.invalid
              }"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="
                form.controls.newLoginRepeat.invalid &&
                formError.newLoginRepeat &&
                submitted
              "
            >
              <p style="margin-bottom: 0px; margin-left: 15px">
                {{ formError.newLoginRepeat }}
              </p></small
            >
          </div>
        </div>
      </form>
    </div>

    <div
      style="
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        padding: 25px;
      "
    >
      <div class="footer-box">
        <div class="warning-box">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              style="margin-right: 8px; margin-top: -2px"
            >
              <path
                d="M9 14.1429C8.76343 14.1429 8.57143 13.9509 8.57143 13.7143V12.9437C8.57143 12.7071 8.76343 12.5151 9 12.5151C9.23657 12.5151 9.42857 12.7071 9.42857 12.9437V13.7143C9.42857 13.9509 9.23657 14.1429 9 14.1429Z"
                fill="#A86800"
              />
              <path
                d="M9 11.3143C8.76343 11.3143 8.57143 11.1223 8.57143 10.8857V6.59999C8.57143 6.36342 8.76343 6.17142 9 6.17142C9.23657 6.17142 9.42857 6.36342 9.42857 6.59999V10.8857C9.42857 11.1223 9.23657 11.3143 9 11.3143Z"
                fill="#A86800"
              />
              <path
                d="M15.4063 16.2857H2.508C1.57543 16.2857 0.819427 15.5297 0.819427 14.5971C0.819427 14.286 0.903427 13.9946 1.05086 13.7443L1.04657 13.752L7.52914 2.55171C7.82228 2.04428 8.36228 1.70914 8.98028 1.70914C8.98371 1.70914 8.988 1.70914 8.99143 1.70914H8.994C8.99657 1.70914 8.99914 1.70914 9.00171 1.70914C9.62228 1.70914 10.1631 2.04685 10.4511 2.54914L10.4554 2.55685L16.8711 13.7589C17.0117 13.9997 17.0949 14.2894 17.0949 14.598C17.0949 15.5297 16.3389 16.2857 15.4063 16.2857ZM8.98971 2.56542C8.988 2.56542 8.98628 2.56542 8.98457 2.56542C8.68028 2.56542 8.41457 2.73085 8.27228 2.97599L8.27057 2.98028L1.788 14.1806C1.71771 14.2997 1.67657 14.4437 1.67657 14.5963C1.67657 15.0557 2.04857 15.4277 2.508 15.4286H15.4063C15.8649 15.4286 16.2377 15.0566 16.2377 14.598C16.2377 14.4463 16.1966 14.3031 16.1254 14.1806L16.1271 14.1849L9.71143 2.98371C9.56743 2.73257 9.30171 2.56628 8.99571 2.56628C8.994 2.56628 8.99228 2.56628 8.99057 2.56628L8.98971 2.56542Z"
                fill="#A86800"
              />
            </svg>
            Wybranie opcji anuluj spowoduje wylogowanie ze sklepu
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right" style="margin-top: 25px">
          <dx-button
            class="dialog-accept-btn"
            text="Anuluj"
            (onClick)="cancel()"
            style="width: 120px; margin-right: 12px; height: 40px"
          ></dx-button>

          <dx-button
            class="btn-danger"
            text="Zapisz"
            (onClick)="onSave()"
            style="width: 120px; height: 40px"
          >
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-popup>
