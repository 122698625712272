import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { ActivatedRoute, Router } from '@angular/router';

import { custom } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-my-customer',
  templateUrl: './my-customer.component.html',
  styleUrls: ['./my-customer.component.scss'],
})
export class MyCustomerComponent implements OnInit, OnDestroy {
  myCustomerList = [];
  rowCount = 0;
  readData: boolean = true;
  selectedRows = [];

  filterCriteria: string = '';
  filterColumn: string = 'Company';
  filterColumn2: string = 'CustomerType';
  filterCriteria2: string = '';

  widthPopUp: number = 500;
  heightpopUp: number = 310;
  heightpopUpUnfasten: number = 250;

  filtrName = [
    { label: 'Nazwa', value: 'Company' },
    { label: 'NIP', value: 'VATNumber' },
    { label: 'Klienci partnera', value: 'SubPartnerCompany' },
  ];

  filterName2 = [
    { label: 'Wszyscy klienci', value: '' },
    { label: 'Moi klienci', value: 'Own' },
  ];

  showModalBox: boolean = false;

  showCheckBoxesMode = 'always';

  isUpdate: boolean = false;

  subpartnersList = [];
  showModalBoxConfirm: boolean = false;
  partnerId = null;
  vatNumber = null;

  tabMenu = [
    { name: 'Wszyscy klienci', myCustomerList: this.myCustomerList },
    { name: 'Moi klienci', myCustomerList: this.myCustomerList },
    { name: 'Utraceni klienci', myCustomerList: this.myCustomerList },
  ];

  loadingVisible = false;
  heightGrid = 600;

  constructor(
    private appServices: AppServices,
    private event: EventService,
    private _route: Router
  ) {
    this.event.showNavbar.next(true);

    this.appServices.headers = this.appServices.headers.append(
      'DataRowCount',
      '0'
    );
    this.appServices.headers = this.appServices.headers.append(
      'FilterColumn',
      'Company'
    );
    this.appServices.headers = this.appServices.headers.append(
      'FilterColumn2',
      'CustomerType'
    );
    this.appServices.headers = this.appServices.headers.append(
      'FilterCriteria',
      ''
    );
    this.appServices.headers = this.appServices.headers.append(
      'FilterCriteria2',
      ''
    );

    if (sessionStorage.getItem('FilterCriteriaMyCustomer')) {
      this.filterCriteria = sessionStorage.getItem('FilterCriteriaMyCustomer');
      this.appServices.headers = this.appServices.headers.set(
        'FilterCriteria',
        this.filterCriteria
      );
    }

    if (sessionStorage.getItem('FilterColumnMyCustomer')) {
      this.filterColumn = sessionStorage.getItem('FilterColumnMyCustomer');
      this.appServices.headers = this.appServices.headers.set(
        'FilterColumn',
        this.filterColumn
      );
    }
  }

  // @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
  //   this.scroll(e);
  // }

  ngOnInit(): void {
    this.onShown();
    this.getCustomerList();

    if (window.innerWidth <= 420) {
      this.widthPopUp = 345;
      this.heightpopUp = 350;
    } else {
      this.showCheckBoxesMode = 'always';
      this.widthPopUp = 500;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 380) {
        this.widthPopUp = 345;
      } else {
        this.showCheckBoxesMode = 'always';
        this.widthPopUp = 500;
      }
    });
  }

  onAdaptiveDetailRowPreparing = (event) => {
    if (window.innerWidth < 1100) {
      if (window.innerWidth < 576 && this.myCustomerList.length <= 8) {
        this.heightGrid = this.myCustomerList.length * 64.6;

        this.myCustomerList.forEach((el) => {
          if (event.component.isAdaptiveDetailRowExpanded(el))
            this.heightGrid += 200;
        });
      }
    }
  };

  onShown() {
    this.loadingVisible = true;
  }

  onHidden() {
    this.loadingVisible = false;
  }

  navigateToSearchLicense = () => {
    this._route.navigate(['weryfikacja-licencji']);
  };

  // showModalBoxConfirmShow(){
  //   this.showModalBoxConfirm = custom({
  //     title: "Czy chcesz odpiąć klienta?",
  //     messageHtml: `<center>Klient zostanie usunięty z Twojej listy oraz jego produkty z listy Produkty klientów.<br>Czy chcesz
  //     odpiąć Klienta?</center>`,
  //     buttons: [{
  //       text: "Tak",
  //       onClick: () => {
  //         this.deleteClientFromCustomer();
  //       }
  //     },
  //     {
  //       text: "Nie"
  //     }
  //     ]
  //   });

  //   this.showModalBoxConfirm.show().done();
  // }

  @ViewChild('tabPanel') tabPanel;
  onSelectionChangedTab = (e) => {
    if (this.tabPanel.selectedIndex == 1) {
      this.filterCriteria2 = 'Own';
    }

    if (this.tabPanel.selectedIndex == 0) {
      this.filterCriteria2 = '';
    }

    if (this.tabPanel.selectedIndex == 2) {
      this.filterCriteria2 = 'Lost';
    }
    this.myCustomerList = [];
    this.getCustomerList();
  };

  ngOnDestroy() {
    this.appServices.headers = this.appServices.headers.delete('DataRowCount');
    this.appServices.headers = this.appServices.headers.delete('FilterColumn');
    this.appServices.headers = this.appServices.headers.delete('FilterColumn2');
    this.appServices.headers =
      this.appServices.headers.delete('FilterCriteria');
    this.appServices.headers =
      this.appServices.headers.delete('FilterCriteria2');
  }

  unselectAll = () => {
    this.selectedRows = [];
  };

  scroll = (e): void => {
    this.appServices.headers = this.appServices.headers.set(
      'DataRowCount',
      String(this.myCustomerList.length - 1)
    );
    let count = this.myCustomerList.length;
    // if ((e.target as Element).scrollTop > ((e.target as Element).scrollHeight * 0.75)) {
    if (
      e.target['scrollingElement'].scrollTop + 3000 >
      e.target['scrollingElement'].scrollHeight
    ) {
      if (this.rowCount > this.myCustomerList.length) {
        this.appServices.headers = this.appServices.headers.set(
          'DataRowCount',
          (this.myCustomerList.length - 1).toString()
        );
        if (this.readData) this.getCustomerList();
      }
    }
  };

  ngModelChange = () => {
    if (this.filterCriteria === '') {
      this.appServices.headers = this.appServices.headers.set(
        'DataRowCount',
        '0'
      );
      this.getCustomerList();
    }
  };

  onSelectionChanged = (event) => {
    this.appServices.headers = this.appServices.headers.set(
      'DataRowCount',
      '0'
    );
    if (event.selectedItem.value === 'SubPartnerCompany') {
      this.filterCriteria2 = '';
      this.appServices.headers = this.appServices.headers.set(
        'FilterCriteria2',
        ''
      );
    }
  };

  getCustomerList() {
    let filterCriteria = this.filterCriteria;
    filterCriteria = filterCriteria.replace(/ó/g, '%');
    filterCriteria = filterCriteria.replace(/Ó/g, '%');

    filterCriteria = filterCriteria.replace(/ł/g, '%');
    filterCriteria = filterCriteria.replace(/Ł/g, '%');

    filterCriteria = filterCriteria.replace(/ę/g, '%');
    filterCriteria = filterCriteria.replace(/Ę/g, '%');

    filterCriteria = filterCriteria.replace(/ą/g, '%');
    filterCriteria = filterCriteria.replace(/Ą/g, '%');

    filterCriteria = filterCriteria.replace(/ś/g, '%');
    filterCriteria = filterCriteria.replace(/Ś/g, '%');

    filterCriteria = filterCriteria.replace(/ż/g, '%');
    filterCriteria = filterCriteria.replace(/Ż/g, '%');

    filterCriteria = filterCriteria.replace(/ź/g, '%');
    filterCriteria = filterCriteria.replace(/Ź/g, '%');

    filterCriteria = filterCriteria.replace(/ć/g, '%');
    filterCriteria = filterCriteria.replace(/Ć/g, '%');

    filterCriteria = filterCriteria.replace(/ń/g, '%');
    filterCriteria = filterCriteria.replace(/Ń/g, '%');

    this.readData = false;
    if (this.myCustomerList.length === 0)
      this.appServices.headers = this.appServices.headers.set(
        'DataRowCount',
        '0'
      );
    this.appServices.headers = this.appServices.headers.set(
      'FilterColumn',
      this.filterColumn
    );
    this.appServices.headers = this.appServices.headers.set(
      'FilterCriteria',
      filterCriteria
    );

    this.appServices.headers = this.appServices.headers.set(
      'FilterColumn2',
      this.filterColumn2
    );
    this.appServices.headers = this.appServices.headers.set(
      'FilterCriteria2',
      this.filterCriteria2
    );

    sessionStorage.setItem('FilterCriteriaMyCustomer', this.filterCriteria);
    sessionStorage.setItem('FilterColumnMyCustomer', this.filterColumn);
    sessionStorage.setItem('FilterCriteriaMyCustomer2', this.filterCriteria2);

    return this.appServices.getAuth('Customer/MyClients').subscribe(
      (response) => {
        if (response.clientList !== null) {
          response.clientList.forEach((element) => {
            this.myCustomerList.push(element);
          });
        }

        this.rowCount = response.rowCount;
        this.myCustomerList = this.event.removeDuplicates(
          this.myCustomerList,
          'vatNumber'
        );
        this.readData = true;

        if (this.myCustomerList.length <= 8)
          this.heightGrid = this.myCustomerList.length * 69;
        // if (window.innerWidth < 576) this.heightGrid = this.myCustomerList.length * 64.6;
        // else this.heightGrid = this.myCustomerList.length * 64.6;

        this.onHidden();
      },
      (error) => {
        this.onHidden();
        this.event.showNotification('error', JSON.parse(error).message);
      }
    );
  }

  onContentReady = (e) => {
    setTimeout(() => {
      let scroll = e.component.getScrollable();
      scroll.on('scroll', (es) => {
        if (es.scrollOffset.top + 600 > es.component.scrollHeight() * 0.8) {
          this.appServices.headers = this.appServices.headers.set(
            'DataRowCount',
            String(this.myCustomerList.length - 1)
          );
          let count = this.myCustomerList.length;

          if (this.rowCount > this.myCustomerList.length) {
            this.appServices.headers = this.appServices.headers.set(
              'DataRowCount',
              (this.myCustomerList.length - 1).toString()
            );
            if (this.readData) this.getCustomerList();
          }
        }
      });
    });
  };

  getSubPartners = () => {
    this.appServices.headers = this.appServices.headers.delete('DataRowCount');
    return this.appServices.getAuth(`Orders/GetSubPartners`).subscribe(
      (response) => {
        this.subpartnersList = response.partners;
        this.appServices.headers = this.appServices.headers.append(
          'DataRowCount',
          '0'
        );
      },
      (error) => {
        this.appServices.headers = this.appServices.headers.append(
          'DataRowCount',
          '0'
        );
      }
    );
  };

  addClientToCustomer = () => {
    let PartnerInfo = {
      rowNumber: null,
      partnerId: null,
      partnerName: null,
      city: null,
      www: null,
      vatNumber: null,
    };
    PartnerInfo.vatNumber =
      this.partnerId != null
        ? (PartnerInfo.vatNumber = this.partnerId)
        : (PartnerInfo.vatNumber = this.vatNumber);
    this.appServices.headers = this.appServices.headers.set(
      'DataRowCount',
      '0'
    );
    this.selectedRows.forEach((field) => {
      this.appServices
        .postAuth(
          `Customer/${field.customerId}/addClientToCustomer`,
          PartnerInfo
        )
        .subscribe(
          (response) => {
            if (response.vatNumber !== null) {
              this.event.showNotification(
                'success',
                `Pomyślnie odpięto Klienta ${field.company} do partnera ${response.vatNumber}`
              );
              this.getCustomerList();
            } else {
              this.event.showNotification(
                'error',
                `Nie udało sie przypiąć Klienta ${field.company} do partnera ${response.vatNumber}`
              );
            }
            this.vatNumber = null;
            this.partnerId = null;
            this.selectedRows = [];
            this.myCustomerList = [];
          },
          (error) => {
            this.event.showNotification(
              'error',
              `Nie udało się przypiąć klienta ${field.company} do partnera ${PartnerInfo.vatNumber}`
            );
            this.vatNumber = null;
            this.partnerId = null;
            this.selectedRows = [];
          }
        );
    });
  };

  deleteClientFromCustomer = () => {
    this.selectedRows.forEach((field) => {
      this.appServices
        .postAuth(`Customer/${field.customerId}/deleteClientFromCustomer`, null)
        .subscribe(
          (response) => {
            this.event.showNotification('success', 'Pomyślnie odpięto kienta');
            this.appServices.headers = this.appServices.headers.set(
              'DataRowCount',
              '0'
            );
            this.myCustomerList = [];
            this.getCustomerList();
          },
          (error) => {
            this.event.showNotification(
              'error',
              'Nie udało się odpiąć klienta'
            );
          }
        );
    });
  };

  onDetalisClick = (clientId) => {
    this._route.navigate(['moje-produkty/client/', clientId]);
  };

  updateAll = (clientId) => {
    this.isUpdate = true;
    this.appServices.getAuth(`MyProducts/${clientId}`).subscribe((response) => {
      let productToUpdate = response;
      //pobieram najnowsza możliwą wersje
      let actualProducts = JSON.parse(
        this.event.decryptString(localStorage.getItem('product-list-wapro-erp'))
      );
      productToUpdate.forEach((field) => {
        for (let i = 0; i < actualProducts.length; i++) {
          if (actualProducts[i].productId === field.productId) {
            field.toVersionId = actualProducts[i].versions[0].versionId;
            field.toVersionNumber = actualProducts[i].versions[0].versionNumber;
            field.toVariantName = field.variantName;
            field.toVariantId = field.variantId;
            field.unicate = field.licenseId;
            field.toPacketName = field.packetName;
            field.toPacketId = field.packetId;
            field.toNumberOfUsers = field.numberOfUsers;
            field.agreementCode = actualProducts[i].agreementCode;
            field.agreementDesc = actualProducts[i].agreementDesc;
            field.agreementUrl = actualProducts[i].agreementUrl;
            break;
          }
        }
      });

      let dateToCalculate = { myProductOrders: [] };
      let msgError = '';
      productToUpdate.forEach((field, index) => {
        if (field.allowModify) {
          dateToCalculate.myProductOrders.push(field);
        } else if (!field.allowModify)
          msgError += `Wybrano licencje ${field.productName} ${field.licenseNumber} której nie można modyfikować<br>`;
      });

      if (msgError !== '') this.event.showNotification('error', msgError);

      //sprawdzam czy w liscie do kalkulacji nie ma produktow o tych samych numerach licencji
      dateToCalculate.myProductOrders = this.event.removeDuplicates(
        dateToCalculate.myProductOrders,
        'licenseNumber'
      );

      if (dateToCalculate.myProductOrders.length > 0) {
        this.event.addToBasket(dateToCalculate);
      }

      this.isUpdate = false;
    });
  };

  onRowClick = (e) => {
    if (!this.showModalBoxConfirm && !this.showModalBox && !this.isUpdate) {
      this._route.navigate(['moje-produkty/client/', e.customerId]);
      sessionStorage.setItem('clientDate', e.company);
    }
  };
}
