import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Component({
  selector: 'app-my-client-product-list',
  templateUrl: './my-client-product-list.component.html',
  styleUrls: ['./my-client-product-list.component.scss'],
})
export class MyClientProductListComponent implements OnInit, OnDestroy {
  @ViewChildren('grid') grid;
  UserRole: string = '';

  myClientsProductList = [];
  selectedRows = [];
  loadingVisible = false;

  filterColumn: string = 'Company';
  filterCriteria: string = '';
  isFilterCriteria: boolean = false;

  dateToCalculate = {
    myProductOrders: [],
  };

  clientId;

  expendAll: boolean = false;

  rowCount;
  readData: boolean = true;
  customerId = 0;

  widthPopUp: number = 500;
  heightpopUp: number = 390;

  showCheckBoxesMode = 'always';

  filtrName = [
    { label: 'Nazwa', value: 'Company' },
    { label: 'NIP', value: 'VATNumber' },
    { label: 'Produkt', value: 'ProductName' },
    { label: 'Licencja', value: 'LicenseNumber' },
    { label: 'Wersja', value: 'VersionNumber' },
    { label: 'Wygasa (dni)', value: 'ExpireDate' },
    { label: 'Wygasłe (dni)', value: 'ExpiredDate' },
    { label: 'Licencja Online', value: 'IsOnline' },
  ];

  autoExpandAll = false;

  aligneLicense: boolean = false;
  dataToday;
  heightGrid = 0;

  maxDateOfExpiration: any = {
    description: '',
    licenseNumber: '',
    maxDateOfExpiration: '',
    dataForProduct: '',
  };

  groupProductClientList = [];
  isSkipLicenses: boolean = true;

  isMobile: boolean = false;

  showGroupPopUp: boolean = false;

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    this.scroll(e);
  }

  constructor(
    private appServices: AppServices,
    private event: EventService,
    private _route: Router
  ) {
    this.event.showNavbar.next(true);

    this.event.showNavbar.subscribe(() => {
      if (localStorage.getItem('wapro-erp-token'))
        this.UserRole = helper.decodeToken(
          localStorage.getItem('wapro-erp-token')
        ).UserRole;
    });

    this.appServices.headers = this.appServices.headers.append(
      'FilterCriteria',
      ''
    );
    this.appServices.headers = this.appServices.headers.append(
      'FilterColumn',
      ''
    );

    if (sessionStorage.getItem('FilterCriteriaClientProductsList')) {
      this.filterCriteria = sessionStorage.getItem(
        'FilterCriteriaClientProductsList'
      );
      this.appServices.headers = this.appServices.headers.set(
        'FilterCriteria',
        this.filterCriteria
      );
    }
    if (sessionStorage.getItem('FilterColumnClientProductsList')) {
      this.filterColumn = sessionStorage.getItem(
        'FilterColumnClientProductsList'
      );
      this.appServices.headers = this.appServices.headers.set(
        'FilterColumn',
        this.filterColumn
      );
    }

    this.appServices.headers = this.appServices.headers.append(
      'DataRowCount',
      '0'
    );
  }

  ngOnInit(): void {
    this.dataToday = new Date();
    this.onShown();
    this.search();

    if (window.innerWidth <= 500) {
      this.widthPopUp = 350;
      this.heightpopUp = 452;
      this.isMobile = true;
    } else this.isMobile = false;

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 500) {
        this.widthPopUp = 350;
        this.heightpopUp = 452;
        this.isMobile = true;
      } else {
        this.widthPopUp = 500;
        this.isMobile = false;
      }
    });
  }

  onAdaptiveDetailRowPreparing = (event, height) => {
    if (window.innerWidth < 1100) {
      height.height = height.list.length * 65.4;
      this.myClientsProductList.forEach((el) => {
        if (event.component.isAdaptiveDetailRowExpanded(el))
          height.height += 250;
      });
    }
  };

  groupClientList = () => {
    this.groupProductClientList = this.event.grouipngList(
      'customerName',
      this.myClientsProductList,
      true,
      65.4
    );
  };

  sortByData = (client) => {
    let list = client;

    list = list.sort((a, b) => {
      try {
        let dataA = a.expireDate;
        let dataB = b.expireDate;

        dataA = new Date(dataA).getTime();
        dataB = new Date(dataB).getTime();

        return dataA - dataB;
      } catch {}
    });

    return list;
  };

  selectedItemsChange = (event) => {
    try {
      this.groupProductClientList.forEach((field) => {
        field.selection = false;
      });
      event.forEach((field) => {
        field.selection = true;
      });
    } catch {}
  };

  ngOnDestroy() {
    this.appServices.headers = this.appServices.headers.delete('FilterColumn');
    this.appServices.headers =
      this.appServices.headers.delete('FilterCriteria');
    this.appServices.headers = this.appServices.headers.delete('DataRowCount');
    this.appServices.headers = this.appServices.headers.delete('lastId');
  }

  scroll = (e): void => {
    this.appServices.headers = this.appServices.headers.set(
      'DataRowCount',
      String(this.myClientsProductList.length - 1)
    );
    this.appServices.headers = this.appServices.headers.set(
      'lastId',
      String(this.myClientsProductList.length - 1)
    );
    let count = this.myClientsProductList.length;

    if (this.rowCount > this.myClientsProductList.length) {
      if (
        e.target['scrollingElement'].scrollTop + 2000 >
        e.target['scrollingElement'].scrollHeight
      ) {
        this.appServices.headers = this.appServices.headers.set(
          'DataRowCount',
          (this.myClientsProductList.length - 1).toString()
        );
        this.appServices.headers = this.appServices.headers.set(
          'lastId',
          String(this.myClientsProductList.length - 1)
        );
        if (this.readData) this.getMyClientProducts();
      }
    }
  };

  onAutoExpandAllChanged = () => {
    this.autoExpandAll = !this.autoExpandAll;
    this.groupProductClientList.forEach((product) => {
      product.selection = this.autoExpandAll;
    });
  };

  onValueChanged = (e) => {
    e.value ? this.selectAll() : this.unselectAll();
  };

  selectAll = () => {
    this.selectedRows = this.myClientsProductList;
    this.groupProductClientList.forEach((product) => {
      product.selection = true;
    });
    setTimeout(() => {
      this.grid.forEach((element) => {
        element.selectedRowKeys = element.dataSource;
      });
    }, 0);
  };

  onShown() {
    this.loadingVisible = true;
  }

  onHidden() {
    this.loadingVisible = false;
  }

  getMyClientProducts() {
    this.onShown();
    this.readData = false;
    //this.myClientsProductList=[];
    return this.appServices.getAuth('Customer/MyClientsProducts').subscribe(
      (response) => {
        this.rowCount = response.rowCount;
        if (response.myClientProducts != null) {
          response.myClientProducts.forEach((field) => {
            try {
              field.lineDescription = field.lineDescription.replace(
                /\\(.)/g,
                '$1'
              );
            } catch {}
            this.myClientsProductList.push(field);
          });
        }

        if (
          this.filterColumn == 'ExpireDate' ||
          this.filterColumn == 'ExpiredDate'
        ) {
          this.myClientsProductList = this.sortByData(
            this.myClientsProductList
          );
        }

        //pobieram najnowsza możliwą wersje
        let actualProducts = JSON.parse(
          this.event.decryptString(
            localStorage.getItem('product-list-wapro-erp')
          )
        );
        this.myClientsProductList.forEach((field) => {
          field.productName = field.productName.replace('WAPRO', '');
          for (let i = 0; i < actualProducts.length; i++) {
            if (actualProducts[i].productId === field.productId) {
              field.description = actualProducts[i].description;
              field.requiredProducts = actualProducts[i].requiredProducts;
              if (field.requiredProducts) {
                field.requiredProducts.forEach((element) => {
                  element.requiredProductName =
                    element.requiredProductName.replace('WAPRO', '');
                });
              }
              field.variantDescription = actualProducts[i].variantDescription;
              field.eduLicenseDescription =
                actualProducts[i].eduLicenseDescription;
              field.packetDescription = actualProducts[i].packetDescription;
              field.toVersionId = actualProducts[i].versions[0].versionId;
              field.toVersionNumber =
                actualProducts[i].versions[0].versionNumber;
              field.toVariantName = field.variantName;
              field.toVariantId = field.variantId;
              field.toPacketName = field.packetName;
              field.toPacketId = field.packetId;
              field.unicate = field.licenseId;
              field.toNumberOfUsers = field.numberOfUsers;
              field.agreementCode = actualProducts[i].agreementCode;
              field.agreementDesc = actualProducts[i].agreementDesc;
              field.agreementUrl = actualProducts[i].agreementUrl;
              field.dayToExpire =
                (new Date(field.expireDate).getTime() - new Date().getTime()) /
                (1000 * 60 * 60 * 24);
              break;
            }
          }
        });
        this.groupClientList();
        if (this.groupProductClientList.length > 0)
          this.groupProductClientList[0].selection = true;
        this.readData = true;
        this.onHidden();
      },
      (error) => {
        this.groupClientList();
        this.onHidden();
        this.event.showNotification('error', JSON.parse(error).message);
      }
    );
  }

  ngModelChange = () => {
    if (this.filterCriteria === '') {
      sessionStorage.setItem(
        'FilterCriteriaClientProductsList',
        this.filterCriteria
      );
      this.appServices.headers =
        this.appServices.headers.delete('FilterColumn');
      this.appServices.headers =
        this.appServices.headers.delete('FilterCriteria');
      this.appServices.headers = this.appServices.headers.set(
        'DataRowCount',
        '0'
      );
      this.getMyClientProducts();
    }
  };

  onCellPrepared = (e) => {
    try {
      let basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
      if (e.rowType == 'data') {
        basket.forEach((element) => {
          try {
            if (e.data.licenseNumber == element.licenseNumber.replace(/ /g, ''))
              e.cellElement.className += ' cls';
          } catch {}
        });
      }
    } catch {}
  };

  checkInBasket = (item) => {
    if (item == 'aktualizuj') {
      this.getMyClientProducts();
    } else {
      setTimeout(() => {
        item.push({ licenseNumber: null });
      }, 300);

      setTimeout(() => {
        item.splice(item.length - 1, 1);
      }, 400);
    }
  };

  search = () => {
    this.myClientsProductList = [];
    if (this.filterColumn === 'IsOnline') {
      this.appServices.headers = this.appServices.headers.set(
        'FilterCriteria',
        'true'
      );
    } else {
      let filtercriteria2 = this.filterCriteria;
      filtercriteria2 = filtercriteria2.replace(/ó/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ó/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ł/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ł/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ę/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ę/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ą/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ą/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ś/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ś/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ż/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ż/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ź/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ź/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ć/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ć/g, '%');

      filtercriteria2 = filtercriteria2.replace(/ń/g, '%');
      filtercriteria2 = filtercriteria2.replace(/Ń/g, '%');
      this.appServices.headers = this.appServices.headers.set(
        'FilterCriteria',
        filtercriteria2
      );
    }
    this.appServices.headers = this.appServices.headers.set(
      'FilterColumn',
      this.filterColumn
    );
    sessionStorage.setItem(
      'FilterCriteriaClientProductsList',
      this.filterCriteria
    );
    sessionStorage.setItem('FilterColumnClientProductsList', this.filterColumn);

    this.appServices.headers = this.appServices.headers.set(
      'DataRowCount',
      '0'
    );
    this.getMyClientProducts();
  };

  onSelectionChanged = (event) => {
    if (event.selectedItem.value === 'IsOnline') {
      this.filterCriteria = '';
      this.isFilterCriteria = true;
    } else if (
      event.selectedItem.value === 'ExpireDate' ||
      event.selectedItem.value === 'ExpiredDate'
    ) {
      this.filterCriteria = '60';
    } else this.isFilterCriteria = false;
  };

  onSelectionChangedGrid = (event) => {
    let selected = [];
    this.grid.forEach((element) => {
      element.selectedRowKeys.forEach((field) => {
        selected.push(field);
      });
    });
    this.selectedRows = selected;
  };

  unselectAll = () => {
    this.expendAll = false;
    this.selectedRows = [];
    this.grid.forEach((element) => {
      element.selectedRowKeys = [];
    });
  };

  updateSelected = (aligne?) => {
    //czyszcze tablice produktów do aktualizacji
    this.dateToCalculate.myProductOrders = [];

    let msgError = '';
    this.selectedRows.forEach((field, index) => {
      if (field.allowModify) {
        this.dateToCalculate.myProductOrders.push(field);
      } else if (!field.allowModify)
        msgError += `Wybrano licencje ${field.productName} ${field.licenseNumber} której nie można modyfikować<br>`;
    });

    if (msgError !== '') this.event.showNotification('error', msgError);

    if (this.dateToCalculate.myProductOrders.length > 0) {
      aligne
        ? this.event.addToBasket(this.dateToCalculate, true)
        : this.event.addToBasket(this.dateToCalculate);
    }
  };

  updateAll = (e) => {
    if (this.selectedRows.length == 0) {
      this.selectedRows = [];
      this.myClientsProductList.forEach((field) => {
        if (field.customerName === e && field.allowModify === true)
          this.selectedRows.push(field);
      });
    }

    this.updateSelected();
    this.selectedRows = [];
    this.grid.forEach((element) => {
      element.selectedRowKeys = [];
    });
  };

  //pobieram dane o dacie z jaka moga zostać wyrównane licencje
  aligneLicenseShow = (clientId) => {
    this.clientId = clientId;
    const index = this.myClientsProductList.findIndex(
      (product) => product.customerName === clientId
    );

    this.appServices
      .getAuth(
        `myproducts/GetMyProductsMaxExpirationInfo/${this.myClientsProductList[index].customerId}`
      )
      .subscribe(
        (response) => {
          this.maxDateOfExpiration = response;
          this.customerId = this.myClientsProductList[index].customerId;
          this.aligneLicense = true;

          let DAY_IN_MILIS = 1000 * 60 * 60 * 24;
          let d1 = new Date(response.maxDateOfExpiration).getTime();

          this.maxDateOfExpiration.dataForProduct = new Date(
            d1 - 90 * DAY_IN_MILIS
          );
        },
        (error) => {
          this.event.showNotification(
            'info',
            'Wyrównanie daty ważności licencji nie jest możliwe. Szczegółowe informacje do uzyskania w dziale handlowym WAPRO'
          );
        }
      );
  };

  //wyrównuje wszystkie możliwe licencje z wybrana datą
  aligneLicene = () => {
    let isAnotherClient: boolean = false;
    this.selectedRows = [];
    this.myClientsProductList.forEach((field) => {
      //sprawdzam czy w nazwie wariantu jest 365, czy produkt mozna modyfikowac i czy data wygaśnięcia jest wieksza lub równa dzisiejszej
      //sprawdzam czy w nazwie wariantu jest 365, czy produkt mozna modyfikowac i czy data wygaśnięcia jest wieksza lub równa dzisiejszej
      if (this.isSkipLicenses) {
        if (
          field.customerName === this.clientId &&
          field.lineDescription !== null &&
          field.lineDescription.indexOf('365') != -1 &&
          field.allowModify === true &&
          (field.expireDate === null ||
            new Date(field.expireDate).getTime() >= this.dataToday.getTime())
        ) {
          let data = { value: this.maxDateOfExpiration.dataForProduct };
          field.maxDateOfExpiration = this.event.dataFormatted(data);
          this.selectedRows.push(field);
        }
      } else {
        if (
          field.customerName === this.clientId &&
          field.lineDescription !== null &&
          field.lineDescription.indexOf('365') != -1 &&
          field.allowModify === true
        ) {
          let data = { value: this.maxDateOfExpiration.dataForProduct };
          field.maxDateOfExpiration = this.event.dataFormatted(data);
          this.selectedRows.push(field);
        }
      }
    });

    let basket = [];
    if (localStorage.getItem('basket-wapro-erp')) {
      basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
    }

    basket.forEach((element) => {
      if (element.customerId !== this.selectedRows[0].customerId)
        isAnotherClient = true;
    });

    if (isAnotherClient)
      this.event.showNotification(
        'info',
        'Nie można wyrównać daty wygaśnięcia licencji jeżeli w koszyku są już produkty'
      );
    else {
      this.updateSelected(true);
    }
    this.selectedRows = [];
    this.aligneLicense = false;
  };

  redirectToReports() {
    this._route.navigate(['raport-finansowy']);
  }

  navigateToSearchLicense = () => {
    this._route.navigate(['weryfikacja-licencji']);
  };
}
