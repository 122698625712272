<div *ngIf="isPayment; else shop">
  <div
    [ngClass]="{
      'payment-wapro':
        paymentSource.toLowerCase() == 'wapro' ||
        paymentSource.toLowerCase() == 'abs',
      'payment-asseco': paymentSource.toLowerCase() == 'asseco',
      'payment-softlab': paymentSource.toLowerCase() == 'softlab',
      'payment-macrologic': paymentSource.toLowerCase() == 'macrologic'
    }"
  >
    <div class="content-payment">
      <div class="container">
        <div class="logo-payment">
          <img
            [src]="
              width > 767
                ? '../assets/img/logo-wapro.svg'
                : '../assets/img/logo-wapro-mobile.svg'
            "
            alt="Asseco Business Solutions"
            *ngIf="
              paymentSource.toLowerCase() == 'wapro' ||
              paymentSource.toLowerCase() == 'abs'
            "
          />
          <img
            [src]="
              width > 767
                ? '../assets/img/logo-aseccobs.svg'
                : '../assets/img/logo-aseccobs-mobile.svg'
            "
            alt="Asseco Business Solutions"
            *ngIf="paymentSource.toLowerCase() == 'asseco'"
          />
          <img
            [src]="
              width > 767
                ? '../assets/img/logo-softlab.svg'
                : '../assets/img/logo-softlab-mobile.svg'
            "
            alt="Asseco Business Solutions"
            *ngIf="paymentSource.toLowerCase() == 'softlab'"
          />
          <img
            [src]="
              width > 767
                ? '../assets/img/logo-macrologic.svg'
                : '../assets/img/logo-macrologic-mobile.svg'
            "
            alt="Asseco Business Solutions"
            *ngIf="paymentSource.toLowerCase() == 'macrologic'"
          />
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>

    <footer class="payment-footer">
      <div class="payment-footer-header"></div>
      <div class="container">
        <div class="row">
          <div
            class="col-md-6 text-left mobile-footer"
            style="padding-left: 6px"
          >
            <p class="footer-copy-payment">
              All rights reserved to Asseco Business Solutions - {{ year }}
            </p>
          </div>
          <div
            class="col-md-6 text-right mobile-logo"
            style="padding-right: 25px"
          >
            <img
              src="../assets/img/abs_logo.svg"
              alt="Asseco Business Solutions"
              style="height: 25px"
            />
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>

<ng-template #shop>
  <!--The content below is only a placeholder and can be replaced.-->
  <app-menu></app-menu>
  <!-- [hidden]="hideNavbar" -->

  <div class="container" style="background: transparent; padding-top: 105px">
    <router-outlet></router-outlet>
  </div>

  <!-- <notifier-container>
      <ng-template #customNotification let-notificationData="notification">
        <my-custom-alert type="notificationData.type" [innerHTML]="notificationData.message"></my-custom-alert>
      </ng-template>
  </notifier-container> -->

  <app-footer [hidden]="hideFooter"></app-footer>
  <script
    src="https://www.czater.pl/assets/modules/chat/js/chat.js"
    async
  ></script>
</ng-template>

<notifier-container [ngClass]="{ 'payment-notifier-bg': isPayment }">
  <ng-template #customNotification let-notificationData="notification">
    <my-custom-alert
      type="notificationData.type"
      [innerHTML]="notificationData.message"
    ></my-custom-alert>
  </ng-template>
</notifier-container>

<!-- <notifier-container [ngClass]="{'payment-notifier-bg': isPayment}">
  <ng-template #customNotification let-notificationData="notification">
    <span type="notificationData.type">
      <div class="d-flex flex-wrap">
        <div [ngSwitch]="notificationData.type" class="notifier-data">
          <ng-container *ngSwitchCase="'success'">
            <img src="../assets/img/icon_ok.svg" alt="wapro-erp"
              class="icon-notification"></ng-container>
          <ng-container *ngSwitchCase="'error'">
            <img src="../assets/img/icon_alert.svg" alt="wapro-erp">
          </ng-container>
          <ng-container *ngSwitchCase="'info'">
            <img src="../assets/img/icon_info.svg" alt="wapro-erp"></ng-container>
        </div>
        <div [innerHTML]="notificationData.message" class="notifier-content" style="padding-top: 3px; width: 260px">
        </div>
      </div>
    </span>
  </ng-template>
</notifier-container> -->

<app-change-login-to-email
  [isVisible]="isLoginToEmail"
  [oldLogin]="oldLogin"
  (onClosing)="onCLoseEmailLogin()"
  *ngIf="isLoginToEmail"
></app-change-login-to-email>
