import { Component, HostListener, OnInit } from '@angular/core'
import { AppServices } from '../app-services.service'
import { EventService } from '../event.service'

@Component({
  selector: 'app-finanse-report',
  templateUrl: './finanse-report.component.html',
  styleUrls: ['./finanse-report.component.scss']
})
export class FinanseReportComponent implements OnInit {

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    this.scroll(e)
  }
  
  filtrName: any[] = [
    { label: 'Nazwa', value: 'Company' },
    { label: 'NIP', value: 'VATNumber' }
  ]
  filterColumn: string = 'Company'
  filterCriteria: string = ''

  isMobile: boolean = false
  autoExpandAll: boolean = false
  rowCount: number = 0
  groupedData: any = []
  rawData: any = []
  readData: boolean = false
  sumIncomeFromLicense: number = null
  sumValueOfLicense: number = null
  loadingVisible = false

  constructor(public event: EventService, private appServices: AppServices) {
    this.event.showNavbar.next(true)

    this.appServices.headers = this.appServices.headers.append('DataRowCount', '0')
    
    if (window.innerWidth <= 425) {
      this.isMobile = true;
    }
    else this.isMobile = false;

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 425) {
        this.isMobile = true;
      }
      else this.isMobile = false;
    });
  }

  ngOnInit(): void {
    this.onShown()
    this.search()
  }

  onAdaptiveDetailRowPreparing = (event, height) => {
    if (window.innerWidth < 1100) {
      height.height = height.list.length * 70; 
      this.groupedData.list.forEach(el => {
        if(event.component.isAdaptiveDetailRowExpanded(el)) height.height += 350;
      });
    }
  }

  onShown() {
    this.loadingVisible = true
  }

  onHidden() {
    this.loadingVisible = false
  }

  ngOnDestroy(): void{
    this.appServices.headers = this.appServices.headers.delete('DataRowCount')
  }

  groupClientList = () => {
    this.groupedData = this.event.grouipngList('customer', this.rawData, false, 78.2).sort((a,b)=>{
      return this.getMaxValueOfLicense(b.list) - this.getMaxValueOfLicense(a.list)
    })
    try{
      this.groupedData.forEach(data=>data.list = data.list.sort((a,b)=>{
      return b.valueOfLicense - a.valueOfLicense
    }))
    }
    catch{}
    
  }

  getMaxValueOfLicense(list: any[]): number{
    return Math.max(...list.map(item=>item.valueOfLicense))
  }

  getFinanseReports(){
    this.readData = false
    this.appServices.getAuth('customer/Reports/partnerReportMissingValues').subscribe(response => {
      this.rowCount = response.rowCount
      this.sumIncomeFromLicense = response.sumIncomeFromLicense
      this.sumValueOfLicense = response.sumValueOfLicense

      try{
        response.partnerReportMissingValueOfLicense = response.partnerReportMissingValueOfLicense.sort((a,b)=>a.valueOfLicense - b.valueOfLicense)
      }
      catch {}

      if (response.partnerReportMissingValueOfLicense != null) {
        response.partnerReportMissingValueOfLicense.forEach(field => {
          field.productName = field.productName.replace("WAPRO","")
          this.rawData.push(field)
        })
      }

      this.groupClientList()

      if (this.groupedData.length > 0) this.groupedData[0].selection = true

      this.readData = true
      this.onHidden()
    },
      error => {
        this.onHidden()
      })
  }

  onAutoExpandAllChanged = () => {
    this.autoExpandAll = !this.autoExpandAll
    this.groupedData.forEach(item => {
      item.selection = this.autoExpandAll
    })
  }

  scroll = (e): void => {
    this.appServices.headers = this.appServices.headers.set('DataRowCount', String(this.groupedData.length - 1))

    if (this.rowCount > this.rawData.length) {
      if (e.target['scrollingElement'].scrollTop > (e.target['scrollingElement'].scrollHeight * 0.50)) {
        this.appServices.headers = this.appServices.headers.set('DataRowCount', (this.rawData.length - 1).toString())
        if (this.readData) this.getFinanseReports()
      }
    }
  }

  search = () => {
    this.rawData = []
    if (this.filterColumn === 'IsOnline') {
      this.appServices.headers = this.appServices.headers.set('FilterCriteria', 'true')
    } else {
      this.appServices.headers = this.appServices.headers.set('FilterCriteria', this.filterCriteria)
    }
    this.appServices.headers = this.appServices.headers.set('FilterColumn', this.filterColumn)
    sessionStorage.setItem('FilterCriteriaClientFinanseReports', this.filterCriteria)
    sessionStorage.setItem('FilterColumnClientFinanseReports', this.filterColumn)

    this.appServices.headers = this.appServices.headers.set('DataRowCount', '0')
    this.getFinanseReports()
  }

  ngModelChange = () => {
    if (this.filterCriteria === '') {
      sessionStorage.setItem('FilterCriteriaClientFinanseReports', this.filterCriteria)
      this.appServices.headers = this.appServices.headers.delete('FilterColumn')
      this.appServices.headers = this.appServices.headers.delete('FilterCriteria')
      this.appServices.headers = this.appServices.headers.set('DataRowCount', '0')
      this.getFinanseReports()
    }
  }

}
