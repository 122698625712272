<div id="employee" *ngIf="loadingVisible"></div>
<div class="row">
  <div class="col-md-12" style="padding-bottom: 39px">
    <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->

    <h1>Zarządzaj produktami<br /><strong>swoich klientów</strong></h1>
  </div>
</div>

<div class="row panel-filtr-wapro-erp" style="margin-bottom: -32px">
  <div class="col-lg-7 col-md-12 col-sm-12 col-12">
    <div class="flex-container">
      <div>
        <dx-text-box
          type="search"
          class="dx-filter"
          valueChangeEvent="keyup"
          id="searchText"
          placeholder="Wpisz szukaną frazę"
          [(ngModel)]="filterCriteria"
          [showClearButton]="true"
          (ngModelChange)="ngModelChange()"
          [disabled]="isFilterCriteria"
          (keydown.enter)="search()"
        >
        </dx-text-box>
        <i
          class="icon icon-search d-none d-none-pc"
          aria-hidden="true"
          title="Filtrowanie oraz odświeżanie listy"
          (click)="search()"
          style="cursor: pointer"
        ></i>
      </div>
      <div>
        <dx-select-box
          class="dx-filtr-input"
          [dataSource]="filtrName"
          displayExpr="label"
          valueExpr="value"
          [dropDownOptions]="{ closeOnTargetScroll: false }"
          placeholder="Nazwa"
          id="filtrName"
          [(ngModel)]="filterColumn"
          (onSelectionChanged)="onSelectionChanged($event)"
        >
        </dx-select-box>
        <i
          class="icon icon-search d-none-mobile"
          aria-hidden="true"
          id="refreshData"
          title="Filtrowanie oraz odświeżanie listy"
          (click)="search()"
          style="cursor: pointer"
        ></i>
      </div>
    </div>
  </div>

  <div
    class="col-lg-5 col-md-12 col-sm-12 col-12 filer-icon-aligne"
    *ngIf="!isMobile"
  >
    <dx-button
      class="icon-border icon-header"
      (onClick)="navigateToSearchLicense()"
    >
      <i
        class="icon search"
        aria-hidden="true"
        title=" Wyszukaj licencję, której nie ma na liście"
      ></i>
    </dx-button>

    <dx-button class="red-circle" (onClick)="redirectToReports()">
      <i
        class="icon icon-raport"
        aria-hidden="true"
        title="Zobacz ile straciłeś"
      ></i>
    </dx-button>
    <dx-button
      class="icon-border icon-header"
      (onClick)="unselectAll()"
      [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
      [disabled]="selectedRows.length === 0"
    >
      <i
        class="icon absui-icon--variables"
        aria-hidden="true"
        title="Odznacz"
      ></i>
    </dx-button>
    <dx-button
      class="icon-border icon-header"
      (onClick)="updateSelected(); checkInBasket('aktualizuj')"
      [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
      [disabled]="selectedRows.length === 0"
    >
      <i class="icon icon-refresh" aria-hidden="true" title="Aktualizuj"></i>
    </dx-button>
    <!-- <dx-button class="icon-border icon-header box-shadow" (onClick)="onAutoExpandAllChanged()">
            <i class="icon icon--keyboard-arrow-right" aria-hidden="true" title="Rozwiń/Zwiń wszystkie"></i>
        </dx-button> -->

    <dx-button
      class="icon-border icon-header box-shadow"
      (onClick)="onAutoExpandAllChanged()"
      [ngClass]="{ 'red-circle': autoExpandAll, 'icon-border': !autoExpandAll }"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-more': autoExpandAll,
          'icon--keyboard-arrow-rightt': !autoExpandAll
        }"
        aria-hidden="true"
        title="Rozwiń/zwiń wszystkie"
      ></i>
    </dx-button>
  </div>
</div>

<div class="row check-marg">
  <div class="col-md-6 col-sm-12" style="height: 20px">
    <dx-check-box
      text="Zaznacz wszystkie"
      class="check-all"
      style="width: 100%"
      (onValueChanged)="onValueChanged($event)"
      [(value)]="expendAll"
    ></dx-check-box>
  </div>

  <div class="col-md-6 col-sm-12 text-right" *ngIf="isMobile">
    <dx-button
      class="icon-border icon-header"
      style="position: relative; top: -30px"
      (onClick)="showGroupPopUp = !showGroupPopUp"
    >
      <i
        class="icon absui-icon--more-horiz"
        aria-hidden="true"
        title="Odznacz"
      ></i>
    </dx-button>
  </div>
</div>

<div class="row" *ngIf="groupProductClientList.length !== 0; else noData"></div>
<div
  class="about-license-wapro-erp mobile-bottom"
  *ngFor="let item of groupProductClientList"
>
  <div class="row" id="gridContainer">
    <div class="col-md-12" (click)="item.selection = !item.selection">
      <div class="row" style="box-sizing: border-box">
        <div class="col-12 col-md-6" style="margin-bottom: 0px">
          <p class="p-client-name-wapro-erp">{{ item.name }}</p>
          <p style="margin-top: -20px; font-weight: bold" *ngIf="item.list[0]">
            NIP: {{ item.list[0].customerVATNumber }}
          </p>
        </div>

        <div
          class="col-12 col-md-6 text-right"
          style="padding-right: 10px; margin-bottom: 0px !important"
        >
          <dx-button
            class="icon-border"
            (onClick)="
              $event.event.stopPropagation(); aligneLicenseShow(item.name)
            "
          >
            <i
              class="icon icon-calendar"
              aria-hidden="true"
              title="Wyrównaj datę wygaśnięcia licencji"
            ></i>
          </dx-button>

          <dx-button
            class="icon-border"
            (onClick)="
              $event.event.stopPropagation();
              updateAll(item.name);
              checkInBasket(item.list)
            "
          >
            <i
              class="icon icon-refresh"
              aria-hidden="true"
              title="Aktualizuj wszystkie"
            ></i>
          </dx-button>

          <dx-button
            [ngClass]="{
              'red-circle': item.selection,
              'icon-border': !item.selection
            }"
            style="opacity: 1"
          >
            <i
              class="icon"
              [ngClass]="{
                'absui-icon--expand-more': item.selection,
                'icon--keyboard-arrow-rightt': !item.selection
              }"
              aria-hidden="true"
            ></i>
          </dx-button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" *ngIf="item.selection">
      <dx-data-grid
        [dataSource]="item.list"
        [allowColumnReordering]="false"
        [hidden]="!item.selection"
        wordWrapEnabled="true"
        [showBorders]="true"
        [(selectedRowKeys)]="item.selectedRows"
        (onSelectionChanged)="onSelectionChangedGrid($event)"
        style="width: 100%; min-height: 0px !important"
        [showRowLines]="true"
        [showBorders]="false"
        [showColumnLines]="false"
        [hoverStateEnabled]="true"
        #grid
        [height]="item.height + 20"
        (onCellClick)="onAdaptiveDetailRowPreparing($event, item)"
        (onCellPrepared)="onCellPrepared($event)"
      >
        <dxi-column
          dataField="productName"
          caption="Produkt / Wariant"
          cellTemplate="productNameCell"
          [allowSorting]="false"
        ></dxi-column>
        <dxi-column
          dataField="licenseNumber"
          caption="Licencja / Wersja"
          cellTemplate="licenseNumberCell"
          width="110"
          [allowSorting]="false"
          [hidingPriority]="5"
        ></dxi-column>
        <dxi-column
          dataField="firstActivationDate"
          caption="Data pierwszej aktywacji"
          dataType="date"
          [hidingPriority]="2"
          format="yyyy-MM-dd"
          width="160"
          [allowSorting]="false"
          alignment="left"
          cellTemplate="firstDateCell"
        >
        </dxi-column>
        <dxi-column
          cellTemplate="expireDateCell"
          caption="Data wygaśnięcia"
          dataType="date"
          [hidingPriority]="3"
          format="yyyy-MM-dd"
          width="160"
          [allowSorting]="true"
          alignment="left"
        >
        </dxi-column>
        <dxi-column
          dataField="numberOfUsers"
          caption="Stanowiska"
          [allowSorting]="false"
          width="110"
          alignment="left"
          [hidingPriority]="1"
          cellTemplate="numberOfUsersCell"
        ></dxi-column>
        <dxi-column
          dataField="isOnline"
          caption="WO"
          [allowSorting]="false"
          cellTemplate="WOCell"
          alignment="left"
          [hidingPriority]="0"
          width="45"
          headerCellTemplate="headerWO"
        >
        </dxi-column>
        <dxi-column
          caption=""
          [allowSorting]="false"
          cellTemplate="iconCell"
          alignment="right"
          width="180"
          [hidingPriority]="4"
        >
        </dxi-column>

        <dxo-selection
          selectAllMode="allPages"
          [showCheckBoxesMode]="showCheckBoxesMode"
          mode="multiple"
        >
        </dxo-selection>

        <dxo-scrolling
          mode="virtual"
          showScrollbar="never"
          [useNative]="false"
        ></dxo-scrolling>
        <dxo-sorting mode="none"></dxo-sorting>

        <dxo-grouping [autoExpandAll]="autoExpandAll"></dxo-grouping>

        <div *dxTemplate="let data of 'headerWO'">
          <div id="headerWO{{ i }}" style="cursor: pointer">WO</div>
          <dx-tooltip
            target="#headerWO{{ i }}"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="{ name: 'mouseover', delay: 500 }"
          >
            <div
              *dxTemplate="let data = data; of: 'content'"
              class="tooltipContent"
            >
              Informacja czy licencja dotyczy usługi Wapro Online
            </div>
          </dx-tooltip>
        </div>

        <div *dxTemplate="let data of 'productNameCell'">
          <p class="p-product-name-wapro-erp">
            WAPRO <strong>{{ data.data.productName }}</strong>
          </p>
          <p class="p-lineDescription-wapro-erp">
            {{ data.data.lineDescription }}
          </p>
        </div>

        <div *dxTemplate="let data of 'firstDateCell'">
          <p class="p-row-wapro-erp">
            {{ data.data.firstActivationDate | date : "yyyy-MM-dd" }}
          </p>
        </div>

        <div *dxTemplate="let data of 'expireDateCell'">
          <p
            class="p-row-wapro-erp"
            [ngClass]="{
              'p-gray-wapro-erp': data.data.dayToExpire < 0,
              'p-orange-wapro-erp':
                data.data.dayToExpire < 60 && data.data.dayToExpire >= 30,
              'p-red-wapro-erp':
                data.data.dayToExpire < 30 && data.data.dayToExpire > 0
            }"
          >
            {{ data.data.expireDate | date : "yyyy-MM-dd" }}
          </p>
        </div>

        <div *dxTemplate="let data of 'licenseNumberCell'">
          <p class="p-number-license-wapro-erp">
            {{ data.data.licenseNumber }}
          </p>
          <p class="p-lineDescription-wapro-erp">
            {{ data.data.versionNumber }}
          </p>
        </div>

        <div *dxTemplate="let data of 'WOCell'">
          <i
            class="fa fa-globe"
            aria-hidden="true"
            [hidden]="!data.data.isOnline"
          ></i>
        </div>

        <div *dxTemplate="let data of 'numberOfUsersCell'">
          <p class="p-row-wapro-erp">{{ data.data.numberOfUsers }}</p>
        </div>

        <div
          *dxTemplate="let data of 'iconCell'"
          class="text-right-mobile-products"
        >
          <dx-button
            class="small-border-row d-none-products"
            (onClick)="event.onHistoryClick(data.data)"
            style="margin-right: 2px"
          >
            <i
              class="icon icon-history"
              aria-hidden="true"
              title="Historia"
            ></i>
            <span>Historia</span>
          </dx-button>

          <dx-button
            class="icon-border-row-table d-none-mobile-products"
            (onClick)="event.onHistoryClick(data.data)"
            [disabled]="!data.data.allowModify"
          >
            <i
              class="icon icon-history icon-row"
              aria-hidden="true"
              title="Historia"
            ></i>
          </dx-button>

          <dx-button
            class="small-border-row d-none-products"
            [disabled]="data.data.operatingSystem === 'Service'"
            style="margin-right: 2px"
            (onClick)="event.downloadCertyfikat(data.data.licenseId)"
          >
            <i
              class="icon icon-certyfikate icon-row"
              aria-hidden="true"
              title="Pobierz certyfikat"
            ></i>
            <span>Certyfikat</span>
          </dx-button>

          <dx-button
            class="icon-border-row-table d-none-mobile-products"
            [disabled]="data.data.operatingSystem === 'Service'"
            (onClick)="event.downloadCertyfikat(data.data.licenseId)"
          >
            <i
              class="icon icon-certyfikate icon-row"
              aria-hidden="true"
              title="Pobierz certyfikat"
            ></i>
          </dx-button>

          <dx-button
            class="icon-border-row-table"
            (onClick)="event.onModifyClick(data.data)"
            [disabled]="
              !data.data.allowModify || event.eventMSG.typeOfLock === 3
            "
          >
            <i
              class="icon icon-pencile icon-row"
              aria-hidden="true"
              title="Modyfikuj/Aktualizuj"
            ></i>
          </dx-button>
        </div> </dx-data-grid
      ><br /><br />
    </div>
  </div>
</div>

<!-- <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
>
</dx-load-panel> -->

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>

<dx-popup
  [width]="widthPopUp"
  [height]="heightpopUp"
  [showTitle]="true"
  title="Licencje"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="aligneLicense"
  class="my-client-product-list-popup"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12 text-left">
        <p class="popup-text">{{ maxDateOfExpiration.description }}</p>
        <p class="popup-text-information">
          Proszę wybrać datę do której zostanie wyrównana licencja
        </p>
        <div class="row">
          <div class="col-md-6 col-12">
            <label>Data wyrównania</label>
            <dx-date-box
              placeholder=""
              [useMaskBehavior]="true"
              displayFormat="yyyy-MM-dd"
              type="date"
              [max]="maxDateOfExpiration.maxDateOfExpiration"
              [min]="dataToday"
              [(value)]="maxDateOfExpiration.dataForProduct"
              class="no-box-shadow"
              style="height: 40px"
              dateOutOfRangeMessage="Data poza dopuszczalnym zakresem"
            >
            </dx-date-box>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <dx-check-box
              [(ngModel)]="isSkipLicenses"
              class="text-box no-box-shadow"
              style="height: 20px"
              text="Pomijaj licencje, które wygasły"
            >
            </dx-check-box>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dx-button
              class="btn-danger"
              text="Aktualizuj"
              [disabled]="maxDateOfExpiration.dataForProduct === ''"
              (onClick)="aligneLicene()"
              style="
                float: right;
                width: 130px;
                height: 40px;
                color: #ffffff;
                margin-right: 10px;
                margin-top: 20px;
                margin-bottom: 15px;
              "
            >
            </dx-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="345"
  [height]="297"
  [showTitle]="true"
  title="Akcje"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showGroupPopUp"
>
  <div *dxTemplate="let data of 'content'">
    <div class="mobile-toolbar">
      <dx-button class="btn-mobile" (onClick)="navigateToSearchLicense()">
        <i
          class="icon search"
          aria-hidden="true"
          title="Wyszukaj licencję"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >Wyszukaj licencję
      </dx-button>
      <dx-button class="btn-mobile" (onClick)="redirectToReports()">
        <i
          class="icon icon-raport icon-raport2"
          aria-hidden="true"
          title="Zobacz ile straciłeś"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >Zobacz ile straciłeś
      </dx-button>
      <dx-button
        [ngClass]="{
          'btn-mobile': !autoExpandAll,
          'btn-mobile-red': autoExpandAll
        }"
        (onClick)="showGroupPopUp = false; onAutoExpandAllChanged()"
      >
        <i
          class="icon"
          [ngClass]="{
            'icon--keyboard-arrow-right-mobile': !autoExpandAll,
            'absui-icon--expand-more-mobile': autoExpandAll
          }"
          aria-hidden="true"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >{{ autoExpandAll ? "Zwiń" : "Rozwiń wszystkie" }}
      </dx-button>

      <dx-button
        class="btn-mobile"
        [disabled]="selectedRows.length === 0"
        (onClick)="showGroupPopUp = false; updateSelected()"
      >
        <i
          class="icon icon-refresh"
          aria-hidden="true"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >Aktualizuj
      </dx-button>
      <dx-button
        class="btn-mobile"
        [disabled]="selectedRows.length === 0"
        (onClick)="showGroupPopUp = false; unselectAll()"
      >
        <i
          class="icon icon-pencile"
          aria-hidden="true"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >Odznacz
      </dx-button>
    </div>
  </div>
</dx-popup>
