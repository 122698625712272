<div class="panel-log-wapro-erp" style="margin-bottom: 50px;">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
            <h1><strong>Ustaw nowe<br>hasło</strong></h1>
        </div>
    </div>
</div>

<ng-container *ngIf="requestIsTrue === false; else newPsw">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
            <div class="d-flex flex-wraper">
                <div>
                    <img src="../../assets/img/icon_alert_black.svg" alt="wapro-erp" style="margin-top: 7px;">
                </div>
                <div style="margin-left: 18px;">
                    <p class="p-error-validation">Żądanie zmiany hasła wygasło, zostało już
                        użyte<br>lub jest błędne. <strong>Wygeneruj ponownie prośbę<br>o zmianę hasła</strong></p>
                </div>
            </div>
        </div>
    </div>

</ng-container>

<ng-template #newPsw>
    <div class="panel-log-wapro-erp">
        <div class="row">
            <div class="col-md-6 col-12">
                <form [formGroup]="newPswForm" class="form-log">
                    <div class="form-group">
                        <label for="password">Nowe hasło <strong>*</strong></label>
                        <dx-text-box name="password" placeholder="" id="password" formControlName="newPassword"
                            [inputAttr]="{ autocomplete: 'off' }" [mode]="modePsw" height="45"
                            [ngClass]="{ 'input-required': newPswForm.controls.newPassword.touched && formError.newPassword}">
                        </dx-text-box>
                        <i class="o-button--inInput"
                            (click)="(modePsw == 'password')?modePsw='text':modePsw='password'">
                            <i class="icon icon-eye" [hidden]="modePsw != 'password'"></i>
                            <i class="icon icon-eye-slash" [hidden]="modePsw == 'password'"></i>
                        </i>
                        <div style="margin-left: 13px; margin-top: -10px;">
                            <small class="text-danger"
                                *ngIf="newPswForm.controls.newPassword.touched && formError.newPassword">
                                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i znak specjalny
                            </small>
                        </div>

                    </div>


                    <div class="form-group">
                        <label for="password">Powtórz nowe hasło <strong>*</strong></label>
                        <dx-text-box name="password" placeholder="" id="password" formControlName="repeatNewPsw"
                            [inputAttr]="{ autocomplete: 'off' }" [mode]="modePswRepeat" height="45"
                            [ngClass]="{ 'input-required': newPswForm.controls.newPassword.touched && formError.repeatNewPsw}">
                        </dx-text-box>
                        <i class="o-button--inInput"
                            (click)="(modePswRepeat == 'password')?modePswRepeat='text':modePswRepeat='password'">
                            <i class="icon icon-eye" [hidden]="modePswRepeat != 'password'"></i>
                            <i class="icon icon-eye-slash" [hidden]="modePswRepeat == 'password'"></i>
                        </i>
                        <div style="margin-left: 13px; margin-top: -10px;">
                            <small class="text-danger"
                                *ngIf="newPswForm.controls.newPassword.touched && formError.repeatNewPsw">
                                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i znak specjalny
                            </small>
                        </div>
                    </div>


                    <dx-button class="btn-danger btn-log-wapro-erp" text="Zapisz"
                        (onClick)="onSubmit(newPswForm.value)">
                    </dx-button>

                </form>
            </div>
        </div>
    </div>
</ng-template>