<div class="row">
    <div class="col-md-12" style="padding-bottom: 39px;">
        <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->

        <h1>Wyszukaj licencję</h1>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <label>Numer licencji</label>
        <dx-text-box height="40" style="max-width: 350px;" [(ngModel)]="licenseNumber"></dx-text-box>
    </div>
    <div class="col-md-3">
        <label>Nip</label>
        <dx-text-box height="40" [maxLength]="15" style="max-width: 350px;" [(ngModel)]="customerVatNumber">
        </dx-text-box>
    </div>
    <div class="col-md-3">
        <dx-button class="icon-border icon-header" style="margin-top: 22px;" (onClick)="licenseList = []; getLicenseList()">
            <i class="icon search" aria-hidden="true" title="Szukaj"></i>
        </dx-button>
    </div>

</div>

<br>

<div class="about-license-wapro-erp mobile-bottom">
    <div class="row">
        <div class="col-md-12">
            <dx-data-grid [dataSource]="licenseList" [allowColumnReordering]="false" [showBorders]="true"
                [columnAutoWidth]="true" wordWrapEnabled="true" #grid [showBorders]="false" [showColumnLines]="false"
                [hoverStateEnabled]="true" [showRowLines]='true' [height]="heightGrid"
                (onCellClick)="onAdaptiveDetailRowPreparing($event)"  *ngIf="licenseList.length !== 0; else noData">

                <dxi-column dataField="customerName" caption="Nazwa użytkownika" [allowSorting]="false"
                    alignment="left">
                </dxi-column>

                <dxi-column caption="Produkt / Wariant" width="160" [allowSorting]="false" [hidingPriority]="2"
                    alignment="left" cellTemplate="productNameCell"></dxi-column>
                <dxi-column caption="Licencja / Wersja" [allowSorting]="false" [hidingPriority]="2" width="150"
                    alignment="left" cellTemplate="licenseNumberCell">
                </dxi-column>

                <dxi-column dataField="numberOfUsers" caption="Liczba stanowisk" [allowSorting]="false" alignment="left"
                    width="110" [hidingPriority]="1">
                </dxi-column>
                <dxi-column caption="" [hidingPriority]="0" width="50" [allowSorting]="false" alignment="left"
                    cellTemplate="iconCell">
                </dxi-column>

                <div *dxTemplate="let data of 'productNameCell'">
                    <p class="p-product-name-wapro-erp">WAPRO <strong>{{data.data.productName}}</strong></p>
                    <p class="p-lineDescription-wapro-erp">{{data.data.lineDescription}}</p>
                </div>

                <div *dxTemplate="let data of 'licenseNumberCell'">
                    <p class="p-number-license-wapro-erp">{{data.data.licenseNumber}}</p>
                    <p class="p-lineDescription-wapro-erp">{{data.data.versionNumber}}</p>
                </div>

                <div *dxTemplate="let data of 'iconCell'" class="text-right-mobile margin-top">
                    <!-- <dx-button class="small-border-row d-none-products" (onClick)="event.onHistoryClick(data.data)"
                        style="margin-right: 2px;">
                        <i class="icon icon-history" aria-hidden="true" title="Historia"></i>
                        <span>Historia</span>
                    </dx-button>

                    <dx-button class="icon-border-row-table d-none-mobile-products"
                        (onClick)="event.onHistoryClick(data.data)">
                        <i class="icon icon-history icon-row" aria-hidden="true" title="Historia"></i>
                    </dx-button> -->


                    <dx-button class="icon-border-row-table" (onClick)="event.onModifyClick(data.data)"
                        [disabled]="!data.data.allowModify">
                        <i class="icon icon-pencile icon-row" aria-hidden="true" title="Modyfikuj/Aktualizuj"></i>
                    </dx-button>
                </div>

                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-sorting mode="none"></dxo-sorting>
            </dx-data-grid>
        </div>
    </div>
</div>


<ng-template #noData>
    <div class="col-md-12 text-center">
        <app-no-data></app-no-data>
    </div>
</ng-template>