import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { EventService } from './event.service';

import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate() {
    if (
      window.localStorage.getItem('wapro-erp-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('wapro-erp-token')).exp * 1000
    ) {
      return true;
    }
    this.event.showNavbar.next(false);
    this.event.baskedProduct.next([]);
    this.event.baskedShop.next(null);
    this._router.navigate(['logowanie']);
    return false;
  }
}

@Injectable()
export class canActivateService implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}
  canActivate() {
    if (this.event.eventMSG.typeOfLock === 1) {
      this._router.navigate(['prace-serwisowe']);
      return false;
    }

    return true;
  }
}

@Injectable()
export class badRolaUse implements CanActivate {
  UserRole: string = '';

  constructor(private event: EventService, private _router: Router) {}
  canActivate() {
    if (localStorage.getItem('wapro-erp-token')) {
      this.UserRole = helper.decodeToken(
        localStorage.getItem('wapro-erp-token')
      ).UserRole;
      if (this.UserRole === 'partner' || this.UserRole === 'subpartner') {
        return true;
      } else {
        this._router.navigate(['brak-dostepu']);
        return false;
      }
    } else {
      this._router.navigate(['brak-dostepu']);
      return false;
    }
  }
}

@Injectable()
export class isLog implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate() {
    if (
      window.localStorage.getItem('wapro-erp-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('wapro-erp-token')).exp * 1000
    ) {
      this._router.navigate(['oferta']);
      return false;
    }
    return true;
  }
}
