import {
  Component,
  Injector,
  Input,
  Type,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
  ElementRef,
  ComponentRef,
  isDevMode,
  OnInit,
} from '@angular/core';

import { ChangePswComponent } from '../my-data/change-psw/change-psw.component';
import { AddressesComponent } from '../my-data/addresses/addresses.component';
import { CodesComponent } from '../my-data/codes/codes.component';
import { ContactComponent } from '../my-data/contact/contact.component';
import { RegisterComponent } from '../register/register.component';
import { NewUserComponent } from '../new-user/new-user.component';

@Component({
  selector: 'app-dynamic-component',
  // entryComponents: [
  //   ChangePswComponent,
  //   AddressesComponent,
  //   CodesComponent,
  //   ContactComponent,
  //   RegisterComponent,
  //   NewUserComponent,
  // ],
  templateUrl: './dynamic-component.component.html',
  styleUrls: ['./dynamic-component.component.scss'],
})
export class DynamicComponentComponent implements OnInit {
  @Input() componentData;

  @ViewChild('dynamicComponentContainer', {
    static: true,
    read: ViewContainerRef,
  })
  dynamicComponentContainer;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.componentData
    );
    const componentRef = this.dynamicComponentContainer.createComponent(
      factory,
      0
    );
  }
}
