import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { FormBuilder, Validators } from '@angular/forms';

import { checkPswIdentical } from '../my-data/change-psw/validators';

import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  submitted: boolean = false;
  registerForm;
  rules: any = { "X": /[0-9]{2}-[0-9]{3}/ };

  modePsw: string = 'password';
  modePswRepeat: string = 'password';

  constructor(private event: EventService, public formBuilder: FormBuilder, private appService: AppServices, public _route: Router) {

  }

  ngOnInit(): void {
    this.onCreateForm();
  }

  resolved(captchaResponse: string) {
    this.registerForm.controls.recaptchaReactive.setValue(captchaResponse);
  }

  onBlur = () => {
    if (this.registerForm.get('invoiceAddress').get('vatNumber').value.length >= 10) this.getDateFromGus(this.registerForm.get('invoiceAddress').get('vatNumber').value, 'invoiceAddress');
  }

  clearValue = (controls) => {
    if (controls === 'repeatNewPsw') this.registerForm.controls.credentialsData.controls.repeatNewPsw.setValue('');
    else this.registerForm.controls.credentialsData.controls.password.setValue('');
  }

  onCreateForm = () => {
    this.registerForm = this.formBuilder.group({
      credentialsData: this.formBuilder.group({
        login: ['', [Validators.required, Validators.email, Validators.min(3)]],
        password: ['', [Validators.required, Validators.pattern(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])([a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,})/)]],
        repeatNewPsw: ['', [Validators.required, checkPswIdentical("password")]]
      }),
      invoiceAddress: this.formBuilder.group({
        company: ['', [Validators.required]],
        name: [''],
        street: ['', [Validators.required]],
        postalCode: ['', [Validators.required, Validators.pattern(/[0-9]{2}-[0-9]{3}/)]],
        city: ['', [Validators.required]],
        phone: ['', [Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/)]],
        email: ['', [Validators.email]],
        vatNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]]
      }),
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/)]],
      marketingAgreement: [false],
      marketingEmail: ['', [Validators.email]],
      recaptchaReactive: [null, Validators.required],
      regulaminAgreement: [false, Validators.requiredTrue],
      przetwarzanieAgreement: [false, Validators.requiredTrue]
    })
  }

  getDateFromGus = (nip, adress) => {
    if (nip === null || nip === '') this.event.showNotification('info', "Podaj nip");
    else {
      this.appService.get(`Customer/InvoiceAddress/${nip}`).subscribe(response => {
        response = JSON.stringify(response).replace(/\\(.)/g, '$1');
        response = JSON.parse(response);
        this.registerForm.get(adress).patchValue(response)
      },
        error => {
          this.event.showNotification('error', 'Błąd pobierania danych z GUS')
        });
    }
  }

  securityPolicy = () => {
    window.open('https://wapro.pl/klauzula-rodo/', '_blank');
  }

  onSubmit = (values) => {
    this.registerForm.get("invoiceAddress").controls.name.setValue(this.registerForm.get("lastName").value)
    this.registerForm.get("invoiceAddress").controls.phone.setValue(this.registerForm.get("phone").value)
    this.submitted = true;

    if (this.registerForm.invalid) {
      this.event.showNotification('error', "Uzupełnij wszystkie wymagane pola")
      return;
    }
    else {
      this.appService.post(`authorization/RegisterNewCustomer`, this.registerForm.value).subscribe(response => {
        if (response.status) {
          this.submitted = false;
          this._route.navigate(['witamy']);
        }
        else {
          this.event.showNotification('error', response.description);
        }
      },
        error => {
          this.event.showNotification('error', 'Błąd podczas rejestracji użytkownika')
        })
    }
  }

}
