<div id="employee" *ngIf="loadingVisible"></div>
<div class="row">
  <div class="col-md-12" style="padding-bottom: 39px">
    <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->
    <h1><strong>Wybierz produkty,</strong> które<br />Cię interesują</h1>
  </div>
</div>

<div class="row">
  <div class="col-lg-7 col-md-7 col-sm-12 col-12">
    <dx-tab-panel
      #tabPanel
      [dataSource]="tabMenu"
      [selectedIndex]="0"
      [loop]="false"
      [animationEnabled]="true"
      [swipeEnabled]="true"
      (onSelectionChanged)="onSelectionChangedTab($event)"
    >
      <div *dxTemplate="let company of 'title'">
        <span>{{ company.name }}</span>
      </div>
    </dx-tab-panel>
  </div>

  <div
    class="col-lg-5 col-md-5 col-sm-12 col-12 filer-icon-aligne"
    *ngIf="!isMobile"
  >
    <div class="row">
      <div class="col-md-12">
        <label class="views">
          <input
            type="radio"
            name="group"
            id="listView"
            name="view"
            value="listView"
            [(ngModel)]="view"
            (ngModelChange)="viewChanged()"
          />
          <i class="icon absui-icon--list-view checkmark" title="lista"></i>
        </label>
        <label class="views">
          <input
            type="radio"
            name="group"
            id="tilesView"
            value="tilesView"
            name="view"
            [(ngModel)]="view"
            (ngModelChange)="viewChanged()"
          />
          <i class="icon absui-icon--select-none checkmark" title="kafelki"></i>
        </label>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="view === 'listView'; else tilesView">
  <div class="row" style="margin-top: -5px">
    <div class="col-md-12" *ngIf="productList.length !== 0; else noData">
      <dx-accordion
        #accordion
        [dataSource]="productList"
        [collapsible]="true"
        [multiple]="false"
        [animationDuration]="300"
        [selectedIndex]="-1"
        (selectedItemChange)="event.viewItemClick($event)"
      >
        <div *dxTemplate="let item of 'title'">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div
                style="
                  position: relative;
                  top: 30px;
                  display: flex;
                  flex-wrap: wrap;
                "
              >
                <div class="hexagon-red" style="margin-top: -8px">
                  <p
                    [ngClass]="{
                      'p-smaler-name-shortcut':
                        item.productNameShortcut.length === 3
                    }"
                  >
                    {{ item.productNameShortcut }}
                  </p>
                </div>
                <div style="margin-top: -8px">
                  <h2
                    class="product-description-wapro-erp"
                    style="text-align: left"
                  >
                    {{ item.productNameDescription }}
                  </h2>
                  <h4 class="h1-product-name-wapro-erp">
                    WAPRO <strong>{{ item.productName }}</strong>
                  </h4>
                  <i
                    class="icon"
                    [ngClass]="{
                      'absui-icon--expand-more':
                        accordion.selectedItems.length > 0 &&
                        accordion.selectedItem.productId === item.productId,
                      'absui-icon--is-greate':
                        accordion.selectedItems.length === 0 ||
                        accordion.selectedItem.productId !== item.productId
                    }"
                    style="margin-left: -8px"
                  ></i>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-3 col-sm-3 text-right"
              style="position: relative; top: 15px"
            >
              <p class="p-price-wapro-erp">
                od
                <strong>{{
                  item.basePrice.toFixed(2) | number | pointReplacer
                }}</strong>
                PLN
              </p>
              <p class="p-detalis-offer-wapro-erp">
                Licencja 365 • Netto • Rocznie
              </p>
            </div>
            <div
              class="col-lg-2 col-md-3"
              style="position: relative; top: 25px"
            >
              <dx-button
                class="btn-danger"
                [ngClass]="{
                  'btn-default': item.isBuy === true && isLogIn,
                  'btn-danger': item.isBuy === false || !isLogIn
                }"
                [text]="getButtonText(item)"
                style="width: 149px; height: 40px; float: right"
                (onClick)="onMdify(item.isBuy, item); event.itemListClick(item)"
                [disabled]="event.eventMSG.typeOfLock === 3"
              >
              </dx-button>
              <dx-button
                [text]="
                  item.operatingSystem === 'Service'
                    ? 'Kup usługę'
                    : 'Kup program'
                "
                (onClick)="onMdify(fasle, item); event.itemListClick(item)"
                [disabled]="event.eventMSG.typeOfLock === 3"
                class="btn-outline-default"
                style="
                  width: 149px;
                  height: 40px;
                  float: right;
                  margin-top: 10px;
                "
                *ngIf="item.isBuy === true && isLogIn"
              >
              </dx-button>
            </div>
          </div>
        </div>
        <div *dxTemplate="let item of 'item'">
          <div class="row">
            <div
              class="col-md-12"
              style="margin-top: -10px; padding-left: 80px"
            >
              <p class="p-detalis-offer-wapro-erp">
                <strong
                  >Aktualna wersja: <b>{{ item.lastVersion }}</b> Data wydania:
                  <b>{{
                    item.lastReleaseDate | date : "yyyy-MM-dd"
                  }}</b></strong
                >
              </p>
              <p class="p-detalis-offer-wapro-erp">{{ item.description }}</p>
              <a
                style="
                  color: #dc1e28;
                  cursor: pointer;
                  font-size: 11px;
                  position: relative;
                  top: -10px;
                "
                (click)="
                  $event.stopPropagation();
                  event.sendUrl.next(item.productUrl);
                  showMoreVisible = event.openReadMore(item.productUrl)
                "
                *ngIf="item.productUrl && item.productUrl.length > 0"
                >Czytaj więcej</a
              >
            </div>
          </div>
        </div>
      </dx-accordion>
    </div>
  </div>
</ng-container>

<ng-template #tilesView>
  <div
    class="row"
    *ngIf="productList.length !== 0; else noData"
    style="margin-top: -5px"
  >
    <article
      *ngFor="let item of productList"
      [className]="
        view == 'tilesView' ? 'col-lg-4 col-md-6 col-sm-6 col-12' : 'col-md-12'
      "
    >
      <div class="tiles-wapro-erp">
        <div
          *ngIf="!item.selected; else description"
          (click)="item.selected = !item.selected; event.viewItemClick(item)"
        >
          <div class="row">
            <div class="col-12 text-center">
              <div class="hexagon-red text-right" style="margin-left: 43%">
                <p
                  [ngClass]="{
                    'p-smaler-name-shortcut':
                      item.productNameShortcut.length === 3
                  }"
                >
                  {{ item.productNameShortcut }}
                </p>
              </div>
              <i class="icon absui-icon--is-greate icon-tilesView-greate"></i>
            </div>
            <div class="col-12 text-center">
              <h2 class="product-description-wapro-erp">
                {{ item.productNameDescription }}
              </h2>
              <h4 class="h1-product-name-wapro-erp">
                WAPRO <strong>{{ item.productName }}</strong>
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <p class="p-price-wapro-erp">
                od
                <strong>{{
                  item.basePrice.toFixed(2) | number | pointReplacer
                }}</strong>
                PLN
              </p>
              <p class="p-detalis-offer-wapro-erp">
                Licencja 365 • Netto •&nbsp;Rocznie
              </p>

              <dx-button
                [text]="getButtonText(item)"
                (onClick)="onMdify(item.isBuy, item); event.itemListClick(item)"
                [ngClass]="{
                  'btn-default': item.isBuy === true && isLogIn,
                  'btn-danger': item.isBuy === false || !isLogIn
                }"
                [disabled]="event.eventMSG.typeOfLock === 3"
                style="width: 159px; height: 40px; float: none"
              >
              </dx-button>
              <dx-button
                [text]="
                  item.operatingSystem === 'Service'
                    ? 'Kup usługę'
                    : 'Kup program'
                "
                (onClick)="onMdify(fasle, item); event.itemListClick(item)"
                class="btn-outline-default"
                [disabled]="event.eventMSG.typeOfLock === 3"
                style="
                  width: 159px;
                  height: 40px;
                  float: none;
                  margin-left: 10px;
                "
                *ngIf="item.isBuy === true && isLogIn"
              >
              </dx-button>
            </div>
          </div>
        </div>

        <ng-template #description>
          <div class="row" (click)="item.selected = !item.selected">
            <div class="col-md-12">
              <i
                class="icon absui-icon--expand-more icon-tilesView-expand-more"
              ></i>
            </div>
            <div class="row">
              <div
                class="col-md-12"
                style="padding-left: 55px; padding-right: 50px"
              >
                <p class="p-detalis-offer-wapro-erp">
                  <strong
                    >Aktualna wersja: <b>{{ item.lastVersion }}</b
                    ><br />Data wydania:
                    <b>{{
                      item.lastReleaseDate | date : "yyyy-MM-dd"
                    }}</b></strong
                  >
                </p>
                <p class="p-detalis-offer-wapro-erp">{{ item.description }}</p>
                <a
                  style="
                    color: #dc1e28;
                    cursor: pointer;
                    font-size: 11px;
                    position: relative;
                    top: -10px;
                  "
                  (click)="
                    $event.stopPropagation();
                    event.sendUrl.next(item.productUrl);
                    showMoreVisible = event.openReadMore(item.productUrl)
                  "
                  *ngIf="item.productUrl && item.productUrl.length > 0"
                  >Czytaj więcej</a
                >
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </article>
  </div>
</ng-template>

<div class="row">
  <div class="col-md-12 text-center">
    <p>
      Pełny aktualny cennik oraz cenniki archiwalne dostępne są na stronie
      <a href="https://wapro.pl/cennik/" target="_blank"
        >https://wapro.pl/cennik/</a
      >
    </p>
  </div>
</div>

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>

<!-- <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
>
</dx-load-panel> -->

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>

<app-read-more
  [isVisible]="showMoreVisible"
  (onClosing)="showMoreVisible = false"
></app-read-more>

<dx-popup
  [width]="360"
  [height]="220"
  [showTitle]="true"
  title="Informacja"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showModalBoxConfirm"
  class="my-customer-popup"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col">
        <p class="popup-text">
          Prosimy zaktualizować swoje dane w ustawieniach
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right" style="margin-top: 10px">
        <dx-button
          class="dialog-accept-btn"
          text="ok"
          (onClick)="showModalBoxConfirm = false"
          style="width: 120px"
        >
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<!-- <div class="row">
    <div class="col-md-12">
        <dx-tab-panel #tabPanel [dataSource]="tabMenu" [selectedIndex]="0" [loop]="false"
            [animationEnabled]="true" [swipeEnabled]="true">
            <div *dxTemplate="let company of 'title'">
                <span>{{company.name}}</span>
            </div>
            <div *dxTemplate="let company of 'item'">
                <div class="row">
                    <div class="col-md-12">
                        <article *ngFor="let product of company.productList" style="margin-bottom: 20px;">
                            <app-offer [product]="product"></app-offer>
                        </article>
                    </div>
                </div>
            </div>
        </dx-tab-panel>
    </div>
</div> -->

<dx-popup
  [width]="600"
  [height]="230"
  [maxWidth]="'100%'"
  [showTitle]="false"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [(visible)]="isConfirmBuy"
  class="order-list-popup"
  [showCloseButton]="false"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12" style="padding-top: 15px">
        <p>
          Próbujesz dodać do koszyka kolejną usługę
          {{ productToConfirm?.productName }}. W Twoim stanie posiadania
          istnieje już taka usługa. Jeśli chcesz dokonać przedłużenia abonamentu
          lub rozbudowy (np. zwiększenie liczby dokumentów lub pracowników)
          skorzystaj z opcji Modyfikuj i zmień parametry istniejącej licencji.
          Jeśli potrzebujesz dodatkową usługę na oddzielną instalację programu
          (np. kolejny oddział) dokonaj zakupu nowej licencji.<br />

          Czy na pewno chcesz zakupić nową licencje?
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-right" style="margin-top: 10px">
        <dx-button
          class="dialog-accept-btn"
          text="Tak"
          (onClick)="
            isConfirmBuy = false;
            onMdify(false, productToConfirm);
            productToConfirm = null
          "
          style="width: 120px; height: 45px"
        ></dx-button>
        <dx-button
          class="btn-danger"
          text="Nie"
          (onClick)="isConfirmBuy = false; productToConfirm = null"
          style="
            width: 120px;
            margin-right: 15px;
            margin-left: 12px;
            height: 45px;
          "
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>
