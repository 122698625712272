import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import { ChangePswComponent } from './change-psw/change-psw.component';
import { AddressesComponent } from './addresses/addresses.component';
import { CodesComponent } from './codes/codes.component';
import { ContactComponent } from './contact/contact.component';
import { NewUserComponent } from '../new-user/new-user.component';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss'],
})
export class MyDataComponent implements OnInit {
  @ViewChild('dynamic') dynamic;

  selectedtab: number = 0;

  component: any = [
    { nameMenu: 'Zmiana hasła', component: ChangePswComponent },
    { nameMenu: 'Dane adresowe', component: AddressesComponent },
    { nameMenu: 'Kod aktywacji', component: CodesComponent },
    { nameMenu: 'Dane kontaktowe', component: ContactComponent },
    { nameMenu: 'Nowy użytkownik', component: NewUserComponent },
  ];

  componentShow = this.component[0].component;
  swipeEnabled = false;
  UserRole: string = '';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 430) {
      this.swipeEnabled = true;
    }
  }

  constructor(private appServices: AppServices, private event: EventService) {
    this.event.showNavbar.next(true);
    if (sessionStorage.getItem('myAccountGenericCode')) {
      this.selectedtab = 2;

      let data = JSON.parse(sessionStorage.getItem('myAccountGenericCode'));

      if (typeof data != 'boolean') {
        setTimeout(() => {
          this.event.dataActivateCode.next(data);
        }, 500);
      }

      sessionStorage.removeItem('myAccountGenericCode');
    }
    if (localStorage.getItem('wapro-erp-token'))
      this.UserRole = helper.decodeToken(
        localStorage.getItem('wapro-erp-token')
      ).UserRole;
  }

  ngOnInit(): void {
    if (window.innerWidth < 430) {
      this.swipeEnabled = true;
    }

    setTimeout(() => {
      if (window.innerWidth < 750 && this.selectedtab == 2) {
        let draw = document.getElementsByClassName(
          'dx-tabpanel-container'
        ) as HTMLCollectionOf<HTMLElement>;
        draw[0].style.height = '850px';
      } else if (window.innerWidth < 800 && this.selectedtab == 2) {
        let draw = document.getElementsByClassName(
          'dx-tabpanel-container'
        ) as HTMLCollectionOf<HTMLElement>;
        draw[0].style.height = '650px';
      } else if (window.innerWidth < 800 && this.selectedtab == 0) {
        let draw = document.getElementsByClassName(
          'dx-tabpanel-container'
        ) as HTMLCollectionOf<HTMLElement>;
        draw[0].style.height = '600px';
      }
    }, 0);
  }

  onSelectionChanged = (e) => {
    if (
      e.addedItems[0].nameMenu === 'Zmiana hasła' &&
      window.innerWidth < 750
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '600px';
    } else if (
      e.addedItems[0].nameMenu === 'Dane adresowe' &&
      window.innerWidth < 750 &&
      this.UserRole === 'client'
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '2100px';
    } else if (
      e.addedItems[0].nameMenu === 'Dane adresowe' &&
      window.innerWidth < 750
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '1760px';
    } else if (
      e.addedItems[0].nameMenu === 'Kod aktywacji' &&
      window.innerWidth < 750
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '850px';
    } else if (
      e.addedItems[0].nameMenu === 'Dane kontaktowe' &&
      window.innerWidth < 750
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '800px';
    } else if (
      e.addedItems[0].nameMenu === 'Nowy użytkownik' &&
      window.innerWidth < 750
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '1050px';
    } else if (
      e.addedItems[0].nameMenu === 'Zmiana hasła' &&
      window.innerWidth < 800
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '600px';
    } else if (
      e.addedItems[0].nameMenu === 'Dane adresowe' &&
      window.innerWidth < 800 &&
      this.UserRole !== 'client'
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '960px';
    } else if (
      e.addedItems[0].nameMenu === 'Dane adresowe' &&
      window.innerWidth < 800 &&
      this.UserRole == 'client'
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '1160px';
    } else if (
      e.addedItems[0].nameMenu === 'Kod aktywacji' &&
      window.innerWidth < 800
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '650px';
    } else if (
      e.addedItems[0].nameMenu === 'Dane kontaktowe' &&
      window.innerWidth < 800
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '600px';
    } else if (
      e.addedItems[0].nameMenu === 'Nowy użytkownik' &&
      window.innerWidth < 800
    ) {
      let draw = document.getElementsByClassName(
        'dx-tabpanel-container'
      ) as HTMLCollectionOf<HTMLElement>;
      draw[0].style.height = '750px';
    }
  };
}
