import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  view: string = 'tilesView';
  productList = [];
  isVisible: boolean = false;
  message = 'Completed successfully!';

  showBreak: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1366)
      document.body.style.background =
        "url('./assets/img/gray_triangle_left.png') no-repeat -580px 540px, url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
    else if (window.innerWidth > 1920)
      document.body.style.background =
        "url('./assets/img/gray_triangle_left.png') no-repeat -580px 540px, url('./assets/img/top_big.png') no-repeat 370px 0, url('./assets/img/background.png') no-repeat 370px -90px";
    else
      document.body.style.background =
        "url('./assets/img/gray_triangle_left.png') no-repeat -180px 540px, url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
    if (window.innerWidth < 430) {
      document.body.style.background =
        "url('./assets/img/kv_mobile.png') no-repeat 185px 0px";
      document.body.style.setProperty(
        'background-color',
        '#ffffff',
        'important'
      );
    }
  }

  constructor(
    private appService: AppServices,
    private event: EventService,
    public _route: Router
  ) {
    if (localStorage.getItem('viewProductList=wapro-erp'))
      this.view = localStorage.getItem('viewProductList=wapro-erp');
  }

  ngOnInit(): void {
    // localStorage.removeItem('wapro-erp-token');
    // localStorage.removeItem('wapro-erp-login-user');
    // localStorage.removeItem('user-info-wapro-erp');
    this.event.baskedProduct.next(null);
    this.event.showNavbar.next(false);

    if (window.innerWidth < 1366)
      document.body.style.background =
        "url('./assets/img/gray_triangle_left.png') no-repeat -580px 540px, url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
    else if (window.innerWidth > 1920)
      document.body.style.background =
        "url('./assets/img/gray_triangle_left.png') no-repeat -580px 540px, url('./assets/img/top_big.png') no-repeat 370px 0, url('./assets/img/background.png') no-repeat 370px -90px";
    else
      document.body.style.background =
        "url('./assets/img/gray_triangle_left.png') no-repeat -180px 540px, url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";

    if (window.innerWidth < 430) {
      document.body.style.background =
        "url('./assets/img/kv_mobile.png') no-repeat 185px 0px";
      document.body.style.setProperty(
        'background-color',
        '#ffffff',
        'important'
      );
    }

    let today = new Date('2023-10-01 10:00:00');

    if (
      new Date().getTime() < today.getTime() &&
      !localStorage.getItem('showBreak')
    ) {
      this.showBreak = true;
    } else if (new Date().getTime() > today.getTime()) {
      localStorage.removeItem('showBreak');
    }
  }

  ngOnDestroy() {
    document.body.style.background = 'none';
  }

  navigateToOfert = () => {
    this._route.navigate(['oferta']);
  };

  viewChanged = () => {
    localStorage.setItem('viewProductList=wapro-erp', this.view);
  };

  showAgain: boolean = false;
  closeBreak() {
    this.showBreak = false;
    if (this.showAgain) {
      localStorage.setItem('showBreak', 'false');
    }
  }
}
