<div class="row">
  <div class="col-md-12" style="padding-bottom: 39px">
    <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->

    <h1>
      Kontroluj status <strong><br />swoich zamówień</strong>
    </h1>
  </div>
</div>

<div class="row">
  <div class="col-lg-7 col-md-7 col-sm-12 col-12">
    <dx-tab-panel
      #tabPanel
      [dataSource]="tabMenu"
      [selectedIndex]="selectedtab"
      [loop]="false"
      [animationEnabled]="true"
      [swipeEnabled]="true"
      [(selectedIndex)]="selectedtab"
      (onSelectionChanged)="onSelectionChangedTab($event)"
    >
      <div *dxTemplate="let company of 'title'">
        <span>{{ company.name }}</span>
      </div>
    </dx-tab-panel>
  </div>

  <div
    class="col-lg-5 col-md-5 col-sm-12 col-12 filer-icon-aligne d-none-mobile"
  >
    <dx-button
      class="icon-border icon-header"
      (onClick)="showModalBox = true"
      [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
      [disabled]="
        selectedRows.length === 0 ||
        filterCriteria !== 'Incoming' ||
        event.eventMSG.typeOfLock === 3
      "
      *ngIf="showCheckBox && selectedtab != 3"
    >
      <i
        class="icon absui-icon--arrow-forward"
        aria-hidden="true"
        title="Prześlij do realizacji do Partnera lub Asseco BS"
      ></i>
    </dx-button>

    <dx-button
      class="icon-border icon-header"
      (onClick)="addSelectedToBasket(true)"
      [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
      [disabled]="selectedRows.length === 0 || event.eventMSG.typeOfLock === 3"
      *ngIf="selectedtab == 3 || selectedtab == 1"
    >
      <i
        class="icon absui-icon--shopping-basket"
        aria-hidden="true"
        title="Przenieś zaznaczone do bieżącego zamóweinia"
      ></i>
    </dx-button>

    <dx-button
      class="icon-border icon-header"
      (onClick)="settextConfirm(); showModalBoxConfirm = true"
      [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
      [disabled]="selectedRows.length === 0"
      *ngIf="showCheckBox"
    >
      <i
        class="icon absui-icon--close"
        aria-hidden="true"
        title="Anuluj zamówienie"
      ></i>
    </dx-button>

    <dx-button
      class="icon-border icon-header"
      [ngClass]="{ 'box-shadow': selectedRows.length! == 0 }"
      [disabled]="selectedRows.length === 0"
      (onClick)="unselectAll()"
      *ngIf="showCheckBox"
    >
      <i
        class="icon icon--remove-selected"
        aria-hidden="true"
        title="Odznacz"
      ></i>
    </dx-button>
  </div>
</div>

<div
  class="row"
  style="margin-right: 0px; margin-left: 0px"
  class="mobile-left"
>
  <div class="col-md-12">
    <div class="row about-license-wapro-erp" style="margin-top: -20px">
      <div
        class="row"
        *ngIf="
          ((UserRole === 'partner' || UserRole === 'subpartner') &&
            (selectedtab == 1 || selectedtab == 2)) ||
          selectedtab == 3 ||
          selectedtab == 4
        "
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="flex-container">
            <div>
              <dx-text-box
                type="search"
                class="dx-filter"
                valueChangeEvent="keyup"
                id="searchText"
                placeholder="Wpisz szukaną frazę"
                [(ngModel)]="filterCriteria2"
                [showClearButton]="true"
                (keydown.enter)="orderList = []; getOrderList()"
                (ngModelChange)="ngModelChange()"
              >
              </dx-text-box>
              <i
                class="icon icon-search d-none d-none-pc"
                aria-hidden="true"
                title="Filtrowanie oraz odświeżanie listy"
                style="cursor: pointer"
              ></i>
            </div>
            <div>
              <dx-select-box
                class="dx-filtr-input box-shadow-none"
                [dataSource]="filtrName2"
                displayExpr="label"
                valueExpr="value"
                placeholder="Nazwa"
                [(ngModel)]="filterColumn2"
                [dropDownOptions]="{ closeOnTargetScroll: false }"
                id="filtrName"
              ></dx-select-box>
              <i
                class="icon icon-search d-none-mobile"
                aria-hidden="true"
                title="Filtrowanie oraz odświeżanie listy"
                (click)="orderList = []; getOrderList()"
                style="cursor: pointer"
                id="refreshData"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <dx-data-grid
          [dataSource]="shortList"
          [allowColumnReordering]="false"
          [showBorders]="true"
          [showRowLines]="true"
          [(selectedRowKeys)]="selectedRows"
          #dataGridDevexpres
          (onCellClick)="onAdaptiveDetailRowPreparing($event, item)"
          [showBorders]="false"
          [height]="heightGrid"
          [showColumnLines]="false"
          *ngIf="orderList.length !== 0; else noData"
          [hoverStateEnabled]="true"
          [columnAutoWidth]="true"
          wordWrapEnabled="true"
        >
          <dxi-column
            dataField="basketName"
            caption="Nazwa koszyka"
            [allowSorting]="false"
            *ngIf="selectedtab == 3"
            width="100"
          ></dxi-column>
          <dxi-column
            dataField="orderNumber"
            caption="Numer zamówienia / Uwagi"
            [allowSorting]="false"
            cellTemplate="orderNumber"
            width="200"
          ></dxi-column>
          <dxi-column
            dataField="orderDate"
            caption="Data zamówienia"
            dataType="date"
            [hidingPriority]="4"
            [allowSorting]="false"
            cellTemplate="orderDateTemplate"
            format="yyyy-MM-dd HH:mm"
            width="130"
          >
          </dxi-column>
          <dxi-column
            dataField="company"
            caption="Klient"
            dataType="date"
            [hidingPriority]="3"
            [allowSorting]="false"
            width="270"
            [visible]="isSend"
          >
          </dxi-column>
          <dxi-column
            dataField="company"
            [caption]="company"
            cellTemplate="companyTemplate"
            [allowSorting]="false"
            [hidingPriority]="2"
          ></dxi-column>
          <dxi-column
            dataField="orderState"
            caption="Status"
            [allowSorting]="false"
            width="60"
            alignment="center"
            [hidingPriority]="1"
            cellTemplate="orderStateTemplate"
          >
          </dxi-column>
          <dxi-column
            cellTemplate="amount"
            caption="Kwota brutto"
            [allowSorting]="false"
            width="110"
            alignment="right"
          >
          </dxi-column>
          <dxi-column
            cellTemplate="sendCell"
            caption=""
            [allowSorting]="false"
            width="130"
            alignment="right"
            [hidingPriority]="0"
            [visible]="
              filterCriteria === 'Incoming' || filterCriteria === 'Saved'
            "
          >
          </dxi-column>
          <dxo-load-panel
            [enabled]="true"
            showScrollbar="always"
          ></dxo-load-panel>

          <dxo-scrolling mode="virtual" showScrollbar="none"></dxo-scrolling>
          <dxo-sorting mode="none"></dxo-sorting>

          <dxo-selection
            selectAllMode="allPages"
            mode="multiple"
            showCheckBoxesMode="always"
            *ngIf="showCheckBox"
          >
          </dxo-selection>

          <div
            *dxTemplate="let data of 'orderDateTemplate'"
            style="font-family: 'InterMedium'"
          >
            <p>{{ data.data.orderDate | date : "yyyy-MM-dd HH:mm" }}</p>
          </div>

          <div
            *dxTemplate="let data of 'companyTemplate'"
            style="font-family: 'InterMedium'"
          >
            <p
              *ngIf="
                (UserRole === 'partner' || UserRole === 'subpartner') && !isSend
              "
            >
              {{ data.data.company }}
            </p>
            <p
              *ngIf="
                (UserRole === 'partner' || UserRole === 'subpartner') && isSend
              "
            >
              {{ data.data.partnerName }}
            </p>
            <p *ngIf="UserRole === 'client'">{{ data.data.partnerName }}</p>
          </div>

          <div *dxTemplate="let data of 'orderNumber'" class="history-orders">
            <p
              class="p-product-name-wapro-erp"
              (click)="onRowClick(data.data)"
              style="cursor: pointer"
            >
              {{ data.data.orderNumber }}
            </p>
            <p
              class="p-lineDescription-wapro-erp d-none-mobile"
              [ngClass]="{ 'margin-top': data.data.remarks != '' }"
            >
              {{ data.data.remarks | slice : 0 : 80 }}
            </p>
          </div>

          <div *dxTemplate="let data of 'orderStateTemplate'">
            <p class="p-status-wapro-erp">
              <i
                class="icon absui-icon--person-out"
                *ngIf="data.data.orderState == 'wysłane'"
                [title]="data.data.orderState"
              ></i>
              <i
                class="icon absui-icon--person-ok"
                *ngIf="data.data.orderState == 'odebrane'"
                [title]="data.data.orderState"
              ></i>
              <i
                class="icon absui-icon--partly-check-circle"
                *ngIf="data.data.orderState == 'w Asseco WAPRO'"
                [title]="data.data.orderState"
              ></i>
              <i
                class="icon absui-icon--check-circle"
                *ngIf="data.data.orderState == 'zaakceptowane'"
                [title]="data.data.orderState"
              ></i>
              <i
                class="icon absui-icon--remove-circle-outline"
                *ngIf="data.data.orderState == 'anulowane'"
                [title]="data.data.orderState"
              ></i>
              <i
                class="icon absui-icon--error-money-outline"
                *ngIf="data.data.orderState == 'nieuregulowana należność'"
                [title]="data.data.orderState"
              ></i>
            </p>
          </div>

          <div *dxTemplate="let data of 'amount'">
            <p style="font-family: 'InterBold'; padding-right: 5px">
              {{ data.data.amount | currency : " " | pointReplacer }}
            </p>
            <dx-button
              class="icon-border-row-table d-none-mobile"
              (onClick)="payOnline(data.data.orderId)"
              style="width: 22px"
              *ngIf="
                data.data.hasPaymentRegistered &&
                data.data.orderState !== 'zaakceptowane'
              "
            >
              <i
                class="icon absui-icon--payment icon-row"
                aria-hidden="true"
                title="Zapłać online"
              ></i>
            </dx-button>
          </div>

          <div *dxTemplate="let data of 'sendCell'" class="text-right-mobile">
            <dx-button
              class="small-border-row d-none-products"
              *ngIf="filterCriteria === 'Incoming'"
              (onClick)="addToSelected(data.data.orderId); showModalBox = true"
              style="margin-right: 2px"
              [disabled]="event.eventMSG.typeOfLock === 3"
            >
              <i
                class="icon absui-icon--arrow-forward icon-row"
                aria-hidden="true"
                title="Prześlij do realizacji do Partnera lub Asseco BS"
              ></i>
              <span>Prześlij</span>
            </dx-button>

            <dx-button
              class="icon-border-row-table d-none-mobile-products"
              *ngIf="filterCriteria === 'Incoming'"
              (onClick)="addToSelected(data.data.orderId); showModalBox = true"
              [disabled]="event.eventMSG.typeOfLock === 3"
            >
              <i
                class="icon absui-icon--arrow-forward icon-row"
                aria-hidden="true"
                title="Prześlij do realizacji do Partnera lub Asseco BS"
              ></i>
            </dx-button>

            <!--przycisk do przechodzenia do zamowienia-->
            <dx-button
              class="small-border-row d-none-products"
              *ngIf="filterCriteria === 'Saved'"
              (onClick)="goToOrder(data.data.orderId)"
              style="margin-right: 2px"
              [disabled]="event.eventMSG.typeOfLock === 3"
            >
              <i
                class="icon absui-icon--arrow-forward icon-row"
                aria-hidden="true"
                title="Dokończ zamówienie"
              ></i>
              <span>Dokończ zamówienie</span>
            </dx-button>

            <dx-button
              class="icon-border-row-table d-none-mobile-products"
              *ngIf="filterCriteria === 'Saved'"
              (onClick)="goToOrder(data.data)"
              [disabled]="event.eventMSG.typeOfLock === 3"
            >
              <i
                class="icon absui-icon--arrow-forward icon-row"
                aria-hidden="true"
                title="Dokończ zamówienie"
              ></i>
            </dx-button>

            <dx-button
              class="small-border-row d-none-products"
              *ngIf="showCheckBox"
              [disabled]="data.data.orderState === 'anulowane'"
              (onClick)="
                settextConfirm();
                showModalBoxConfirm = true;
                onSelect(data.data)
              "
            >
              <i
                class="icon absui-icon--close icon-row"
                aria-hidden="true"
                title="Anuluj zamówienie"
              ></i>
              <span>Anuluj</span>
            </dx-button>

            <dx-button
              class="icon-border-row-table d-none-mobile-products"
              *ngIf="showCheckBox"
              [disabled]="data.data.orderState === 'anulowane'"
              (onClick)="
                settextConfirm();
                showModalBoxConfirm = true;
                onSelect(data.data)
              "
            >
              <i
                class="icon absui-icon--close icon-row"
                aria-hidden="true"
                title="Anuluj zamówienie"
              ></i>
            </dx-button>
          </div>

          <dxo-load-panel
            [visible]="true"
            [showIndicator]="true"
            [showPane]="true"
            indicatorSrc="..\\..\\assets\\img\\preloader.svg"
            [enabled]="true"
          >
          </dxo-load-panel>
        </dx-data-grid>
      </div>
      <div class="col-md-12 text-center" style="padding-top: 30px">
        <p (click)="showMore()" *ngIf="isShowMore" class="show-more">
          Wczytaj kolejne
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>

<dx-popup
  [width]="350"
  [height]="200"
  [showTitle]="true"
  title="Anuluj zamówienie"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showModalBoxConfirm"
  class="order-list-popup"
  [showCloseButton]="false"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12 text-center">
        <p>{{ confirmMes }}</p>
      </div>
    </div>
    <div class="row" style="margin-top: -10px">
      <div class="col-md-12 text-center">
        <br />
        <dx-button
          class="dialog-accept-btn"
          text="Tak"
          (onClick)="showModalBoxConfirm = false; cancelOrder()"
          style="width: 120px; height: 40px"
        ></dx-button>
        <dx-button
          class="btn-danger"
          text="Nie"
          (onClick)="showModalBoxConfirm = false"
          style="
            width: 120px;
            margin-right: 15px;
            margin-left: 12px;
            height: 40px;
          "
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="widthPopUp"
  [height]="290"
  [showTitle]="true"
  title="Prześlij do realizacji"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showModalBox"
  class="order-list-popup"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group" style="padding: 0 0px">
          <label for="remarks" class="label-for-wapro-erp">Uwagi</label><br />
          <dx-text-area
            [height]="80"
            class="no-box-shadow"
            [(ngModel)]="remarks"
            id="remarks"
            style="text-align: left"
          ></dx-text-area>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <dx-button
          class="btn-danger"
          text="Prześlij"
          width="120"
          height="40"
          style="margin-top: 15px; margin-right: 5px"
          (onClick)="sendToRealize()"
        >
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>
