<div id="employee" *ngIf="loadingVisible"></div>

<div class="row">
  <div class="col-md-12">
    <a style="cursor: pointer" (click)="event.historyBack()"
      ><i class="icon keyboard-arrow-left"></i>
      <h5>Produkty klientów</h5>
    </a>
  </div>
</div>

<div
  class="row"
  *ngIf="!(groupedData.length > 0 && sumValueOfLicense === null); else noData"
></div>

<div
  class="about-license-wapro-erp mobile-bottom mobile-top"
  *ngIf="sumValueOfLicense !== null"
  style="margin-bottom: 30px"
>
  <div class="row">
    <div class="col-md-12 text-center">
      <p class="p-client-name-wapro-erp" style="font-size: 16px">
        Zobacz ile straciłeś
      </p>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-6"
      [ngClass]="{
        'text-center col-12': isMobile,
        'text-right col-6': !isMobile
      }"
    >
      <p class="p-product-name-wapro-erp bigger-text">
        <i class="icon absui-icon--docs"></i>
        <span class="pad-span">Wartość licencji</span>
      </p>
      <p class="p-lineDescription-wapro-erp pad-span money-text">
        <strong style="font-family: InterBold"
          >{{ sumValueOfLicense | currency : " " | pointReplacer }} zł</strong
        >
      </p>
    </div>
    <div
      class="col-md-6"
      [ngClass]="{ 'text-center col-12': isMobile, 'col-6': !isMobile }"
    >
      <p class="p-product-name-wapro-erp bigger-text">
        <i class="icon absui-icon--trending-down"></i>
        <span class="pad-span">Wartość straty</span>
      </p>
      <p class="p-lineDescription-wapro-erp pad-span money-text">
        <strong style="font-family: InterBold"
          >{{
            sumIncomeFromLicense | currency : " " | pointReplacer
          }}
          zł</strong
        >
      </p>
    </div>
  </div>
</div>

<div class="row panel-filtr-wapro-erp" *ngIf="groupedData.length !== 0">
  <div class="col-lg-7 col-md-12 col-sm-12 col-12">
    <div class="flex-container">
      <div>
        <dx-text-box
          type="search"
          class="dx-filter"
          valueChangeEvent="keyup"
          placeholder="Wpisz szukaną frazę"
          [(ngModel)]="filterCriteria"
          [showClearButton]="true"
          (ngModelChange)="ngModelChange()"
          (keydown.enter)="search()"
        >
        </dx-text-box>
        <i
          class="icon icon-search d-none d-none-pc"
          aria-hidden="true"
          title="Filtrowanie oraz odświeżanie listy"
          (click)="search()"
          style="cursor: pointer"
        ></i>
      </div>
      <div>
        <dx-select-box
          class="dx-filtr-input"
          [dataSource]="filtrName"
          displayExpr="label"
          valueExpr="value"
          [dropDownOptions]="{ closeOnTargetScroll: false }"
          placeholder="Nazwa"
          [(ngModel)]="filterColumn"
        >
        </dx-select-box>
        <i
          class="icon icon-search d-none-mobile"
          aria-hidden="true"
          title="Filtrowanie oraz odświeżanie listy"
          (click)="search()"
          style="cursor: pointer"
        ></i>
      </div>
    </div>
  </div>

  <div class="col-lg-5 col-md-12 col-sm-12 col-12 text-right">
    <dx-button
      class="icon-border icon-header box-shadow"
      (onClick)="onAutoExpandAllChanged()"
      [ngClass]="{ 'red-circle': autoExpandAll, 'icon-border': !autoExpandAll }"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-more': autoExpandAll,
          'icon--keyboard-arrow-rightt': !autoExpandAll
        }"
        aria-hidden="true"
        title="Rozwiń/zwiń wszystkie"
      ></i>
    </dx-button>
  </div>
</div>

<div class="row" *ngIf="groupedData.length !== 0; else noData"></div>

<div
  class="about-license-wapro-erp mobile-bottom"
  *ngFor="let item of groupedData"
>
  <div class="row" id="gridContainer">
    <div class="col-md-12" (click)="item.selection = !item.selection">
      <div class="row" style="box-sizing: border-box; padding-left: 5px">
        <div class="col-12 col-md-6">
          <p class="p-client-name-wapro-erp">{{ item.name }}</p>
        </div>

        <div class="col-12 col-md-6 text-right">
          <dx-button
            [ngClass]="{
              'red-circle': item.selection,
              'icon-border': !item.selection
            }"
            style="opacity: 1"
          >
            <i
              class="icon"
              [ngClass]="{
                'absui-icon--expand-more': item.selection,
                'icon--keyboard-arrow-rightt': !item.selection
              }"
              aria-hidden="true"
            ></i>
          </dx-button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" *ngIf="item.selection">
      <dx-data-grid
        [dataSource]="item.list"
        [allowColumnReordering]="false"
        [hidden]="!item.selection"
        wordWrapEnabled="true"
        style="width: 100%; min-height: 0px !important"
        [showRowLines]="true"
        [showBorders]="false"
        [showColumnLines]="false"
        [hoverStateEnabled]="true"
        #grid
        [height]="item.height + 50"
        (onCellClick)="onAdaptiveDetailRowPreparing($event, item)"
      >
        <dxi-column
          dataField="productName"
          caption="Produkt / Wariant"
          cellTemplate="productNameCell"
          [allowSorting]="false"
          width="220"
        ></dxi-column>
        <dxi-column
          dataField="licenseNumber"
          caption="Licencja / Wersja"
          alignment="left"
          cellTemplate="licenseNumberCell"
          width="140"
          [allowSorting]="false"
          [hidingPriority]="4"
        ></dxi-column>
        <dxi-column
          dataField="vatNumber"
          caption="NIP"
          width="130"
          [allowSorting]="false"
          alignment="left"
          [hidingPriority]="3"
        ></dxi-column>
        <dxi-column
          cellTemplate="valueOfLicense"
          dataField="valueOfLicense"
          caption="Wartość licencji"
          width="80"
          [allowSorting]="false"
          alignment="left"
          [hidingPriority]="6"
        ></dxi-column>
        <dxi-column
          cellTemplate="incomeFromLicense"
          dataField="incomeFromLicense"
          caption="Wartość rabatu"
          width="80"
          [allowSorting]="false"
          alignment="left"
          [hidingPriority]="5"
        ></dxi-column>
        <dxi-column
          dataField="numberOfUsers"
          caption="Stanowiska"
          [allowSorting]="false"
          width="100"
          alignment="left"
          [hidingPriority]="2"
          cellTemplate="numberOfUsersCell"
        ></dxi-column>
        <dxi-column
          dataField="isActive"
          caption="Aktywna"
          alignment="left"
          width="100"
          [allowSorting]="false"
          cellTemplate="isActiveCell"
          [hidingPriority]="1"
        ></dxi-column>
        <dxi-column
          cellTemplate="expireDateCell"
          dataField="expireDate"
          caption="Data wygaśnięcia"
          dataType="date"
          [hidingPriority]="0"
          format="yyyy-MM-dd"
          width="150"
          [allowSorting]="false"
          alignment="left"
        ></dxi-column>

        <div *dxTemplate="let data of 'incomeFromLicense'">
          <p style="font-family: 'InterBold'">
            {{ data.data.incomeFromLicense | currency : " " | pointReplacer }}
          </p>
        </div>

        <div *dxTemplate="let data of 'valueOfLicense'">
          <p style="font-family: 'InterBold'">
            {{ data.data.valueOfLicense | currency : " " | pointReplacer }}
          </p>
        </div>

        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-sorting mode="none"></dxo-sorting>

        <dxo-grouping [autoExpandAll]="autoExpandAll"></dxo-grouping>

        <div *dxTemplate="let data of 'productNameCell'">
          <p class="p-product-name-wapro-erp">
            WAPRO <strong>{{ data.data.productName }}</strong>
          </p>
          <p class="p-lineDescription-wapro-erp">{{ data.data.variantName }}</p>
        </div>

        <div *dxTemplate="let data of 'isActiveCell'">
          <p>
            {{ data.data.isActive ? "Tak" : "Nie" }}
          </p>
        </div>

        <div *dxTemplate="let data of 'expireDateCell'">
          <p
            class="p-row-wapro-erp"
            [ngClass]="{
              'p-gray-wapro-erp': data.data.dayToExpire < 0,
              'p-orange-wapro-erp':
                data.data.dayToExpire < 60 && data.data.dayToExpire >= 30,
              'p-red-wapro-erp':
                data.data.dayToExpire < 30 && data.data.dayToExpire > 0
            }"
          >
            {{ data.data.expireDate | date : "yyyy-MM-dd" }}
          </p>
        </div>

        <div *dxTemplate="let data of 'licenseNumberCell'">
          <p class="p-number-license-wapro-erp">
            {{ data.data.licenseNumber }}
          </p>
          <p class="p-lineDescription-wapro-erp">
            {{ data.data.versionNumber }}
          </p>
        </div>

        <div *dxTemplate="let data of 'numberOfUsersCell'">
          <p class="p-row-wapro-erp">{{ data.data.numberOfUsers }}</p>
        </div>

        <dxo-load-panel
          [visible]="true"
          [showIndicator]="true"
          [showPane]="true"
          indicatorSrc="..\\..\\assets\\img\\preloader.svg"
          width="90"
          height="90"
          [enabled]="false"
        >
        </dxo-load-panel>
      </dx-data-grid>
    </div>
  </div>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>

<!-- <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
>
</dx-load-panel> -->

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>
