<div
  class="container"
  style="margin-bottom: 50px; margin-top: -20px"
  *ngIf="this.href === '/rejestracja'; else registerUser"
>
  <form [formGroup]="newUserNoRegister">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="about-license-wapro-erp padding-left">
          <h4 style="padding-bottom: 20px">Dane logowania</h4>
          <div class="form-group order-box">
            <label for="login">Email <strong>*</strong></label>
            <dx-text-box
              name="login"
              placeholder=""
              id="login"
              formControlName="Login"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              mode="email"
              [ngClass]="{
                'input-required':
                  newUserNoRegister.controls.Login.touched &&
                  !newUserNoRegister.controls.Login.valid
              }"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="
                newUserNoRegister.controls.Login.touched &&
                !newUserNoRegister.controls.Login.valid
              "
            >
              Podaj poprawny adres email
            </small>
          </div>

          <div class="form-group order-box">
            <label for="password">Hasło <strong>*</strong></label>
            <dx-text-box
              name="password"
              placeholder=""
              id="password"
              formControlName="Password"
              class="text-box"
              [inputAttr]="{ autocomplete: 'off' }"
              [mode]="modePsw"
              style="padding-right: 70px"
              [ngClass]="{
                'input-required':
                  newUserNoRegister.controls.Password.touched &&
                  !newUserNoRegister.controls.Password.valid
              }"
            >
            </dx-text-box>
            <i
              class="o-button--inInput"
              (click)="
                modePsw == 'password'
                  ? (modePsw = 'text')
                  : (modePsw = 'password')
              "
            >
              <i
                class="icon icon-eye icon-eye2"
                [hidden]="modePsw != 'password'"
              ></i>
              <i
                class="icon icon-eye-slash icon-eye2"
                [hidden]="modePsw == 'password'"
              ></i>
            </i>
            <div
              style="margin-left: 13px; margin-top: -20px; padding-bottom: 20px"
              calss="mpbile-div"
            >
              <small
                class="text-danger error-msg text-error-mobile"
                *ngIf="
                  newUserNoRegister.controls.Password.touched &&
                  !newUserNoRegister.controls.Password.valid
                "
              >
                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i
                znak specjalny
              </small>
            </div>
          </div>

          <div class="form-group order-box pwd-box mpbile-div">
            <label for="password">Powtórz hasło <strong>*</strong></label>
            <dx-text-box
              name="password"
              placeholder=""
              id="password"
              formControlName="PasswordConfirmation"
              class="text-box"
              [inputAttr]="{ autocomplete: 'off' }"
              [mode]="modePswRepeat"
              style="padding-right: 70px"
              [ngClass]="{
                'input-required':
                  newUserNoRegister.controls.PasswordConfirmation.touched &&
                  !newUserNoRegister.controls.PasswordConfirmation.valid
              }"
            >
            </dx-text-box>
            <i
              class="o-button--inInput"
              (click)="
                modePswRepeat == 'password'
                  ? (modePswRepeat = 'text')
                  : (modePswRepeat = 'password')
              "
            >
              <i
                class="icon icon-eye icon-eye2"
                [hidden]="modePswRepeat != 'password'"
              ></i>
              <i
                class="icon icon-eye-slash icon-eye2"
                [hidden]="modePswRepeat == 'password'"
              ></i>
            </i>
            <div
              style="margin-left: 13px; margin-top: -20px; padding-bottom: 20px"
              calss="mpbile-div"
            >
              <small
                class="text-danger error-msg text-error-mobile"
                *ngIf="
                  newUserNoRegister.controls.PasswordConfirmation.touched &&
                  !newUserNoRegister.controls.PasswordConfirmation.valid
                "
              >
                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i
                znak specjalny
              </small>
            </div>
          </div>
        </div>

        <div class="about-license-wapro-erp">
          <div formGroupName="customerContact">
            <h4 style="padding-bottom: 20px">Dane osobowe</h4>

            <div class="form-group order-box">
              <label for="login">Imię <strong>*</strong></label>
              <dx-text-box
                name="firstName"
                placeholder=""
                id="firstName"
                formControlName="firstName"
                class="text-box"
                [showClearButton]="true"
                [inputAttr]="{ autocomplete: 'off' }"
                [ngClass]="{
                  'input-required': submitted && formError.firstName
                }"
              >
              </dx-text-box>
              <small
                class="text-danger"
                *ngIf="submitted && formError.firstName"
              >
                {{ formError.firstName }}
              </small>
            </div>

            <div class="form-group order-box">
              <label for="login">Nazwisko <strong>*</strong></label>
              <dx-text-box
                name="lastName"
                placeholder=""
                id="lastName"
                formControlName="lastName"
                class="text-box"
                [showClearButton]="true"
                [inputAttr]="{ autocomplete: 'off' }"
                [ngClass]="{
                  'input-required': submitted && formError.lastName
                }"
              >
              </dx-text-box>
              <small
                class="text-danger"
                *ngIf="submitted && formError.lastName"
              >
                {{ formError.lastName }}
              </small>
            </div>

            <div class="form-group order-box">
              <label for="login">Telefon komórkowy</label>
              <dx-text-box
                name="MobilePhone"
                placeholder=""
                id="MobilePhone"
                formControlName="MobilePhone"
                class="text-box"
                [showClearButton]="true"
                [inputAttr]="{ autocomplete: 'off' }"
                [ngClass]="{
                  'input-required': submitted && formError.MobilePhone
                }"
              >
              </dx-text-box>
              <small
                class="text-danger"
                *ngIf="submitted && formError.MobilePhone"
              >
                {{ formError.MobilePhone }}
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <dx-check-box
              formControlName="MarketingAgreement"
              class="text-box"
              style="height: 60px"
              text="Udzielam zgody na przesyłanie mi informacji handlowych drogą elektroniczną na podany adres e-mail"
            >
            </dx-check-box>

            <div style="position: relative; top: 10px">
              <dx-check-box
                text=" "
                class="text-box"
                style="display: inline-block; height: 60px"
                formControlName="regulaminAgreement"
              ></dx-check-box>
              <a
                href="https://wapro.pl/doc/Regulamin_sklep_assecobs_pl.pdf"
                target="_blank"
                style="
                  display: inline-block;
                  color: #30393f;
                  position: relative;
                  top: -55px;
                  left: 20px;
                  font-weight: normal;
                  font-family: Inter;
                "
              >
                <strong style="color: #dc1e28; font-size: 12px">* </strong
                >Zapoznałem się i w pełni akceptuję Regulamin sklepu</a
              >
            </div>

            <div style="position: relative; top: -30px; margin-bottom: -80px">
              <dx-check-box
                text=" "
                class="text-box"
                style="display: inline-block; height: 60px"
                formControlName="przetwarzanieAgreement"
              ></dx-check-box>
              <a
                href="https://wapro.pl/doc/klauzula_informacyjna.pdf"
                target="_blank"
                style="
                  display: inline-block;
                  color: #30393f;
                  position: relative;
                  top: -55px;
                  left: 20px;
                  font-weight: normal;
                  font-family: Inter;
                "
              >
                <strong style="color: #dc1e28; font-size: 12px">* </strong
                >Oświadczam, że zapoznałam/-em się z informacjami o zasadach i
                celach przetwarzania moich danych osobowych</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div class="about-license-wapro-erp">
          <h4 style="padding-bottom: 20px">Kody aktywacji</h4>

          <div class="form-group order-box">
            <label for="login">Numer seryjny <strong>*</strong></label>
            <dx-text-box
              name="SerialNumber"
              placeholder=""
              id="SerialNumber"
              formControlName="SerialNumber"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              [ngClass]="{
                'input-required': submitted && formError.SerialNumber
              }"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="submitted && formError.SerialNumber"
            >
              {{ formError.SerialNumber }}
            </small>
          </div>

          <div class="form-group order-box">
            <label for="login">Numer tajny <strong>*</strong></label>
            <dx-text-box
              name="SecretNumber"
              placeholder=""
              id="SecretNumber"
              formControlName="SecretNumber"
              class="text-box text-left"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off', maxLength: 4 }"
              [maxLength]="4"
              [ngClass]="{
                'input-required': submitted && formError.SecretNumber
              }"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="submitted && formError.SecretNumber"
            >
              {{ formError.SecretNumber }}
            </small>
          </div>
        </div>

        <br />

        <re-captcha
          formControlName="recaptchaReactive"
          (resolved)="resolved($event)"
          style="float: right; margin-bottom: 20px"
        ></re-captcha>
        <br /><br />

        <div class="text-right" style="margin-top: 62px">
          <dx-button
            class="btn-danger"
            text="Załóż konto"
            width="161"
            height="48"
            (onClick)="onSubmit(newUserNoRegister.value)"
            [disabled]="newUserNoRegister.controls.recaptchaReactive.invalid"
          >
          </dx-button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #registerUser>
  <div style="margin-bottom: 200px">
    <form [formGroup]="newUserNoRegister">
      <div class="row" style="margin-bottom: 30px">
        <div class="col-md-12 text-center">
          <h4 style="font-size: 13px">
            Wprowadź dane nowego użytkownika, któremu chcesz nadać dostęp do
            moje konto wapro.
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h4 style="padding-bottom: 20px">Dane logowania</h4>

          <div class="form-group order-box">
            <label for="login">Email <strong>*</strong></label>
            <dx-text-box
              name="login"
              placeholder=""
              id="login"
              formControlName="Login"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              mode="email"
              [ngClass]="{
                'input-required':
                  newUserNoRegister.controls.Login.touched &&
                  !newUserNoRegister.controls.Login.valid
              }"
            >
            </dx-text-box>
            <small
              class="text-danger"
              *ngIf="
                newUserNoRegister.controls.Login.touched &&
                !newUserNoRegister.controls.Login.valid
              "
            >
              Podaj poprawny adres email
            </small>
          </div>

          <div class="form-group order-box">
            <label for="password">Hasło <strong>*</strong></label>
            <dx-text-box
              name="password"
              placeholder=""
              id="password"
              formControlName="Password"
              class="text-box"
              [inputAttr]="{ autocomplete: 'off' }"
              [mode]="modePsw"
              style="padding-right: 70px"
              [ngClass]="{
                'input-required':
                  newUserNoRegister.controls.Password.touched &&
                  !newUserNoRegister.controls.Password.valid
              }"
            >
            </dx-text-box>
            <i
              class="o-button--inInput"
              (click)="
                modePsw == 'password'
                  ? (modePsw = 'text')
                  : (modePsw = 'password')
              "
              style="right: 20px"
            >
              <i class="icon icon-eye eye" [hidden]="modePsw != 'password'"></i>
              <i
                class="icon icon-eye-slash eyslash"
                [hidden]="modePsw == 'password'"
              ></i>
            </i>
            <div
              style="
                margin-left: 13px;
                margin-top: -20px;
                padding-bottom: 20px;
                max-width: 350px;
              "
            >
              <small
                class="text-danger error-msg"
                *ngIf="
                  newUserNoRegister.controls.Password.touched &&
                  !newUserNoRegister.controls.Password.valid
                "
              >
                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i
                znak specjalny
              </small>
            </div>
          </div>

          <div class="form-group order-box pwd-box mpbile-div">
            <label for="password">Powtórz hasło <strong>*</strong></label>
            <dx-text-box
              name="password"
              placeholder=""
              id="password"
              formControlName="PasswordConfirmation"
              class="text-box"
              [inputAttr]="{ autocomplete: 'off' }"
              [mode]="modePswRepeat"
              style="padding-right: 70px"
              [ngClass]="{
                'input-required':
                  newUserNoRegister.controls.PasswordConfirmation.touched &&
                  !newUserNoRegister.controls.PasswordConfirmation.valid
              }"
            >
            </dx-text-box>
            <i
              class="o-button--inInput"
              (click)="
                modePswRepeat == 'password'
                  ? (modePswRepeat = 'text')
                  : (modePswRepeat = 'password')
              "
              style="right: 20px"
            >
              <i
                class="icon icon-eye eye"
                [hidden]="modePswRepeat != 'password'"
                style="left: -30px"
              ></i>
              <i
                class="icon icon-eye-slash eyslash"
                [hidden]="modePswRepeat == 'password'"
                style="left: -30px"
              ></i>
            </i>
            <div
              style="margin-left: 13px; margin-top: -20px; padding-bottom: 20px"
            >
              <small
                class="text-danger error-msg"
                *ngIf="
                  newUserNoRegister.controls.PasswordConfirmation.touched &&
                  !newUserNoRegister.controls.PasswordConfirmation.valid
                "
              >
                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i
                znak specjalny
              </small>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" formGroupName="customerContact">
          <div>
            <h4 style="padding-bottom: 20px">Dane osobowe</h4>

            <div class="form-group right-box">
              <label for="login">Imię <strong>*</strong></label>
              <dx-text-box
                name="firstName"
                placeholder=""
                id="firstName"
                formControlName="firstName"
                class="text-box"
                [showClearButton]="true"
                [inputAttr]="{ autocomplete: 'off' }"
                [ngClass]="{
                  'input-required': submitted && formError.firstName
                }"
              >
              </dx-text-box>
              <small
                class="text-danger"
                *ngIf="submitted && formError.firstName"
              >
                {{ formError.firstName }}
              </small>
            </div>

            <div class="form-group right-box">
              <label for="login">Nazwisko <strong>*</strong></label>
              <dx-text-box
                name="lastName"
                placeholder=""
                id="lastName"
                formControlName="lastName"
                class="text-box"
                [showClearButton]="true"
                [inputAttr]="{ autocomplete: 'off' }"
                [ngClass]="{
                  'input-required': submitted && formError.lastName
                }"
              >
              </dx-text-box>
              <small
                class="text-danger"
                *ngIf="submitted && formError.lastName"
              >
                {{ formError.lastName }}
              </small>
            </div>

            <div class="form-group right-box">
              <label for="login">Telefon komórkowy</label>
              <dx-text-box
                name="MobilePhone"
                placeholder=""
                id="MobilePhone"
                formControlName="MobilePhone"
                class="text-box"
                [showClearButton]="true"
                [inputAttr]="{ autocomplete: 'off' }"
                [ngClass]="{
                  'input-required': submitted && formError.MobilePhone
                }"
              >
              </dx-text-box>
              <small
                class="text-danger"
                *ngIf="submitted && formError.MobilePhone"
              >
                {{ formError.MobilePhone }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-12"
        style="
          margin-top: 0px;
          padding-top: 20px;
          margin-bottom: 30px;
          border-top: 1px solid rgba(218, 222, 225, 0.5);
        "
      >
        <div class="panel-re-captcha">
          <re-captcha
            formControlName="recaptchaReactive"
            (resolved)="resolved($event)"
          ></re-captcha>
        </div>
      </div>

      <div class="text-center" style="margin-bottom: -200px">
        <dx-button
          class="btn-danger"
          text="Załóż konto"
          width="161"
          height="40"
          (onClick)="onSubmit(newUserNoRegister.value)"
          [disabled]="newUserNoRegister.controls.recaptchaReactive.invalid"
        >
        </dx-button>
      </div>
    </form>
  </div>
</ng-template>
