import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { EventService } from '../event.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1366) document.body.style.background = "url('./assets/img/graphics_5.png')  no-repeat 0px 250px, url('./assets/img/graphics_6.png') no-repeat 450px 400px";
    else document.body.style.background = document.body.style.background = "url('./assets/img/graphics_5.png') no-repeat 300px 0px, url('./assets/img/graphics_6.png') no-repeat 1350px 400px";
  }


  constructor(private event: EventService, public _route: Router) { 
    this.event.logOnNavbar.next(false);
    this.event.welcomeMessage.next('');
  }

  ngOnInit(): void {
    if (window.innerWidth < 1366) document.body.style.background = "url('./assets/img/graphics_5.png') no-repeat 0px 250px, url('./assets/img/graphics_6.png') no-repeat 450px 400px";
    else document.body.style.background = document.body.style.background = "url('./assets/img/graphics_5.png') no-repeat 300px 0px, url('./assets/img/graphics_6.png') no-repeat 1350px 400px";
  }

  ngOnDestroy(){
    this.event.logOnNavbar.next(true);
    this.event.welcomeMessage.next('Kontynuuj bez logowania');
  }

  navigatoToLog = () => {
    this._route.navigate(['logowanie']);
    setTimeout(()=>{
      this.event.hideRegisterButton.next(true);
    },0)
  }
}
