<div class="payment-container" style="height: auto; margin-top: 30px">
  <p class="p-chose-bank">Wybierz formę płatności</p>

  <div class="justify-content-center">
    <div class="justify-content-start">
      <div
        class="bank-panel"
        *ngFor="let item of bankList"
        [ngClass]="{ 'selected-bank': item.selected }"
        (click)="selectBank(item)"
      >
        <img [src]="item[3]" [alt]="item[1]" style="width: 110px" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-center">
      <dx-check-box
        style="height: 16px; margin-top: 40px !important"
        [(value)]="agreeRegulations"
      ></dx-check-box>
      <label
        for="scales"
        class="label-checbox"
        [ngClass]="{
          wapro:
            paymentSource?.toLowerCase() == 'wapro' ||
            paymentSource?.toLowerCase() == 'abs',
          asseco: paymentSource?.toLowerCase() == 'asseco',
          softlab: paymentSource?.toLowerCase() == 'softlab',
          macrologic: paymentSource?.toLowerCase() == 'macrologic'
        }"
        >Akceptuję warunki
        <strong
          ><a
            href="https://s3.eu-central-1.amazonaws.com/secure-pliki/regulamin.pdf"
            target="_blank"
            >regulaminu</a
          ></strong
        >
        serwisu Tpay</label
      ><br />

      <button
        type="button"
        (click)="submitPayment()"
        [ngClass]="{
          'btn-wapro':
            paymentSource?.toLowerCase() == 'wapro' ||
            paymentSource?.toLowerCase() == 'abs',
          'btn-asseco':
            paymentSource?.toLowerCase() == 'asseco' ||
            paymentSource?.toLowerCase() == 'softlab',
          'btn-macrologic': paymentSource?.toLowerCase() == 'macrologic'
        }"
        type="confirm"
        class="payment-btn"
        style="margin-top: 10px"
      >
        Zapłać z Tpay
      </button>
    </div>
  </div>
</div>

<div
  class="payment-container payment-container-tpay"
  style="height: auto; margin-top: 30px"
>
  <div class="extra-info">
    <i
      class="icon icon-info"
      style="
        font-size: 21px;
        width: 21px;
        height: 28px;
        position: absolute;
        top: 3px;
        left: 0;
      "
    ></i>
    <div>Informacje dodatkowe</div>
    <div class="payment-description">
      Jeżeli masz jakiekolwiek wątpliwości dotyczące faktury lub systemu
      płatności zadzwoń na numer Działu Sprzedaży Asseco WAPRO
      <strong>22 702 89 02</strong> lub wyślij numer na adres
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource?.toLowerCase() == 'wapro' ||
            paymentSource?.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource?.toLowerCase() == 'asseco' ||
            paymentSource?.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource?.toLowerCase() == 'macrologic'
        }"
        href="mailto:sprzedaz.wapro@assecobs.pl"
        >sprzedaz.wapro&#64;assecobs.pl</a
      >
    </div>
    <div class="payment-description">
      Więcej informacji na witrynach
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource?.toLowerCase() == 'wapro' ||
            paymentSource?.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource?.toLowerCase() == 'asseco' ||
            paymentSource?.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource?.toLowerCase() == 'macrologic'
        }"
        href="https://tpay.com/"
        >Tpay</a
      >
      oraz
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource?.toLowerCase() == 'wapro' ||
            paymentSource?.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource?.toLowerCase() == 'asseco' ||
            paymentSource?.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource?.toLowerCase() == 'macrologic'
        }"
        href="https://asseco.pl"
        >Asseco BS</a
      >
    </div>
  </div>
</div>
