<form [formGroup]="changePswForm">
    <h4 style="padding-bottom: 20px;">Hasło</h4>
    <div style="max-width:350px; margin: 0 auto;">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="oldPassword">Stare hasło <strong>*</strong></label>
                    <dx-text-box class="text-box" [ngClass]="{'input-required': submitted && formError.oldPassword }"
                        name="oldPassword" placeholder="" id="oldPassword" formControlName="oldPassword"
                        [mode]="modePsw" [showClearButton]="true" style="padding-right: 70px;">
                    </dx-text-box>
                    <i class="o-button--inInput" (click)="(modePsw == 'password')?modePsw='text':modePsw='password'">
                        <i class="icon icon-eye" [hidden]="modePsw != 'password'"></i>
                        <i class="icon icon-eye-slash" [hidden]="modePsw == 'password'"></i>
                    </i>
                    <small class="text-danger" *ngIf="submitted && formError.oldPassword">
                        <p>{{ formError.oldPassword}}</p>
                    </small>
                </div>
                <div class="form-group">
                    <label for="newPassword">Nowe hasło <strong>*</strong></label>
                    <dx-text-box class="text-box"
                        [ngClass]="{'input-required': changePswForm.controls.newPassword.touched && formError.newPassword }"
                        name="newPassword" placeholder="" id="newPassword" formControlName="newPassword"
                        [mode]="modeNewPsw" [showClearButton]="true" style="padding-right: 70px;">
                    </dx-text-box>
                    <i class="o-button--inInput"
                        (click)="(modeNewPsw == 'password')?modeNewPsw='text':modeNewPsw='password'">
                        <i class="icon icon-eye" [hidden]="modeNewPsw != 'password'"></i>
                        <i class="icon icon-eye-slash" [hidden]="modeNewPsw == 'password'"></i>
                    </i>
                    <small class="text-danger"
                        *ngIf="changePswForm.controls.newPassword.touched && formError.newPassword">
                        <p>{{ formError.newPassword}}</p>
                    </small>
                </div>
                <div class="form-group">
                    <label for="repeatNewPsw">Powtórz nowe hasło <strong>*</strong></label>
                    <dx-text-box class="text-box"
                        [ngClass]="{'input-required': changePswForm.controls.repeatNewPsw.touched && formError.repeatNewPsw }"
                        name="repeatNewPsw" placeholder="" id="repeatNewPsw" style="padding-right: 70px;"
                        formControlName="repeatNewPsw" [mode]="modeNewPswRepeat" [showClearButton]="true">
                    </dx-text-box>
                    <i class="o-button--inInput"
                        (click)="(modeNewPswRepeat == 'password')?modeNewPswRepeat='text':modeNewPswRepeat='password'">
                        <i class="icon icon-eye" [hidden]="modeNewPswRepeat != 'password'"></i>
                        <i class="icon icon-eye-slash" [hidden]="modeNewPswRepeat == 'password'"></i>
                    </i>
                    <small class="text-danger"
                        *ngIf="changePswForm.controls.repeatNewPsw.touched && formError.repeatNewPsw">
                        <p>{{ formError.repeatNewPsw}}</p>
                    </small>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        
        <div class="col-md-12" style="margin-top: 0px; padding-top: 20px; margin-bottom: 30px; border-top: 1px solid rgba(218, 222, 225, 0.5);">
            <div class="panel-re-captcha">
                <re-captcha formControlName="recaptchaReactive" (resolved)="resolved($event)"></re-captcha>
            </div>            
        </div>

        <div class="col-md-12 text-center">
            <dx-button class="btn-danger btn-center-350" text="Zapisz zmiany" width="179" height="40" style="margin-right:5px;"
                (onClick)="onSubmit(changePswForm.value)" [disabled]="changePswForm.controls.recaptchaReactive.invalid">
            </dx-button>
        </div>
    </div>
</form>