import { Component, OnInit } from '@angular/core';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';

import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {

  addresses: Addresses = {
    invoiceAddress: {
      company: '',
      name: '',
      street: '',
      postalCode: '',
      city: '',
      phone: '',
      email: '',
      vatNumber: ''
    },
    receiverAddress: {
      company: '',
      name: '',
      street: '',
      postalCode: '',
      city: '',
      phone: '',
      email: '',
      vatNumber: ''
    },
    partnerInfo: {
      rowNumber: null,
      partnerId: null,
      partnerName: '',
      city: '',
      www: '',
      vatNumber: ''
    }
  };

  UserRole: string = '';

  constructor(private event: EventService, private appService: AppServices) { 
    if(localStorage.getItem('wapro-erp-token')) this.UserRole = helper.decodeToken(localStorage.getItem('wapro-erp-token')).UserRole;
  }

  ngOnInit(): void {
    this.getAddresses();
  }

  getAddresses = () => {
    this.appService.getAuth(`customer/Addresses`).subscribe(response =>{
      this.addresses = response;
      return this.addresses;
    },
    error => {
      this.event.showNotification('error', JSON.parse(error).message) 
    })  
  }

}

export interface Addresses {
  invoiceAddress: {
    company: string,
    name: string,
    street: string,
    postalCode: string,
    city: string,
    phone: string,
    email: string,
    vatNumber: string
  },
  receiverAddress: {
    company: string,
    name: string,
    street: string,
    postalCode: string,
    city: string,
    phone: string,
    email: string,
    vatNumber: string
  },
  partnerInfo: {
    rowNumber: number,
    partnerId: number,
    partnerName: string,
    city: string,
    www: string,
    vatNumber: string
  }
}