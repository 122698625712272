import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-psw',
  templateUrl: './reset-psw.component.html',
  styleUrls: ['./reset-psw.component.scss']
})
export class ResetPswComponent implements OnInit, OnDestroy {

  resetPswForm;
  submitted: boolean = false;

  formError = {
    login: '',
  }

  validationMessages = {
    login: {
      required: 'login jest wymagany'
    }
  }

  description: string = '';

  constructor(private appService: AppServices, private event: EventService, public formBuilder: FormBuilder) {
    this.event.logOnNavbar.next(false);
    this.event.welcomeMessage.next('Powrót do ekranu logowania');
  }

  ngOnInit(): void {
    document.body.style.background = "url('./assets/img/top_small.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
    this.createform();

    this.resetPswForm.valueChanges.subscribe((value) => {
      this.event.onControlValueChanged(this.resetPswForm, this.formError, this.validationMessages);
    });
  }

  resolved(captchaResponse: string) {
    this.resetPswForm.controls.recaptchaReactive.setValue(captchaResponse);
  }

  ngOnDestroy() {
    document.body.style.background = "none";
    this.event.welcomeMessage.next('Kontynuuj bez logowania');
    this.event.logOnNavbar.next(true);
  }

  createform = () => {
    this.resetPswForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: [''],
      recaptchaReactive: [null, Validators.required]
    })
  }
 
  onSubmit = (values) => {
    this.submitted = true;
    this.description = '';
    if (this.resetPswForm.invalid) {
      this.event.onControlValueChanged(this.resetPswForm, this.formError, this.validationMessages);
      return;
    }
    else {
      this.appService.post(`authorization/ResetPasswordPre`, this.resetPswForm.getRawValue()).subscribe(response => {
        if (response.status === false) {
          this.event.showNotification('error', response.description);
        }
        else {
          this.resetPswForm.controls.login.setValue('');
          this.submitted = false;

          // let pom = response.description.replace('Aby zmienić hasło w systemie Asystent WAPRO ERP kliknij w poniższy link: <br><br>', '');
          // pom = pom.replace('<br><br><br><b>Link ważny jest do', '');
          // let namesTable = pom.split(" ");
          // let inf = `Wysłaliśmy do Ciebie link na podany adres email do resetowania hasła. Link ważny jest do ${namesTable[1]}`

          this.event.showNotification('success', response.description)
        }
      })
    }
  }
}
