import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component'
import { PaymentSuccessComponent } from './payment-success/payment-success.component'
import { TpayBankComponent } from './tpay-bank/tpay-bank.component'

const routes: Routes = [
  {
    path: '', component: DashboardComponent
  },
  {
    path: 'sukces', component: PaymentSuccessComponent
  },
  {
    path: 'niepowodzenie', component: PaymentFailComponent
  },
  {
    path: 'bank', component: TpayBankComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class routing {}
