import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError, ReplaySubject, TimeoutError } from "rxjs";
import { retry, catchError, tap, map } from "rxjs/operators";

import { environment } from '../../environments/environment';

import { Router } from '@angular/router';
import {from} from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class AppServicesPayment {
  baseUrl = environment.apipeymentlink;

  headers: HttpHeaders;

  constructor(private http: HttpClient, private _route: Router) {
    this.headers = new HttpHeaders();
    //this.baseUrl = "https://pay2dev.abs.assecobs.pl/Transaction/"
  }

  get(uri): Observable<any> {
    return this.http
      .get<any>(this.baseUrl + uri, {headers: this.headers})
      .pipe(retry(1), catchError(this.errorHandl));
  }

  post(uri, date): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + uri, date, {headers: this.headers})
      .pipe(retry(1), catchError(this.errorHandl));
  }

  errorHandl(error) {
    let errorMessage = "";
    errorMessage = JSON.stringify({ error: error.status, message: (error.error!==null)?error.error.title:'Brak dostępu'});
    return throwError(errorMessage);
  }

  //I get list bank for available for client
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
}
