import { Component, OnInit } from '@angular/core';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  submitted: boolean = false;

  formError = {
    firstName: '',
    lastName: '',
    email: ''
  }

  validationMessages = {
    firstName: {
      required: 'Imię jest wymagane'
    },
    lastName: {
      required: 'Nazwisko jest wymagane'      
    },
    email:{
      required: 'Email jest wymagany',
      email: 'Podaj poprawny email'
    }
  }

  formContact;

  constructor(private event: EventService, public formBuilder: FormBuilder, private appService: AppServices) { }

  ngOnInit(): void {
    this.createForm();
    this.getContact();

    this.formContact.valueChanges.subscribe(()=>{
      this.event.onControlValueChanged(this.formContact, this.formError, this.validationMessages)
    })
  }

  resolved(captchaResponse: string) {
    this.formContact.controls.recaptchaReactive.setValue(captchaResponse);   
 }

  createForm = () => {
    this.formContact = this.formBuilder.group({
      contactId: [null, Validators.required],
      customerId: [null, Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobilePhone: [''],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      recaptchaReactive: [null, Validators.required]
    })
  }

  getContact = () => {
    this.appService.getAuth(`customer/Contacts`).subscribe(response=>{
      this.formContact.patchValue(response);
      this.formContact.controls.email.setValue(this.formContact.get('email').value.replace(' ', ''));
    })
  }


  onSubmit = (values) => {
    this.submitted = true;
    if(this.formContact.invalid){
        this.event.onControlValueChanged(this.formContact, this.formError, this.validationMessages);
        return;
    }
    else{
      this.appService.postAuth(`customer/Contacts`, values).subscribe(response=>{
        if(response.status) this.event.showNotification('success', 'Dane zostały zaktualizowane')
      }, 
      error=>{
        this.event.showNotification('error', JSON.parse(error).message)
      })
    }
  }

}
