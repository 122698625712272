<div class="row" *ngIf="!isUnauthorized">
  <div class="col-md-12">
    <a style="cursor: pointer" (click)="history()"
      ><i class="icon absui-icon--arrow-back"></i>
    </a>
    <h5>Dopasuj {{ operatingSystem }} do swoich potrzeb</h5>
  </div>
</div>

<div
  [ngClass]="{
    'match-wapro-erp':
      dateToCalculate.myProductOrders[0].licenseId != 0 &&
      dateToCalculate.myProductOrders[0].licenseId != -1
  }"
  *ngIf="
    dateToCalculate.myProductOrders[0].licenseId != 0 &&
    dateToCalculate.myProductOrders[0].licenseId != -1
  "
>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-12">
      <div class="flex-container">
        <div>
          <div class="hexagon-red">
            <p>{{ dateToCalculate.myProductOrders[0].productNameShortcut }}</p>
          </div>
        </div>
        <div style="margin-right: 50px">
          <h2 class="product-description-wapro-erp" style="text-align: left">
            {{ dateToCalculate.myProductOrders[0].productNameDescription }}
          </h2>
          <h4 class="h1-product-name-wapro-erp">
            WAPRO
            <strong>{{
              dateToCalculate.myProductOrders[0].productName
            }}</strong>
          </h4>
        </div>

        <div>
          <i
            class="icon"
            [ngClass]="{
              'absui-icon--expand-more': showDescription,
              'absui-icon--is-greate': !showDescription
            }"
            style="margin-left: -8px; margin-top: 10px; cursor: pointer"
            (click)="showDescription = !showDescription"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showDescription">
    {{ dateToCalculate.myProductOrders[0].descriptionProduct }}

    <div
      *ngIf="dateToCalculate.myProductOrders[0].requiredProducts"
      style="margin-top: 20px"
    >
      Produkty wymagane:
      <br />
      <ul style="margin-top: 10px">
        <li
          *ngFor="
            let item of dateToCalculate.myProductOrders[0].requiredProducts
          "
        >
          <p class="h1-product-name-wapro-erp">
            WAPRO<strong>{{ item.requiredProductName }}</strong>
          </p>
        </li>
      </ul>
    </div>
  </div>
  <div class="row" style="margin-top: 25px">
    <div class="col-md-2 col-6">
      <p class="p-header-license-wapro-erp">Licencja</p>
      <p class="p-row-license-wapro-erp">
        {{ dateToCalculate.myProductOrders[0].licenseNumber }}
      </p>
    </div>

    <div class="col-md-2 col-6">
      <p class="p-header-license-wapro-erp">Wersja</p>
      <p class="p-row-license-wapro-erp">
        {{ dateToCalculate.myProductOrders[0].versionNumber }}
      </p>
    </div>

    <div class="col-md-2 col-6">
      <p class="p-header-license-wapro-erp">Data aktywacji</p>
      <p class="p-row-license-wapro-erp">
        {{
          dateToCalculate.myProductOrders[0].lastActivationDate
            | date : "yyyy-MM-dd"
        }}
      </p>
    </div>

    <div
      class="col-md-2 col-6"
      *ngIf="
        dateToCalculate.myProductOrders[0].expireDate && dayToExpireDate > 0
      "
    >
      <p class="p-header-license-wapro-erp">Data wygaśnięcia</p>
      <p class="p-row-license-wapro-erp">
        {{
          dateToCalculate.myProductOrders[0].expireDate | date : "yyyy-MM-dd"
        }}
        ({{ dayToExpireDate }} dni)
      </p>
    </div>

    <div class="col-md-2 col-6">
      <p class="p-header-license-wapro-erp">Linia - wariant</p>
      <p class="p-row-license-wapro-erp">
        {{ dateToCalculate.myProductOrders[0].lineDescription }}
      </p>
    </div>

    <div class="col-md-2 col-6">
      <p class="p-header-license-wapro-erp">Stanowiska</p>
      <p class="p-row-license-wapro-erp">
        {{ dateToCalculate.myProductOrders[0].numberOfUsers }}
      </p>
    </div>
  </div>
</div>

<div class="about-license-wapro-erp" style="padding: 25px 25px 0px 25px">
  <div
    class="flex-container"
    *ngIf="
      dateToCalculate.myProductOrders[0].licenseId == 0 ||
      dateToCalculate.myProductOrders[0].licenseId == -1
    "
    style="padding-bottom: 25px"
  >
    <div>
      <div class="hexagon-red">
        <p>{{ dateToCalculate.myProductOrders[0].productNameShortcut }}</p>
      </div>
    </div>
    <div style="margin-right: 50px">
      <h2 class="product-description-wapro-erp" style="text-align: left">
        {{ dateToCalculate.myProductOrders[0].productNameDescription }}
      </h2>
      <h4 class="h1-product-name-wapro-erp">
        WAPRO
        <strong>{{ dateToCalculate.myProductOrders[0].productName }}</strong>
      </h4>
    </div>

    <div>
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-more': showDescription,
          'absui-icon--is-greate': !showDescription
        }"
        style="margin-left: -8px; margin-top: 10px; cursor: pointer"
        (click)="showDescription = !showDescription"
      ></i>
    </div>
  </div>

  <div
    *ngIf="showDescription && dateToCalculate.myProductOrders[0].licenseId == 0"
  >
    {{ dateToCalculate.myProductOrders[0].descriptionProduct }}

    <div
      *ngIf="dateToCalculate.myProductOrders[0].requiredProducts"
      style="margin-top: 20px"
    >
      Produkty wymagane:

      <ul style="margin-top: 10px">
        <li
          *ngFor="
            let item of dateToCalculate.myProductOrders[0].requiredProducts
          "
        >
          <p class="h1-product-name-wapro-erp">
            WAPRO<strong>{{ item.requiredProductName }}</strong>
          </p>
        </li>
      </ul>
    </div>
    <a
      style="color: #dc1e28; cursor: pointer; font-size: 11px"
      (click)="
        $event.stopPropagation();
        event.sendUrl.next(dateToCalculate.myProductOrders[0].productUrl);
        showMoreVisible = event.openReadMore(
          dateToCalculate.myProductOrders[0].productUrl
        )
      "
      >Czytaj więcej</a
    >
  </div>

  <div class="d-flex flex-wrap" style="margin-top: 12px">
    <div class="">
      <label
        class="label-wapro-erp"
        for="variantName"
        id="variantDescription"
        style="cursor: pointer"
        >Wariant</label
      >
      <dx-select-box
        [dataSource]="variantsList"
        displayExpr="variantName"
        valueExpr="variantId"
        class="no-box-shadow selected-modifi"
        placeholder="Wariant"
        id="variantName"
        [(ngModel)]="dateToCalculate.myProductOrders[0].toVariantId"
        [dropDownOptions]="{ closeOnTargetScroll: false }"
        (onSelectionChanged)="onSelectionChanged($event)"
        style="width: 285px; height: 42px"
      >
      </dx-select-box>

      <dx-tooltip
        target="#variantDescription"
        showEvent="dxhoverstart"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="{ name: 'mouseover', delay: 500 }"
        *ngIf="dateToCalculate.myProductOrders[0].variantDescription != null"
      >
        <div
          *dxTemplate="let data = data; of: 'content'"
          class="tooltipContent"
        >
          {{ dateToCalculate.myProductOrders[0].variantDescription }}

          <br /><br />

          {{ dateToCalculate.myProductOrders[0].eduLicenseDescription }}
        </div>
      </dx-tooltip>
    </div>
    <div class="number-of-users" *ngIf="packetsList.length > 0">
      <label
        class="label-wapro-erp"
        for="variantName"
        id="packetDescription"
        style="cursor: pointer"
        >Pakiet</label
      >
      <dx-select-box
        [dataSource]="packetsList"
        displayExpr="packetName"
        valueExpr="packetId"
        id="packetName"
        class="no-box-shadow"
        placeholder="Pakiet"
        [(ngModel)]="dateToCalculate.myProductOrders[0].toPacketId"
        [dropDownOptions]="{ closeOnTargetScroll: false }"
        (onSelectionChanged)="onSelectionPacketChanged($event)"
        style="width: 285px; height: 42px"
      >
      </dx-select-box>

      <dx-tooltip
        target="#packetDescription"
        showEvent="dxhoverstart"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="{ name: 'mouseover', delay: 500 }"
        *ngIf="dateToCalculate.myProductOrders[0].variantDescription != null"
      >
        <div
          *dxTemplate="let data = data; of: 'content'"
          class="tooltipContent"
        >
          {{ dateToCalculate.myProductOrders[0].packetDescription }}
        </div>
      </dx-tooltip>
    </div>
    <div class="number-of-users" *ngIf="maxNumberOfUsers > 1">
      <label class="label-wapro-erp" for="toNumberOfUsers"
        >Liczba stanowisk</label
      >
      <dx-number-box
        [showSpinButtons]="true"
        [min]="1"
        [max]="maxNumberOfUsers"
        class="no-box-shadow"
        [(ngModel)]="dateToCalculate.myProductOrders[0].toNumberOfUsers"
        (onValueChanged)="onChange()"
        style="width: 135px; height: 42px"
        [useLargeSpinButtons]="useLargeSpinButtons"
        id="NumberOfUsers"
      >
      </dx-number-box>
    </div>
    <div class="number-of-user">
      <div
        *ngIf="
          (UserRole === 'partner' || UserRole === 'subpartner') &&
          dateToCalculate.myProductOrders[0].licenseId == 0
        "
        style="margin-left: 10px"
      >
        <label class="label-wapro-erp">Liczba pakietów</label>
        <dx-number-box
          [showSpinButtons]="true"
          [min]="1"
          class="no-box-shadow"
          id="NumberOfPackets"
          [(ngModel)]="dateToCalculate.myProductOrders[0].numberOfPackets"
          (onValueChanged)="onChange()"
          style="width: 135px; height: 42px"
          [useLargeSpinButtons]="useLargeSpinButtons"
        >
        </dx-number-box>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 20px; margin-left: -13px"
    *ngIf="isSkipChangeLicenseDate"
  >
    <div class="col-md-12">
      <dx-select-box
        [dataSource]="radioButtonList"
        displayExpr="label"
        valueExpr="value"
        class="no-box-shadow"
        [(ngModel)]="dateToCalculate.myProductOrders[0].skipChangeLicenseDate"
        id="skipChangeLicenseDate"
        [dropDownOptions]="{ closeOnTargetScroll: false }"
        (onSelectionChanged)="onChange()"
        width="283"
      >
      </dx-select-box>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="padding-left: 35px; padding-right: 35px">
      <div *ngIf="basket.length !== 0; else noData" style="margin-top: 20px">
        <dx-data-grid
          [dataSource]="basket"
          [allowColumnReordering]="false"
          [showBorders]="true"
          [showRowLines]="true"
          [columnAutoWidth]="true"
          wordWrapEnabled="true"
          [showBorders]="false"
          [showColumnLines]="false"
          (onCellClick)="onAdaptiveDetailRowPreparing($event)"
          [height]="heightGrid + 7"
        >
          <dxi-column
            dataField="description"
            caption="Szczegóły"
            [allowSorting]="false"
            cellTemplate="descriptionCell"
          >
          </dxi-column>
          <dxi-column
            dataField="quantity"
            caption="Stanowiska"
            [allowSorting]="false"
            width="150"
            alignment="right"
            [hidingPriority]="0"
            cellTemplate="quantityCell"
          ></dxi-column>
          <dxi-column
            dataField="netPrice"
            caption="Cena netto za szt."
            [hidingPriority]="1"
            [allowSorting]="false"
            cellTemplate="netPriceCell"
            alignment="right"
            width="150"
          >
          </dxi-column>
          <dxi-column
            dataField="amount"
            caption="Kwota"
            [allowSorting]="false"
            alignment="right"
            width="100"
            cellTemplate="amountCell"
            width="120"
          ></dxi-column>
          <dxi-column
            dataField="discountType"
            caption="R"
            [allowSorting]="false"
            alignment="center"
            width="50"
            cellTemplate="discountType"
            [hidingPriority]="3"
            *ngIf="UserRole === 'partner' || UserRole === 'subpartner'"
          ></dxi-column>
          <div *dxTemplate="let data of 'discountType'">
            <p
              class="p-row-wapro-erp"
              [id]="'row' + data.rowIndex + data.data.productId"
            >
              {{ data.data.discountType }}
            </p>

            <dx-tooltip
              [target]="'#row' + data.rowIndex + data.data.productId"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
              *ngIf="data.data.discountType == 'R'"
            >
              <div *dxTemplate="let data of 'content'">rabat rozwojowy</div>
            </dx-tooltip>

            <dx-tooltip
              [target]="'#row' + data.rowIndex + data.data.productId"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
              *ngIf="data.data.discountType == 'U'"
            >
              <div *dxTemplate="let data of 'content'">rabat utrzymaniowy</div>
            </dx-tooltip>

            <dx-tooltip
              [target]="'#row' + data.rowIndex + data.data.productId"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
              *ngIf="data.data.discountType == 'S'"
            >
              <div *dxTemplate="let data of 'content'">
                rabat WAPRO STANDARD
              </div>
            </dx-tooltip>

            <dx-tooltip
              [target]="'#row' + data.rowIndex + data.data.productId"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
              *ngIf="data.data.discountType == 'A'"
            >
              <div *dxTemplate="let data of 'content'">usługi abonamentowe</div>
            </dx-tooltip>
          </div>

          <dxo-scrolling mode="virtual" showScrollbar="never"></dxo-scrolling>

          <dxo-sorting mode="none"></dxo-sorting>

          <div *dxTemplate="let data of 'descriptionCell'">
            <p class="p-row-wapro-erp">{{ data.data.description }}</p>
          </div>

          <div *dxTemplate="let data of 'quantityCell'">
            <p class="p-row-wapro-erp">{{ data.data.quantity }}</p>
          </div>

          <div *dxTemplate="let data of 'netPriceCell'">
            <p class="p-row-wapro-erp">
              {{ data.data.netPrice | currency : " " | pointReplacer }}
            </p>
          </div>

          <div *dxTemplate="let data of 'amountCell'">
            <p class="p-row-price-wapro-erp">
              {{ data.data.amount | currency : " " | pointReplacer }}
            </p>
          </div>
        </dx-data-grid>
        <br /><br />
      </div>
    </div>
  </div>
</div>

<div class="row" style="padding: 25px 15px">
  <div class="col-md-12" *ngIf="isUnauthorized">
    <p style="font-weight: bold; margin-bottom: 20px">
      Jeśli posiadasz już produkty i usługi Wapro ERP, zaloguj się aby sprawdzić
      rzeczywiste koszty aktualizacji/rozbudowy swoich produktów.
    </p>
  </div>
  <div class="col-md-6 col-12">
    <p class="label-wapro-erp">Uwagi do pozycji</p>
    <dx-text-area
      [(ngModel)]="dateToCalculate.myProductOrders[0].remarks"
      class="no-box-shadow"
      [height]="105"
      style="background: #fdfeff; border-radius: 30px"
      valueChangeEvent="input"
      id="remarks"
    >
    </dx-text-area>

    <div
      *ngIf="
        (UserRole === 'partner' || UserRole === 'subpartner') &&
        dateToCalculate.myProductOrders[0].licenseId == 0
      "
      style="margin-top: 10px"
    >
      <label class="label-wapro-erp">Nip użytkownika licencji</label>
      <dx-text-box
        style="height: 45px"
        valueChangeEvent="input"
        [(ngModel)]="nipClient"
        [maxLength]="15"
        (onValueChanged)="checkNip()"
        id="nipClient"
      ></dx-text-box>
      <p *ngIf="!isNipOK && nipClient.length != 0" class="incorrectNip">
        Niepoprawny NIP
      </p>
      <p class="label-wapro-erp" style="margin-top: 10px; width: 100%">
        Typ rabatu może ulec zmianie po weryfikacji numer Nip kontrahenta przez
        dział handlowy.
      </p>
    </div>
  </div>

  <div class="col-md-6 col-12 text-right" style="padding-top: 20px">
    <p class="p-price-wapro-erp">
      Do zapłaty
      <strong>{{ countToPrices | currency : " " | pointReplacer }}</strong> PLN
    </p>
    <p class="p-price-netto-wapro-erp">
      {{ countNetPrice | currency : " " | pointReplacer }} netto
    </p>
    <dx-button
      class="btn-default"
      text="Oblicz"
      (onClick)="count()"
      id="count"
      style="margin-right: 10px; margin-top: 29px; width: 119px; height: 40px"
    >
    </dx-button>
    <dx-button
      class="btn-danger"
      text="Dodaj"
      [disabled]="isChange || !agreement ? true : false"
      (onClick)="addToBasket()"
      style="margin-top: 29px; width: 119px; height: 40px"
      id="addToBasket"
    ></dx-button>
  </div>

  <div
    *ngIf="
      dateToCalculate.myProductOrders[0].agreementCode !== '' &&
      dateToCalculate.myProductOrders[0].agreementCode
    "
  >
    <dx-check-box
      [(ngModel)]="agreement"
      style="margin-left: 20px"
      id="agreement"
    >
    </dx-check-box
    ><a
      href="https://wapro.pl/doc/Regulamin_sklep_assecobs_pl.pdf"
      target="_blank"
      style="color: #dc1e28; margin-left: 10px; margin-top: -2px"
      >Zapoznałem się i akceptuje regulamin usługi</a
    >
  </div>

  <div
    class="col-md-12 info-variant"
    *ngIf="
      dateToday &&
      ((dateToCalculate.myProductOrders[0] &&
        dateToCalculate.myProductOrders[0].toVariantName == 'BIZNES') ||
        dateToCalculate.myProductOrders[0].toVariantName == 'START' ||
        dateToCalculate.myProductOrders[0].toVariantName == 'BIZNES/FILIA' ||
        dateToCalculate.myProductOrders[0].toPacketName == 'BIZNES' ||
        dateToCalculate.myProductOrders[0].toPacketName == 'START' ||
        dateToCalculate.myProductOrders[0].toPacketName == 'BIZNES/FILIA') &&
      dateToCalculate.myProductOrders[0].toVariantName != '30 DNI'
    "
  >
    <p style="margin-bottom: 0px">
      Uwaga! Informujemy, że od dnia 01.01.2021 r. wycofane zostały warianty
      oraz pakiety START oraz BIZNES w linii standard (licencje bezterminowe) i
      zastąpione wariantami 365 START oraz 365 BIZNES z linii produktów 365
      (licencje roczne abonamentowe). Twoja kalkulacja zawiera cenę przejścia z
      linii standard do linii 365 produktów Wapro.
    </p>
  </div>

  <div class="col-md-12" style="padding-top: 20px">
    <p>
      Pełny aktualny cennik oraz cenniki archiwalne dostępne są na stronie
      <a href="https://wapro.pl/cennik/" target="_blank"
        >https://wapro.pl/cennik/</a
      >
    </p>
  </div>

  <!-- <div class="col-md-12">
    <img src="../../assets/img/icon_alert_black.svg" alt="wapro erp" class="img-info-mobile">
    <p class="p-info-wapro-erp">

      Procent wartości nowego oprogramowania. Wartość ta może ulec zmianie po
      weryfikacji przez dział handlowy.</p>

  </div> -->
</div>

<div class="row" *ngIf="recommendedProducts.length > 0">
  <div class="col-md-12">
    <div class="about-license-wapro-erp">
      <h4>Produkty i usługi, które mogą Cię zainteresować</h4>
      <div class="row">
        <dx-gallery
          #gallery
          id="gallery"
          [dataSource]="recommendedProducts"
          width="100%"
          [height]="heightRecommendate"
          [slideshowDelay]="slideshowDelay"
          [loop]="false"
          [showNavButtons]="true"
          [showIndicator]="true"
          style="max-width: 100%"
        >
          <div *dxTemplate="let item of 'item'">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div
                  style="
                    position: relative;
                    top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                  "
                >
                  <div class="hexagon-red" style="margin-top: -8px">
                    <p
                      [ngClass]="{
                        'p-smaler-name-shortcut':
                          item.productNameShortcut.length === 3
                      }"
                    >
                      {{ item.productNameShortcut }}
                    </p>
                  </div>
                  <div style="margin-top: -8px" class="text-left">
                    <h2
                      class="product-description-wapro-erp"
                      style="text-align: left"
                    >
                      {{ item.productNameDescription }}
                    </h2>
                    <h4 class="h1-product-name-wapro-erp">
                      WAPRO
                      <strong>{{ item.productName }}</strong>
                    </h4>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-3 col-sm-3 text-right mobile-float-right"
                style="position: relative; top: 15px"
              >
                <p class="p-price-wapro-erp">
                  od
                  <strong>{{
                    item.basePrice.toFixed(2) | number | pointReplacer
                  }}</strong>
                  PLN
                </p>
                <p class="p-detalis-offer-wapro-erp">
                  Licencja 365 • Netto • Rocznie
                </p>
              </div>
              <div
                class="col-lg-2 col-md-3"
                style="position: relative; top: 25px"
              >
                <dx-button
                  class="btn-danger"
                  [text]="getButtonText(item)"
                  style="width: 149px; height: 40px"
                  (onClick)="addToBasketRecommendateProduct(item)"
                >
                </dx-button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-10 textarea-mobile">
                <textarea
                  style="
                    width: 100%;
                    font-size: 11px;
                    border: none;
                    resize: none;
                    margin-top: 10px;
                    background: transparent;
                  "
                  [(ngModel)]="item.description"
                  disabled
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 textarea-mobile text-left">
                <a
                  style="color: #dc1e28; cursor: pointer; font-size: 11px"
                  (click)="
                    $event.stopPropagation();
                    event.sendUrl.next(item.productUrl);
                    showMoreVisible = event.openReadMore(productUrl)
                  "
                  >Czytaj więcej</a
                >
              </div>
            </div>
          </div>
        </dx-gallery>
      </div>
    </div>
  </div>
</div>

<app-read-more
  [isVisible]="showMoreVisible"
  (onClosing)="showMoreVisible = false; cd.detectChanges()"
  [(url)]="dateToCalculate.myProductOrders[0].productUrl"
></app-read-more>

<ng-template #noData>
  <div class="col-md-12 text-center" style="padding-top: 10px">
    <app-no-data></app-no-data>
  </div>
</ng-template>
