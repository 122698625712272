import { Component, OnInit, ViewChild } from '@angular/core';
import { EventService } from '../event.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  order = {orderNumber:'', amountToPay: 0};

  constructor(private event: EventService, private _route: Router) { 
    this.event.showNavbar.next(true);

    this.event.informationAboutOrder.subscribe(order=>{
      this.order = order;
    })
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('orderInformation')){
      let dateSessionStorage = JSON.parse(this.event.decryptString(sessionStorage.getItem('orderInformation')));
      this.order = dateSessionStorage;
    }
  }

}
