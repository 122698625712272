<div class="row">
  <div class="col-md-12">
    <a style="cursor: pointer" (click)="event.historyBack()"
      ><i class="icon absui-icon--arrow-back"></i>
    </a>
    <h5>Historia licencji</h5>
  </div>
</div>

<div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 40px">
  <div class="col-md-12" *ngIf="historyList.length !== 0; else noData">
    <div>
      <div class="row">
        <div class="col-lg-4 col-12">
          <div class="flex-container">
            <div>
              <div class="hexagon-red">
                <p
                  [ngClass]="{
                    'p-smaler-name-shortcut':
                      historyList[0].productNameShortcut.length === 3
                  }"
                >
                  {{ historyList[0].productNameShortcut }}
                </p>
              </div>
            </div>
            <div>
              <h2
                class="product-description-wapro-erp"
                style="text-align: left"
              >
                {{ historyList[0].productNameDescription }}
              </h2>
              <h4 class="h1-product-name-wapro-erp">
                WAPRO <strong>{{ historyList[0].productName }}</strong>
              </h4>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <div class="row">
            <div class="col-md-3 col-6">
              <p class="p-header-license-wapro-erp">Licencja</p>
              <p class="p-row-license-wapro-erp" *ngIf="historyList[0]">
                {{ historyList[0].licenseNumber }}
              </p>
            </div>

            <div class="col-md-3 col-6">
              <p class="p-header-license-wapro-erp">Data ostatniej aktywacji</p>
              <p class="p-row-license-wapro-erp" *ngIf="historyList[0]">
                {{ historyList[0].dateOfOrder | date : "yyyy-MM-dd" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row">
    <div class="col-lg-12">
        <p class="p-history-product-name-wapro-erp">WAPRO <strong>{{productName}}</strong></p>
        <p class="p-history-info-wapro-erp">Licencja <strong>{{licenseId}}</strong></p>
        <p class="p-history-info-wapro-erp">Data ostatniej aktywacji
            <strong>{{lastActivationDate | date: 'yyyy-MM-dd'}}</strong></p>
    </div>
</div> -->

<div
  class="row about-license-wapro-erp"
  *ngIf="historyList.length > 0"
  style="margin-right: 0px; margin-left: 0px"
>
  <div class="col-lg-12">
    <dx-data-grid
      id="gridContainer"
      [dataSource]="historyList"
      [allowColumnReordering]="false"
      [showBorders]="true"
      [columnAutoWidth]="true"
      wordWrapEnabled="true"
      [showBorders]="false"
      [showColumnLines]="false"
      [hoverStateEnabled]="true"
      [showRowLines]="true"
      [height]="heightGrid"
      (onCellClick)="onAdaptiveDetailRowPreparing($event)"
    >
      <dxi-column
        dataField="dateOfOrder"
        caption="Data"
        [allowSorting]="false"
        width="130"
        alignment="left"
        cellTemplate="dateOfOrderCell"
      ></dxi-column>
      <dxi-column
        dataField="versionNumber"
        caption="Wersja"
        [allowSorting]="false"
        width="150"
        alignment="left"
        cellTemplate="versionNumber"
      ></dxi-column>
      <dxi-column
        dataField="numberOfUsers"
        caption="Stanowiska"
        width="150"
        [allowSorting]="false"
        [hidingPriority]="0"
        alignment="left"
        cellTemplate="numberOfUsers"
      ></dxi-column>
      <dxi-column
        dataField="variantName"
        caption="Linia - wariant"
        width="150"
        [allowSorting]="false"
        alignment="left"
        [hidingPriority]="3"
        cellTemplate="variantName"
      >
      </dxi-column>
      <dxi-column
        dataField="serialNumber"
        caption="Numer seryjny"
        [allowSorting]="false"
        [hidingPriority]="1"
        cellTemplate="serialNumber"
        alignment="left"
      >
      </dxi-column>
      <dxi-column
        width="180"
        [allowSorting]="false"
        cellTemplate="unlockCell"
        alignment="center"
        [hidingPriority]="4"
      ></dxi-column>

      <dxo-load-panel [enabled]="true"></dxo-load-panel>

      <div *dxTemplate="let data of 'dateOfOrderCell'">
        <p
          [ngClass]="{
            'row-disable-wapro-erp': !data.data.serialNumberIsActive
          }"
        >
          {{ data.data.dateOfOrder | date : "yyyy-MM-dd" }}
        </p>
      </div>

      <div *dxTemplate="let data of 'versionNumber'">
        <p
          [ngClass]="{
            'row-disable-wapro-erp': !data.data.serialNumberIsActive
          }"
        >
          {{ data.data.versionNumber }}
        </p>
      </div>

      <div *dxTemplate="let data of 'numberOfUsers'">
        <p
          [ngClass]="{
            'row-disable-wapro-erp': !data.data.serialNumberIsActive
          }"
        >
          {{ data.data.numberOfUsers }}
        </p>
      </div>

      <div *dxTemplate="let data of 'variantName'">
        <p
          [ngClass]="{
            'row-disable-wapro-erp': !data.data.serialNumberIsActive
          }"
        >
          {{ data.data.variantName }}
        </p>
      </div>

      <div *dxTemplate="let data of 'serialNumber'">
        <p
          [ngClass]="{
            'row-disable-wapro-erp': !data.data.serialNumberIsActive
          }"
        >
          {{ data.data.serialNumber }}
        </p>
      </div>

      <div *dxTemplate="let data of 'unlockCell'" class="text-right-mobile">
        <dx-button
          class="small-border-row d-none-products"
          (onClick)="modelShow(data.data)"
          [disabled]="data.data.operatingSystem === 'Service' || isClient"
        >
          {{ data.data.serialNumberIsActive }}
          <i
            class="icon icon-row"
            aria-hidden="true"
            [ngClass]="{
              'icon-lock': data.data.serialNumberIsActive,
              'icon-unlock': !data.data.serialNumberIsActive,
              'row-disable-wapro-erp': !data.data.serialNumberIsActive
            }"
            [title]="data.data.serialNumberIsActive ? 'Zablokuj' : 'Odblokuj'"
          ></i>
          <span
            *ngIf="data.data.serialNumberIsActive"
            style="position: relative; top: -9px"
            >Zablokuj</span
          >
          <span
            *ngIf="!data.data.serialNumberIsActive"
            style="position: relative; top: -9px"
            >Odblokuj</span
          >
        </dx-button>

        <dx-button
          class="small-border-row d-none-products"
          [disabled]="data.data.operatingSystem === 'Service'"
          (onClick)="downloadCertyfikat(data.data)"
        >
          <i
            class="icon icon-certyfikate icon-row"
            aria-hidden="true"
            title="Pobierz certyfikat"
          ></i>
          <span>Certyfikat</span>
        </dx-button>

        <dx-button
          class="icon-border-row-table d-none-mobile-products"
          (onClick)="modelShow(data.data)"
          [disabled]="data.data.operatingSystem === 'Service' || isClient"
        >
          <i
            class="icon icon-row"
            aria-hidden="true"
            [ngClass]="{
              'icon-lock': data.data.serialNumberIsActive,
              'icon-unlock': !data.data.serialNumberIsActive,
              'row-disable-wapro-erp': !data.data.serialNumberIsActive
            }"
            [title]="data.data.serialNumberIsActive ? 'Zablokuj' : 'Odblokuj'"
          ></i>
        </dx-button>

        <dx-button
          class="icon-border-row-table d-none-mobile-products"
          [disabled]="data.data.operatingSystem === 'Service'"
          (onClick)="downloadCertyfikat(data.data)"
        >
          <i
            class="icon icon-certyfikate icon-row"
            aria-hidden="true"
            title="Pobierz certyfikat"
          ></i>
        </dx-button>

        <dx-button
          class="icon-border-row-table d-none-mobile-products"
          (onClick)="generateCode(data.data)"
          [disabled]="data.data.operatingSystem == 'Service'"
        >
          <i
            class="icon icon-key icon-row"
            aria-hidden="true"
            title="Kod aktywacji"
          ></i>
        </dx-button>
      </div>
    </dx-data-grid>
    <br />
  </div>
</div>

<dx-popup
  [width]="widthPopUp"
  [height]="220"
  [showTitle]="true"
  title="Potwierdź"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="popVisible"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12" style="margin-top: 10px">
        <p>Czy chcesz {{ lockLicenseInfo.modalMsg }} wybrany numer seryjny?</p>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-12 text-right"
        style="margin-top: 20px; padding-right: 5px !important"
      >
        <dx-button
          class="dialog-accept-btn"
          text="Tak"
          (onClick)="lock(); popVisible = false"
          style="width: 120px; height: 45px"
        ></dx-button>
        <dx-button
          class="btn-danger"
          text="Nie"
          (onClick)="popVisible = false"
          style="
            width: 120px;
            margin-right: 15px;
            margin-left: 12px;
            height: 45px;
          "
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>
