import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EventService } from '../../event.service';

import { checkPswIdentical } from './validators';
import { AppServices } from '../../app-services.service';

@Component({
  selector: 'app-change-psw',
  templateUrl: './change-psw.component.html',
  styleUrls: ['./change-psw.component.scss']
})
export class ChangePswComponent implements OnInit {

  changePswForm;
  submitted: boolean = false;
  modePsw: string = 'password';
  modeNewPsw: string = 'password';
  modeNewPswRepeat: string = 'password';

  formError = {
    oldPassword: '',
    newPassword: '',
    repeatNewPsw: ''
  }

  validationMessages = {
    oldPassword: {
      required: 'Stare hasło jest wymagane'
    },
    newPassword: {
      required: 'Nowe hasło jest wymagane',
      pattern: 'Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i znak specjalny'

    },
    repeatNewPsw: {
      required: 'Nowe hasło jest wymagane',
      checkIfPasswordIdentical: "Podane hasła nie są identyczne"
    }
  }

  constructor(private event: EventService, public formBuilder: FormBuilder, private appService: AppServices) { }

  ngOnInit(): void {
    this.onCreateForm();

    this.changePswForm.valueChanges.subscribe((value) => {
      this.event.onControlValueChanged(this.changePswForm, this.formError, this.validationMessages);
    });
  }

  onCreateForm = () => {
    this.changePswForm = this.formBuilder.group({
      login: [localStorage.getItem('wapro-erp-login-user')],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])([a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,})/)]],
      repeatNewPsw: ['', [Validators.required, checkPswIdentical("newPassword")]],
      recaptchaReactive: [null, Validators.required]
    });
  }

  resolved(captchaResponse: string) {
    this.changePswForm.controls.recaptchaReactive.setValue(captchaResponse);
  }

  onSubmit = (values) => {
    this.submitted = true;
    if (this.changePswForm.invalid) {
      this.event.onControlValueChanged(this.changePswForm, this.formError, this.validationMessages);
      return;
    }
    else {
      this.appService.postAuth(`authorization/ChangePassword`, values).subscribe(response => {
        if (response.status) {
          this.event.showNotification('success', 'Hasło zostało zmienione');
          this.changePswForm.controls.oldPassword.setValue('');
          this.changePswForm.controls.newPassword.setValue('');
          this.changePswForm.controls.repeatNewPsw.setValue('');
          this.formError.newPassword = '';
          this.formError.repeatNewPsw = '';
          this.submitted = false;
        }
        else this.event.showNotification('error', response.description);
      },
        error => this.event.showNotification('error', 'Błąd przy zmianie hasła'))
    }
  }

}
