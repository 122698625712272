import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, Validators } from '@angular/forms';
import { checkPswIdentical } from '../my-data/change-psw/validators';

@Component({
  selector: 'app-new-psw',
  templateUrl: './new-psw.component.html',
  styleUrls: ['./new-psw.component.scss']
})
export class NewPswComponent implements OnInit, OnDestroy{

  guid;
  requestIsTrue: boolean = false;
  description: string = '';

  newPswForm;
  modePsw: string = 'password';
  modePswRepeat: string = 'password';

  submitted: boolean = false;

  formError = {
    newPassword: '',
    repeatNewPsw: ''
  }

  validationMessages = {
    newPassword: {
      required: 'Nowe hasło jest wymagane',
      pattern: 'Hasło musie mieć minimum 8 znaków, małą i dużą literę, cyfrę i znak specjalny'
      
    },
    repeatNewPsw:{
      required: 'Nowe hasło jest wymagane',
      checkIfPasswordIdentical: "Podane hasła nie sa identyczne"
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1366) document.body.style.background = "url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
    else document.body.style.background = "url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
  }

  constructor(private appServices: AppServices, private event: EventService, private route: ActivatedRoute, public formBuilder: FormBuilder, public _route: Router) { 
    this.event.logOnNavbar.next(false);
    this.event.welcomeMessage.next('');
  }

  ngOnInit(): void {
    if (window.innerWidth < 1366) document.body.style.background = "url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
    else document.body.style.background = "url('./assets/img/top_big.png') no-repeat 0 0, url('./assets/img/background.png') no-repeat 370px -90px";
    this.route
      .queryParams
      .subscribe(params => {
        this.guid = params['Guid'];
      });

    this.checkRequestId();

    this.onCreateForm();

    this.newPswForm.valueChanges.subscribe((value)=>{
      this.event.onControlValueChanged(this.newPswForm, this.formError, this.validationMessages);
    });

  }

  ngOnDestroy(){
    document.body.style.background = "none";
    this.event.logOnNavbar.next(true);
    this.event.welcomeMessage.next('Kontynuuj bez logowania');
  }

  checkRequestId = () => {
    this.appServices.get(`authorization/ResetPasswordCheck/${this.guid}`).subscribe(response => {
      if (response.status) this.requestIsTrue = true;
    })
  }

  onCreateForm = () =>{
    this.newPswForm = this.formBuilder.group({
      login: [''],
      oldPassword: [''],
      newPassword: ['', [Validators.required, Validators.pattern(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])([a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,})/)]],
      requestId: [this.guid],
      repeatNewPsw: ['', [Validators.required, checkPswIdentical("newPassword")]]
    })
  }

  onSubmit = (values) => {
    this.submitted = true;
    if(this.newPswForm.invalid){
        this.event.onControlValueChanged(this.newPswForm, this.formError, this.validationMessages);
        return;
    }
    else{
        this.appServices.post(`authorization/ResetPasswordDo`, this.newPswForm.getRawValue()).subscribe(response => {
          if(response.status) {
            this.event.showNotification('success', 'Hasło zostało zmienione');
            this.newPswForm.reset();
            this.submitted = false;
            this._route.navigate(['logowanie']);
          }
          else{
            this.event.showNotification('error', response.description)
          }
        },
        error => this.event.showNotification('error', 'Błąd przy zmianie hasła'))
    }
  }

}
