import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Md5 } from 'ts-md5/dist/md5';

const helper = new JwtHelperService();

const md5 = new Md5();
const salt = '%CxR$%j$i9^2:9_0*2Q!230xs.+';

@Component({
  selector: 'app-info-order',
  templateUrl: './info-order.component.html',
  styleUrls: ['./info-order.component.scss'],
})
export class InfoOrderComponent implements OnInit, OnDestroy {
  @ViewChild('gridContainer') gridContainer;
  @ViewChild('gridClass') gridClass;

  orderId;
  orderInfo = [];
  countToPrices: Number = 0;
  infoOrder: {
    amount: null;
    company: '';
    hasPaymentRegistered: null;
    orderDate: '';
    orderId: null;
    orderNumber: '';
    orderState: null;
    paymentStatus: '';
    remarks: '';
    rowNumber: null;
    vatNumber: '';
  };

  isInfoPanelShow: boolean = false;
  orderGuid = null;
  heightGrid: any = 'auto';
  netAmount = 0;
  selectedRows = [];
  showCheckBox: boolean = false;
  arrayItem = { orderIds: null, positionIds: [] };

  customerInfoOrder = {
    city: '',
    company: '',
    email: '',
    name: '',
    phone: '',
    postalCode: '',
    street: '',
    vatNumber: '',
  };

  isCustomerName: boolean = false;

  constructor(
    private appServices: AppServices,
    private event: EventService,
    private route: ActivatedRoute,
    private _route: Router,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('detalis-order-wapro-erp')) {
      this.route.params.subscribe(
        (params) => (this.orderId = parseInt(params['orderId']))
      );
      this.infoOrder = JSON.parse(
        this.event.decryptString(
          sessionStorage.getItem('detalis-order-wapro-erp')
        )
      );
      this.isInfoPanelShow = true;
      this.event.showNavbar.next(true);
      this.getOrderInfo();
      this.getOrderDetail(this.orderId);
      this.showCheckBox = true;
    } else {
      this.route.params.subscribe(
        (params) => (this.orderGuid = params['Guid'])
      );
      this.calculationByLink();
    }
  }

  removeDuplicates = (array) => {
    const result = [];
    const blocker = {}; // zapobiaga duplikowaniu elementów
    for (const item of array) {
      if (blocker.hasOwnProperty(item)) {
        continue;
      }
      blocker[item] = true;
      result.push(item);
    }
    return result;
  };

  addSelectedToBasket = () => {
    let addToBasket = [];
    // this.selectedRows.forEach(item=>{
    //   addToBasket.push(item)
    // });
    this.arrayItem.positionIds = [];
    this.cd.detectChanges();

    for (let i = 0; i < this.orderInfo.length; i++) {
      if (
        this.orderInfo[i].licenseId === -1 ||
        this.orderInfo[i].licenseId === 0
      ) {
        if (this.orderInfo[i].quantity == 1)
          this.orderInfo[i].unicate = this.orderInfo[i].positionId;
        else if (this.orderInfo[i].productId == this.orderInfo[i - 1].productId)
          this.orderInfo[i].unicate = this.orderInfo[i - 1].positionId;
      } else this.orderInfo[i].unicate = this.orderInfo[i].licenseId;
    }

    let pomItem = [];
    this.selectedRows.forEach((item) => {
      pomItem.push(item.unicate);
    });

    pomItem = this.removeDuplicates(pomItem);

    pomItem.forEach((item) => {
      for (let i = 0; i < this.orderInfo.length; i++) {
        if (this.orderInfo[i].unicate == item) {
          addToBasket.push(this.orderInfo[i]);
        }
      }
    });

    this.cd.detectChanges();
    let actualProducts = JSON.parse(
      this.event.decryptString(localStorage.getItem('product-list-wapro-erp'))
    );
    addToBasket.forEach((field, index) => {
      this.arrayItem.positionIds.push({ id: field.positionId });

      //field.unicate = (field.licenseId == -1 || field.licenseId == 0)?new Date().getTime() + Math.random():field.licenseId;
      for (let i = 0; i < actualProducts.length; i++) {
        if (actualProducts[i].productId === field.productId) {
          field.descriptionProduct = actualProducts[i].description;
          field.eduLicenseDescription = actualProducts[i].eduLicenseDescription;
          field.packetDescription = actualProducts[i].packetDescription;
          field.productNameDescription =
            actualProducts[i].productNameDescription;
          field.productNameShortcut = actualProducts[i].productNameShortcut;
          field.requiredProducts = actualProducts[i].requiredProducts;
          field.variantDescription = actualProducts[i].variantDescription;
          if (field.variantId === null) field.variantId = field.toVariantId;
          if (field.variantName === null)
            field.variantName = field.toVariantName;
          if (field.versionId === null) field.versionId = field.toVersionId;
          if (field.versionNumber === null)
            field.versionNumber = field.toVersionNumber;
        }
      }
    });

    try {
      let basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
      let maxDateOfExpiration = false;
      basket.forEach((element) => {
        if (element.maxDateOfExpiration) {
          maxDateOfExpiration = true;
        }
      });

      if (maxDateOfExpiration) {
        this.event.showNotification(
          'error',
          'Nie można dodać zamówienia do bieżącego koszyka jeśli w koszyku są licencje z datą wyrównania'
        );
        return false;
      }
    } catch {}
    this.event.baskedShop.next(addToBasket);

    this.appServices
      .postAuth(`orders/removeOrderPosition`, this.arrayItem)
      .subscribe((response) => {
        if (!response.status)
          this.event.showNotification('error', response.description);
      });
    this._route.navigate(['koszyk']);
  };

  ngOnDestroy() {
    //sessionStorage.removeItem('detalis-order-wapro-erp')
  }

  getOrderDetail = (idOrder) => {
    this.appServices
      .getAuth(`orders/${idOrder}/orderDetail`)
      .subscribe((res) => {
        this.customerInfoOrder = res.invoiceAddress;
      });
  };

  backToOrder = () => {
    this._route.navigate(['historia-zamowien']);
  };

  onAdaptiveDetailRowPreparing = (event) => {
    if (window.innerWidth < 1100) {
      this.heightGrid = this.orderInfo.length * 107;
      this.orderInfo.forEach((el) => {
        if (event.component.isAdaptiveDetailRowExpanded(el))
          this.heightGrid += 200;
      });
    } else this.heightGrid = 'auto';
  };

  getOrderInfo = () => {
    return this.appServices
      .getAuth(`Orders/${this.orderId}/calculation`)
      .subscribe((response) => {
        this.orderInfo = response;
        if (window.innerWidth < 1100) {
          this.heightGrid = this.orderInfo.length * 107;
        } else this.heightGrid = 'auto';
        this.countToPrices = 0;
        this.netAmount = 0;
        response.forEach(
          (element) => {
            if (element.customerName !== null) this.isCustomerName = true;
            this.countToPrices += element.amount;
            this.netAmount += element.netAmount;
            element.remarks = element.remarks.replace('[NIP:] ', '');
          },
          (error) => {
            this.event.showNotification('error', JSON.parse(error).message);
          }
        );
      });
  };

  payOnline = () => {
    this.appServices
      .postAuth(
        `Orders/${this.infoOrder.orderId}/AddPayment`,
        JSON.stringify({ orderId: this.infoOrder.orderId })
      )
      .subscribe(
        (response) => {
          this.event.newPayment(response);
          // let parameters = this.createParameterString(response);
          // this.postForm(response.paymentServiceUrl, parameters, 'POST');
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).message);
        }
      );
  };

  calculationByLink = () => {
    this.appServices
      .get(`Orders/${this.orderGuid}/calculationByLink`)
      .subscribe((response) => {
        this.orderInfo = response;
        this.countToPrices = 0;
        this.netAmount = 0;
        response.forEach(
          (element) => {
            if (window.innerWidth < 1100) {
              this.heightGrid = this.orderInfo.length * 107;
            } else this.heightGrid = 'auto';
            if (element.remarks.length > 21)
              element.remarks = element.remarks.substr(0, 20) + ']';
            this.countToPrices += element.amount;
            this.netAmount += element.netAmount;
          },
          (error) => {
            this.event.showNotification('error', JSON.parse(error).message);
          }
        );
      });
  };
}
