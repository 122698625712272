<div class="row">
    <div class="col-md-12" style="padding-bottom: 59px;">
        <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->
    </div>
</div>

<div class="row" style="margin-bottom: 185px;">
    <div class="col-md-12 text-center">
        <img src="../../assets/img/404_icon.svg" alt="Asseco Business Solutions Wapro">
        <h1>Strony nie znaleziono</h1>
        <p class="info">Sprawdź czy wprowadziłeś poprawny adres<br>lub wróć do <strong (click)="history()">poprzedniej strony</strong>.</p>
    </div>
</div>