import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { FormBuilder, Validators } from '@angular/forms';

import { checkPswIdentical } from '../my-data/change-psw/validators';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  href;

  newUserNoRegister;

  modePsw: string = 'password';
  modePswRepeat: string = 'password';

  formError = {
    firstName: '',
    lastName: '',
    MobilePhone: '',
    Phone: '',
    Email: '',
    Login: '',
    Password: '',
    PasswordConfirmation: '',
    SerialNumber: '',
    SecretNumber: '',
    recaptchaReactive: '',
  };

  validationMessages = {
    firstName: {
      required: 'Imię jest wymagane',
    },
    lastName: {
      required: 'Nazwisko jest wymagane',
    },
    MobilePhone: {
      pattern: 'Dozwolone są liczby, spacja i myślnik',
    },
    Phone: {
      pattern: 'Dozwolone są liczby, spacja i myślnik',
    },
    Email: {
      required: 'Email jest wymagany',
      email: 'Podaj poprawny adres email',
    },
    Login: {
      required: 'Login jest wymagany',
      email: 'Podaj poprawny adres email',
      minLength: 'Login musi mieć minimum 5 znaków',
    },
    Password: {
      required: 'Hasło jest wymagany',
      pattern:
        'Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i znak specjalny',
    },
    PasswordConfirmation: {
      required: 'Hasło jest wymagany',
      checkIfPasswordIdentical: 'Podane hasła nie są identyczne',
    },
    SerialNumber: {
      required: 'Numer seryjny jest wymagany',
    },
    SecretNumber: {
      required: 'Numer tajny jest wymagany',
      pattern: 'Błędny numer tajny',
    },
    recaptchaReactive: {
      required: '',
    },
  };

  submitted: boolean = false;

  constructor(
    private event: EventService,
    public formBuilder: FormBuilder,
    private appService: AppServices,
    public _route: Router,
    public route: ActivatedRoute,
    public cd: ChangeDetectorRef
  ) {
    this.href = this._route.url;
  }

  ngOnInit(): void {
    this.href === '/rejestracja'
      ? this.createFormNewUserNoRegister()
      : this.createFornRegisterUser();
  }

  createFormNewUserNoRegister = () => {
    this.newUserNoRegister = this.formBuilder.group({
      customerContact: this.formBuilder.group({
        ContactId: [0],
        CustomerId: [0],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        MobilePhone: [
          '',
          Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/),
        ],
        Phone: ['', Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/)],
        Email: [''],
      }),
      MarketingAgreement: [false],
      recaptchaReactive: [null, Validators.required],
      Login: [
        '',
        [Validators.required, Validators.email, Validators.minLength(5)],
      ],
      Password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])([a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,})/
          ),
        ],
      ],
      PasswordConfirmation: [
        '',
        [Validators.required, checkPswIdentical('Password')],
      ],
      SerialNumber: ['', Validators.required],
      SecretNumber: ['', [Validators.required, Validators.pattern(/[0-9]{4}/)]],
      regulaminAgreement: [false, Validators.requiredTrue],
      przetwarzanieAgreement: [false, Validators.requiredTrue],
    });
  };

  resolved(captchaResponse: string) {
    this.newUserNoRegister.controls.recaptchaReactive.setValue(captchaResponse);
  }

  createFornRegisterUser = () => {
    this.newUserNoRegister = this.formBuilder.group({
      customerContact: this.formBuilder.group({
        ContactId: [0],
        CustomerId: [0],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        MobilePhone: [
          '',
          Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/),
        ],
        Phone: ['', Validators.pattern(/^(?:\(?\?)?(?:[+\(\)\s]*(\d))*\)?$/)],
        Email: [''],
      }),
      recaptchaReactive: [null, Validators.required],
      Login: [
        '',
        [Validators.required, Validators.email, Validators.minLength(5)],
      ],
      Password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])([a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,})/
          ),
        ],
      ],
      PasswordConfirmation: [
        '',
        [Validators.required, checkPswIdentical('Password')],
      ],
    });
  };

  clearValue = (controls) => {
    if (controls === 'Password')
      this.newUserNoRegister.controls.Password.setValue('');
    else this.newUserNoRegister.controls.PasswordConfirmation.setValue('');
  };

  onSubmit = (values) => {
    this.submitted = true;
    values.customerContact.Email = values.Login;

    if (this.newUserNoRegister.invalid) {
      this.event.showNotification('error', 'Uzupełnij wszystkie wymagane pola');
      this.event.onControlValueChanged(
        this.newUserNoRegister,
        this.formError,
        this.validationMessages
      );
      return;
    } else {
      if (this.href !== '/rejestracja') {
        this.appService.postAuth(`customer/Contacts/Add`, values).subscribe(
          (response) => {
            if (response.status) {
              this.event.showNotification(
                'success',
                'Dodano nowego użytkownika'
              );
              this.newUserNoRegister
                .get('customerContact')
                .controls.ContactId.setValue(0);
              this.newUserNoRegister
                .get('customerContact')
                .controls.CustomerId.setValue(0);
              this.newUserNoRegister
                .get('customerContact')
                .controls.firstName.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.lastName.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.MobilePhone.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.Phone.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.Email.setValue('');
              this.newUserNoRegister.controls.Login.setValue('');
              this.newUserNoRegister.controls.Password.setValue('');
              this.newUserNoRegister.controls.PasswordConfirmation.setValue('');
              this.submitted = false;
              this.cd.detectChanges();
            } else {
              this.event.showNotification('error', response.description);
            }
          },
          (error) => {
            this.event.showNotification(
              'error',
              'Błąd dodawania nowego użytkownika'
            );
          }
        );
      } else {
        values.SecretNumber = Number(values.SecretNumber);
        this.appService.post(`customer/Contacts/Add`, values).subscribe(
          (response) => {
            if (response.status) {
              this.event.showNotification(
                'success',
                'Dodano nowego użytkownika'
              );
              this.newUserNoRegister
                .get('customerContact')
                .controls.ContactId.setValue(0);
              this.newUserNoRegister
                .get('customerContact')
                .controls.CustomerId.setValue(0);
              this.newUserNoRegister
                .get('customerContact')
                .controls.firstName.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.lastName.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.MobilePhone.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.Phone.setValue('');
              this.newUserNoRegister
                .get('customerContact')
                .controls.Email.setValue('');

              this.newUserNoRegister.controls.MarketingAgreement.setValue(
                false
              );
              this.newUserNoRegister.controls.Login.setValue('');
              this.newUserNoRegister.controls.Password.setValue('');
              this.newUserNoRegister.controls.PasswordConfirmation.setValue('');
              this.newUserNoRegister.controls.SerialNumber.setValue('');
              this.newUserNoRegister.controls.SecretNumber.setValue('');
              this.newUserNoRegister.controls.regulaminAgreement.setValue(
                false
              );
              this.newUserNoRegister.controls.przetwarzanieAgreement.setValue(
                false
              );
              this.submitted = false;
              this.cd.detectChanges();
            } else {
              this.event.showNotification('error', response.description);
            }
          },
          (error) => {
            this.event.showNotification(
              'error',
              'Błąd dodawania nowego użytkownika'
            );
          }
        );
      }
    }
  };
}
