<div class="row">
  <div class="col-md-12 text-center">
    <img
      src="assets/img/service-break.svg"
      alt="service-break"
      style="margin-bottom: 10px; margin-top: 100px"
      *ngIf="event.eventMSG.typeOfLock !== 0"
    />

    <img
      src="assets/img/prace_serwisowe.svg"
      alt="service-break"
      style="margin-bottom: 10px; margin-top: 100px"
      *ngIf="event.eventMSG.typeOfLock === 0"
    />
    <p class="p_service_break" *ngIf="event.eventMSG.typeOfLock !== 0">
      Trwają prace serwisowe
    </p>
    <p class="p_service_small">
      {{ event.eventMSG.messageDesc }}

      <strong><br />{{ event.eventMSG.typeOfLockDesc }}</strong>
    </p>

    <!-- przyciski  -->

    <dx-button
      class="dialog-accept-btn"
      text="Przejdź do oferty"
      *ngIf="event.eventMSG.typeOfLock === 0"
      (onClick)="routerNavigate('oferta')"
      style="width: 170px; height: 40px; margin-top: 6px"
    ></dx-button>

    <dx-button
      class="btn-danger"
      text="Zaloguj"
      *ngIf="event.eventMSG.typeOfLock === 0"
      (onClick)="routerNavigate('logowanie')"
      style="width: 170px; height: 40px; margin-top: 6px; margin-left: 6px"
    ></dx-button>
  </div>
</div>

<img
  src="assets/img/bg_break.svg"
  alt="service-break"
  style="position: absolute; top: 10px; left: 21px"
/>

<img
  src="assets/img/bg2_break.svg"
  alt="service-break"
  style="position: absolute; top: 371px; right: 0px"
/>
