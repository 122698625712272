<div class="row">
    <div class="col-md-12" style="padding-bottom: 59px;">
        <!-- <h2  class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->

        <h1>Utwórz konto i korzystaj<strong><br>ze wszystkich funkcji</strong> </h1>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <dx-tab-panel #tabPanel [dataSource]="component" [selectedIndex]="0" [loop]="false" [animationEnabled]="false"
            [swipeEnabled]="false" (onSelectionChanged)="onSelectionChanged($event)">
            <div *dxTemplate="let menu of 'title'">
                <span>{{menu.nameMenu}}</span>
            </div>

            <div *dxTemplate="let component of 'item'">
                <app-dynamic-component [componentData]="component.component" #dynamic></app-dynamic-component>
            </div>
        </dx-tab-panel>
    </div>
</div>
