<div id="employee" *ngIf="loadingVisible"></div>
<div class="row">
  <div class="col-md-12" style="padding-bottom: 39px">
    <!-- <h2 class="h2-logo" style="float:right">wapro <strong>erp</strong></h2> -->

    <h1>
      Informacje o <strong>Twoich<br />klientach</strong>
    </h1>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <dx-tab-panel
      #tabPanel
      [dataSource]="tabMenu"
      [selectedIndex]="0"
      [loop]="false"
      [animationEnabled]="true"
      [swipeEnabled]="true"
      (onSelectionChanged)="onSelectionChangedTab($event)"
    >
      <div *dxTemplate="let company of 'title'">
        <span>{{ company.name }}</span>
      </div>
    </dx-tab-panel>
  </div>
</div>

<div class="about-license-wapro-erp" style="margin-top: 0px">
  <div class="row">
    <div class="col-lg-7 col-md-12 col-sm-12 col-12">
      <div class="flex-container">
        <div>
          <dx-text-box
            type="search"
            class="dx-filter"
            valueChangeEvent="keyup"
            placeholder="Wpisz szukaną frazę"
            [(ngModel)]="filterCriteria"
            [showClearButton]="true"
            (ngModelChange)="ngModelChange()"
            id="searchText"
            (keydown.enter)="myCustomerList = []; getCustomerList()"
          ></dx-text-box>
          <i
            class="icon icon-search d-none d-none-pc"
            aria-hidden="true"
            title="Filtrowanie oraz odświeżanie listy"
            (click)="myCustomerList = []; getCustomerList()"
            style="cursor: pointer"
          ></i>
        </div>
        <div>
          <dx-select-box
            class="dx-filtr-input box-shadow-none"
            [dataSource]="filtrName"
            displayExpr="label"
            valueExpr="value"
            placeholder="Nazwa"
            [(ngModel)]="filterColumn"
            id="filtrName"
            [dropDownOptions]="{ closeOnTargetScroll: false }"
            (onSelectionChanged)="onSelectionChanged($event)"
          >
          </dx-select-box>
          <i
            class="icon icon-search d-none-mobile"
            aria-hidden="true"
            title="Filtrowanie oraz odświeżanie listy"
            (click)="myCustomerList = []; getCustomerList()"
            style="cursor: pointer"
            id="refreshData"
          ></i>
        </div>
      </div>
    </div>

    <div class="col-lg-5 col-md-12 col-sm-12 col-12 filer-icon-aligne">
      <dx-button
        class="icon-border icon-header"
        (onClick)="navigateToSearchLicense()"
      >
        <i
          class="icon search"
          aria-hidden="true"
          title=" Wyszukaj licencję, której nie ma na liście"
        ></i>
      </dx-button>

      <dx-button
        class="icon-border icon-header"
        (onClick)="getSubPartners(); showModalBox = true"
        [disabled]="selectedRows.length === 0"
      >
        <i class="icon icon-user-add" aria-hidden="true" title="Przypnij"></i>
      </dx-button>

      <dx-button
        class="icon-border icon-header"
        [disabled]="selectedRows.length === 0"
        (onClick)="showModalBoxConfirm = true"
      >
        <i class="icon icon-user-remove" aria-hidden="true" title="Odepnij"></i>
      </dx-button>

      <dx-button
        class="icon-border icon-header"
        (onClick)="unselectAll()"
        [disabled]="selectedRows.length === 0"
      >
        <i
          class="icon icon--remove-selected"
          aria-hidden="true"
          title="Odznacz"
        ></i>
      </dx-button>
    </div>
  </div>

  <div class="row" id="gridContainer">
    <div class="col-md-12" style="padding-left: 20px; padding-bottom: 20px">
      <dx-data-grid
        [dataSource]="myCustomerList"
        [allowColumnReordering]="false"
        wordWrapEnabled="true"
        [height]="heightGrid"
        [(selectedRowKeys)]="selectedRows"
        [showRowLines]="true"
        (onContentReady)="onContentReady($event)"
        [showBorders]="false"
        [showColumnLines]="false"
        [hoverStateEnabled]="true"
        *ngIf="myCustomerList.length !== 0; else noData"
        (onCellClick)="onAdaptiveDetailRowPreparing($event)"
      >
        <dxi-column
          dataField="company"
          caption="Klient / Subpartner"
          [allowSorting]="false"
          cellTemplate="companyCell"
          renderAsync="true"
        ></dxi-column>
        <dxi-column
          dataField="vatNumber"
          caption="NIP"
          [allowSorting]="false"
          [hidingPriority]="1"
          width="200"
          cellTemplate="nipCell"
          renderAsync="true"
        >
        </dxi-column>
        <dxi-column
          caption="Adres"
          cellTemplate="adressCell"
          width="280"
          [allowSorting]="false"
          [hidingPriority]="2"
          renderAsync="true"
        >
        </dxi-column>
        <dxi-column
          caption=""
          [hidingPriority]="0"
          width="150"
          [allowSorting]="false"
          alignment="left"
          cellTemplate="iconCell"
          renderAsync="true"
        >
        </dxi-column>

        <dxo-selection
          selectAllMode="allPages"
          [showCheckBoxesMode]="showCheckBoxesMode"
          mode="multiple"
        >
        </dxo-selection>

        <dxo-scrolling
          mode="virtual"
          showScrollbar="always"
          columnRenderingMode="virtual"
          preloadEnabled="true"
        ></dxo-scrolling>
        <dxo-sorting mode="none"></dxo-sorting>

        <div *dxTemplate="let data of 'companyCell'">
          <p
            class="p-product-name-wapro-erp"
            (click)="onRowClick(data.data)"
            style="cursor: pointer"
            [ngStyle]="{
              'margin-bottom': !data.data.subPartnerCompany.length ? 0 : '-10px'
            }"
          >
            {{ data.data.company }}
          </p>
          <p class="p-lineDescription-wapro-erp">
            {{ data.data.subPartnerCompany }}
          </p>
        </div>

        <div *dxTemplate="let data of 'nipCell'" class="font-medium">
          <p>{{ data.data.vatNumber }}</p>
        </div>

        <div *dxTemplate="let data of 'adressCell'">
          <p class="p-product-name-wapro-erp" style="color: #1b1a1e">
            {{ data.data.street }}
          </p>
          <p class="p-lineDescription-wapro-erp">
            {{ data.data.postalCode }} {{ data.data.city }}
          </p>
        </div>

        <div
          *dxTemplate="let data of 'iconCell'"
          class="text-right-mobile margin-top"
        >
          <dx-button
            class="small-border-row d-none"
            (onClick)="
              getSubPartners();
              showModalBox = true;
              selectedRows = [];
              selectedRows.push(data.data)
            "
          >
            <i
              class="icon icon-user-add"
              aria-hidden="true"
              title="Przypnij"
            ></i>
            <span>Przypnij</span>
          </dx-button>

          <dx-button
            class="icon-border-row-table d-none-mobile"
            (onClick)="
              getSubPartners();
              showModalBox = true;
              selectedRows = [];
              selectedRows.push(data.data)
            "
          >
            <i
              class="icon icon-user-add icon-row"
              aria-hidden="true"
              title="Przypnij do partnera"
            ></i>
          </dx-button>

          <dx-button
            class="small-border-row d-none"
            (onClick)="
              showModalBoxConfirm = true;
              selectedRows = [];
              selectedRows.push(data.data)
            "
          >
            <i
              class="icon icon-user-remove"
              aria-hidden="true"
              title="Odepnij"
            ></i>
            <span>Odepnij</span>
          </dx-button>

          <dx-button
            class="icon-border-row-table d-none-mobile"
            (onClick)="
              showModalBoxConfirm = true;
              selectedRows = [];
              selectedRows.push(data.data)
            "
          >
            <i
              class="icon icon-user-remove icon-row"
              aria-hidden="true"
              title="Odepnij od partnera"
            ></i>
          </dx-button>

          <dx-button
            class="icon-border-row-table"
            (onClick)="updateAll(data.data.customerId)"
          >
            <i
              class="icon icon-refresh icon-row"
              aria-hidden="true"
              title="Aktualizuj wszystkie"
            ></i>
          </dx-button>
        </div> </dx-data-grid
      ><br />
    </div>
  </div>
</div>

<!-- <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
>
</dx-load-panel> -->

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>

<dx-popup
  [width]="widthPopUp"
  [height]="heightpopUp"
  [showTitle]="true"
  title="Przypnij klientów do subpartnera"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showModalBox"
  class="my-customer-popup"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="subpartnersList"
            >Wybór partnera <span style="color: #dc1e28">*</span></label
          >
          <dx-select-box
            [dataSource]="subpartnersList"
            [(ngModel)]="partnerId"
            displayExpr="partnerName"
            style="height: 40px"
            valueExpr="vatNumber"
            placeholder=""
            id="subpartnersList"
            class="partner-select no-box-shadow"
            noDataText="Brak danych do wyświetlenia"
          >
          </dx-select-box>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="vatNumber">NIP partnera</label>
        <dx-text-box
          placeholder=""
          id="vatNumber"
          [(ngModel)]="vatNumber"
          valueChangeEvent="keyup"
          style="height: 40px"
          [showClearButton]="true"
          class="no-box-shadow nip-enter"
        >
        </dx-text-box>
      </div>
      <div class="col-md-6">
        <p class="p-info-wapro-erp">
          Jeżeli partnera nie ma na liście wprowadź jego NIP
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col text-right">
        <dx-button
          class="btn-danger"
          text="Przypnij"
          [disabled]="partnerId === null && vatNumber === null"
          (onClick)="addClientToCustomer(); showModalBox = false"
          style="
            width: 120px;
            margin-right: 10px;
            margin-top: 15px;
            height: 40px;
          "
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="widthPopUp"
  [height]="heightpopUpUnfasten"
  [showTitle]="true"
  title="Potwierdź"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showModalBoxConfirm"
  class="my-customer-popup"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col">
        <p class="popup-text">Czy chcesz odpiąć klienta?</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="popup-text-information">
          Klient zostanie usunięty z Twojej listy oraz jego produkty z listy
          Produkty klientów.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right" style="margin-top: 10px">
        <dx-button
          class="dialog-accept-btn"
          text="Tak"
          (onClick)="deleteClientFromCustomer(); showModalBoxConfirm = false"
          style="width: 120px; height: 40px"
        >
        </dx-button>
        <dx-button
          class="btn-danger"
          text="Nie"
          (onClick)="showModalBoxConfirm = false"
          style="
            width: 120px;
            margin-right: 15px;
            margin-left: 12px;
            height: 40px;
          "
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>
