<div class="row">
    <div class="col-md-12" style="padding-bottom: 59px;">
        <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->
    </div>
</div>

<div class="row">
    <div class="col-md-12 text-center">
        <h1>Dziękujemy!</h1>
        <p class="p-thak-you-wapro-erp">Twoje zamówienie nr: <strong> {{order.orderNumber}}</strong><br>
            na kwotę: <strong>{{order.amountToPay.toFixed(2)| currency:' ' | pointReplacer}} PLN</strong> zostało złożone
        </p>

        <p class="p-contact-wapro-erp">Oczekuj na kontakt działu handlowego lub partnera</p>

        <dx-button class="btn-danger" text="Wróć do oferty" [routerLink]="'../panel/oferta'" width="183" height="40"></dx-button>
    </div>
</div>