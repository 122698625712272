import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  AfterViewInit,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { AppServices } from './app-services.service';
import { EventService } from './event.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { filter } from 'rxjs/operators';
import { ConstantPool } from '@angular/compiler';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { DxoColCountByScreenModule } from 'devextreme-angular/ui/nested';
const helper = new JwtHelperService();
import { environment } from '../environments/environment';
//import * as plMessages from "../assets/devextreme/localization/messages/pl.json";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('customNotification') customNotificationTmpl;

  hideNavbar: boolean = false;
  hidePanelLog: boolean = true;
  hideFooter: boolean = false;

  deviceInfo: any;

  locale: string;
  locales = [
    {
      Name: 'English',
      Value: 'en',
    },
    {
      Name: 'Polski',
      Value: 'pl',
    },
  ];
  formatMessage = formatMessage;

  isPayment: boolean = false;
  year;
  paymentSource = 'wapro';
  window;
  width;
  source$: Subscription;
  scriptChater;
  script;
  isLoginToEmail: boolean = false;
  oldLogin: string = '';
  public constructor(
    public readonly notifier: NotifierService,
    private appServices: AppServices,
    private event: EventService,
    private router: ActivatedRoute,
    private _router: Router,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService //private paymentsService: PaymentsService
  ) {
    console.log('env', environment);
    this.window = window;
    this.notifier = notifier;
    this.event.notifyMsg.subscribe((msg) => {
      this.showNotification(msg.type, msg.message);
    });

    this.event.welcomeMessage.subscribe((e) => {
      if (e !== '') this.hideFooter = true;
      else this.hideFooter = false;
    });

    this.deviceInfo = this.deviceService.getDeviceInfo();

    this.event.logOnNavbar.subscribe((show) => {
      this.hideNavbar = !show;
      this.hideFooter = !show;
    });

    this.event.hideFooter.subscribe((show) => {
      this.hideFooter = show;
    });

    window.addEventListener('resize', this.listener);
    this.listener(null);

    this.source$ = this.event.source.subscribe((source: string) => {
      this.paymentSource = source || 'wapro';
    });

    this.scriptChater = document.getElementsByTagName('head')[0];
    this.script = document.createElement('script');
    this.script.setAttribute('type', 'text/javascript');
    this.script.setAttribute(
      'src',
      'https://www.czater.pl/assets/modules/chat/js/chat.js'
    );

    _router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (this._router.url.indexOf('platnosc') !== -1) {
          try {
            this.scriptChater.removeChild(this.script);
          } catch {}
          this.isPayment = true;
        } else {
          this.scriptChater.appendChild(this.script);
          this.isPayment = false;
        }
        // this.addJsToElement('https://www.czater.pl/assets/modules/chat/js/chat.js').onload =
        //   () => {};
      }
    });

    this.event.isLoginToEmail.subscribe((e) => {
      this.oldLogin = e;
      this.isLoginToEmail = true;
    });
  }

  listener = (e) => {
    this.width = window.innerWidth;
  };

  @HostListener('window:beforeunload', ['$event']) onClose(event) {
    // event.preventDefault();
  }

  ngOnDestroy() {
    if (this.source$) this.source$.unsubscribe();
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  ngOnInit() {
    this.locale = this.getLocale();
    this.initMessages();
    locale(this.locale);
    this.year = new Date().getFullYear();

    this.router.queryParamMap.subscribe((params: any): void => {
      let paramsObject = params?.params;
      this.paymentSource = paramsObject?.source || 'wapro';
    });

    this.checkMessages();
  }

  // addJsToElement(src: string): HTMLScriptElement {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = src;
  //   this.renderer.appendChild(document.body, script);
  //   return script;
  // }

  ngAfterViewInit() {
    let loginNative = {
      dbCode: '',
      registrationCode: '',
      vatNumber: '',
    };
    this.router.queryParams.subscribe((params) => {
      if (params.dbCode) loginNative.dbCode = params.dbCode;
      if (params.registrationCode)
        loginNative.registrationCode = params.registrationCode;
      if (params.vatNumber) loginNative.vatNumber = params.vatNumber;
      if (
        loginNative.dbCode != '' &&
        loginNative.registrationCode != '' &&
        loginNative.vatNumber != ''
      ) {
        if (localStorage.getItem('wapro-erp-token')) {
          this.appServices.deleteAuth('Authorization/Logout').subscribe(
            (response) => {
              localStorage.removeItem('wapro-erp-token');
              localStorage.removeItem('wapro-erp-login-user');
              localStorage.removeItem('user-info-wapro-erp');
              sessionStorage.clear();
              this.event.baskedProduct.next(null);
              this.event.showNavbar.next(false);
              this.autoLog(loginNative);
            },
            (error) => {
              this.event.showNotification('error', error);
            }
          );
        } else {
          this.autoLog(loginNative);
        }
      }
    });
  }

  autoLog = (loginNative) => {
    loginNative.registrationCode = loginNative.registrationCode.replace(
      / /g,
      '+'
    );
    localStorage.removeItem('wapro-erp-token');
    localStorage.removeItem('wapro-erp-login-user');
    localStorage.removeItem('user-info-wapro-erp');
    sessionStorage.clear();

    this.appServices
      .post(`authorization/LoginNative`, loginNative)
      .subscribe((res) => {
        localStorage.setItem('wapro-erp-token', res.token);
        this.appServices.headers = this.appServices.headers.set(
          'Authorization',
          'Bearer ' + localStorage.getItem('wapro-erp-token')
        );
        this.appServices.getAuth(`customer/Addresses`).subscribe(
          (response) => {
            let userInfo = this.event.encryptString({
              email: response.invoiceAddress.email,
              login: '',
            });
            localStorage.setItem('user-info-wapro-erp', userInfo);
            this.event.showNavbar.next(true);
            this._router.navigate(['oferta']);
          },
          (error) => {
            this.event.showNotification('error', JSON.parse(error).message);
          }
        );
      });
  };

  initMessages() {
    //loadMessages(plMessages);
  }

  getLocale() {
    var locale = sessionStorage.getItem('locale');
    return locale != null ? locale : 'pl';
  }

  showNotification(type, message) {
    this.notifier.show({
      message: message,
      type: type,
      template: this.customNotificationTmpl,
    });
  }

  checkMessages() {
    this.appServices
      .get(`authorization/checkMessages`)
      .subscribe((response) => {
        let today = new Date().getTime();
        response.forEach((element) => {
          if (today < new Date(element.dateFrom).getTime()) {
            this.showNotification('info', element.messageDesc);
          }

          if (
            today >= new Date(element.dateFrom).getTime() &&
            today <= new Date(element.dateTo).getTime()
          ) {
            this.event.eventMSG = element;
          }
        });

        if (this.event.eventMSG.typeOfLock != null) {
          this._router.navigate(['prace-serwisowe']);
          if (
            localStorage.getItem('wapro-erp-token') &&
            this.event.eventMSG.typeOfLock === 1
          ) {
            this.appServices.deleteAuth('Authorization/Logout').subscribe(
              (response) => {
                localStorage.removeItem('wapro-erp-token');
                localStorage.removeItem('wapro-erp-login-user');
                localStorage.removeItem('user-info-wapro-erp');

                sessionStorage.clear();
              },
              (error) => {
                this.event.showNotification('error', error);
              }
            );
          }
        }
      });
  }

  onCLoseEmailLogin() {
    this.isLoginToEmail = false;
  }
}
