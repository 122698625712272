import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChild, ViewChildren } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import DataSource from "devextreme/data/data_source";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.scss']
})
export class MyProductsComponent implements OnInit, OnDestroy {
  @ViewChildren('grid') grid;
  @ViewChild('accordion') accordion;

  filterColumn: string = 'ProductName';

  filterValue: string = '';
  isProductNameVisible: boolean = false;
  aligneLicense: boolean = false;
  dataToday;
  expendAll: boolean = false;

  maxDateOfExpiration: any = {
    description: '',
    licenseNumber: '',
    maxDateOfExpiration: '',
    dataForProduct: ''
  }

  dateToCalculate = {
    myProductOrders: []
  }

  selectedRows = [];

  filtrName = [
    { label: 'Produkt', value: 'ProductName' },
    { label: 'Licencja', value: 'LicenseNumber' },
  ];

  myProductList = [];
  showCheckBoxesMode = 'always';

  productId = null;
  clientId = null;

  groupList = [];

  widthPopUp: number = 500;
  heightpopUp: number = 390;

  customerName = '';

  isSkipLicenses: boolean = true;
  isMobile: boolean = false;

  showGroupPopUp: boolean = false;

  isExpendAll: boolean = true;
  loadingVisible = false;

  constructor(public cd: ChangeDetectorRef, private appServices: AppServices, private event: EventService, private route: ActivatedRoute, private _route: Router) {
    this.event.showNavbar.next(true);

    this.route.params.subscribe(params => {
      this.productId = parseInt(params['productId']);
    });

    this.route.params.subscribe(params => {
      this.clientId = parseInt(params['clientId']);
    });


  }

  ngOnInit(): void {
    this.dataToday = new Date();
    this.onShown();
    if (isNaN(this.clientId)) this.getMyProductList();
    else {
      this.getClientProduct(this.clientId);
      this.customerName = sessionStorage.getItem('clientDate')
    }

    if (window.innerWidth <= 500) {
      if (window.innerWidth <= 375) {
        this.isMobile = true;
        this.widthPopUp = 350;
        this.heightpopUp = 450;
      }
      else this.isMobile = false;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 500) {
        this.isMobile = true;
        this.widthPopUp = 350;
        this.heightpopUp = 450;
      }
      else this.isMobile = false;
    });

    this.event.nameCustomer.subscribe(e => {
      this.customerName = e;
    });
  }

  onShown() {
    this.loadingVisible = true;
  }

  onHidden() {
    this.loadingVisible = false;
  }

  clearUri = () => {
    this._route.navigate(['moje-produkty']);
  }

  ngOnDestroy() {
  }

  ngModelChange() {
    if (this.filterValue === '') this.filter();
  }

  deletekProduct(productId) {
    for (var i = this.myProductList.length - 1; i >= 0; --i) {
      if (this.myProductList[i].productId !== productId) {
        this.myProductList.splice(i, 1);
      }
    }
  }

  deletedProductName = (name) => {
    name = name.toLowerCase();
    for (let i = this.groupList.length - 1; i >= 0; --i) {
      if (this.groupList[i].name.toLowerCase().indexOf(name) === -1) {
        this.groupList.splice(i, 1);
      }
    }
  }

  deletedProductLicense = (name) => {
    name = name.toLowerCase();
    this.groupList.forEach(field => {
      for (let i = field.list.length - 1; i >= 0; --i) {
        if (field.list[i].licenseNumber.toString().toLowerCase().indexOf(name) === -1) {
          field.list.splice(i, 1);
        }
      }
    })

    for (let i = this.groupList.length - 1; i >= 0; --i) {
      if (this.groupList[i].list.length === 0) {
        this.groupList.splice(i, 1);
      }
    }
  }


  unselectAll = () => {
    this.expendAll = false
    this.selectedRows = [];
    this.grid.forEach(element => {
      element.selectedRowKeys = [];
    });
  }

  selectAll = () => {
    this.selectedRows = this.myProductList;

    setTimeout(() => {
      this.grid.forEach(element => {
        element.selectedRowKeys = element.dataSource
      });
    }, 0)
  }

  expandAll = () => {
    this.isExpendAll = !this.isExpendAll;

    this.groupList.forEach(product => {
      product.selection = !product.selection
    })
  }

  //pobieram moje produkty
  getMyProductList = () => {
    return this.appServices.postAuth(`MyProducts`, null).subscribe(response => {
      this.myProductList = response;
      //pobieram najnowsza możliwą wersje
      let actualProducts = JSON.parse(this.event.decryptString(localStorage.getItem("product-list-wapro-erp")));
      this.myProductList.forEach(field => {
        try{
          field.lineDescription = field.lineDescription.replace(/\\(.)/g, '$1');
        }
        catch{}
        field.productName = field.productName.replace('WAPRO', '');
        field.productNameDescription = field.productNameDescription.toLowerCase();
        for (let i = 0; i < actualProducts.length; i++) {
          if (actualProducts[i].productId === field.productId) {
            field.toVersionId = actualProducts[i].versions[0].versionId;
            field.requiredProducts = actualProducts[i].requiredProducts;
            if (field.requiredProducts) {
              field.requiredProducts.forEach(element => {
                element.requiredProductName = element.requiredProductName.replace("WAPRO", "")
              });
            }
            field.variantDescription = actualProducts[i].variantDescription
            field.eduLicenseDescription = actualProducts[i].eduLicenseDescription
            field.packetDescription = actualProducts[i].packetDescription
            field.description = actualProducts[i].description
            field.toVersionId = actualProducts[i].versions[0].versionId;
            field.toVersionNumber = actualProducts[i].versions[0].versionNumber;
            field.toVariantName = field.variantName;
            field.toVariantId = field.variantId;
            field.toPacketName = field.packetName;
            field.toPacketId = field.packetId;
            field.unicate = field.licenseId;
            field.toNumberOfUsers = field.numberOfUsers;
            field.agreementCode = actualProducts[i].agreementCode;
            field.agreementDesc = actualProducts[i].agreementDesc;
            field.agreementUrl = actualProducts[i].agreementUrl;
            field.dayToExpire = (new Date(field.expireDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
            break;
          }
        }


      });
      if (!isNaN(this.productId)) {
        this.deletekProduct(this.productId);
      }

      this.groupList = this.event.grouipngList('productName', this.myProductList);
      this.groupList.forEach(product => product.selection = true);
      this.onHidden();
    },
      error => this.onHidden())
  }

  onAdaptiveDetailRowPreparing = (event, height) => {
    if (window.innerWidth < 1100) {
      height.height = height.list.length * 46.4;
      this.myProductList.forEach(el => {
        if (event.component.isAdaptiveDetailRowExpanded(el)) height.height += 300;
      })
    }
  }

  onCellPrepared = (e) => {
    try{
      let basket = JSON.parse(this.event.decryptString(localStorage.getItem('basket-wapro-erp')));
      if (e.rowType == "data") {
        basket.forEach(element => {
          try {
            if (e.data.licenseNumber == element.licenseNumber.replace(/ /g, ''))
              e.cellElement.className += " cls";
          }
          catch { }
  
        });
      }
    }
    catch{}
  }

  checkInBasket = (item) => {
    if(item == 'aktualizuj'){
      if (isNaN(this.clientId)) this.getMyProductList();
      else {
        this.getClientProduct(this.clientId);
      }
    }
    else{
      setTimeout(() => {
        item.push({ licenseNumber: null })
      }, 300);
  
      setTimeout(() => {
        item.splice(item.length - 1, 1)
      }, 400);
    }
  }


  //pobieram produkty klienta
  getClientProduct = (clientId) => {
    return this.appServices.getAuth(`MyProducts/${clientId}`).subscribe(response => {
      this.myProductList = response;
      //pobieram najnowsza możliwą wersje
      let actualProducts = JSON.parse(this.event.decryptString(localStorage.getItem("product-list-wapro-erp")));
      this.myProductList.forEach(field => {
        field.productName = field.productName.replace('WAPRO', '');
        try{
          field.lineDescription = field.lineDescription.replace(/\\(.)/g, '$1');
        }
        catch{}
        for (let i = 0; i < actualProducts.length; i++) {
          if (actualProducts[i].productId === field.productId) {
            field.requiredProducts = actualProducts[i].requiredProducts
            if (field.requiredProducts) {
              field.requiredProducts.forEach(element => {
                element.requiredProductName = element.requiredProductName.replace("WAPRO", "")
              });
            }
            field.toVersionId = actualProducts[i].versions[0].versionId;
            field.eduLicenseDescription = actualProducts[i].eduLicenseDescription
            field.packetDescription = actualProducts[i].packetDescription
            field.variantDescription = actualProducts[i].variantDescription
            field.toVersionNumber = actualProducts[i].versions[0].versionNumber;
            field.description = actualProducts[i].description
            field.toVariantName = field.variantName;
            field.toVariantId = field.variantId;
            field.toPacketName = field.packetName;
            field.toPacketId = field.packetId;
            field.unicate = field.licenseId;
            field.toNumberOfUsers = field.numberOfUsers;
            field.agreementCode = actualProducts[i].agreementCode;
            field.agreementDesc = actualProducts[i].agreementDesc;
            field.agreementUrl = actualProducts[i].agreementUrl;
            field.dayToExpire = (new Date(field.expireDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
            break;
          }
        }
      });

      this.groupList = this.event.grouipngList('productName', this.myProductList);
      this.groupList.forEach(product => product.selection = true);
      this.onHidden();
    },
      error => {
        this.onHidden();
      })

  }

  //aktualizuje zaznaczone produkty
  updateSelected = (aligne?) => {
    //czyszcze tablice produktów do aktualizacji
    this.dateToCalculate.myProductOrders = [];

    let msgError = '';
    this.selectedRows.forEach((field, index) => {
      if (field.allowModify) {
        this.dateToCalculate.myProductOrders.push(field)
      }
      else if (!field.allowModify) msgError += `Wybrano licencje ${field.productName} ${field.licenseNumber} której nie można modyfikować<br>`;
    });

    if (msgError !== '') this.event.showNotification('error', msgError);

    //sprawdzam czy w liscie do kalkulacji nie ma produktow o tych samych numerach licencji
    this.dateToCalculate.myProductOrders = this.event.removeDuplicates(this.dateToCalculate.myProductOrders, 'licenseNumber');

    if (this.dateToCalculate.myProductOrders.length > 0) {
      (aligne) ? this.event.addToBasket(this.dateToCalculate, true) : this.event.addToBasket(this.dateToCalculate)
    }
  }

  //wyrównuje wszystkie możliwe licencje z wybrana datą
  aligneLicene = () => {
    this.selectedRows = [];
    let isAnotherClient: boolean = false;
    this.myProductList.forEach(field => {
      //sprawdzam czy w nazwie wariantu jest 365, czy produkt mozna modyfikowac i czy data wygaśnięcia jest wieksza lub równa dzisiejszej
      if (this.isSkipLicenses) {
        if (field.lineDescription !== null && field.lineDescription.indexOf('365') != -1 && field.allowModify === true && (field.expireDate === null || new Date(field.expireDate).getTime() >= this.dataToday.getTime())) {
          let data = { value: this.maxDateOfExpiration.dataForProduct }
          field.maxDateOfExpiration = this.event.dataFormatted(data);
          this.selectedRows.push(field);
        }
      }
      else {
        if (field.lineDescription !== null && field.lineDescription.indexOf('365') != -1 && field.allowModify === true) {
          let data = { value: this.maxDateOfExpiration.dataForProduct }
          field.maxDateOfExpiration = this.event.dataFormatted(data);
          this.selectedRows.push(field);
        }
      }
    })

    let basket = [];
    if (localStorage.getItem('basket-wapro-erp')) {
      basket = JSON.parse(this.event.decryptString(localStorage.getItem('basket-wapro-erp')));
    }

    basket.forEach(element => {
      if (element.customerId !== this.selectedRows[0].customerId) isAnotherClient = true;
    });

    if (isAnotherClient) this.event.showNotification('error', 'Nie można wyrównać daty końca licencji, gdy w koszyku już są produkty innego klienta');
    else {
      this.updateSelected(true);
    }
    this.selectedRows = [];
    this.aligneLicense = false;
  }

  onSelectionChanged = () => {
    let selected = [];
    this.grid.forEach(element => {
      element.selectedRowKeys.forEach(field => {
        selected.push(field)
      });
    });
    this.selectedRows = selected;
  }

  //aktualizuje wszsytkie możliwe produkty
  updateAll = (e) => {
    if (this.selectedRows.length == 0) {
      this.selectedRows = [];
      this.myProductList.forEach(field => {
        if (field.productId === e && field.allowModify === true) this.selectedRows.push(field)
      });
    }
    this.updateSelected();
    this.selectedRows = [];
    this.grid.forEach(element => {
      element.selectedRowKeys = [];
    });
  }

  //filtruje dane w gridzie
  filter = () => {
    this.groupList = this.event.grouipngList('productName', this.myProductList);
    this.groupList.forEach(product => product.selection = true)
    //this.grid.instance.clearFilter();
    if (this.filterColumn === 'LicenseNumber') {
      // this.grid.dataSource = new DataSource({
      //   store: this.myProductList,
      //   filter: ['licenseNumber', 'Contains', this.filterValue]
      // });

      this.deletedProductLicense(this.filterValue);
    }
    else {
      this.deletedProductName(this.filterValue)
      // this.grid.dataSource = new DataSource({
      //   store: this.myProductList,
      //   filter: ['productName', 'Contains', this.filterValue]
      // });
    }

    // this.accordion.selectedItems = this.groupList[0];
  }

  //dodawanie nowej licencji
  addNewLicense = (product) => {
    product.licenseId = 0;
    product.licenseNumber = '          ';
    product.toNumberOfUsers = 1;

    product.unicate = new Date().getTime();

    let actualProducts = JSON.parse(this.event.decryptString(localStorage.getItem("product-list-wapro-erp")));
    for (let i = 0; i < actualProducts.length; i++) {
      if (product.productId === actualProducts[i].productId) {
        actualProducts[i].variants.sort(function (a, b) {
          return a.orderWWW < b.orderWWW ? -1 : a.orderWWW > b.orderWWW ? 1 : 0;
        });
        product.toVariantId = actualProducts[i].variants[0].variantId;
        product.toVariantName = actualProducts[i].variants[0].variantName;

        if (actualProducts[i].packets !== null) {
          actualProducts[i].packets.sort(function (a, b) {
            return a.orderWWW < b.orderWWW ? -1 : a.orderWWW > b.orderWWW ? 1 : 0;
          });
          product.toPacketId = actualProducts[i].packets[0].packetId;
          product.toPacketName = actualProducts[i].packets[0].packetName;
        }
      }
    }
    if (this.customerName !== '') {
      product.remarks = `[NIP:${product.customerVATNumber}] `
    }
    this.event.onModifyClick(product)
  }

  selectedItemsChange = (event) => {
    try {
      this.groupList.forEach(field => {
        field.selection = false;
      })
      event.forEach(field => {
        field.selection = true;
      });
    }
    catch { }
  }

  onValueChanged = (e) => {
    (e.value) ? this.selectAll() : this.unselectAll();
  }

  //pobieram dane o dacie z jaka moga zostać wyrównane licencje
  aligneLicenseShow = () => {
    let clientId;
    (isNaN(this.clientId)) ? clientId = 0 : clientId = this.clientId;
    this.appServices.getAuth(`myproducts/GetMyProductsMaxExpirationInfo/${clientId}`).subscribe(response => {
      this.maxDateOfExpiration = response;
      this.aligneLicense = true;

      let DAY_IN_MILIS = 1000 * 60 * 60 * 24;
      let d1 = new Date(response.maxDateOfExpiration).getTime();

      this.maxDateOfExpiration.dataForProduct = new Date(d1 - 90 * DAY_IN_MILIS);
    },
      error => {
        this.event.showNotification('info', 'Wyrównanie daty ważności licencji nie jest możliwe. Szczegółowe informacje do uzyskania w dziale handlowym WAPRO')
      })
  }

}
