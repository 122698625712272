<div id="employee" *ngIf="loadingVisible"></div>
<div class="row">
  <div class="col-md-12" style="padding-bottom: 39px">
    <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->

    <h1>Swobodnie zarządzaj<br /><strong>swoimi licencjami</strong></h1>
  </div>
</div>

<div class="row panel-filtr-wapro-erp" style="margin-bottom: -32px">
  <div class="col-lg-7 col-md-12 col-sm-12 col-12">
    <div class="flex-container">
      <div>
        <dx-text-box
          type="search"
          class="dx-filter"
          valueChangeEvent="keyup"
          placeholder="Wpisz szukaną frazę"
          [(ngModel)]="filterValue"
          [showClearButton]="true"
          (keydown.enter)="filter()"
          (ngModelChange)="ngModelChange()"
          id="searchText"
        ></dx-text-box>
        <i
          class="icon icon-search d-none d-none-pc"
          aria-hidden="true"
          title="Filtrowanie oraz odświeżanie listy"
          (click)="filter()"
          style="cursor: pointer"
        ></i>
      </div>
      <div>
        <dx-select-box
          class="dx-filtr-input"
          [dataSource]="filtrName"
          displayExpr="label"
          valueExpr="value"
          id="filtrName"
          placeholder="Nazwa"
          [(ngModel)]="filterColumn"
          [dropDownOptions]="{ closeOnTargetScroll: false }"
        >
        </dx-select-box>
        <i
          class="icon icon-search d-none-mobile"
          aria-hidden="true"
          title="Filtrowanie oraz odświeżanie listy"
          (click)="filter()"
          id="refreshData"
          style="cursor: pointer"
        ></i>
      </div>
    </div>
  </div>

  <div
    class="col-lg-5 col-md-12 col-sm-12 col-12 filer-icon-aligne"
    *ngIf="!isMobile"
  >
    <dx-button
      class="icon-border icon-header"
      (onClick)="unselectAll()"
      [disabled]="selectedRows.length === 0"
      [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
    >
      <i
        class="icon absui-icon--variables"
        aria-hidden="true"
        title="Odznacz"
      ></i>
    </dx-button>

    <dx-button
      class="icon-border icon-header"
      (onClick)="updateSelected(); checkInBasket('aktualizuj')"
      [disabled]="selectedRows.length === 0"
      [ngClass]="{ 'box-shadow': selectedRows.length !== 0 }"
    >
      <i class="icon icon-refresh" aria-hidden="true" title="Aktualizuj"></i>
    </dx-button>

    <dx-button
      class="icon-border icon-header box-shadow"
      (onClick)="aligneLicenseShow()"
    >
      <i
        class="icon icon-calendar"
        aria-hidden="true"
        title="Wyrównaj datę wygaśnięcia licencji"
      ></i>
    </dx-button>

    <!-- <dx-button class="icon-border" (onClick)="unselectAll()" [disabled]="selectedRows.length === 0">
            <i class="icon icon-pencile" aria-hidden="true" title="Odznacz"></i>
        </dx-button> -->

    <dx-button
      class="icon-border icon-header box-shadow"
      (onClick)="expandAll()"
      [ngClass]="{ 'red-circle': isExpendAll, 'icon-border': !isExpendAll }"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-more': isExpendAll,
          'icon--keyboard-arrow-rightt': !isExpendAll
        }"
        aria-hidden="true"
        title="Rozwiń/zwiń wszystkie"
      ></i>
    </dx-button>
  </div>
</div>

<div class="row" *ngIf="customerName !== ''">
  <div class="col-md-12 text-right">
    <div class="customer-name-container text-center current-mobile">
      <p class="p-customer-name current-mobile">
        <span>Klient: </span><br />
        <strong>{{ customerName }}</strong>
        <i
          class="dx-icon dx-icon-clear"
          (click)="clearUri()"
          style="position: relative; top: 4px"
        ></i>
      </p>
    </div>
  </div>
</div>

<div class="row check-marg">
  <div class="col-md-6" style="height: 20px">
    <dx-check-box
      text="Zaznacz wszystkie"
      class="check-all"
      style="width: 100%"
      (onValueChanged)="onValueChanged($event)"
      [(value)]="expendAll"
    >
    </dx-check-box>
  </div>
  <div class="col-md-6 text-right" *ngIf="isMobile">
    <dx-button
      class="icon-border icon-header"
      style="position: relative; top: -30px; margin-bottom: -35px"
      (onClick)="showGroupPopUp = !showGroupPopUp"
    >
      <i
        class="icon absui-icon--more-horiz"
        aria-hidden="true"
        title="Odznacz"
      ></i>
    </dx-button>
  </div>
</div>

<div class="row" *ngIf="groupList.length !== 0; else noData"></div>
<div
  class="about-license-wapro-erp"
  *ngFor="let item of groupList; let i = index"
>
  <div class="row" id="gridContainer">
    <div class="col-md-12" (click)="item.selection = !item.selection">
      <div class="row" style="box-sizing: border-box">
        <div class="col-12 col-md-6">
          <div class="flex-container">
            <div>
              <div class="hexagon-red">
                <p
                  [ngClass]="{
                    'p-smaler-name-shortcut':
                      item.productNameShortcut.length === 3
                  }"
                >
                  {{ item.productNameShortcut }}
                </p>
              </div>
            </div>
            <div>
              <h2
                class="product-description-wapro-erp"
                style="text-align: left"
              >
                {{ item.productNameDescription }}
              </h2>
              <h4 class="h1-product-name-wapro-erp">
                WAPRO <strong>{{ item.name }}</strong>
              </h4>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 text-right" style="padding-right: 10px">
          <dx-button
            class="icon-border"
            (onClick)="
              $event.event.stopPropagation(); addNewLicense(item.list[0])
            "
            [disabled]="event.eventMSG.typeOfLock === 3"
          >
            <i
              class="icon icon-plus"
              aria-hidden="true"
              title="Dodaj nową licencję"
            ></i>
          </dx-button>

          <dx-button
            class="icon-border"
            (onClick)="
              $event.event.stopPropagation();
              updateAll(item.list[0].productId);
              checkInBasket(item.list)
            "
          >
            <i
              class="icon icon-refresh"
              aria-hidden="true"
              title="Aktualizuj wszystkie"
            ></i>
          </dx-button>

          <dx-button
            [ngClass]="{
              'red-circle': item.selection,
              'icon-border': !item.selection
            }"
            style="opacity: 1"
          >
            <i
              class="icon"
              [ngClass]="{
                'absui-icon--expand-more': item.selection,
                'icon--keyboard-arrow-rightt': !item.selection
              }"
              aria-hidden="true"
              title="Rozwiń/Zwiń"
            ></i>
          </dx-button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="item.selection">
    <div class="col-md-12">
      <dx-data-grid
        [dataSource]="item.list"
        [allowColumnReordering]="false"
        [showBorders]="true"
        [columnAutoWidth]="true"
        wordWrapEnabled="true"
        [(selectedRowKeys)]="item.selectedRows"
        #grid
        [showBorders]="false"
        [showColumnLines]="false"
        [hoverStateEnabled]="true"
        [showRowLines]="true"
        (onSelectionChanged)="onSelectionChanged()"
        [height]="item.height"
        (onCellClick)="onAdaptiveDetailRowPreparing($event, item)"
        (onCellPrepared)="onCellPrepared($event)"
      >
        <dxi-column
          dataField="licenseNumber"
          caption="Licencja"
          [allowSorting]="false"
          alignment="left"
        >
        </dxi-column>
        <dxi-column
          dataField="versionNumber"
          caption="Wersja"
          [allowSorting]="false"
          [hidingPriority]="6"
          width="100"
          alignment="left"
        >
        </dxi-column>
        <dxi-column
          caption="Data pierwszej aktywacji"
          width="160"
          cellTemplate="firstActivationDateCell"
          [allowSorting]="false"
          [hidingPriority]="2"
          alignment="left"
        ></dxi-column>
        <dxi-column
          caption="Data wygaśnięcia"
          [allowSorting]="false"
          alignment="left"
          width="130"
          [hidingPriority]="3"
          cellTemplate="expireDateCell"
        >
        </dxi-column>
        <dxi-column
          dataField="lineDescription"
          caption="Linia - wariant"
          [allowSorting]="false"
          width="120"
          alignment="left"
          width="170"
          [hidingPriority]="5"
        >
        </dxi-column>
        <dxi-column
          dataField="numberOfUsers"
          caption="Stanowiska"
          [allowSorting]="false"
          alignment="left"
          width="110"
          [hidingPriority]="0"
        >
        </dxi-column>
        <dxi-column
          caption="WO"
          [allowSorting]="false"
          alignment="left"
          [hidingPriority]="1"
          cellTemplate="WOCell"
          width="45"
          headerCellTemplate="headerWO"
        >
        </dxi-column>
        <dxi-column
          caption=""
          [allowSorting]="false"
          cellTemplate="iconCell"
          alignment="right"
          width="175"
          [hidingPriority]="4"
        >
        </dxi-column>

        <dxo-selection
          selectAllMode="allPages"
          [showCheckBoxesMode]="showCheckBoxesMode"
          mode="multiple"
        >
        </dxo-selection>

        <dxo-scrolling mode="virtual" showScrollbar="never"></dxo-scrolling>
        <dxo-sorting mode="none"></dxo-sorting>

        <dxo-grouping
          [autoExpandAll]="true"
          [allowCollapsing]="false"
        ></dxo-grouping>

        <div *dxTemplate="let data of 'firstActivationDateCell'">
          <p>{{ data.data.firstActivationDate | date : "yyyy-MM-dd" }}</p>
        </div>

        <div *dxTemplate="let data of 'headerWO'">
          <div id="headerWO{{ i }}" style="cursor: pointer">WO</div>
          <dx-tooltip
            target="#headerWO{{ i }}"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="{ name: 'mouseover', delay: 500 }"
          >
            <div
              *dxTemplate="let data = data; of: 'content'"
              class="tooltipContent"
            >
              Informacja czy licencja dotyczy usługi Wapro Online
            </div>
          </dx-tooltip>
        </div>

        <div *dxTemplate="let data of 'expireDateCell'">
          <p
            [ngClass]="{
              'p-gray-wapro-erp': data.data.dayToExpire < 0,
              'p-orange-wapro-erp':
                data.data.dayToExpire < 60 && data.data.dayToExpire >= 30,
              'p-red-wapro-erp':
                data.data.dayToExpire < 30 && data.data.dayToExpire > 0
            }"
          >
            {{ data.data.expireDate | date : "yyyy-MM-dd" }}
          </p>
        </div>

        <div *dxTemplate="let data of 'WOCell'">
          <i
            class="fa fa-globe"
            aria-hidden="true"
            [hidden]="!data.data.isOnline"
          ></i>
        </div>

        <div *dxTemplate="let data of 'iconCell'" class="text-right-mobile">
          <dx-button
            class="small-border-row d-none"
            (onClick)="event.onHistoryClick(data.data)"
          >
            <i
              class="icon icon-history"
              aria-hidden="true"
              title="Historia"
              [disabled]="!data.data.allowModify"
            ></i>
            <span>Historia</span>
          </dx-button>

          <dx-button
            class="icon-border-row-table d-none-mobile"
            (onClick)="event.onHistoryClick(data.data)"
          >
            <i
              class="icon icon-history icon-row"
              aria-hidden="true"
              title="Historia"
            ></i>
          </dx-button>

          <dx-button
            class="small-border-row d-none"
            [disabled]="data.data.operatingSystem === 'Service'"
            (onClick)="event.downloadCertyfikat(data.data.licenseId)"
          >
            <i
              class="icon icon-certyfikate"
              aria-hidden="true"
              title="Pobierz certyfikat"
            ></i>
            <span>Certyfikat</span>
          </dx-button>

          <dx-button
            class="icon-border-row-table d-none-mobile"
            [disabled]="data.data.operatingSystem === 'Service'"
            (onClick)="event.downloadCertyfikat(data.data.licenseId)"
          >
            <i
              class="icon icon-certyfikate icon-row"
              aria-hidden="true"
              title="Pobierz certyfikat"
            ></i>
          </dx-button>

          <dx-button
            class="icon-border-row-table"
            (onClick)="event.onModifyClick(data.data)"
            [disabled]="
              !data.data.allowModify || event.eventMSG.typeOfLock === 3
            "
          >
            <i
              class="icon icon-pencile icon-row"
              aria-hidden="true"
              title="Modyfikuj/Aktualizuj"
            ></i>
          </dx-button>
        </div> </dx-data-grid
      ><br /><br />
    </div>
  </div>
</div>

<!-- <div class="row">
    <div class="col-md-12" *ngIf="groupList.length !== 0; else noData">
        <dx-accordion #accordion [dataSource]="groupList" [collapsible]="true" [multiple]="true"
            [animationDuration]="300" [selectedIndex]="0" (selectedItemsChange)="selectedItemsChange($event)">
            <div *dxTemplate="let item of 'title'">

            </div>

            <div *dxTemplate="let item of 'item'">

            </div>
        </dx-accordion>
    </div> -->
<!--
<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
>
</dx-load-panel> -->

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>

<dx-popup
  [width]="widthPopUp"
  [height]="heightpopUp"
  [showTitle]="true"
  title="Licencje"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="aligneLicense"
>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12 text-left">
        <p class="popup-text">{{ maxDateOfExpiration.description }}</p>
        <p class="popup-text-information">
          Proszę wybrać datę do której zostanie wyrównana licencja
        </p>
        <div class="row">
          <div class="col-md-6 col-12">
            <label>Data wyrównania</label>
            <dx-date-box
              placeholder=""
              [useMaskBehavior]="true"
              displayFormat="yyyy-MM-dd"
              type="date"
              [max]="maxDateOfExpiration.maxDateOfExpiration"
              [min]="dataToday"
              style="height: 40px"
              [(value)]="maxDateOfExpiration.dataForProduct"
              class="no-box-shadow"
              dateOutOfRangeMessage="Data poza dopuszczalnym zakresem"
            >
            </dx-date-box>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <dx-check-box
              [(ngModel)]="isSkipLicenses"
              class="text-box no-box-shadow"
              style="height: 20px"
              text="Pomijaj licencje, które wygasły"
            >
            </dx-check-box>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dx-button
              class="btn-danger"
              text="Aktualizuj"
              [disabled]="maxDateOfExpiration.dataForProduct === ''"
              (onClick)="aligneLicene()"
              style="
                float: right;
                width: 130px;
                height: 40px;
                color: #ffffff;
                margin-right: 10px;
                margin-top: 20px;
                margin-bottom: 15px;
              "
            >
            </dx-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="345"
  [height]="267"
  [showTitle]="true"
  title="Akcje"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showGroupPopUp"
>
  <div *dxTemplate="let data of 'content'">
    <div class="mobile-toolbar">
      <dx-button
        [ngClass]="{
          'btn-mobile': !isExpendAll,
          'btn-mobile-red': isExpendAll
        }"
        (onClick)="showGroupPopUp = false; expandAll()"
      >
        <i
          class="icon"
          [ngClass]="{
            'icon--keyboard-arrow-right-mobile': !isExpendAll,
            'absui-icon--expand-more-mobile': isExpendAll
          }"
          aria-hidden="true"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >{{ isExpendAll ? "Zwiń" : "Rozwiń wszystkie" }}
      </dx-button>
      <dx-button
        class="btn-mobile"
        (onClick)="showGroupPopUp = false; aligneLicenseShow()"
      >
        <i
          class="icon icon-calendar"
          aria-hidden="true"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i>
        <p class="mobile-text">Wyrównaj datę wygaśnięcia licencji</p>
      </dx-button>
      <dx-button
        class="btn-mobile"
        [disabled]="selectedRows.length === 0"
        (onClick)="showGroupPopUp = false; updateSelected()"
      >
        <i
          class="icon icon-refresh"
          aria-hidden="true"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >Aktualizuj
      </dx-button>
      <dx-button
        class="btn-mobile"
        [disabled]="selectedRows.length === 0"
        (onClick)="showGroupPopUp = false; unselectAll()"
      >
        <i
          class="icon absui-icon-variables"
          aria-hidden="true"
          style="margin-right: 14px; position: relative; top: 3px"
        ></i
        >Odznacz
      </dx-button>
    </div>
  </div>
</dx-popup>
