import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { ActivatedRoute, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { custom } from 'devextreme/ui/dialog';

const helper = new JwtHelperService();

@Component({
  selector: 'app-history-licence',
  templateUrl: './history-licence.component.html',
  styleUrls: ['./history-licence.component.scss'],
})
export class HistoryLicenceComponent implements OnInit {
  licenseId: number = 0;
  historyList = [];
  productName: String = '';
  lastActivationDate: string = '';
  popVisible: boolean = false;

  lockLicenseInfo = { modalMsg: '', licenseId: null, serialNumber: null };
  widthPopUp: number = 500;
  heightGrid = 0;

  isClient;
  UserRole;

  constructor(
    private appServices: AppServices,
    public event: EventService,
    private route: ActivatedRoute,
    public _router: Router
  ) {
    this.event.showNavbar.next(true);
  }

  ngOnInit(): void {
    this.UserRole = helper.decodeToken(
      localStorage.getItem('wapro-erp-token')
    ).UserRole;

    if (this.UserRole === 'partner' || this.UserRole === 'subpartner') {
      this.isClient = false;
    } else {
      this.isClient = true;
    }

    this.route.params.subscribe(
      (params) => (this.licenseId = parseInt(params['licenseId']))
    );
    this.getHistoryList();
    if (window.innerWidth <= 380) {
      this.widthPopUp = 350;
    }

    // this.route
    //   .queryParams
    //   .subscribe(params => {
    //     this.lastActivationDate = params['dataProduct'];
    //     this.productName = params['productName'].replace('WAPRO ', '');
    //   });
  }

  downloadCertyfikat = (icenseId) => {
    this.appServices
      .getAuth(
        `MyProducts/${icenseId.licenseId}/certificate/${icenseId.serialNumber}`
      )
      .subscribe(
        (response) => {
          let pom = response.certificate.replace(/\\(.)/g, '$1');
          this.event.downloadFile(pom, response.fileName);

          // const linkSource = `data:application/pdf;base64,${pom}`;
          // const downloadLink = document.createElement("a");
          // const fileName = response.fileName;

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.click();
        },
        (error) => {
          this.event.showNotification('error', 'Błąd pobierania certyfikatu');
        }
      );
  };

  getHistoryList() {
    return this.appServices
      .getAuth(`MyProducts/${this.licenseId}/history`)
      .subscribe((response) => {
        this.historyList = response;
        this.heightGrid = this.historyList.length * 47.2 + 40;
        this.historyList[0].productName =
          this.historyList[0].productName.replace('WAPRO', '');
        this.historyList[0].productNameDescription =
          this.historyList[0].productNameDescription.toLowerCase();
      });
  }

  onAdaptiveDetailRowPreparing = (event) => {
    if (window.innerWidth < 1100) {
      this.heightGrid = this.historyList.length * 47.2 + 40;
      this.historyList.forEach((el) => {
        if (event.component.isAdaptiveDetailRowExpanded(el))
          this.heightGrid += 240;
      });
    }
  };

  modelShow = (date) => {
    date.serialNumberIsActive
      ? (this.lockLicenseInfo.modalMsg = 'zablokować')
      : (this.lockLicenseInfo.modalMsg = 'odblokować');
    this.lockLicenseInfo.licenseId = date.licenseId;
    this.lockLicenseInfo.serialNumber = date.serialNumber;
    this.popVisible = true;
    // let myDialog = custom({
    //   title: "Potwierdź",
    //   messageHtml: `Czy chcesz ${this.lockLicenseInfo.modalMsg} wybrany numer seryjny?`,
    //   buttons: [{
    //     text: "Tak",
    //     elementAttr: { class: "dialog-accept-btn"},
    //     onClick: () => {
    //       this.lock();
    //     }
    //   },
    //   {
    //     text: "Nie",
    //     elementAttr: { class: "dialog-reject-btn"}
    //   }
    //   ]
    // });

    // myDialog.show().done();
  };

  generateCode(data) {
    sessionStorage.setItem('myAccountGenericCode', JSON.stringify(data));
    this._router.navigate(['moje-dane']);
  }

  lock() {
    let data = { state: false };
    this.lockLicenseInfo.modalMsg == 'zablokować'
      ? (data.state = false)
      : (data.state = true);

    this.appServices
      .postAuth(
        `MyProducts/${this.lockLicenseInfo.licenseId}/serialNumberState/${this.lockLicenseInfo.serialNumber}`,
        data
      )
      .subscribe(
        (response) => {
          let msgInfo = '';
          if (response.status) {
            this.lockLicenseInfo.modalMsg == 'zablokować'
              ? (msgInfo = 'Zablokowano')
              : (msgInfo = 'Odblokowano');
            this.event.showNotification(
              'success',
              `${msgInfo} produkt o numerze seryjnym ${this.lockLicenseInfo.serialNumber}`
            );
            this.getHistoryList();
          } else {
            let block =
              this.lockLicenseInfo.modalMsg == 'zablokować'
                ? 'zablokować'
                : 'odblokować';
            msgInfo = `Nie udało się ${block} produktu o numerze seryjnym ${this.lockLicenseInfo.serialNumber}`;
            this.event.showNotification('error', msgInfo);
          }
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).message);
        }
      );
  }
}
