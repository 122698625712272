import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppServicesPayment } from '../app-services.service'
import { ActivatedRoute } from '@angular/router'
import { DetailsResponse, SubmitResponse } from './payments-responses'
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/event.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  paymentSource = 'wapro'
  window
  width
  guid: string = null
  isPaymentTpay: any = false;
  paymentData: DetailsResponse
  source$: Subscription
  isTpayOperator$: Subscription
  isReturn: boolean = false
  constructor(public appService: AppServicesPayment, private route: ActivatedRoute,
     //private paymentsService: PaymentsService,
     private event: EventService) {

    this.source$ = event.source.subscribe((source: string)=>{
      this.paymentSource = source || "wapro"
    })

    this.isTpayOperator$ = event.isTpayOperator.subscribe((isTpay: boolean)=>{
      this.isPaymentTpay = isTpay || false

      this.updateTpayDisplay()
    })
  }

  ngOnInit(): void {
    this.window = window
    this.route.queryParamMap
      .subscribe((params: any): void => {
        let paramsObject = params?.params
        this.paymentSource = paramsObject?.source || "wapro"
        this.guid = paramsObject?.id || ""
        if(paramsObject.isPaymentTpay){
          (paramsObject.isPaymentTpay == 'false')?this.isPaymentTpay=false:this.isPaymentTpay=true;
        }
        if(paramsObject.return){
          (paramsObject.return == 'true')?this.isReturn=true:this.isReturn=false;
        }
        if(this.guid){
          this.getDetails()
        }
      }
    );


    window.addEventListener('resize', this.listener);
    this.listener(null)

    this.updateTpayDisplay()
  }

  updateTpayDisplay(){
    try{
      if(this.isPaymentTpay){
        let con = document.getElementsByClassName('content-payment') as HTMLCollectionOf<HTMLElement>
        con[0].style.display = 'block';
        if(this.width > 767){
          con[0].style.paddingTop = '40px';
        }
        else{
          con[0].style.paddingTop = '0';
        }
      }
    }
    catch{}
  }

  listener = (e) =>{
    this.width = window.innerWidth
    this.updateTpayDisplay()
  }

  ngOnDestroy(){
    if(this.source$) this.source$.unsubscribe()
    if(this.isTpayOperator$) this.isTpayOperator$.unsubscribe()

    window.removeEventListener('resize', this.listener)
  }

  getDetails(){
    this.appService.get(`Transaction/details/${this.guid}`).subscribe((data: DetailsResponse)=>{
      this.paymentData = data
      this.event.source.next(data.appName)
      this.event.isTpayOperator.next(data.operatorName === "tpay")
    },
    err=>{
      this.event.showNotification('error', `Wystąpił problem z pobraniem informacji o pśatności`);
    })
  }

  pey = () => {
  }

  submitPayment(paymentMethod: any = null){
    //if(!this.isPaymentTpay){
      this.submitApi(paymentMethod)
    //}
  }

  submitApi(paymentMethod: any = null){
    this.appService.post("Transaction/submit",{
      transactionId: this.guid,
      paymentMethod: parseInt(paymentMethod)
    }).subscribe((res: SubmitResponse)=>{
      if(res.status !== "error"){
        this.window.location.href = res.url
      }else{
        this.event.showNotification('error', `Wystąpił problem ze zgłoszeniem płatności`);
      }
    },err=>{
      this.event.showNotification('error', `Wystąpił problem ze zgłoszeniem płatności`);
    })
  }

  goBack(){
    if(this.isReturn){
      window.history.back()
    }
  }
}
