import { Component, OnInit } from '@angular/core';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';
import { FormBuilder, Validators } from '@angular/forms';
import value from 'globalize';

@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.scss'],
})
export class CodesComponent implements OnInit {
  submitted: boolean = false;
  maxLengthCode = 9;
  formCodesActivation;
  activeCode = '';

  formError = {
    registrationCode: '',
    licenseNumber: '',
    vatNumber: '',
    serialNumber: '',
    secretNumber: '',
  };

  validationMessages = {
    registrationCode: {
      required: 'Kod rejestracyjny jest wymagany',
    },
    licenseNumber: {
      required: 'Numer licencji jest wymagany',
    },
    vatNumber: {
      required: 'Nip firmy jest wymagany',
    },
    serialNumber: {
      required: 'Numer seryjny jest wymagany',
    },
    secretNumber: {
      required: 'Numer tajny jest wymagany',
      pattern: 'Błędny numer tajny',
    },
  };

  constructor(
    private event: EventService,
    public formBuilder: FormBuilder,
    private appService: AppServices
  ) {
    this.event.dataActivateCode.subscribe((data) => {
      setTimeout(() => {
        this.formCodesActivation.controls.vatNumber.setValue(
          data.customerVATNumber
        );
        this.formCodesActivation.controls.licenseNumber.setValue(
          data.licenseNumber
        );
        this.formCodesActivation.controls.serialNumber.setValue(
          data.serialNumber
        );
        this.formCodesActivation.controls.secretNumber.setValue(
          data.secretCode
        );
      }, 0);
    });
  }

  ngOnInit(): void {
    this.onCreateForm();

    this.formCodesActivation.valueChanges.subscribe((value) => {
      this.event.onControlValueChanged(
        this.formCodesActivation,
        this.formError,
        this.validationMessages
      );
    });
  }

  onCreateForm = () => {
    this.formCodesActivation = this.formBuilder.group({
      registrationCode: ['', Validators.required],
      licenseNumber: ['', Validators.required],
      vatNumber: ['', Validators.required],
      serialNumber: ['', Validators.required],
      secretNumber: ['', [Validators.required, Validators.pattern(/[0-9]{4}/)]],
    });
  };

  downloadInstruction = () => {
    window.open(
      'https://wapro.pl/aktualizacja/?&_ga=2.47319655.1369159032.1598249641-1302885121.1593607427#tab-3',
      '_blank'
    );
  };

  resolved(captchaResponse: string) {
    this.formCodesActivation.controls.recaptchaReactive.setValue(
      captchaResponse
    );
  }

  onSubmit = (values) => {
    this.activeCode = '';
    this.submitted = true;
    if (this.formCodesActivation.invalid) {
      this.event.onControlValueChanged(
        this.formCodesActivation,
        this.formError,
        this.validationMessages
      );
      return;
    } else {
      try {
        values.secretNumber = Number(values.secretNumber);
        this.appService
          .postAuth(`myproducts/GenereateActivationCode`, values)
          .subscribe(
            (response) => {
              if (response.status) {
                this.event.showNotification('success', response.description);
                this.submitted = false;
                this.activeCode = response.description;
                this.formCodesActivation.controls.registrationCode.setValue('');
                this.formCodesActivation.controls.licenseNumber.setValue('');
                this.formCodesActivation.controls.vatNumber.setValue('');
                this.formCodesActivation.controls.serialNumber.setValue('');
                this.formCodesActivation.controls.secretNumber.setValue('');
              } else this.event.showNotification('error', response.description);
            },
            (error) => {
              this.event.showNotification(
                'error',
                'Błąd generowania kodu aktywacyjnego'
              );
            }
          );
      } catch {
        this.event.showNotification(
          'error',
          'Kod tajny może zawierać tylko cyfry'
        );
      }
    }
  };
}
