import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';

import { HttpClientModule } from '@angular/common/http';

import { AppServices } from './app-services.service';
import { AuthGuard, badRolaUse, isLog, canActivateService } from './auth.guard';

import { PageNotFaoundComponent } from './page-not-faound/page-not-faound.component';

import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxSliderModule } from 'devextreme-angular/ui/slider';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxToastModule } from 'devextreme-angular/ui/toast';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxGalleryModule } from 'devextreme-angular/ui/gallery';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { WaproLoaderComponent } from './wapro-loader/wapro-loader.component';
//import { DxTemplateModule } from 'devextreme-angular';

// import {  DxDataGridModule,
//   DxCheckBoxModule ,
//   DxSelectBoxModule,
//   DxTemplateModule,
//   DxPopupModule,
//   DxButtonModule,
//   DxTextBoxModule,
//   DxValidatorModule,
//   DxTextAreaModule,
//   DxAccordionModule,
//   DxSliderModule,
//   DxTagBoxModule,
//   DxListModule,
//   DxNumberBoxModule} from 'devextreme-angular';

import { LogOnComponent } from './log-on/log-on.component';
import { OfferListComponent } from './offer-list/offer-list.component';
import { ShopingCartComponent } from './shoping-cart/shoping-cart.component';
import { MyProductsComponent } from './my-products/my-products.component';
import { OrderComponent } from './order/order.component';
import { MyCustomerComponent } from './my-customer/my-customer.component';
import { HistoryLicenceComponent } from './history-licence/history-licence.component';
import { MyClientProductListComponent } from './my-client-product-list/my-client-product-list.component';
import { ModyfiComponent } from './modyfi/modyfi.component';
import { OrderListComponent } from './order-list/order-list.component';

import { PointReplacerPipe, SafePipe } from './pipes/pipe';
import { InfoOrderComponent } from './info-order/info-order.component';
import { MyDataComponent } from './my-data/my-data.component';
import { DynamicComponentComponent } from './dynamic-component/dynamic-component.component';
import { ChangePswComponent } from './my-data/change-psw/change-psw.component';
import { AddressesComponent } from './my-data/addresses/addresses.component';
import { MenuComponent } from './menu/menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPswComponent } from './reset-psw/reset-psw.component';
import { NewPswComponent } from './new-psw/new-psw.component';
import { CodesComponent } from './my-data/codes/codes.component';
import { RegisterComponent } from './register/register.component';
import { NoDataComponent } from './no-data/no-data.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ContactComponent } from './my-data/contact/contact.component';
import { RegistrationPanelComponent } from './registration-panel/registration-panel.component';
import { NewUserComponent } from './new-user/new-user.component';
import { FinanseReportComponent } from './finanse-report/finanse-report.component';
import { LogOn2Component } from './log-on2/log-on2.component';
import { PipesModule } from './pipes/pipes.module';
import { ChangeLoginToEmailComponent } from './change-login-to-email/change-login-to-email.component';

import { environment } from '../environments/environment';
import { RegisterLicenseComponent } from './register-license/register-license.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { ServiceBreakComponent } from './service-break/service-break.component';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    PageNotFaoundComponent,
    LogOnComponent,
    OfferListComponent,
    ShopingCartComponent,
    SafePipe,
    MyProductsComponent,
    OrderComponent,
    MyCustomerComponent,
    HistoryLicenceComponent,
    MyClientProductListComponent,
    ModyfiComponent,
    OrderListComponent,
    InfoOrderComponent,
    MyDataComponent,
    DynamicComponentComponent,
    ChangePswComponent,
    AddressesComponent,
    MenuComponent,
    DashboardComponent,
    ResetPswComponent,
    NewPswComponent,
    CodesComponent,
    RegisterComponent,
    NoDataComponent,
    WelcomeComponent,
    ThankYouComponent,
    ContactComponent,
    RegistrationPanelComponent,
    NewUserComponent,
    FinanseReportComponent,
    LogOn2Component,
    RegisterLicenseComponent,
    ReadMoreComponent,
    ServiceBreakComponent,
  ],
  imports: [
    WaproLoaderComponent,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxPopupModule,
    DxButtonModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxTextAreaModule,
    DxAccordionModule,
    DxSliderModule,
    RecaptchaModule,
    DxTagBoxModule,
    DxListModule,
    DxDateBoxModule,
    DxTabPanelModule,
    DxToastModule,
    PipesModule,
    ChangeLoginToEmailComponent,
    DxRadioGroupModule,
    DxTooltipModule,
    DxLoadPanelModule,
    DxToolbarModule,
    DxGalleryModule,
    NotifierModule.withConfig(customNotifierOptions),
    RecaptchaFormsModule,
  ],
  // entryComponents: [
  //   ChangePswComponent,
  //   AddressesComponent,
  //   CodesComponent,
  //   ContactComponent,
  //   RegisterComponent,
  //   NewUserComponent,
  // ],

  providers: [
    AppServices,
    AuthGuard,
    badRolaUse,
    isLog,
    canActivateService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.sitekey } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
