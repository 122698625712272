import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFaoundComponent } from './page-not-faound/page-not-faound.component';

import { DashboardComponent } from './dashboard/dashboard.component';

import { OfferListComponent } from './offer-list/offer-list.component';
import { ShopingCartComponent } from './shoping-cart/shoping-cart.component';
import { MyProductsComponent } from './my-products/my-products.component';
import { MyCustomerComponent } from './my-customer/my-customer.component';
import { OrderComponent } from './order/order.component';
import { HistoryLicenceComponent } from './history-licence/history-licence.component';
import { MyClientProductListComponent } from './my-client-product-list/my-client-product-list.component';
import { ModyfiComponent } from './modyfi/modyfi.component';
import { OrderListComponent } from './order-list/order-list.component';
import { InfoOrderComponent } from './info-order/info-order.component';
import { MyDataComponent } from './my-data/my-data.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

import { LogOnComponent } from './log-on/log-on.component';
import { ResetPswComponent } from './reset-psw/reset-psw.component';
import { NewPswComponent } from './new-psw/new-psw.component';
import { RegisterComponent } from './register/register.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { RegistrationPanelComponent } from './registration-panel/registration-panel.component';
import { RegisterLicenseComponent } from './register-license/register-license.component';

import { AuthGuard, badRolaUse, isLog, canActivateService } from './auth.guard';
import { FinanseReportComponent } from './finanse-report/finanse-report.component';
import { PaymentsModule } from './payments/payments.module';
import { ServiceBreakComponent } from './service-break/service-break.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [isLog, canActivateService],
  },
  {
    path: 'logowanie',
    component: LogOnComponent,
    canActivate: [isLog, canActivateService],
  },
  { path: 'reset-hasla', component: ResetPswComponent, canActivate: [isLog] },
  { path: 'zmiana-hasla', component: NewPswComponent, canActivate: [isLog] },
  {
    path: 'rejestracja',
    component: RegistrationPanelComponent,
    canActivate: [isLog],
  },
  { path: 'witamy', component: WelcomeComponent, canActivate: [isLog] },
  {
    path: 'produkty-klientow',
    component: MyClientProductListComponent,
    canActivate: [AuthGuard, badRolaUse],
  },
  {
    path: 'podsumowanie',
    component: ThankYouComponent,
    canActivate: [AuthGuard],
  },
  { path: 'oferta', component: OfferListComponent },
  {
    path: 'panel/oferta',
    component: OfferListComponent,
    canActivate: [AuthGuard],
  },
  { path: 'koszyk', component: ShopingCartComponent },
  {
    path: 'moje-produkty',
    component: MyProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'moje-produkty/:productId',
    component: MyProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'moje-produkty/client/:clientId',
    component: MyProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'moi-klienci',
    component: MyCustomerComponent,
    canActivate: [AuthGuard, badRolaUse],
  },
  {
    path: 'historia-zamowien',
    component: OrderListComponent,
    canActivate: [AuthGuard],
  },
  { path: 'zamowienie', component: OrderComponent, canActivate: [AuthGuard] },
  {
    path: 'historia/:licenseId',
    component: HistoryLicenceComponent,
    canActivate: [AuthGuard],
  },
  { path: 'modyfikuj/:productId', component: ModyfiComponent },
  {
    path: 'zamowienie-info/:orderId',
    component: InfoOrderComponent,
    canActivate: [AuthGuard],
  },
  { path: 'moje-dane', component: MyDataComponent, canActivate: [AuthGuard] },
  {
    path: 'raport-finansowy',
    component: FinanseReportComponent,
    canActivate: [AuthGuard, badRolaUse],
  },
  { path: 'zamowienie-infoLink/:Guid', component: InfoOrderComponent },
  {
    path: 'niezarejestrowany/modyfikuj/:productId',
    component: ModyfiComponent,
  },
  {
    path: 'weryfikacja-licencji',
    component: RegisterLicenseComponent,
    canActivate: [AuthGuard, badRolaUse],
  },
  {
    path: 'prace-serwisowe',
    component: ServiceBreakComponent,
  },
  {
    path: 'platnosc',
    loadChildren: () =>
      import('./payments/payments.module').then((m) => PaymentsModule),
  },
  { path: '**', component: PageNotFaoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
