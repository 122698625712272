<div class="panel-log-wapro-erp">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
            <h1><strong>Nie pamiętasz<br>hasła?</strong></h1>
            <p class="p-logOn-header-wapro-erp">Podaj swój login, a wyślemy Ci<br>instrukcję zmiany hasła</p>
        </div>
    </div>

    <form [formGroup]="resetPswForm" class="form-log">
        <div class="form-group">
            <label for="login">Login <strong>*</strong></label>
            <dx-text-box [ngClass]="{'input-required': submitted && formError.login }" name="login" placeholder=""
                id="login" formControlName="login" [showClearButton]="true" mode="email"
                style="width: 350px; height: 45px;">
            </dx-text-box>
            <small class="text-danger" *ngIf="submitted && formError.login" style="font-size: 80%;">
                {{ formError.login}}
            </small>
        </div>

        <div class="row">
            <div class="col-md-12">
                <br>
                <re-captcha formControlName="recaptchaReactive" (resolved)="resolved($event)" ></re-captcha>
                <br><br>
                <dx-button class="btn-danger btn-log-wapro-erp" text="Resetuj hasło"
                    (onClick)="onSubmit(resetPswForm.value)" [disabled]="resetPswForm.controls.recaptchaReactive.invalid">
                </dx-button>
            </div>
        </div>
    </form>
</div>