<div class="payment-container" [ngClass]="{ 'no-tpay': !isPaymentTpay }">
  <div class="payment-title">Szczegóły płatności</div>

  <ng-container *ngIf="width >= 767">
    <table class="payment-table">
      <thead>
        <tr>
          <th>Nazwa klienta</th>
          <th>Opis usługi</th>
          <th style="text-align: right">Brutto</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ paymentData?.customer.companyName }}
            {{ paymentData?.customer.firstName }}
            {{ paymentData?.customer.lastName }}
          </td>
          <td>{{ paymentData?.description }}</td>
          <td style="text-align: right">
            {{ paymentData?.amount | currency : " " | pointReplacer }} PLN
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="width < 767">
    <div class="payment-info-mobile">
      <div class="h-info">Nazwa klienta</div>
      <div class="desc-info">
        {{ paymentData?.customer.vatNumber }}
        {{ paymentData?.customer.firstName }}
        {{ paymentData?.customer.lastName }}
      </div>

      <div class="h-info">Opis usługi</div>
      <div class="desc-info">{{ paymentData?.description }}</div>

      <div class="h-info">Brutto</div>
      <div class="desc-info">
        {{ paymentData?.amount | currency : " " | pointReplacer }} PLN
      </div>
    </div>
  </ng-container>

  <div class="payment-summary">
    Do zapłaty
    <span
      [ngClass]="{
        'a-wapro':
          paymentSource.toLowerCase() == 'wapro' ||
          paymentSource.toLowerCase() == 'abs',
        'a-asseco':
          paymentSource.toLowerCase() == 'asseco' ||
          paymentSource.toLowerCase() == 'softlab',
        'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
      }"
      class="payment-value"
      >{{ paymentData?.amount | currency : " " | pointReplacer }}</span
    >
    PLN
  </div>

  <div class="options" *ngIf="!isPaymentTpay">
    <div
      class="return"
      (click)="goBack()"
      [style.visibility]="isReturn ? 'visible' : 'hidden'"
      [ngClass]="{
        'a-wapro':
          paymentSource.toLowerCase() == 'wapro' ||
          paymentSource.toLowerCase() == 'abs',
        'a-asseco':
          paymentSource.toLowerCase() == 'asseco' ||
          paymentSource.toLowerCase() == 'softlab',
        'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
      }"
    >
      <i class="icon absui-icon--is-less"></i>
      Wróć do aplikacji
    </div>

    <div class="operator">
      <div class="operator-redirect" *ngIf="width >= 767">
        Zostaniesz przeniesiony do okna płatności
        <strong *ngIf="paymentData?.operatorName == 'payu'">PayU</strong>
        <strong *ngIf="paymentData?.operatorName == 'p24'">Przelewy24</strong>
      </div>

      <button
        type="button"
        (click)="submitPayment()"
        [ngClass]="{
          'btn-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'btn-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'btn-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        type="confirm"
        class="payment-btn"
      >
        Zapłać teraz
      </button>
    </div>
  </div>

  <hr class="payment-hr" *ngIf="!isPaymentTpay" />

  <ng-container *ngIf="width < 767">
    <div class="operator-redirect" *ngIf="!isPaymentTpay">
      Zostaniesz przeniesiony do okna płatności
      <strong *ngIf="paymentData?.operatorName == 'payu'">PayU</strong>
      <strong *ngIf="paymentData?.operatorName == 'p24'">Przelewy24</strong>
    </div>

    <hr class="payment-hr" />
  </ng-container>

  <div class="extra-info" *ngIf="!isPaymentTpay">
    <i
      class="icon icon-info"
      style="
        font-size: 21px;
        width: 21px;
        height: 28px;
        position: absolute;
        top: 3px;
        left: 0;
      "
    ></i>
    <div>Informacje dodatkowe</div>
    <div class="payment-description">
      Jeżeli masz jakiekolwiek wątpliwości dotyczące faktury lub systemu
      płatności zadzwoń na numer Działu Sprzedaży Asseco WAPRO
      <strong>22 702 89 02</strong> lub wyślij numer na adres
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        href="mailto:sprzedaz.wapro@assecobs.pl"
        >sprzedaz.wapro&#64;assecobs.pl</a
      >
    </div>
    <div class="payment-description">
      Więcej informacji na witrynach
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        href="https://poland.payu.pl"
        *ngIf="paymentData?.operatorName == 'payu'"
        >PayU</a
      >
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        href="https://www.przelewy24.pl/"
        *ngIf="paymentData?.operatorName == 'p24'"
        >Przelewy24</a
      >
      oraz
      <a
        [ngClass]="{
          'a-wapro':
            paymentSource.toLowerCase() == 'wapro' ||
            paymentSource.toLowerCase() == 'abs',
          'a-asseco':
            paymentSource.toLowerCase() == 'asseco' ||
            paymentSource.toLowerCase() == 'softlab',
          'a-macrologic': paymentSource.toLowerCase() == 'macrologic'
        }"
        href="https://asseco.pl"
        >Asseco BS</a
      >
    </div>
  </div>
</div>

<app-tpay-bank
  [paymentData]="paymentData"
  [paymentSource]="paymentSource"
  (onSubmit)="submitPayment($event)"
  merchantID="10100"
  *ngIf="isPaymentTpay"
></app-tpay-bank>
