import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { AppServicesPayment } from '../app-services.service';
import { ActivatedRoute } from '@angular/router';
import { get } from 'scriptjs';
import { DetailsResponse } from '../dashboard/payments-responses';
import { EventService } from 'src/app/event.service';

declare const window: any;

@Component({
  selector: 'app-tpay-bank',
  templateUrl: './tpay-bank.component.html',
  styleUrls: ['./tpay-bank.component.scss'],
  inputs: ['paymentSource', 'merchantID', 'paymentData'],
})
export class TpayBankComponent implements OnInit {
  @Output() onSubmit = new EventEmitter<any>();

  paymentData: DetailsResponse;
  bankList = [];
  selectedBank = [];
  merchantID;
  paymentSource: string;

  agreeRegulations: boolean = false;

  constructor(
    public appService: AppServicesPayment,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private event: EventService
  ) {
    this.addJsToElement('https://secure.tpay.com/groups-775601.js').onload =
      () => {
        this.bankList = window.tr_groups;

        for (let i = this.bankList.length - 1; i >= 0; i--) {
          if (
            // this.bankList[i][1] == 'Karta płatnicza' ||
            this.bankList[i][1] == 'Google Pay' ||
            this.bankList[i][1] == 'Alior Raty' ||
            this.bankList[i][1] == 'Euro Payment' ||
            // this.bankList[i][1] == "Druczek płatności / Przelew z innego banku" ||
            this.bankList[i][1] == 'Visa Checkout' ||
            this.bankList[i][1] == 'PayPal' ||
            this.bankList[i][1] == 'Buy Now Pay Later' ||
            this.bankList[i][1] == 'Kup teraz, zapłać później' ||
            this.bankList[i][1] == 'Apple Pay'
          )
            this.bankList.splice(i, 1);
        }
      };
  }

  ngOnInit(): void {
    // Object.keys(this.bankListTest).forEach(field=>{
    //   this.bankList.push(this.bankListTest[field])
    // })
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  selectBank = (item) => {
    this.bankList.forEach((field) => (field.selected = false));
    item.selected = true;
    this.selectedBank = item;
  };

  submitPayment() {
    if (!this.selectedBank.length) {
      this.event.showNotification('error', `Wybierz bank`);
      return;
    } else if (!this.agreeRegulations) {
      this.event.showNotification(
        'error',
        `Musisz zaakceptować regulamin serwisu Tpay`
      );
      return;
    }
    this.onSubmit.emit(this.selectedBank[0]);
  }
}
