import { Component, OnInit } from '@angular/core';

import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

@Component({
  selector: 'app-register-license',
  templateUrl: './register-license.component.html',
  styleUrls: ['./register-license.component.scss']
})
export class RegisterLicenseComponent implements OnInit {
  licenseList = [];
  heightGrid = 0;
  customerVatNumber = '';
  licenseNumber = ''


  constructor(private appServices: AppServices, private event: EventService) { 
    this.event.showNavbar.next(true);
  }

  ngOnInit(): void {
  
  }

  onAdaptiveDetailRowPreparing = (event) => {
    if (window.innerWidth < 1100) {
      if(window.innerWidth < 576) this.heightGrid = this.licenseList.length *  80 + 20;
      else this.heightGrid = this.licenseList.length * 80 + 20;

      this.licenseList.forEach(el=>{
        if(event.component.isAdaptiveDetailRowExpanded(el)) this.heightGrid += 200;
      })

    }
  }

  getLicenseList = () => {
    this.appServices.getAuth(`customer/findCustomerLicense?customerVatNumber=${this.customerVatNumber}&licenseNumber=${this.licenseNumber}`).subscribe(response=>{
      if(response.myClientProducts !== null){
        this.licenseList = response.myClientProducts;
        this.heightGrid = this.licenseList.length * 80 + 20;
        let actualProducts = JSON.parse(this.event.decryptString(localStorage.getItem("product-list-wapro-erp")));
        this.licenseList.forEach(field => {
          field.productName = field.productName.replace('WAPRO', '');
          field.remarks = '';
          field.unicate = field.licenseId;
          field.toNumberOfUsers = field.numberOfUsers;
          if(field.agreementCode === null) field.agreementCode = '';
          for (let i = 0; i < actualProducts.length; i++) {
            if (actualProducts[i].productId === field.productId) {
              field.toPacketId = field.packetId;
              field.toPacketName = field.packetName;
              field.toVariantId = field.variantId;
              field.toVariantName = field.variantName;
              field.toVersionId = actualProducts[i].versions[0].versionId;
              field.toVersionNumber = actualProducts[i].versions[0].versionNumber;
            }
          }
        });
      }
      else this.event.showNotification('error', 'Nie odnaleziono licencji ')
    },
    error=>{
      this.event.showNotification('error', 'Błąd pobierania danych o licencji')
    })

  }

}
