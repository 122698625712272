import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/event.service';

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.scss']
})
export class PaymentFailComponent implements OnInit, OnDestroy {

  paymentSource = 'wapro'
  source$: Subscription
  
  constructor(private route: ActivatedRoute, private event: EventService) { 
    this.source$ = event.source.subscribe((source: string)=>{
      this.paymentSource = source || "wapro"
    })
  }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params: any): void => {
        let paramsObject = params?.params
        this.paymentSource = paramsObject?.source || "wapro"
      }
    );
  }

  ngOnDestroy(){
    if(this.source$) this.source$.unsubscribe()
  }
}
