import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  Validators,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { EventService } from '../event.service';
import { AppServices } from '../app-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-login-to-email',
  standalone: true,
  imports: [
    CommonModule,
    DxPopupModule,
    DxTextBoxModule,
    DxButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './change-login-to-email.component.html',
  styleUrl: './change-login-to-email.component.scss',
  inputs: ['isVisible', 'oldLogin'],
})
export class ChangeLoginToEmailComponent implements OnInit, OnDestroy {
  @Output() onClosing = new EventEmitter();
  isVisible: boolean = false;

  form;
  submitted: boolean = false;

  formError = {
    newLogin: '',
    newLoginRepeat: '',
  };
  oldLogin;

  validationMessages = {
    newLogin: {
      required: 'Email jest wymagany',
      email: 'Podany email jest nieprawidłowy',
      checkIfPasswordIdentical: 'Podane emaile nie są identyczne',
    },
    newLoginRepeat: {
      required: 'Email jest wymagany',
      email: 'Podany email jest nieprawidłowy',
      checkIfPasswordIdentical: 'Podane emaile nie są identyczne',
    },
  };

  constructor(
    public formBuilder: FormBuilder,
    public event: EventService,
    public appService: AppServices,
    private _route: Router
  ) {}

  ngOnInit() {
    this.createForm();

    this.form.valueChanges.subscribe((value) => {
      this.event.onControlValueChanged(
        this.form,
        this.formError,
        this.validationMessages
      );
    });
  }

  onVisibleChange(e) {
    if (!e) {
      this.onClosing.emit(true);
      this.logout();
    }
  }

  ngOnDestroy(): void {
    this.onClosing.emit(true);
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function (arg) {});
  }

  createForm() {
    this.form = this.formBuilder.group({
      login: [this.oldLogin, Validators.required],
      newLogin: ['', [Validators.required, Validators.email]],
      newLoginRepeat: [
        '',
        [Validators.required, Validators.email, checkPswIdentical('newLogin')],
      ],
    });
  }

  cancel() {
    this.isVisible = false;
  }

  logout() {
    this.appService.headers = this.appService.headers.delete('DataRowCount');
    this.appService.headers = this.appService.headers.delete('FilterColumn');
    this.appService.headers = this.appService.headers.delete('FilterColumn2');
    this.appService.headers = this.appService.headers.delete('FilterCriteria');
    this.appService.headers = this.appService.headers.delete('FilterCriteria2');
    localStorage.removeItem('basket-wapro-erp');

    this.event.baskedProduct.next([]);
    this.event.baskedShop.next(null);

    this.appService.deleteAuth('Authorization/Logout').subscribe(
      (response) => {
        localStorage.removeItem('wapro-erp-token');
        localStorage.removeItem('wapro-erp-login-user');
        localStorage.removeItem('user-info-wapro-erp');
        this._route.navigateByUrl('/');
        sessionStorage.clear();
        this.event.baskedProduct.next(null);
        this.event.showNavbar.next(false);
      },
      (error) => {
        this.event.showNotification('error', error);
      }
    );
  }

  onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.appService
      .postAuth(`authorization/ChangeLogin`, {
        login: this.form.value.login,
        newLogin: this.form.value.newLogin,
      })
      .subscribe(
        () => {
          this.isVisible = false;
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).description);
        }
      );
  }
}

export function checkPswIdentical(otherControlName: string) {
  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {
    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error(
          'matchOtherValidator(): other control is not found in parent group'
        );
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        checkIfPasswordIdentical: true,
      };
    }

    return null;
  };
}
