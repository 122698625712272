<div class="footer-img-container" style="margin-top: 120px">
  <div class="container">
    <img
      src="../../assets/img/graphics_footer_1.png"
      alt="wapro-erp"
      class="graphics_footer"
    />
  </div>
</div>

<footer class="page-footer font-small">
  <div class="container">
    <div class="row" style="margin-bottom: 40px">
      <div class="col-md-12 text-center">
        <h3>Kontakt</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="d-flex flex-row">
          <div style="padding-top: 5px">
            <i class="icon icon-absui-icon--location"></i>
          </div>
          <div>
            <h4>Asseco Business Solutions</h4>
            <p class="text">
              Oddział w Warszawie <br />
              ul. Adama Branickiego 13 <br />
              02-972 Warszawa
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="d-flex flex-row">
          <div style="padding-top: 5px">
            <i class="icon absui-icon--shopping-bag"></i>
          </div>
          <div>
            <h4>Dział sprzedaży</h4>
            <p class="text">
              <strong>22 702 89 02</strong><br />
              <a href="mailto:sprzedaz.wapro@assecobs.pl" class="a-mailto-href"
                >sprzedaz.wapro&#64;assecobs.pl</a
              ><br />
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="d-flex flex-row">
          <div style="padding-top: 5px">
            <i class="icon absui-icon--key"></i>
          </div>
          <div>
            <h4>Dział aktywacji</h4>
            <p class="text">
              <strong>22 702 89 07</strong><br />
              <a href="mailto:aktywacja.wapro@assecobs.pl" class="a-mailto-href"
                >aktywacja.wapro&#64;assecobs.pl</a
              ><br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="d-flex flex-row">
          <div style="padding-top: 5px">
            <i class="icon absui-icon--location-circle"></i>
          </div>
          <div>
            <h4>Centrala</h4>
            <p class="text">
              <strong>22 702 89 00</strong><br />
              <a href="mailto:info.wapro@assecobs.pl" class="a-mailto-href"
                >info.wapro&#64;assecobs.pl</a
              ><br />
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="d-flex flex-row">
          <div style="padding-top: 5px">
            <i class="icon absui-icon--help-outline"></i>
          </div>
          <div>
            <h4>Pomoc Techniczna / Serwis</h4>
            <p class="text">
              <strong>22 702 89 89</strong><br />
              <a href="mailto:serwis.wapro@assecobs.pl" class="a-mailto-href"
                >serwis.wapro&#64;assecobs.pl</a
              ><br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 60px; margin-left: 20px">
      <img
        src="../../assets//img/wapro erp by asseco.svg"
        alt="wapro erp"
        style="width: 181px"
      />
    </div>
  </div>

  <div class="copyright">
    <div class="container">
      <img
        src="../../assets/img/graphics_footer_3.png"
        alt="wapro-erp"
        class="graphics_footer_3"
      />
      <div class="row">
        <div class="col-md-6 col-sm-6 col-12"></div>
        <div
          class="col-md-6 col-sm-6 col-12 text-right"
          style="text-align: right; padding-right: 80px"
        >
          <img
            src="../../assets/img/abs_logo.svg"
            alt="wapro erp"
            class="logo_abs copyrigth-logo"
          />
          <p class="footer-copyright copyrigth-text">
            All rights reserved to Asseco Business Solutions S.A. © {{ year }}
          </p>

          <p class="footer-copyright copyrigth-text" style="margin-top: 30px">
            Sklep internetowy moje konto wapro wykorzystuje pliki cookies, które
            umożliwiają i&nbsp;ułatwiają Tobie korzystanie z&nbsp;jego zasobów.
            Korzystając z&nbsp;serwisu wyrażasz jednocześnie zgodę na
            wykorzystanie plików cookies.
            <a href="https://wapro.pl/polityka-prywatnosci/" target="_blank"
              >Zobacz szczegóły</a
            ><br /><br />
            <a
              href="http://wapro.pl/doc/Regulamin_sklep_assecobs_pl.pdf"
              target="_blank"
              s
              >Regulamin sklepu</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
