<dx-popup
  width="90%"
  height="95%"
  [showTitle]="true"
  title="Informacja"
  [dragEnabled]="false"
  hideOnOutsideClick="true"
  [showCloseButton]="true"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
>
  <div *dxTemplate="let data of 'content'">
    <iframe
      id="wapro"
      *ngIf="url"
      width="100%"
      style="border: none; height: calc(100vh - 155px)"
      [src]="url | safe"
    >
    </iframe>
  </div>
</dx-popup>
