<form [formGroup]="formContact">
<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-6">
                <h4 style="padding-bottom: 20px;">Dane kontaktowe</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-12 order-box">
                <div class="form-group">
                    <label for="vatNumber">Imię <strong>*</strong></label><br>
                    <dx-text-box formControlName="firstName" class="text-box" [ngClass]="{'input-required': submitted && formError.firstName }"></dx-text-box>
                    <small class="text-danger" *ngIf="submitted && formError.firstName" valueChangeEvent="keyup">
                        {{ formError.firstName}}
                    </small>
                </div>
                <div class="form-group">
                    <label for="vatNumber">Telefon komórkowy</label><br>
                    <dx-text-box formControlName="mobilePhone" class="text-box"></dx-text-box>
                </div>
                <div class="form-group">
                    <label for="vatNumber">Email <strong>*</strong></label><br>
                    <dx-text-box formControlName="email" class="text-box" mode="email" [ngClass]="{'input-required': submitted && formError.email }"></dx-text-box>
                    <small class="text-danger" *ngIf="submitted && formError.email" valueChangeEvent="keyup">
                        {{ formError.email}}
                    </small>
                </div>
            </div>

            <div class="col-md-6 col-12x">
                <div class="form-group">
                    <label for="vatNumber">Nazwisko <strong>*</strong></label><br>
                    <dx-text-box formControlName="lastName" class="text-box" [ngClass]="{'input-required': submitted && formError.lastName }"></dx-text-box>
                    <small class="text-danger" *ngIf="submitted && formError.lastName" valueChangeEvent="keyup">
                        {{ formError.lastName}}
                    </small>
                </div>
                <div class="form-group">
                    <label for="vatNumber">Telefon</label><br>
                    <dx-text-box formControlName="phone" class="text-box"></dx-text-box>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" style="margin-top: 0px; padding-top: 20px; margin-bottom: 30px; border-top: 1px solid rgba(218, 222, 225, 0.5);">
                <div class="panel-re-captcha">
                    <re-captcha formControlName="recaptchaReactive" (resolved)="resolved($event)"></re-captcha>
                </div>            
            </div> 
            <div class="col-md-12 text-center">
                <dx-button class="btn-danger" text="Zapisz zmiany" width="179" height="40" style="margin-right:5px;"
                    (onClick)="onSubmit(formContact.value)" [disabled]="formContact.controls.recaptchaReactive.invalid">
                </dx-button>
            </div>
        </div>
    </div>
</div>
</form>