import { Component, OnDestroy, OnInit, HostListener} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-page-not-faound',
  templateUrl: './page-not-faound.component.html',
  styleUrls: ['./page-not-faound.component.scss']
})
export class PageNotFaoundComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
     if (window.innerWidth > 1024) {
      document.body.style.background = "url('./assets/img/graphics_5.png') no-repeat 70px 0px, url('./assets/img/graphics_6.png') no-repeat 1200px 363px";
     }
     else document.body.style.background = "none";
  }

  constructor(private appServices: AppServices, private event: EventService, private _route: Router) {     
    (localStorage.getItem('wapro-erp-token'))?this.event.showNavbar.next(true):this.event.showNavbar.next(false);
  }

  ngOnInit() {
    if (window.innerWidth > 1024) {
        document.body.style.background = "url('./assets/img/graphics_5.png') no-repeat 70px 0px, url('./assets/img/graphics_6.png') no-repeat 1200px 363px";
    }
    else document.body.style.background = "none";
  }

  ngOnDestroy() {
    document.body.style.background = "none";
  }

  history(){
    history.back();
  }
}
