 <div class="row">
    <div class="col-md-12" style="padding-bottom: 59px;">
        <!-- <h2 class="h2-logo" style="display: inline-block; float:right">wapro <strong>erp</strong></h2> -->
    </div>
</div>


<div class="row" style="margin-bottom: 300px;">
    <div class="col-md-12 text-center" >
        
        <h1>Dziękujemy!</h1>
        <h3>Twoje konto zostało utworzone</h3>
        <p class="p-welcome">Zaloguj sie aby swobodnie zarządzać usługami</p>

        <dx-button width="155" height="48" class="btn-danger btn-log-wapro-erp" text="Zaloguj" (click)="navigatoToLog()"></dx-button>

    </div>
</div>
