import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: 'pointReplacer'
})

export class PointReplacerPipe implements PipeTransform {
    transform(value: string, args: any[]): string {
        if (value) {
            while(value.indexOf(",") > -1){
                value = value.replace(',', ' ');
            }
            while(value.indexOf(".") > -1){
                value = value.replace('.', ',');
            }
            return value
        }
        return '';
    }
}

@Pipe({ name: 'safe' })

export class SafePipe implements PipeTransform {

constructor(private sanitizer: DomSanitizer) { }
transform(url) {
 return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
