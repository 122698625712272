import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/event.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  source$: Subscription
  paymentSource = 'wapro'
  constructor(private route: ActivatedRoute, private event: EventService) { 
    this.source$ = event.source.subscribe((source: string)=>{
      this.paymentSource = source || "wapro"
    })
  }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params: any): void => {
        let paramsObject = params?.params
        this.paymentSource = paramsObject?.source || "wapro"
      }
    );
  }

  ngOnDestroy(){
    if(this.source$) this.source$.unsubscribe()
  }
}
