<div class="row">
  <div class="col-md-12">
    <a style="cursor: pointer" (click)="backToBasket()"
      ><i class="icon absui-icon--arrow-back"></i>
    </a>
    <h5>Zamówienie</h5>
  </div>
</div>

<form [formGroup]="userOrderForm">
  <div class="row">
    <div class="col-md-12">
      <dx-radio-group
        [items]="radioButton"
        [(value)]="radioButtonValue"
        valueExpr="value"
        displayExpr="display"
        style="height: 60px; margin-bottom: 30px"
        (onValueChanged)="onValueChanged($event)"
      >
        <div *dxTemplate="let priority of 'item'">
          <div [id]="priority.idp">{{ priority.display }}</div>

          <dx-tooltip
            [target]="'#' + priority.idp"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="{ name: 'mouseover' }"
          >
            <div *dxTemplate="let data of 'content'">
              <p [innerHtml]="priority.tooltip" style="text-align: left"></p>
            </div>
          </dx-tooltip>
        </div>
      </dx-radio-group>

      <dx-accordion
        #accordion
        [dataSource]="groupList"
        [collapsible]="true"
        [multiple]="true"
        [animationDuration]="300"
        [selectedItems]="selectedIndex"
      >
        <div *dxTemplate="let item of 'title'">
          <div class="row" style="margin-right: -0px; margin-left: 0px">
            <div class="col-6">
              <h4 style="padding-bottom: 20px">{{ item.title }}</h4>
            </div>
            <div
              class="col-md-6 col-12 text-right"
              *ngIf="item.title == 'Dane do wysyłki'"
            >
              <p class="p-order-wapro-erp">Uzupełnij danymi z faktur</p>
              <dx-button
                id="completeDataFromInvoice"
                class="icon-border-order"
                (onClick)="completeDataFromInvoice(); stop($event)"
              >
                <i
                  class="icon absui-icon--arrow-downward"
                  aria-hidden="true"
                ></i>
              </dx-button>
            </div>
            <div
              class="col-md-6 col-12 text-right"
              *ngIf="item.title == 'Dane do faktury'"
            >
              <div style="width: 190px; float: right">
                <dx-check-box
                  [(value)]="invoiceAddress"
                  id="changeInvoiceDetails"
                  text="Zmień dane do faktury"
                  style="margin-top: 30px"
                  *ngIf="item.title == 'Dane do faktury'"
                  (onValueChanged)="stop($event)"
                >
                </dx-check-box>
              </div>
            </div>
          </div>
        </div>

        <div *dxTemplate="let item of 'item'">
          <div
            formGroupName="receiverAddress"
            *ngIf="item.title == 'Dane do wysyłki'"
          >
            <div class="row">
              <div class="col-md-6 col-sm-12 order-box">
                <div class="form-group">
                  <label for="vatNumber">NIP</label><br />
                  <dx-text-box
                    formControlName="vatNumber"
                    id="vatNumberReceiverAddress"
                    [showClearButton]="true"
                    class="input-gus"
                    style="box-shadow: none"
                    placeholder=""
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls
                          .vatNumber.valid
                    }"
                  >
                  </dx-text-box>
                  <dx-button
                    class="btn-danger gus-btn"
                    text="Pobierz z GUS"
                    id="getDateFromGus"
                    style="margin-top: -10px; height: 40px"
                    [elementAttr]="{ tabindex: '-1' }"
                    [height]="40"
                    (onClick)="
                      getDateFromGus(
                        userOrderForm.get('receiverAddress').get('vatNumber')
                          .value,
                        'receiverAddress'
                      )
                    "
                  >
                  </dx-button>
                  <small
                    class="text-danger"
                    style="left: 0px"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls.vatNumber
                        .valid
                    "
                  >
                    Wprowadź numer NIP
                  </small>
                </div>

                <div class="form-group">
                  <label for="company">Nazwa firmy <strong>*</strong></label>
                  <dx-text-area
                    [height]="65"
                    formControlName="company"
                    class="text-box"
                    id="companyReceiverAddress"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls.company
                          .validsubmitted &&
                        !userOrderForm.controls.receiverAddress.controls.company
                          .valid
                    }"
                  >
                  </dx-text-area>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls.company
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>

                <div class="form-group">
                  <label for="name">Nazwisko </label>
                  <dx-text-box
                    formControlName="name"
                    [showClearButton]="true"
                    id="nameReceiverAddress"
                    class="text-box"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls.name
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls.name
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>

                <div class="form-group">
                  <label for="street">Ulica <strong>*</strong></label>
                  <dx-text-box
                    formControlName="street"
                    id="streetReceiverAddress"
                    [showClearButton]="true"
                    class="text-box"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls.street
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls.street
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>
              </div>

              <div class="col-md-6 col-sm-12" style="padding-left: 0px">
                <div class="form-group">
                  <label for="city">Miejscowość <strong>*</strong></label>
                  <dx-text-box
                    formControlName="city"
                    id="cityReceiverAddress"
                    [showClearButton]="true"
                    class="text-box"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls.city
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls.city
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>
                <div class="form-group">
                  <label for="postalCode"
                    >Kod pocztowy <strong>*</strong></label
                  >
                  <dx-text-box
                    placeholder=""
                    id="postalCodeReceiverAddress"
                    formControlName="postalCode"
                    [showClearButton]="true"
                    style="box-shadow: none"
                    height="40"
                    style="width: 163px"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls
                          .postalCode.valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    style="position: absolute; left: 0px"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls
                        .postalCode.valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>
                <div class="form-group" style="margin-top: 40px">
                  <label for="email">Email</label>
                  <dx-text-box
                    formControlName="email"
                    id="emailCodeReceiverAddress"
                    class="text-box"
                    [showClearButton]="true"
                    height="40"
                    mode="email"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls.email
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls.email
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>
                <div class="form-group">
                  <label for="phone">Telefon <strong>*</strong></label>
                  <dx-text-box
                    formControlName="phone"
                    id="phoneCodeReceiverAddress"
                    class="text-box"
                    [showClearButton]="true"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.receiverAddress.controls.phone
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.receiverAddress.controls.phone
                        .valid
                    "
                  >
                    <p>Podaj poprawny numer telefonu</p>
                  </small>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-12" style="height: 20px"
                        >

                    </div> -->

          <div
            formGroupName="invoiceAddress"
            *ngIf="item.title == 'Dane do faktury'"
          >
            <div class="row">
              <div class="col-md-6 col-sm-12 order-box">
                <div class="form-group">
                  <label for="vatNumber">NIP <strong>*</strong></label
                  ><br />
                  <dx-text-box
                    id="vatNumberInvoiceAddress"
                    formControlName="vatNumber"
                    [disabled]="!invoiceAddress"
                    style="box-shadow: none"
                    [showClearButton]="true"
                    class="input-gus"
                    placeholder=""
                    height="40"
                    [maxLength]="15"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.invoiceAddress.controls
                          .vatNumber.valid
                    }"
                  >
                  </dx-text-box>
                  <dx-button
                    id="getDateFromGusInvoiceAddress"
                    class="btn-confirm"
                    text="Pobierz z GUS"
                    class="btn-danger gus-btn"
                    [height]="40"
                    style="margin-top: -10px; height: 40px"
                    (onClick)="
                      getDateFromGus(
                        userOrderForm.get('invoiceAddress').get('vatNumber')
                          .value,
                        'invoiceAddress'
                      )
                    "
                    [disabled]="!invoiceAddress"
                  ></dx-button>
                </div>
                <small
                  class="text-danger"
                  *ngIf="
                    submitted &&
                    !userOrderForm.controls.invoiceAddress.controls.vatNumber
                      .valid
                  "
                >
                  <p>Błędne dane</p>
                </small>

                <div class="form-group">
                  <label for="vatNumber">Nazwa firmy <strong>*</strong></label>
                  <dx-text-area
                    [height]="65"
                    formControlName="company"
                    class="text-box"
                    id="companyNumberInvoiceAddress"
                    [disabled]="!invoiceAddress"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.invoiceAddress.controls.company
                          .valid
                    }"
                  >
                  </dx-text-area>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.invoiceAddress.controls.company
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>

                <div class="form-group">
                  <label for="street">Ulica <strong>*</strong></label>
                  <dx-text-box
                    formControlName="street"
                    id="streetNumberInvoiceAddress"
                    class="text-box"
                    [disabled]="!invoiceAddress"
                    [showClearButton]="true"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.invoiceAddress.controls.street
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.invoiceAddress.controls.street
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>
              </div>
              <div class="col-md-6 col-sm-12" style="padding-left: 0px">
                <div class="form-group">
                  <label for="city">Miejscowość <strong>*</strong></label>
                  <dx-text-box
                    formControlName="city"
                    class="text-box"
                    [disabled]="!invoiceAddress"
                    [showClearButton]="true"
                    height="40"
                    id="cityNumberInvoiceAddress"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.invoiceAddress.controls.city
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.invoiceAddress.controls.city.valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>
                <div class="form-group">
                  <label for="postalCode"
                    >Kod pocztowy <strong>*</strong></label
                  >
                  <dx-text-box
                    placeholder=""
                    [width]="165"
                    id="postalCodeNumberInvoiceAddress"
                    formControlName="postalCode"
                    style="box-shadow: none"
                    [disabled]="!invoiceAddress"
                    [showClearButton]="true"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.invoiceAddress.controls
                          .postalCode.valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    style="position: absolute; left: 0px"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.invoiceAddress.controls.postalCode
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>

                <div class="form-group" style="margin-top: 40px">
                  <label for="email"
                    >Email
                    <strong
                      *ngIf="
                        radioButtonValue ==
                        'Nie chcę przesyłki kurierskiej z nośnikiem programu i papierowym certyfikatem'
                      "
                      >*</strong
                    ></label
                  >
                  <dx-text-box
                    formControlName="email"
                    id="emailCodeNumberInvoiceAddress"
                    class="text-box"
                    [disabled]="!invoiceAddress"
                    [showClearButton]="true"
                    mode="email"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.invoiceAddress.controls.email
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.invoiceAddress.controls.email
                        .valid
                    "
                  >
                    <p>Błędne dane</p>
                  </small>
                </div>

                <div class="form-group">
                  <label for="phone">Telefon</label>
                  <dx-text-box
                    id="phoneCodeNumberInvoiceAddress"
                    formControlName="phone"
                    class="text-box"
                    [disabled]="!invoiceAddress"
                    [showClearButton]="true"
                    height="40"
                    [ngClass]="{
                      'input-required':
                        submitted &&
                        !userOrderForm.controls.invoiceAddress.controls.phone
                          .valid
                    }"
                  >
                  </dx-text-box>
                  <small
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !userOrderForm.controls.invoiceAddress.controls.phone
                        .valid
                    "
                  >
                    <p>Podaj poprawny numer telefonu</p>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dx-accordion>
      <!-- <div class="about-license-wapro-erp">
                <div class="row">
                    <div class="col-6">
                        <h4 style="padding-bottom: 20px;">Dane do wysyłki</h4>
                    </div>
                    <div class="col-md-6 col-12 text-right">
                        <p class="p-order-wapro-erp">Uzupełnij danymi z faktur</p>
                        <dx-button class="icon-border-order" (onClick)="completeDataFromInvoice()">
                            <i class="icon absui-icon--arrow-downward" aria-hidden="true"></i>
                        </dx-button>
                    </div>
                </div>



            </div> -->
    </div>
  </div>

  <!-- <div class="row">

    </div> -->

  <div class="row" style="margin-top: 35px">
    <div class="col-md-6">
      <div
        *ngIf="UserRole !== 'partner' && UserRole !== 'subpartner'"
        style="margin-bottom: 60px"
      >
        <div style="height: 22px; margin-bottom: 20px">
          <dx-check-box
            [(value)]="realizatonOnPartner"
            text="Realizacja przez partnera"
            style="margin-top: 20px; margin-bottom: 20px"
            id="realizatonOnPartner"
            (onValueChanged)="realizatonOnPartnerChanged($event)"
          >
          </dx-check-box>

          <dx-tooltip
            target="#realizatonOnPartner"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="{ name: 'mouseover', delay: 500 }"
          >
            <div
              *dxTemplate="let data = data; of: 'content'"
              class="tooltipContent"
            >
              Wybierając opcję realizacji przez Partnera, Twoje zamówienie
              skierowane jest właśnie do tej firmy. Czas realizacji zależny
              zatem jest od czasu obsługi przez firmę Partnerską Wapro ERP.
            </div>
          </dx-tooltip>
        </div>

        <dx-select-box
          [dataSource]="partnerList"
          displayExpr="partnerName"
          valueExpr="partnerId"
          [ngModelOptions]="{ standalone: true }"
          placeholder=""
          id="partnerName"
          [(ngModel)]="partner"
          height="40"
          (onSelectionChanged)="onSelectionChanged($event)"
          [searchEnabled]="true"
          [disabled]="!realizatonOnPartner"
          class="text-box"
          noDataText="Brak partnerów"
        ></dx-select-box>

        <img
          src="../../assets/img/icon_alert_black.svg"
          alt="wapro erp"
          *ngIf="realizatonOnPartner"
          style="margin-top: -25px"
        />
        <p class="p-info-wapro-erp" *ngIf="realizatonOnPartner">
          Dział sprzedaży skontaktuje się z Państwem w celu<br />
          realizacji procedury zmiany Partnera
        </p>
      </div>

      <div
        class="form-group"
        [ngClass]="{ 'client-remarks': UserRole == 'client' }"
      >
        <label for="vatNumber">Uwagi do zamówienia</label>
        <dx-text-area
          [height]="80"
          id="remarks"
          formControlName="remarks"
          class="remarks-input-wapro-erp"
          style="box-shadow: none"
        ></dx-text-area>
      </div>
    </div>

    <div class="col-md-6 text-right">
      <p class="p-price-wapro-erp">
        Do zapłaty
        <strong>{{
          countToPrices.toFixed(2) | currency : " " | pointReplacer
        }}</strong>
        PLN
      </p>
      <dx-button
        id="sendOrder"
        class="btn-danger btn-order"
        text="Złóż zamówienie"
        width="196"
        height="40"
        style="margin-top: 29px"
        (onClick)="sendOrder(userOrderForm.value)"
        [disabled]="blockBtn"
      ></dx-button>
    </div>
  </div>
</form>

<!-- <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#body' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  indicatorSrc="..\\..\\assets\\img\\preloader.svg"
  [height]="100"
>
</dx-load-panel> -->

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#body' }"
  [(visible)]="loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="'Ładowanie danych...'"
    ></wapro-loader>
  </div>
</dx-load-panel>
