<div class="container" style="margin-top: -20px">
  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div formGroupName="credentialsData" class="about-license-wapro-erp">
          <h4 style="padding-bottom: 20px">Dane logowania</h4>
          <div class="form-group order-box">
            <label for="login">Email <strong>*</strong></label>
            <dx-text-box
              name="login"
              placeholder=""
              id="login"
              formControlName="login"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              mode="email"
              [ngClass]="{
                'input-required':
                  registerForm.controls.credentialsData.controls.login
                    .touched &&
                  !registerForm.controls.credentialsData.controls.login.valid
              }"
            >
            </dx-text-box>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.credentialsData.controls.login
                    .touched &&
                  !registerForm.controls.credentialsData.controls.login.valid
                "
              >
                Podaj poprawny adres email
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <label for="password">Hasło <strong>*</strong></label>
            <dx-text-box
              name="password"
              placeholder=""
              id="password"
              formControlName="password"
              class="text-box"
              [inputAttr]="{ autocomplete: 'off' }"
              [mode]="modePsw"
              style="padding-right: 70px"
              [ngClass]="{
                'input-required':
                  registerForm.controls.credentialsData.controls.password
                    .touched &&
                  !registerForm.controls.credentialsData.controls.password.valid
              }"
            >
            </dx-text-box>
            <i
              class="o-button--inInput"
              (click)="
                modePsw == 'password'
                  ? (modePsw = 'text')
                  : (modePsw = 'password')
              "
            >
              <i class="icon icon-eye" [hidden]="modePsw != 'password'"></i>
              <i
                class="icon icon-eye-slash"
                [hidden]="modePsw == 'password'"
              ></i>
            </i>
            <i
              class="dx-icon dx-icon-clear clear-psw"
              (click)="clearValue('password')"
              *ngIf="
                registerForm.controls.credentialsData.get('password').value
                  .length > 0
              "
            ></i>
            <div
              style="margin-left: 13px; margin-top: -20px; padding-bottom: 20px"
              class="text-error-mobile"
            >
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.credentialsData.controls.password
                    .touched &&
                  !registerForm.controls.credentialsData.controls.password.valid
                "
              >
                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i
                znak specjalny
              </small>
            </div>
          </div>

          <div
            style="margin-top: -22px"
            class="form-group order-box mpbile-div"
          >
            <label for="repeatNewPsw">Powtórz hasło <strong>*</strong></label>
            <dx-text-box
              name="repeatNewPsw"
              placeholder=""
              id="repeatNewPsw"
              class="text-box"
              style="padding-right: 70px"
              formControlName="repeatNewPsw"
              [inputAttr]="{ autocomplete: 'off' }"
              [mode]="modePswRepeat"
              [ngClass]="{
                'input-required':
                  registerForm.controls.credentialsData.controls.repeatNewPsw
                    .touched &&
                  !registerForm.controls.credentialsData.controls.repeatNewPsw
                    .valid
              }"
            >
            </dx-text-box>
            <i
              class="o-button--inInput"
              (click)="
                modePswRepeat == 'password'
                  ? (modePswRepeat = 'text')
                  : (modePswRepeat = 'password')
              "
            >
              <i
                class="icon icon-eye"
                [hidden]="modePswRepeat != 'password'"
              ></i>
              <i
                class="icon icon-eye-slash"
                [hidden]="modePswRepeat == 'password'"
              ></i>
            </i>
            <i
              class="dx-icon dx-icon-clear clear-psw"
              (click)="clearValue('repeatNewPsw')"
              *ngIf="
                registerForm.controls.credentialsData.get('repeatNewPsw').value
                  .length > 0
              "
            ></i>
            <div
              style="margin-left: 13px; margin-top: -20px; padding-bottom: 20px"
              class="text-error-mobile text-error-mobile"
            >
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.credentialsData.controls.repeatNewPsw
                    .touched &&
                  !registerForm.controls.credentialsData.controls.repeatNewPsw
                    .valid
                "
              >
                Hasło musi mieć minimum 8 znaków, małą i dużą literę, cyfrę i
                znak specjalny
              </small>
            </div>
          </div>
        </div>

        <div class="about-license-wapro-erp" style="padding-bottom: -100px">
          <h4 style="padding-bottom: 20px">Dane osobowe</h4>
          <div class="form-group order-box">
            <label for="firstName">Imię <strong>*</strong></label>
            <dx-text-box
              name="firstName"
              placeholder=""
              id="firstName"
              formControlName="firstName"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              [ngClass]="{
                'input-required':
                  registerForm.controls.firstName.touched &&
                  !registerForm.controls.firstName.valid
              }"
            >
            </dx-text-box>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.firstName.touched &&
                  !registerForm.controls.firstName.valid
                "
              >
                Imię jest wymagane
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <label for="lastName">Nazwisko <strong>*</strong></label>
            <dx-text-box
              name="lastName"
              placeholder=""
              id="lastName"
              formControlName="lastName"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              [ngClass]="{
                'input-required':
                  registerForm.controls.lastName.touched &&
                  !registerForm.controls.lastName.valid
              }"
            >
            </dx-text-box>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.lastName.touched &&
                  !registerForm.controls.lastName.valid
                "
              >
                Nazwisko jest wymagane
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <label for="phone">Telefon komórkowy <strong>*</strong></label>
            <dx-text-box
              name="phone"
              placeholder=""
              id="phone"
              formControlName="phone"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              [ngClass]="{
                'input-required':
                  registerForm.controls.phone.touched &&
                  !registerForm.controls.phone.valid
              }"
            >
            </dx-text-box>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.phone.touched &&
                  !registerForm.controls.phone.valid
                "
              >
                Telefon jest wymagane. Dozwolone są liczby, spacja i znak +
              </small>
            </div>
          </div>

          <div class="form-group order-box" style="margin-top: 15px">
            <dx-check-box
              formControlName="marketingAgreement"
              class="text-box"
              text="Udzielam zgody na przesyłanie mi informacji handlowych drogą elektroniczną na podany adres e-mail"
            >
            </dx-check-box>

            <div style="position: relative; top: 10px">
              <dx-check-box
                text=" "
                class="text-box"
                style="display: inline-block"
                formControlName="regulaminAgreement"
              ></dx-check-box>
              <a
                href="https://wapro.pl/doc/Regulamin_sklep_assecobs_pl.pdf"
                target="_blank"
                style="
                  display: inline-block;
                  color: #30393f;
                  position: relative;
                  top: -48px;
                  left: 20px;
                  font-weight: normal;
                  font-family: Inter;
                "
              >
                <strong style="color: #dc1e28; font-size: 12px">* </strong
                >Zapoznałem się i w pełni akceptuję Regulamin sklepu</a
              >
            </div>

            <div style="position: relative; top: -30px">
              <dx-check-box
                text=" "
                class="text-box"
                style="display: inline-block"
                formControlName="przetwarzanieAgreement"
              ></dx-check-box>
              <a
                href="https://wapro.pl/doc/klauzula_informacyjna.pdf"
                target="_blank"
                style="
                  display: inline-block;
                  color: #30393f;
                  position: relative;
                  top: -48px;
                  left: 20px;
                  font-weight: normal;
                  font-family: Inter;
                "
              >
                <strong style="color: #dc1e28; font-size: 12px">* </strong
                >Oświadczam, że zapoznałam/-em się z informacjami o zasadach i
                celach przetwarzania moich danych osobowych</a
              >
            </div>

            <dx-button
              class="btn-default btn-security"
              text="Polityka prywatności"
              height="48"
              (onClick)="securityPolicy()"
              style="margin-right: 20px; margin-top: -50px"
            >
            </dx-button>

            <!-- <a href="https://wapro.pl/klauzula-rodo/" class="btn btn-danger" target="_blank">Polityka
                            prywatności</a> -->
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div formGroupName="invoiceAddress" class="about-license-wapro-erp">
          <h4 style="padding-bottom: 20px">Dane do faktury</h4>

          <div class="form-group order-box">
            <div>
              <label for="vatNumber">NIP <strong>*</strong></label
              ><br />
              <dx-text-box
                formControlName="vatNumber"
                class="input-gus"
                style="box-shadow: none"
                placeholder=""
                (onBlur)="onBlur()"
                [ngClass]="{
                  'input-required':
                    registerForm.controls.invoiceAddress.controls.vatNumber
                      .touched &&
                    !registerForm.controls.invoiceAddress.controls.vatNumber
                      .valid
                }"
              >
              </dx-text-box>
              <dx-button
                class="btn-danger gus-btn"
                text="Pobierz z GUS"
                height="45"
                style="
                  margin-top: -10px;
                  height: 45px;
                  position: relative;
                  z-index: 1000;
                  line-height: 1.8 !important;
                "
                [elementAttr]="{ tabindex: '-1' }"
                (onClick)="
                  getDateFromGus(
                    registerForm.get('invoiceAddress').get('vatNumber').value,
                    'invoiceAddress'
                  )
                "
              >
              </dx-button>
            </div>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.invoiceAddress.controls.vatNumber
                    .touched &&
                  !registerForm.controls.invoiceAddress.controls.vatNumber.valid
                "
              >
                Błędne dane
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <label for="company">Nazwa firmy <strong>*</strong></label>
            <dx-text-area
              name="company"
              placeholder=""
              id="company"
              formControlName="company"
              class="text-box"
              [height]="65"
              [inputAttr]="{ autocomplete: 'off' }"
              [ngClass]="{
                'input-required':
                  registerForm.controls.invoiceAddress.controls.company
                    .touched &&
                  !registerForm.controls.invoiceAddress.controls.company.valid
              }"
            >
            </dx-text-area>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.invoiceAddress.controls.company
                    .touched &&
                  !registerForm.controls.invoiceAddress.controls.company.valid
                "
              >
                Nazwa jest wymagana
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <label for="street">Ulica <strong>*</strong></label>
            <dx-text-box
              name="street"
              placeholder=""
              id="street"
              formControlName="street"
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              [ngClass]="{
                'input-required':
                  registerForm.controls.invoiceAddress.controls.street
                    .touched &&
                  !registerForm.controls.invoiceAddress.controls.street.valid
              }"
            >
            </dx-text-box>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.invoiceAddress.controls.street
                    .touched &&
                  !registerForm.controls.invoiceAddress.controls.street.valid
                "
              >
                Ulica jest wymagana
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <label for="city">Miejscowość <strong>*</strong></label>
            <dx-text-box
              name="city"
              placeholder=""
              id="city"
              formControlName="city"
              v
              class="text-box"
              [showClearButton]="true"
              [inputAttr]="{ autocomplete: 'off' }"
              [ngClass]="{
                'input-required':
                  registerForm.controls.invoiceAddress.controls.city.touched &&
                  !registerForm.controls.invoiceAddress.controls.city.valid
              }"
            >
            </dx-text-box>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.invoiceAddress.controls.city.touched &&
                  !registerForm.controls.invoiceAddress.controls.city.valid
                "
              >
                Miejscowość jest wymagana
              </small>
            </div>
          </div>

          <div class="form-group order-box">
            <label for="postalCode">Kod pocztowy <strong>*</strong></label>
            <dx-text-box
              placeholder=""
              formControlName="postalCode"
              [showClearButton]="true"
              class="text-box"
              [ngClass]="{
                'input-required':
                  registerForm.controls.invoiceAddress.controls.postalCode
                    .touched &&
                  !registerForm.controls.invoiceAddress.controls.postalCode
                    .valid
              }"
            >
            </dx-text-box>
            <div style="margin-left: 13px">
              <small
                class="text-danger"
                *ngIf="
                  registerForm.controls.invoiceAddress.controls.postalCode
                    .touched &&
                  !registerForm.controls.invoiceAddress.controls.postalCode
                    .valid
                "
              >
                Kod pocztowy musi być numeryczny i mieć format XX-XXX
              </small>
            </div>
          </div>
        </div>

        <br />
        <re-captcha
          formControlName="recaptchaReactive"
          (resolved)="resolved($event)"
          style="float: right; margin-bottom: 20px"
        ></re-captcha>
        <br /><br />

        <div class="text-right" style="margin-top: 62px">
          <dx-button
            class="btn-danger"
            text="Załóż konto"
            width="161"
            height="48"
            (onClick)="onSubmit(registerForm.value)"
            [disabled]="registerForm.controls.recaptchaReactive.invalid"
          >
          </dx-button>
        </div>
      </div>
      <br /><br /><br />
    </div>
  </form>
</div>
