import { Component, OnInit, OnDestroy } from '@angular/core';

import { RegisterComponent } from '../register/register.component';
import { NewUserComponent } from '../new-user/new-user.component';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { FormBuilder, Validators } from '@angular/forms';

import { checkPswIdentical } from '../my-data/change-psw/validators';

import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-panel',
  templateUrl: './registration-panel.component.html',
  styleUrls: ['./registration-panel.component.scss']
})
export class RegistrationPanelComponent implements OnInit, OnDestroy {

  component: any = [
    { nameMenu: 'Nowa firma', component: RegisterComponent },
    { nameMenu: 'Zarejestrowana firma', component: NewUserComponent },
  ];
  

  constructor(private event: EventService, public formBuilder: FormBuilder, private appService: AppServices, public _route: Router) {
    this.event.logOnNavbar.next(false);
    this.event.welcomeMessage.next('Powrót do ekranu logowania');
    this.event.hideFooter.next(false);
  }

  ngOnInit(): void {
      setTimeout(() => {
        if(window.innerWidth < 750){
          let draw = document.getElementsByClassName('dx-tabpanel-container') as HTMLCollectionOf<HTMLElement>
          draw[0].style.height = "2000px";
        }
        else if(window.innerWidth < 800){
          let draw = document.getElementsByClassName('dx-tabpanel-container') as HTMLCollectionOf<HTMLElement>
          draw[0].style.height = "1000px";
        }
      }, 0);
      
  }

  ngOnDestroy(){
    document.body.style.background = "none";
    this.event.welcomeMessage.next('');
    this.event.logOnNavbar.next(true);
  }

  onSelectionChanged = (e) =>{
    if(e.addedItems[0].nameMenu === 'Nowa firma' && window.innerWidth < 750){
       let draw = document.getElementsByClassName('dx-tabpanel-container') as HTMLCollectionOf<HTMLElement>
       draw[0].style.height = "2000px";
    }
    else if(e.addedItems[0].nameMenu === 'Zarejestrowana firma' && window.innerWidth < 750){
      let draw = document.getElementsByClassName('dx-tabpanel-container') as HTMLCollectionOf<HTMLElement>
       draw[0].style.height = "1450px";
    } 
    
    else if(e.addedItems[0].nameMenu === 'Nowa firma' && window.innerWidth < 800){
      let draw = document.getElementsByClassName('dx-tabpanel-container') as HTMLCollectionOf<HTMLElement>
      draw[0].style.height = "1000px";
   }
   else if(e.addedItems[0].nameMenu === 'Zarejestrowana firma' && window.innerWidth < 800){
     let draw = document.getElementsByClassName('dx-tabpanel-container') as HTMLCollectionOf<HTMLElement>
      draw[0].style.height = "1150px";
   } 
  }
}
