import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppServices } from './app-services.service';
import { Subject, BehaviorSubject } from 'rxjs';

import * as CryptoJS from 'crypto-js';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';

const salt = '%CxR$%j$i9^2:9_0*2Q!230xs.+';
const md5 = new Md5();

@Injectable({
  providedIn: 'root',
})
export class EventService {
  source: Subject<string> = new Subject<string>();
  isTpayOperator: Subject<boolean> = new Subject<boolean>();
  dataActivateCode: Subject<any> = new Subject<any>();
  baskedShop = new Subject<any>();
  baskedProduct = new BehaviorSubject<Array<any>>(null);

  notifyMsg = new Subject<any>();
  deleteFromBasket = new Subject<any>();
  showNavbar = new Subject<boolean>();
  logOnNavbar = new Subject<boolean>(); //ukrywa menu w logowaniu
  logOnRegistration = new Subject<boolean>(); //ukrywa menu w oknie rejestracji
  nameCustomer = new Subject<string>(); //przekazuje do komponentu nazwe klienta
  welcomeMessage = new Subject<string>();
  hideRegisterButton = new Subject<boolean>();
  deleteAllProductLicense = new Subject<number>();
  hideFooter = new Subject<boolean>();
  informationAboutOrder = new Subject<any>(); //wysyłan informacje o zamówieniu numer i kwote do zaplaty do okna podziekowanie
  sendCountToPay = new Subject<Number>(); //przesyła kwote do zaplaty do okna realizacji zamowienia
  maxDateOfExpiration = new Subject<boolean>(); //informuje ze mam usunac z koszyka wszystkie licencje z maxDateOfExpiration
  askForUpdate = new Subject<boolean>(); //informuje czy mamy wiswietlic komunikat o aktualizacji danych
  sendUrl = new Subject<string>();
  isLoginToEmail = new Subject<string>();
  eventMSG: any = {
    dateFrom: '',
    dateTo: '',
    messageDesc: '',
    messageId: null,
    typeOfLock: null,
    typeOfLockDesc: '',
  };

  public constructor(public AppServices: AppServices, private _route: Router) {}

  onControlValueChanged(from, formErrors, validationMsg) {
    const form = from;
    for (let field in formErrors) {
      formErrors[field] = '';
      let control = form.get(field);
      if (!control && form.controls.customerContact) {
        control = form.controls.customerContact.get(field);
      }
      const validationMessages = validationMsg[field];
      for (const key in control.errors) {
        formErrors[field] += validationMessages[key] + ' ';
      }
    }
  }

  checkActualBasket = () => {
    //sprawdzam czy w session sotrage znajduja sie produkty w koszyku ,jesli tak to dodaje je do koszyka
    if (localStorage.getItem('basket-wapro-erp')) {
      let basket = [];
      let basketSession = JSON.parse(
        this.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
      basketSession.forEach((field) => {
        basket.push(field);
      });
      this.baskedProduct.next(basket);
    }
  };

  grouipngList = (groupCiteria, list, reverse?, height?) => {
    let groupList = [];

    list.forEach((element) => {
      if (groupList.length === 0) {
        let valueToInsert = {
          name: element[groupCiteria],
          list: [],
          productNameShortcut: element.productNameShortcut,
          productNameDescription: element.productNameDescription,
          selectedRows: [],
          selection: false,
        };
        valueToInsert.list.push(element);
        groupList.push(valueToInsert);
      } else {
        let index = groupList.findIndex(
          (obj) => obj.name == element[groupCiteria]
        );
        if (index === -1) {
          let valueToInsert = {
            name: element[groupCiteria],
            list: [],
            productNameShortcut: element.productNameShortcut,
            productNameDescription: element.productNameDescription,
            selectedRows: [],
            selection: false,
          };
          valueToInsert.list.push(element);
          groupList.push(valueToInsert);
        } else {
          groupList[index].list.push(element);
        }
      }
    });
    if (!reverse) {
      groupList.reverse();
    }

    for (let i = 0; i < groupList.length; i++) {
      groupList[i].index = i;
      if (height) groupList[i].height = groupList[i].list.length * height;
      else groupList[i].height = groupList[i].list.length * 47;
    }

    return groupList;
  };

  //funkcja pobiera certyfikat w PDF
  downloadCertyfikat = (icenseId) => {
    this.AppServices.getAuth(`MyProducts/${icenseId}/certificate/0`).subscribe(
      (response) => {
        let pom = response.certificate.replace(/\\(.)/g, '$1');
        this.downloadFile(pom, response.fileName);

        // const linkSource = `data:application/pdf;base64,${pom}`;
        // const downloadLink = document.createElement("a");
        // const fileName = response.fileName;

        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();
      },
      (error) => {
        this.showNotification('error', 'Błąd pobierania certyfikatu');
      }
    );
  };

  onHistoryClick = (data) => {
    this._route.navigate(['historia', data.licenseId]);
  };

  historyBack = () => {
    history.back();
  };

  //szyfruje JSON do AES, aby dane w localstorage czy sessionstorage nie byly przechowywane jawnie
  encryptString = (strignToEncrypt) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(JSON.stringify(strignToEncrypt)),
      '%CxR$%j$i9^2:9_0*2Q!230xs.+'
    ).toString();
  };

  //funkcja odszysfrowuje dane AES
  decryptString = (stringToDecrypt) => {
    stringToDecrypt = CryptoJS.AES.decrypt(
      stringToDecrypt,
      '%CxR$%j$i9^2:9_0*2Q!230xs.+'
    );
    if (stringToDecrypt.toString()) {
      let pom = JSON.parse(stringToDecrypt.toString(CryptoJS.enc.Utf8));
      return JSON.parse(stringToDecrypt.toString(CryptoJS.enc.Utf8));
    }
  };

  onModifyClick = (product, sessionId?) => {
    sessionStorage.setItem(
      'data-product-wapro-erp',
      this.encryptString(product)
    );
    sessionId
      ? this._route.navigate(['modyfikuj', product.productId])
      : this._route.navigate(['modyfikuj', product.productId]);
  };

  addToBasket = (dataToCalculate, aligne?) => {
    let msgSuccess = '';
    let msgIfno = '';
    this.AppServices.postAuth('Orders/Calculate', dataToCalculate).subscribe(
      (response) => {
        msgSuccess = `Pomyślnie dodano do koszyka`;
        response.forEach((field) => {
          dataToCalculate.myProductOrders.forEach((element) => {
            if (element.licenseId === field.licenseId) {
              field.maxDateOfExpiration = element.maxDateOfExpiration;
              field.licenseNumberEnc = element.licenseNumberEnc;
              field.productName = element.productName;
              field.unicate = element.unicate;
              field.customerVATNumber = element.customerVATNumber;
              field.lineDescription = element.lineDescription;
              field.packetId = element.packetId;
              field.packetName = element.packetName;
              field.productNameDescription = element.productNameDescription;
              field.productNameShortcut = element.productNameShortcut;
              field.toNumberOfUsers = element.toNumberOfUsers;
              field.toPacketId = element.toPacketId;
              field.toPacketName = element.toPacketName;
              field.toVariantId = element.toVariantId;
              field.toVariantName = element.toVariantName;
              field.toVersionId = element.toVersionId;
              field.toVersionNumber = element.toVersionNumber;
              field.variantId = element.variantId;
              field.variantName = element.variantName;
              field.versionId = element.versionId;
              field.versionNumber = element.versionNumber;
              field.productId = element.productId;
              field.numberOfUsers = element.numberOfUsers;
              field.agreementCode = element.agreementCode;
              field.agreementDesc = element.agreementDesc;
              field.agreementUrl = element.agreementUrl;
              field.company = field.company;
              field.numberOfPackets = element.numberOfPackets;
              field.requiredProducts =
                dataToCalculate.myProductOrders[0].requiredProducts;
              field.variantDescription =
                dataToCalculate.myProductOrders[0].variantDescription;
              field.eduLicenseDescription =
                dataToCalculate.myProductOrders[0].eduLicenseDescription;
              field.packetDescription =
                dataToCalculate.myProductOrders[0].packetDescription;
            }
          });
        });
        if (aligne) {
          let inf = '';
          let index = -1;
          dataToCalculate.myProductOrders.forEach((element) => {
            for (let i = 0; i < response.length; i++) {
              index = response[i].licenseNumber.indexOf(element.licenseNumber);
              if (index !== -1) break;
            }

            if (index === -1) inf += element.licenseNumber + ' ';
          });
          if (inf !== '')
            this.showNotification(
              'info',
              `Nie udało się wyrównać daty końca licencji: ${inf}, prosimy o kontakt z działem sprzedaży -tel: 22 702 89 02, email: sprzedaz.wapro@assecobs.pl`
            );
          else {
            this.maxDateOfExpiration.next(true);
            this.baskedShop.next(response);
            this.showNotification('success', msgSuccess);
          }
        } else {
          this.baskedShop.next(response);
          this.showNotification('success', msgSuccess);
        }
      },
      (error) => {
        if (JSON.parse(error).error === 404) {
          dataToCalculate.myProductOrders.forEach((product, index) => {
            msgIfno += `Dla produktu ${product.productName} nie udało się wyliczyć nowej ceny aktualizacji lub przedłużenia licencji.<br>`;
            dataToCalculate.myProductOrders.splice(index, 1);
          });
          if (msgIfno !== '') this.showNotification('info', msgIfno);
          if (dataToCalculate.myProductOrders.length > 0)
            this.addToBasket(dataToCalculate);
        }
      }
    );

    if (msgSuccess !== '') this.showNotification('success', msgSuccess);
  };

  addToBasketUnatorized = (dataToCalculate, aligne?) => {
    let msgSuccess = '';
    let msgIfno = '';
    this.AppServices.post('Orders/Calculate', dataToCalculate).subscribe(
      (response) => {
        msgSuccess = `Pomyślnie dodano do koszyka`;
        response.forEach((field) => {
          dataToCalculate.myProductOrders.forEach((element) => {
            if (element.licenseId === field.licenseId) {
              field.licenseNumberEnc = element.licenseNumberEnc;
              field.maxDateOfExpiration = element.maxDateOfExpiration;
              field.productName = element.productName;
              field.unicate = element.unicate;
              field.customerVATNumber = element.customerVATNumber;
              field.lineDescription = element.lineDescription;
              field.packetId = element.packetId;
              field.packetName = element.packetName;
              field.productNameDescription = element.productNameDescription;
              field.productNameShortcut = element.productNameShortcut;
              field.toNumberOfUsers = element.toNumberOfUsers;
              field.toPacketId = element.toPacketId;
              field.toPacketName = element.toPacketName;
              field.toVariantId = element.toVariantId;
              field.toVariantName = element.toVariantName;
              field.toVersionId = element.toVersionId;
              field.toVersionNumber = element.toVersionNumber;
              field.variantId = element.variantId;
              field.variantName = element.variantName;
              field.versionId = element.versionId;
              field.versionNumber = element.versionNumber;
              field.productId = element.productId;
              field.numberOfUsers = element.numberOfUsers;
              field.agreementCode = element.agreementCode;
              field.agreementDesc = element.agreementDesc;
              field.agreementUrl = element.agreementUrl;
              field.company = dataToCalculate.myProductOrders[0].customerName;
              field.numberOfPackets = element.numberOfPackets;
              field.requiredProducts =
                dataToCalculate.myProductOrders[0].requiredProducts;
              field.variantDescription =
                dataToCalculate.myProductOrders[0].variantDescription;
              field.eduLicenseDescription =
                dataToCalculate.myProductOrders[0].eduLicenseDescription;
              field.packetDescription =
                dataToCalculate.myProductOrders[0].packetDescription;
            }
          });
        });
        if (aligne) {
          let inf = '';
          let index = -1;
          dataToCalculate.myProductOrders.forEach((element) => {
            for (let i = 0; i < response.length; i++) {
              index = response[i].licenseNumber.indexOf(element.licenseNumber);
              if (index !== -1) break;
            }

            if (index === -1) inf += element.licenseNumber + ' ';
          });
          if (inf !== '')
            this.showNotification(
              'info',
              `Nie udało się wyrównać daty końca licencji: ${inf}, prosimy o kontakt z działem sprzedaży -tel: 22 702 89 02, email: sprzedaz.wapro@assecobs.pl`
            );
          else {
            this.maxDateOfExpiration.next(true);
            this.baskedShop.next(response);
            this.showNotification('success', msgSuccess);
          }
        } else {
          this.baskedShop.next(response);
          this.showNotification('success', msgSuccess);
        }
      },
      (error) => {
        if (JSON.parse(error).error === 404) {
          dataToCalculate.myProductOrders.forEach((product, index) => {
            if (
              product.versionId === product.toVersionId &&
              product.licenseId !== 0
            ) {
              msgIfno += `Produkt ${product.productName} już jest w najnowszej wersji<br>`;
              dataToCalculate.myProductOrders.splice(index, 1);
            }
          });
          if (msgIfno !== '') this.showNotification('info', msgIfno);
          if (dataToCalculate.myProductOrders.length > 0)
            this.addToBasket(dataToCalculate);
        }
      }
    );

    if (msgSuccess !== '') this.showNotification('success', msgSuccess);
  };

  //funkcja usuwa duplikaty z koszyka
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  downloadFile(pdfBase64, fileName) {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      var byteCharacters = atob(pdfBase64);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], { type: 'application/pdf' });
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
      return;
    }

    // For Other Browsers
    const linkSource = 'data:application/pdf;base64,' + pdfBase64;
    const link = document.createElement('a');
    link.href = linkSource;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.addEventListener(
      'focus',
      function () {
        URL.revokeObjectURL(link.href);
      },
      { once: true }
    );
  }

  public showNotification(type: string, message: string): void {
    let msg = { type: type, message: message };
    this.notifyMsg.next(msg);
  }

  dataFormatted(params) {
    return new DatePipe('en-US').transform(params.value, 'yyyy-MM-dd');
  }

  itemListClick = (productObj) => {
    if (productObj) {
      let purchases = [];
      purchases.push({
        item_name: 'WAPRO ' + productObj.productName, // Name or ID is required.
        item_id: productObj.productId,
        price: productObj.basePrice.toFixed(2),
        item_brand: 'ASSECO BS',
        item_category: productObj.description,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_variant: productObj.toVariantName,
        item_list_name: '',
        item_list_id: '',
        index: 1,
        quantity: productObj.quantity,
      });

      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'select_item',
        ecommerce: {
          items: purchases,
        },
      });
    }
  };

  viewItemClick = (productObj) => {
    if (productObj) {
      let purchases = [];
      purchases.push({
        item_name: 'WAPRO ' + productObj.productName, // Name or ID is required.
        item_id: productObj.productId,
        price: productObj.basePrice.toFixed(2),
        item_brand: 'ASSECO BS',
        item_category: productObj.description,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_variant: productObj.toVariantName,
        item_list_name: '',
        item_list_id: '',
        index: 1,
        quantity: productObj.quantity,
      });

      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'view_item',
        ecommerce: {
          items: purchases,
        },
      });
    }
  };

  addProductToShoppingCart = (productObj) => {
    if (productObj) {
      let purchases = [];
      productObj.forEach((product) => {
        purchases.push({
          item_name: 'WAPRO ' + product.productName, // Name or ID is required.
          item_id: product.productId,
          price: product.amount.toFixed(2),
          item_brand: 'ASSECO BS',
          item_category: product.description,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_variant: product.toVariantName,
          item_list_name: '',
          item_list_id: '',
          index: 1,
          quantity: product.quantity,
        });
      });

      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'add_to_cart',
        ecommerce: {
          items: purchases,
        },
      });
    }
  };

  removeProductFromShoppingCart = (productObj) => {
    if (productObj) {
      let purchases = [];
      productObj.forEach((product) => {
        purchases.push({
          item_name: 'WAPRO ' + product.productName, // Name or ID is required.
          item_id: product.productId,
          price: product.amount.toFixed(2),
          item_brand: 'ASSECO BS',
          item_category: product.description,
          item_variant: product.toVariantName,
          item_list_name: '',
          item_list_id: '',
          index: 1,
          quantity: product.quantity,
        });
      });

      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'remove_from_cart',
        ecommerce: {
          items: purchases,
        },
      });
    }
  };

  measurePurchases = (transaction, productObj) => {
    let gross = 0;
    let net = 0;

    if (productObj) {
      let purchases = [];
      productObj.forEach((product) => {
        purchases.push({
          item_name: 'WAPRO ' + product.productName,
          item_id: product.productId,
          price: product.amount.toFixed(2),
          item_brand: 'ASSECO BS',
          item_category: product.description,
          item_variant: product.toVariantName,
          quantity: product.quantity,
        });

        gross += product.amount;
        net += product.netAmount;
      });

      let vat = <any>gross.toFixed(2) - net;

      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'purchase',
        ecommerce: {
          transaction_id: transaction.orderId,
          affiliation: 'Moje konto web',
          value: transaction.amountToPay,
          tax: vat,
          shipping: '',
          currency: 'PLN',
          coupon: '',
          items: purchases,
        },
      });
    }
  };

  openReadMore = (url) => {
    if (window.innerWidth > 1024) {
      return true;
    } else window.open(url, '_blank');
    return false;
  };

  newPayment = (response) => {
    if (response.paymentServiceUrl.indexOf('platnosc?id=') != -1) {
      let modePayment = {
        appName: response.appName,
        visibleDescription: response.desc,
        operatorName: 'tpay',
        amount: response.priceGross,
        description: response.desc,
        additionalDescription: response.desc,
        customer: {
          vatNumber: '',
          email: response.mail,
          phone: '',
          firstName: response.firstname,
          lastName: response.lastname,
          language: response.lang,
          companyName: response.companyName,
        },
        articles: [
          {
            id: '',
            name: response.desc,
            priceNet: response.priceNet,
            priceGross: response.priceGross,
            vat: response.priceVat,
            quantity: 1,
            // name: response.desc,
            // unitPrice: response.priceGross,
            // quantity: 1
          },
        ],
        currencyCode: 'PLN',
        paymentMethod: 115,
        orderId: response.orderId.toString(),
        extNotificationUrl: response.notificationUrl,
        redirectUrl: response.returnUrl,
        extOrderId: response.transactionId,
      };
      this.AppServices.transactionPrepare(modePayment).subscribe((res) => {
        this._route.navigate(['/platnosc'], {
          queryParams: { id: res.data.transactionId, return: true },
        });
      });
    } else {
      let parameters = this.createParameterString(response);
      this.postForm(response.paymentServiceUrl, parameters, 'POST');
    }
  };

  postForm(path, params, method) {
    method = method || 'post';

    var form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }

  createParameterString = (checkSum) => {
    let payment = '';
    payment += checkSum.transactionId;
    payment += checkSum.firstname;
    payment += checkSum.lastname;
    payment += checkSum.desc;
    payment += checkSum.lang;
    payment += checkSum.mail;
    payment += checkSum.accountId;
    payment += checkSum.returnUrl;
    payment += checkSum.notificationUrl;
    payment += checkSum.priceNet;
    payment += checkSum.priceGross;
    payment += checkSum.priceVat;
    payment += checkSum.sendToErp;

    let dataNow = new DatePipe('en-US').transform(new Date(), 'yyyyMMdd');

    let string = Md5.hashStr(payment);
    string = string + '/' + dataNow + '/' + salt;
    string = Md5.hashStr(string);

    checkSum.checkSum = string;

    return checkSum;
  };

  isValidNip(nip: string): boolean {
    if (typeof nip !== 'string') return false;

    nip = nip.replace(/[\ \-]/gi, '');

    let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    let controlNumber = parseInt(nip.substring(9, 10));
    let weightCount = weight.length;
    for (let i = 0; i < weightCount; i++) {
      sum += parseInt(nip.substr(i, 1)) * weight[i];
    }
    return sum % 11 === controlNumber;
  }
}
