import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
  Testability,
  ChangeDetectorRef,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';

import { ActivatedRoute, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-modyfi',
  templateUrl: './modyfi.component.html',
  styleUrls: ['./modyfi.component.scss'],
})
export class ModyfiComponent implements OnInit, OnDestroy {
  productId;
  variantsList: Array<any> = [];
  packetsList: Array<any> = [];
  maxNumberOfUsers: number = 1;
  variantId;
  countToPrices = 0;
  countNetPrice = 0;
  agreement: boolean = false;
  UserRole: string = '';
  isNipOK: boolean = true;
  isChange: boolean = false;

  dateToCalculate = {
    myProductOrders: [
      {
        customerName: '',
        productName: '',
        toVariantId: null,
        toVariantName: '',
        lastActivationDate: '',
        toNumberOfUsers: null,
        expireDate: '',
        unicate: null,
        customerId: 0,
        numberOfUsers: null,
        variantName: '',
        licenseNumber: '',
        variantId: null,
        packetId: '',
        toPacketId: '',
        toPacketName: '',
        toVersionId: null,
        versionId: null,
        productId: null,
        description: '',
        customerVATNumber: '',
        lineDescription: '',
        packetName: '',
        productNameDescription: '',
        productNameShortcut: '',
        toVersionNumber: null,
        versionNumber: null,
        agreementCode: '',
        agreementDesc: '',
        agreementUrl: '',
        variantDescription: null,
        eduLicenseDescription: null,
        packetDescription: null,
        licenseId: 0,
        maxDateOfExpiration: null,
        skipChangeLicenseDate: true,
        company: '',
        remarks: '',
        numberOfPackets: 1,
        operatingSystem: '',
        requiredProducts: [],
        descriptionProduct: '',
        dayToExpire: null,
        licenseNumberEnc: '',
        productUrl: '',
      },
    ],
  };

  basket: Array<Basket> = [];
  useLargeSpinButtons: boolean = false;

  recommendedProducts = [];
  slideshowDelay = 5000;
  heightRecommendate = '190px';

  isBuyRecommendate: boolean = false;
  heightGrid = 0;

  isUnauthorized: boolean = true;
  numberOfPackets: number = 1;
  nipClient = '';
  operatingSystem = 'program';
  showDescription: boolean = false;

  radioButtonList = [
    { value: false, label: 'Rozbuduj i przedłuż licencje' },
    { value: true, label: 'Tylko rozbuduj (bez przedłużania)' },
  ];
  isSkipChangeLicenseDate: boolean = false;
  dayToExpireDate = 0;
  showMoreVisible: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 750) {
      this.useLargeSpinButtons = true;
      this.heightRecommendate = '310px';
    } else {
      this.useLargeSpinButtons = false;
      this.heightRecommendate = '190px';
    }
  }

  copyVatNumber;
  parametersObservable;
  dateToday: boolean = false;
  isLoad: boolean = false;

  constructor(
    private appServices: AppServices,
    private event: EventService,
    private route: ActivatedRoute,
    private _route: Router,
    public cd: ChangeDetectorRef
  ) {
    if (
      window.localStorage.getItem('wapro-erp-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('wapro-erp-token')).exp * 1000
    ) {
      this.event.showNavbar.next(true);
      this.isUnauthorized = false;
    }

    if (localStorage.getItem('wapro-erp-token'))
      this.UserRole = helper.decodeToken(
        localStorage.getItem('wapro-erp-token')
      ).UserRole;

    //odswieza komponent przy zmianie params
    this.parametersObservable = this.route.queryParams.subscribe((params) => {
      if (
        params['variantName'] ||
        params['packetName'] ||
        params['numberOfUsers'] ||
        params['numberOfPackets'] ||
        params['remarks'] ||
        params['nip']
      ) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    if (
      new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd') >= '2021-10-01'
    )
      this.dateToday = true;

    if (window.innerWidth < 430) {
      this.useLargeSpinButtons = true;
      this.heightRecommendate = '310px';
    } else {
      this.useLargeSpinButtons = false;
      this.heightRecommendate = '190px';
    }

    this.route.params.subscribe(
      (params) => (this.productId = parseInt(params['productId']))
    );
    if (sessionStorage.getItem('data-product-wapro-erp')) {
      setTimeout(() => {
        this.dateToCalculate.myProductOrders[0] = JSON.parse(
          this.event.decryptString(
            sessionStorage.getItem('data-product-wapro-erp')
          )
        );
        if (
          JSON.parse(
            this.event.decryptString(
              sessionStorage.getItem('data-product-wapro-erp')
            )
          ).numberOfPackets
        ) {
          this.dateToCalculate.myProductOrders[0].numberOfPackets = JSON.parse(
            this.event.decryptString(
              sessionStorage.getItem('data-product-wapro-erp')
            )
          ).numberOfPackets;
        } else {
          this.dateToCalculate.myProductOrders[0].numberOfPackets = 1;
        }
        if (!this.dateToCalculate.myProductOrders[0].licenseNumberEnc)
          this.dateToCalculate.myProductOrders[0].licenseNumberEnc = '';

        if (!this.dateToCalculate.myProductOrders[0].remarks)
          this.dateToCalculate.myProductOrders[0].remarks = '';
        if (
          this.dateToCalculate.myProductOrders[0].remarks.indexOf('[NIP:') !==
          -1
        ) {
          let remarks =
            this.dateToCalculate.myProductOrders[0].remarks.split('] ');
          this.nipClient = remarks[0].replace('[NIP:', '');
          this.dateToCalculate.myProductOrders[0].remarks = remarks[1];
        }
        this.checkQueryParams();
        setTimeout(() => {
          this.isLoad = true;
          this.count();
        }, 100);
      }, 0);
    }

    this.numberOfPackets =
      this.dateToCalculate.myProductOrders[0].numberOfPackets;

    this.isMaxDateOfExpiration();

    this.dateToCalculate.myProductOrders[0].productName =
      this.dateToCalculate.myProductOrders[0].productName.replace('WAPRO ', '');
    this.variantId = this.dateToCalculate.myProductOrders[0].variantId;

    if (this.dateToCalculate.myProductOrders[0].agreementCode == '')
      this.agreement = true;

    if (this.isUnauthorized) {
      this.getInformationAboutProduct();
      this.getRecommendationProductsUnatorized(this.productId);
    } else {
      this.getInformationAboutProductUnauthorized();
      this.getRecommendationProducts(this.productId);
    }

    if (!this.dateToCalculate.myProductOrders[0].remarks)
      this.dateToCalculate.myProductOrders[0].remarks = '';
    if (
      this.dateToCalculate.myProductOrders[0].remarks.indexOf('[NIP:') !== -1
    ) {
      let remarks = this.dateToCalculate.myProductOrders[0].remarks.split('] ');
      this.nipClient = remarks[0].replace('[NIP:', '');
      this.dateToCalculate.myProductOrders[0].remarks = remarks[1];
    }
    //this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate = true;

    this.operatingSystem =
      this.dateToCalculate.myProductOrders[0].operatingSystem === 'Service'
        ? 'usługę'
        : 'program';
    this.chechProductInBasket();
    setTimeout(() => {
      if (
        this.dateToCalculate.myProductOrders[0].licenseId != 0 &&
        this.dateToCalculate.myProductOrders[0].dayToExpire <= 90 &&
        this.dateToCalculate.myProductOrders[0].dayToExpire > 0 &&
        !this.dateToCalculate.myProductOrders[0].maxDateOfExpiration
      ) {
        this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate = false;
      }
    }, 0);
  }

  ngOnDestroy() {}

  checkQueryParams = () => {
    this.route.queryParams.subscribe((params) => {
      //sprawdzam czy w url występuje informacja o nazwie wariantu
      if (params.variantName) {
        let idVariant = this.variantsList.find(
          (item) => item.variantName == params.variantName.toUpperCase()
        );
        if (idVariant) {
          this.dateToCalculate.myProductOrders[0].toVariantId =
            idVariant.variantId;
          this.dateToCalculate.myProductOrders[0].toVariantName =
            idVariant.variantName;
        }
      }

      //sprawdzam czy w url występuje informacja o nazwie pakietu
      if (params.packetName) {
        let idPacket = this.packetsList.find(
          (item) => item.packetName == params.packetName.toUpperCase()
        );
        if (idPacket) {
          this.dateToCalculate.myProductOrders[0].toPacketId =
            idPacket.packetId;
          this.dateToCalculate.myProductOrders[0].toPacketName =
            idPacket.packetName;
        }
      }

      //sprawdzam czy w url występuje informacja o liczbie stanowisk
      if (params.numberOfUsers) {
        if (Number(params.numberOfUsers) > this.maxNumberOfUsers)
          this.dateToCalculate.myProductOrders[0].toNumberOfUsers =
            this.maxNumberOfUsers;
        else if (Number(params.numberOfUsers) < 1)
          this.dateToCalculate.myProductOrders[0].toNumberOfUsers = 1;
        else
          this.dateToCalculate.myProductOrders[0].toNumberOfUsers = Number(
            params.numberOfUsers
          );
      }

      //sprawdzam czy w url występuje informacja o liczbie pakietów
      if (params.numberOfPackets) {
        if (
          (this.UserRole === 'partner' || this.UserRole === 'subpartner') &&
          this.dateToCalculate.myProductOrders[0].licenseId == 0
        ) {
          if (Number(params.numberOfPackets) < 1)
            this.dateToCalculate.myProductOrders[0].numberOfPackets = 1;
          else
            this.dateToCalculate.myProductOrders[0].numberOfPackets = Number(
              params.numberOfPackets
            );
        }
      }

      //sprawdzam czy w url występuje informacja o uwagach
      if (params.remarks) {
        this.dateToCalculate.myProductOrders[0].remarks = params.remarks;
      }
      // else this.dateToCalculate.myProductOrders[0].remarks = '';
      //sprawdzam czy w url występuje informacja o nipie i czy pole nip jest wyswietlane
      if (params.nip) {
        if (
          (this.UserRole === 'partner' || this.UserRole === 'subpartner') &&
          this.dateToCalculate.myProductOrders[0].licenseId == 0
        ) {
          this.nipClient = params.nip;
        }
      }

      //sprawdzam czy w url występuje informacja o LicenseNumberEnc
      if (params.licenseNumberEnc && params.variantName) {
        this.dateToCalculate.myProductOrders[0].licenseNumberEnc =
          params.licenseNumberEnc;
      }
    });
  };

  //function check product in basket. if product exist in basket and requires confirm, confirm automatic set true
  chechProductInBasket = () => {
    try {
      let basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
      basket.forEach((item) => {
        if (item.unicate === this.dateToCalculate.myProductOrders[0].unicate)
          this.agreement = true;
      });
    } catch {}
  };

  history = () => {
    history.back();
  };

  // ngOnDestroy() {
  //   sessionStorage.removeItem('data-product-wapro-erp');
  // }

  getButtonText(item: any) {
    if (item.operatingSystem === 'Service') return 'Kup usługę';
    else return 'Kup program';
  }

  onChange = () => {
    try {
      let previueValue = JSON.parse(
        this.event.decryptString(
          sessionStorage.getItem('previusValueMyAccount')
        )
      );
      if (
        previueValue.myProductOrders[0].numberOfPackets !== this.numberOfPackets
      )
        this.isChange = true;
      if (
        previueValue.myProductOrders[0].toNumberOfUsers !==
        this.dateToCalculate.myProductOrders[0].toNumberOfUsers
      )
        this.isChange = true;
      if (
        previueValue.myProductOrders[0].toVariantId !==
        this.dateToCalculate.myProductOrders[0].toVariantId
      )
        this.isChange = true;
      if (
        previueValue.myProductOrders[0].toPacketId !==
        this.dateToCalculate.myProductOrders[0].toPacketId
      )
        this.isChange = true;
      if (
        previueValue.myProductOrders[0].skipChangeLicenseDate !==
        this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate
      )
        this.isChange = true;

      if (
        this.dateToCalculate.myProductOrders[0].toNumberOfUsers >
          this.dateToCalculate.myProductOrders[0].numberOfUsers &&
        this.dateToCalculate.myProductOrders[0].licenseId != 0 &&
        this.dateToCalculate.myProductOrders[0].dayToExpire <= 90 &&
        this.dateToCalculate.myProductOrders[0].dayToExpire > 0 &&
        !this.dateToCalculate.myProductOrders[0].maxDateOfExpiration
      ) {
        if (!this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate)
          this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate = false;
        this.isSkipChangeLicenseDate = true;
      } else {
        this.isSkipChangeLicenseDate = false;
        this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate = false;
      }
    } catch {}
    this.count();
  };

  onSelectionChanged = (event) => {
    setTimeout(() => {
      this.maxNumberOfUsers = event.selectedItem.maxNumberOfUsers;
      this.dateToCalculate.myProductOrders[0].toVariantName =
        event.selectedItem.variantName;
      this.onChange();
    }, 0);
  };

  getInformationAboutProduct = () => {
    return this.appServices
      .get(`Products/${this.productId}`)
      .subscribe((response) => {
        this.variantsList = response.variants;

        this.variantsList.sort(function (a, b) {
          return a.orderWWW < b.orderWWW ? -1 : a.orderWWW > b.orderWWW ? 1 : 0;
        });

        this.variantsList.forEach((variant) => {
          variant.variantName = variant.variantName.replace(/\\(.)/g, '$1');
        });

        this.maxNumberOfUsers = response.maxNumberOfUsers;
        if (response.packets !== null) {
          this.packetsList = response.packets;
          this.packetsList.sort(function (a, b) {
            return a.orderWWW < b.orderWWW
              ? -1
              : a.orderWWW > b.orderWWW
              ? 1
              : 0;
          });
        }

        this.packetsList.forEach((packet) => {
          packet.packetName = packet.packetName.replace(/\\(.)/g, '$1');
        });
        setTimeout(() => {
          //jeśli nowy produkt usuwam licencje Start i biznes
          if (
            this.dateToCalculate.myProductOrders[0].licenseId == 0 &&
            this.dateToday
          ) {
            for (let i = this.variantsList.length - 1; i >= 0; i--) {
              if (
                this.variantsList[i].variantName == 'BIZNES' ||
                this.variantsList[i].variantName == 'START' ||
                this.variantsList[i].variantName == 'BIZNES/FILIA'
              )
                this.variantsList.splice(i, 1);
            }
          }

          try {
            if (
              this.dateToCalculate.myProductOrders[0].licenseId == 0 &&
              this.dateToday
            ) {
              for (let i = this.packetsList.length - 1; i >= 0; i--) {
                if (
                  this.packetsList[i].packetName == 'BIZNES' ||
                  this.packetsList[i].packetName == 'START' ||
                  this.packetsList[i].packetName == 'BIZNES/FILIA'
                )
                  this.packetsList.splice(i, 1);
              }
            }
          } catch {}
        }, 10);
        //jesli nowy produkt usuwamy pakiety Star i biznes

        if (this.dateToCalculate.myProductOrders[0].requiredProducts) {
          this.dateToCalculate.myProductOrders[0].requiredProducts.forEach(
            (element) => {
              element.requiredProductName = element.requiredProductName.replace(
                'WAPRO',
                ''
              );
            }
          );
        }

        this.dateToCalculate.myProductOrders[0].descriptionProduct =
          response.description;

        if (!sessionStorage.getItem('data-product-wapro-erp')) {
          this.dateToCalculate.myProductOrders[0] = response;
          this.dateToCalculate.myProductOrders[0].productName =
            this.dateToCalculate.myProductOrders[0].productName.replace(
              'WAPRO ',
              ''
            );
          this.dateToCalculate.myProductOrders[0].licenseId = 0;
          this.dateToCalculate.myProductOrders[0].licenseNumber = null;
          this.dateToCalculate.myProductOrders[0].numberOfPackets = 1;
          this.dateToCalculate.myProductOrders[0].numberOfUsers = 1;
          if (response.packets == null) {
            this.dateToCalculate.myProductOrders[0].packetId = null;
            this.dateToCalculate.myProductOrders[0].packetName = null;
            this.dateToCalculate.myProductOrders[0].toPacketId = null;
            this.dateToCalculate.myProductOrders[0].toPacketName = null;
          } else {
            this.dateToCalculate.myProductOrders[0].packetId =
              this.packetsList[0].packetId;
            this.dateToCalculate.myProductOrders[0].packetName =
              this.packetsList[0].packetName;
            this.dateToCalculate.myProductOrders[0].toPacketId =
              this.packetsList[0].packetId;
            this.dateToCalculate.myProductOrders[0].toPacketName =
              this.packetsList[0].packetName;
          }

          this.dateToCalculate.myProductOrders[0].toNumberOfUsers = 1;

          this.dateToCalculate.myProductOrders[0].toVariantId =
            response.variants[0].variantId;
          this.dateToCalculate.myProductOrders[0].toVariantName =
            response.variants[0].variantName;
          this.dateToCalculate.myProductOrders[0].toVersionId =
            response.versions[0].versionId;
          this.dateToCalculate.myProductOrders[0].toVersionNumber =
            response.versions[0].versionNumber;
          this.dateToCalculate.myProductOrders[0].unicate = 0;
          this.dateToCalculate.myProductOrders[0].variantId =
            this.variantsList[0].variantId;
          this.dateToCalculate.myProductOrders[0].variantName =
            this.variantsList[0].variantName;
          this.dateToCalculate.myProductOrders[0].versionId =
            response.versions[0].versionId;
          this.dateToCalculate.myProductOrders[0].versionNumber =
            response.versions[0].versionNumber;

          this.checkQueryParams();
          this.isLoad = true;
          this.count();
        }
      });
  };

  getInformationAboutProductUnauthorized = () => {
    let product = this.event.decryptString(
      localStorage.getItem('product-list-wapro-erp')
    );
    product = JSON.parse(product);

    if (product.productId !== this.productId) {
      this.getInformationAboutProduct();
    } else {
      product.forEach((element) => {
        if (element.productId === this.productId) {
          this.variantsList = element.variants;

          this.variantsList.sort(function (a, b) {
            return a.orderWWW < b.orderWWW
              ? -1
              : a.orderWWW > b.orderWWW
              ? 1
              : 0;
          });

          this.variantsList.forEach((variant) => {
            variant.variantName = variant.variantName.replace(/\\(.)/g, '$1');
          });

          this.maxNumberOfUsers = element.maxNumberOfUsers;
          if (element.packets !== null) {
            this.packetsList = element.packets;
            this.packetsList.sort(function (a, b) {
              return a.orderWWW < b.orderWWW
                ? -1
                : a.orderWWW > b.orderWWW
                ? 1
                : 0;
            });
          }

          this.packetsList.forEach((packet) => {
            packet.packetName = packet.packetName.replace(/\\(.)/g, '$1');
          });
        }
      });
    }
  };

  onSelectionPacketChanged = (event) => {
    this.dateToCalculate.myProductOrders[0].toPacketName =
      event.selectedItem.packetName;
    this.onChange();
  };

  count = () => {
    sessionStorage.setItem(
      'previusValueMyAccount',
      this.event.encryptString(this.dateToCalculate)
    );

    if (this.isLoad) {
      this.isUnauthorized ? this.countUnauthorized() : this.countAuthorized();
    }
  };

  countAuthorized = () => {
    this.basket = [];
    this.countToPrices = 0;
    this.countNetPrice = 0;
    if (this.dateToCalculate.myProductOrders[0].customerId === null)
      this.dateToCalculate.myProductOrders[0].customerId = 0;

    try {
      if (this.dateToCalculate.myProductOrders[0].expireDate) {
        let DataToday = new DatePipe('en-US').transform(
          new Date(),
          'yyyy-MM-dd'
        );
        let expireDate = new DatePipe('en-US').transform(
          new Date(this.dateToCalculate.myProductOrders[0].expireDate),
          'yyyy-MM-dd'
        );
        let countDay =
          new Date(expireDate).getTime() - new Date(DataToday).getTime();
        this.dayToExpireDate = countDay / (1000 * 3600 * 24);
      }
    } catch {}
    this.appServices
      .postAuth('Orders/Calculate', this.dateToCalculate)
      .subscribe(
        (response) => {
          this.basket = [];
          response.forEach((field, index) => {
            this.basket.push(field);
            this.basket[index].licenseNumberEnc =
              this.dateToCalculate.myProductOrders[0].licenseNumberEnc;
            this.basket[index].maxDateOfExpiration =
              this.dateToCalculate.myProductOrders[0].maxDateOfExpiration;
            this.basket[index].numberOfUsers =
              this.dateToCalculate.myProductOrders[0].numberOfUsers;
            this.basket[index].productId =
              this.dateToCalculate.myProductOrders[0].productId;
            this.basket[index].productName =
              this.dateToCalculate.myProductOrders[0].productName;
            this.basket[index].unicate =
              this.dateToCalculate.myProductOrders[0].unicate;
            this.basket[index].customerVATNumber =
              this.dateToCalculate.myProductOrders[0].customerVATNumber;
            this.basket[index].lineDescription =
              this.dateToCalculate.myProductOrders[0].lineDescription;
            this.basket[index].packetId =
              this.dateToCalculate.myProductOrders[0].packetId;
            this.basket[index].packetName =
              this.dateToCalculate.myProductOrders[0].packetName;
            this.basket[index].productNameDescription =
              this.dateToCalculate.myProductOrders[0].productNameDescription;
            this.basket[index].productNameShortcut =
              this.dateToCalculate.myProductOrders[0].productNameShortcut;
            this.basket[index].toNumberOfUsers = Number(
              this.dateToCalculate.myProductOrders[0].toNumberOfUsers
            );
            this.basket[index].toPacketId =
              this.dateToCalculate.myProductOrders[0].toPacketId;
            this.basket[index].toPacketName =
              this.dateToCalculate.myProductOrders[0].toPacketName;
            this.basket[index].toVariantId =
              this.dateToCalculate.myProductOrders[0].toVariantId;
            this.basket[index].toVariantName =
              this.dateToCalculate.myProductOrders[0].toVariantName;
            this.basket[index].toVersionId =
              this.dateToCalculate.myProductOrders[0].toVersionId;
            this.basket[index].toVersionNumber =
              this.dateToCalculate.myProductOrders[0].toVersionNumber;
            this.basket[index].variantId =
              this.dateToCalculate.myProductOrders[0].variantId;
            this.basket[index].variantName =
              this.dateToCalculate.myProductOrders[0].variantName;
            this.basket[index].versionId =
              this.dateToCalculate.myProductOrders[0].versionId;
            this.basket[index].versionNumber =
              this.dateToCalculate.myProductOrders[0].versionNumber;
            this.basket[index].agreementCode =
              this.dateToCalculate.myProductOrders[0].agreementCode;
            this.basket[index].agreementDesc =
              this.dateToCalculate.myProductOrders[0].agreementDesc;
            this.basket[index].agreementUrl =
              this.dateToCalculate.myProductOrders[0].agreementUrl;
            this.basket[index].remarks =
              this.dateToCalculate.myProductOrders[0].remarks;
            this.basket[index].numberOfPackets =
              this.dateToCalculate.myProductOrders[0].numberOfPackets;
            this.basket[index].requiredProducts =
              this.dateToCalculate.myProductOrders[0].requiredProducts;
            this.basket[index].licenseNumberEnc =
              this.dateToCalculate.myProductOrders[0].licenseNumberEnc;
            this.basket[index].descriptionProduct =
              this.dateToCalculate.myProductOrders[0].descriptionProduct;
            this.basket[index].variantDescription =
              this.dateToCalculate.myProductOrders[0].variantDescription;
            this.basket[index].eduLicenseDescription =
              this.dateToCalculate.myProductOrders[0].eduLicenseDescription;
            this.basket[index].packetDescription =
              this.dateToCalculate.myProductOrders[0].packetDescription;

            //dodaje inofmacje o przedluzeniu licenci
            this.basket[index].skipChangeLicenseDate =
              this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate;
            if (this.basket[index].company === null)
              this.basket[index].company =
                this.dateToCalculate.myProductOrders[0].customerName;

            this.basket[index].remarks =
              this.dateToCalculate.myProductOrders[0].remarks;
            this.isChange = false;
          });

          this.countToPrices = 0;
          this.countNetPrice = 0;
          this.basket.forEach((product) => {
            this.countNetPrice += product.netAmount;
            this.countToPrices += product.amount;
          });
          if (window.innerWidth < 576)
            this.heightGrid = this.basket.length * 80;
          else this.heightGrid = this.basket.length * 60;
        },
        (error) => {
          //sprawdzam czy lista pakietow nie jet null jesli pakiety występuja a produkt ma nullowe pakiety przypisuje my pierwszy pakiet z listy do kalkulacji
          let actualProducts = JSON.parse(
            this.event.decryptString(
              localStorage.getItem('product-list-wapro-erp')
            )
          );
          for (let i = 0; i < actualProducts.length; i++) {
            if (
              this.dateToCalculate.myProductOrders[0].productId ===
              actualProducts[i].productId
            ) {
              if (
                actualProducts[i].packets !== null &&
                this.dateToCalculate.myProductOrders[0].packetId === null
              ) {
                actualProducts[i].packets.sort(function (a, b) {
                  return a.orderWWW < b.orderWWW
                    ? -1
                    : a.orderWWW > b.orderWWW
                    ? 1
                    : 0;
                });

                this.dateToCalculate.myProductOrders[0].packetId =
                  actualProducts[i].packets[0].packetId;
                this.dateToCalculate.myProductOrders[0].packetName =
                  actualProducts[i].packets[0].packetName;
                this.dateToCalculate.myProductOrders[0].toPacketId =
                  actualProducts[i].packets[0].packetId;
                this.dateToCalculate.myProductOrders[0].toPacketName =
                  actualProducts[i].packets[0].packetName;
                setTimeout(() => {
                  this.checkQueryParams();
                  // this.count();
                }, 0);
                return;
              }
            }
          }

          if (JSON.parse(error).error === 404) {
            this.event.showNotification(
              'info',
              `Dla produktu ${this.dateToCalculate.myProductOrders[0].productName} nie udało się wyliczyć nowej ceny aktualizacji lub przedłużenia licencji.`
            );
          }
          this.isChange = true;
        }
      );
  };

  countUnauthorized = () => {
    this.basket = [];
    this.countToPrices = 0;
    this.countNetPrice = 0;
    if (this.dateToCalculate.myProductOrders[0].customerId === null)
      this.dateToCalculate.myProductOrders[0].customerId = 0;

    this.appServices.post('Orders/Calculate', this.dateToCalculate).subscribe(
      (response) => {
        this.basket = [];
        response.forEach((field, index) => {
          this.basket.push(field);
          this.basket[index].licenseNumberEnc =
            this.dateToCalculate.myProductOrders[0].licenseNumberEnc;
          this.basket[index].maxDateOfExpiration =
            this.dateToCalculate.myProductOrders[0].maxDateOfExpiration;
          this.basket[index].numberOfUsers =
            this.dateToCalculate.myProductOrders[0].numberOfUsers;
          this.basket[index].productId =
            this.dateToCalculate.myProductOrders[0].productId;
          this.basket[index].productName =
            this.dateToCalculate.myProductOrders[0].productName;
          this.basket[index].unicate =
            this.dateToCalculate.myProductOrders[0].unicate;
          this.basket[index].customerVATNumber =
            this.dateToCalculate.myProductOrders[0].customerVATNumber;
          this.basket[index].lineDescription =
            this.dateToCalculate.myProductOrders[0].lineDescription;
          this.basket[index].packetId =
            this.dateToCalculate.myProductOrders[0].packetId;
          this.basket[index].packetName =
            this.dateToCalculate.myProductOrders[0].packetName;
          this.basket[index].productNameDescription =
            this.dateToCalculate.myProductOrders[0].productNameDescription;
          this.basket[index].productNameShortcut =
            this.dateToCalculate.myProductOrders[0].productNameShortcut;
          this.basket[index].toNumberOfUsers = Number(
            this.dateToCalculate.myProductOrders[0].toNumberOfUsers
          );
          this.basket[index].toPacketId =
            this.dateToCalculate.myProductOrders[0].toPacketId;
          this.basket[index].toPacketName =
            this.dateToCalculate.myProductOrders[0].toPacketName;
          this.basket[index].toVariantId =
            this.dateToCalculate.myProductOrders[0].toVariantId;
          this.basket[index].toVariantName =
            this.dateToCalculate.myProductOrders[0].toVariantName;
          this.basket[index].toVersionId =
            this.dateToCalculate.myProductOrders[0].toVersionId;
          this.basket[index].toVersionNumber =
            this.dateToCalculate.myProductOrders[0].toVersionNumber;
          this.basket[index].variantId =
            this.dateToCalculate.myProductOrders[0].variantId;
          this.basket[index].variantName =
            this.dateToCalculate.myProductOrders[0].variantName;
          this.basket[index].versionId =
            this.dateToCalculate.myProductOrders[0].versionId;
          this.basket[index].versionNumber =
            this.dateToCalculate.myProductOrders[0].versionNumber;
          this.basket[index].agreementCode =
            this.dateToCalculate.myProductOrders[0].agreementCode;
          this.basket[index].agreementDesc =
            this.dateToCalculate.myProductOrders[0].agreementDesc;
          this.basket[index].agreementUrl =
            this.dateToCalculate.myProductOrders[0].agreementUrl;
          this.basket[index].remarks =
            this.dateToCalculate.myProductOrders[0].remarks;
          this.basket[index].numberOfPackets =
            this.dateToCalculate.myProductOrders[0].numberOfPackets;

          this.basket[index].descriptionProduct =
            this.dateToCalculate.myProductOrders[0].descriptionProduct;
          this.basket[index].requiredProducts =
            this.dateToCalculate.myProductOrders[0].requiredProducts;
          this.basket[index].variantDescription =
            this.dateToCalculate.myProductOrders[0].variantDescription;
          this.basket[index].eduLicenseDescription =
            this.dateToCalculate.myProductOrders[0].eduLicenseDescription;
          this.basket[index].packetDescription =
            this.dateToCalculate.myProductOrders[0].packetDescription;

          //dodaje inofmacje o przedluzeniu licenci
          this.basket[index].skipChangeLicenseDate =
            this.dateToCalculate.myProductOrders[0].skipChangeLicenseDate;
          if (this.basket[index].company === null)
            this.basket[index].company =
              this.dateToCalculate.myProductOrders[0].customerName;

          this.basket[index].remarks =
            this.dateToCalculate.myProductOrders[0].remarks;
          this.isChange = false;
        });

        this.countToPrices = 0;
        this.countNetPrice = 0;
        this.basket.forEach((product) => {
          this.countNetPrice += product.netAmount;
          this.countToPrices += product.amount;
        });
        if (window.innerWidth < 576) this.heightGrid = this.basket.length * 80;
        else this.heightGrid = this.basket.length * 60;
      },
      (error) => {
        //sprawdzam czy lista pakietow nie jet null jesli pakiety występuja a produkt ma nullowe pakiety przypisuje my pierwszy pakiet z listy do kalkulacji
        let actualProducts = JSON.parse(
          this.event.decryptString(
            localStorage.getItem('product-list-wapro-erp')
          )
        );
        for (let i = 0; i < actualProducts.length; i++) {
          if (
            this.dateToCalculate.myProductOrders[0].productId ===
            actualProducts[i].productId
          ) {
            if (
              actualProducts[i].packets !== null &&
              this.dateToCalculate.myProductOrders[0].packetId === null
            ) {
              actualProducts[i].packets.sort(function (a, b) {
                return a.orderWWW < b.orderWWW
                  ? -1
                  : a.orderWWW > b.orderWWW
                  ? 1
                  : 0;
              });

              this.dateToCalculate.myProductOrders[0].packetId =
                actualProducts[i].packets[0].packetId;
              this.dateToCalculate.myProductOrders[0].packetName =
                actualProducts[i].packets[0].packetName;
              this.dateToCalculate.myProductOrders[0].toPacketId =
                actualProducts[i].packets[0].packetId;
              this.dateToCalculate.myProductOrders[0].toPacketName =
                actualProducts[i].packets[0].packetName;
              setTimeout(() => {
                this.checkQueryParams();
                // this.count();
              }, 0);
              return;
            }
          }
        }

        if (JSON.parse(error).error === 404) {
          if (this.dateToCalculate.myProductOrders[0].licenseId != 0)
            this.event.showNotification(
              'info',
              `Produkt ${this.dateToCalculate.myProductOrders[0].productName} jest już w najnowszej wersji`
            );
        }
        this.isChange = true;
      }
    );
  };

  addToBasket() {
    if (
      (this.UserRole === 'partner' || this.UserRole === 'subpartner') &&
      this.dateToCalculate.myProductOrders[0].licenseId == 0 &&
      this.nipClient.length == 0
    ) {
      this.event.showNotification(
        'info',
        'Proszę uzupełnić Nip użytkownika licencji'
      );
      return;
    }

    if (
      window.localStorage.getItem('wapro-erp-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('wapro-erp-token')).exp * 1000
    ) {
      this.isUnauthorized = false;
    } else this.event.showNavbar.next(false);

    let basket = [];
    if (localStorage.getItem('basket-wapro-erp')) {
      basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
    }

    let isAnotherClient = false;
    try {
      basket.forEach((element) => {
        if (
          element.customerVATNumber.replace(/-/g, '') !==
            this.dateToCalculate.myProductOrders[0].customerVATNumber.replace(
              /-/g,
              ''
            ) &&
          element.maxDateOfExpiration
        )
          isAnotherClient = true;
      });
    } catch {}

    if (isAnotherClient) {
      this.event.showNotification(
        'error',
        'Nie można dodać do koszyka, gdy w koszyku są produkty innego klienta z datą wyrównania licencji'
      );
      return;
    }

    if (this.nipClient.length > 0)
      this.dateToCalculate.myProductOrders[0].remarks = `[NIP:${this.nipClient}] ${this.dateToCalculate.myProductOrders[0].remarks}`;
    // this.dateToCalculate.myProductOrders[0].remarks = `[NIP:${this.nipClient}] ${this.dateToCalculate.myProductOrders[0].remarks}`
    this.event.addProductToShoppingCart(this.basket);
    if (this.isUnauthorized) {
      this.basket.forEach((field) => {
        field.remarks = this.dateToCalculate.myProductOrders[0].remarks;
      });
      this._route.navigate(['oferta']);
      this.event.baskedShop.next(this.basket);
      this.event.showNotification('success', 'Pomyślnie dodano do koszyka');
    } else {
      this.basket.forEach((field) => {
        field.remarks = this.dateToCalculate.myProductOrders[0].remarks;
      });
      this.event.showNotification('success', 'Pomyślnie dodano do koszyka');
      this.event.baskedShop.next(this.basket);

      if (this.isBuyRecommendate) this._route.navigate(['koszyk']);
      else history.back();
    }
  }

  //funkcja sprawdza czy w koszyku są produkty z data wyrównania licencji
  isMaxDateOfExpiration = () => {
    if (localStorage.getItem('basket-wapro-erp')) {
      let basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
      basket.forEach((element) => {
        if (element.maxDateOfExpiration) {
          try {
            if (
              element.customerVATNumber.replace(/-/g, '') ==
              this.dateToCalculate.myProductOrders[0].customerVATNumber.replace(
                /-/g,
                ''
              )
            ) {
              this.dateToCalculate.myProductOrders[0].maxDateOfExpiration =
                element.maxDateOfExpiration;
            }
          } catch {}
        }
      });
    }
  };

  getRecommendationProducts = (productId) => {
    this.appServices
      .getAuth(
        `Products/${productId}/recomendations/${this.dateToCalculate.myProductOrders[0].licenseId}`
      )
      .subscribe((response) => {
        this.recommendedProducts = response;
        this.recommendedProducts.forEach((field) => {
          field.showMoreVisible = false;
          field.isBuy = false;
          field.showDescription = false;
          field.productName = field.productName.replace('WAPRO ', '');
          field.productNameDescription =
            field.productNameDescription.toLowerCase();
        });
      });
  };

  getRecommendationProductsUnatorized = (productId) => {
    this.appServices
      .get(
        `Products/${productId}/recomendations/${this.dateToCalculate.myProductOrders[0].licenseId}`
      )
      .subscribe((response) => {
        this.recommendedProducts = response;
        this.recommendedProducts.forEach((field) => {
          field.isBuy = false;
          field.showDescription = false;
          field.productName = field.productName.replace('WAPRO ', '');
          field.productNameDescription =
            field.productNameDescription.toLowerCase();
        });
      });
  };

  onAdaptiveDetailRowPreparing = (event) => {
    if (window.innerWidth < 1100) {
      if (window.innerWidth < 576) this.heightGrid = this.basket.length * 80;
      else this.heightGrid = this.basket.length * 60;
      this.basket.forEach((el) => {
        if (event.component.isAdaptiveDetailRowExpanded(el))
          this.heightGrid += 150;
      });
    }
  };

  timerNip = 200;
  myTimerNip;

  checkNip = () => {
    this.timerNip = 100;
    clearInterval(this.myTimerNip);
    this.myTimerNip = setInterval(() => {
      this.timerNip--;
      if (this.timerNip == 0) {
        clearInterval(this.myTimerNip);
        if (this.nipClient.length > 0)
          this.isNipOK = this.event.isValidNip(this.nipClient);
      }
    });
  };

  addToBasketRecommendateProduct = (product) => {
    product.customerId = this.dateToCalculate.myProductOrders[0].customerId;
    product.customerName = this.dateToCalculate.myProductOrders[0].customerName;
    product.customerVATNumber =
      this.dateToCalculate.myProductOrders[0].customerVATNumber;
    product.maxDateOfExpiration =
      this.dateToCalculate.myProductOrders[0].maxDateOfExpiration;
    product.licenseId = 0;
    product.licenseNumber = null;
    product.toNumberOfUsers = 1;
    product.numberOfUsers = 1;
    product.numberOfPackets = 1;
    product.versionId = product.versions[0].versionId;
    product.versionNumber = product.versions[0].versionNumber;
    product.toVersionId = product.versions[0].versionId;
    product.toVersionNumber = product.versions[0].versionNumber;
    product.descriptionProduct = product.descriptionProduct;
    product.requiredProducts = product.requiredProducts;
    product.variantDescription = product.variantDescription;
    product.eduLicenseDescription = product.eduLicenseDescription;
    product.packetDescription = product.packetDescription;
    product.company = this.dateToCalculate.myProductOrders[0].customerName;
    product.unicate = new Date().getTime();

    product.variants.sort(function (a, b) {
      return a.orderWWW < b.orderWWW ? -1 : a.orderWWW > b.orderWWW ? 1 : 0;
    });
    product.variantId = product.variants[0].variantId;
    product.variantName = product.variants[0].variantName;
    product.toVariantId = product.variants[0].variantId;
    product.toVariantName = product.variants[0].variantName;

    product.packetId = null;
    product.packetName = null;
    product.toPacketId = null;
    product.toPacketName = null;

    if (product.packets !== null) {
      product.packets.sort(function (a, b) {
        return a.orderWWW < b.orderWWW ? -1 : a.orderWWW > b.orderWWW ? 1 : 0;
      });
      product.packetId = product.packets[0].packetId;
      product.packetName = product.packets[0].packetName;
      product.toPacketId = product.packets[0].packetId;
      product.toPacketName = product.packets[0].packetName;
    }

    let dateToCalculate = {
      myProductOrders: [],
    };

    dateToCalculate.myProductOrders[0] = product;

    this.isUnauthorized
      ? this.event.addToBasketUnatorized(dateToCalculate)
      : this.event.addToBasket(dateToCalculate);
    this.isBuyRecommendate = true;
    this.event.addProductToShoppingCart(dateToCalculate.myProductOrders);
  };
}

export interface Basket {
  amount: number;
  company: string;
  customerId: number;
  description: string;
  grossPrice: number;
  groupOrderPositionId: number;
  licenseId: number;
  licenseNumber: string;
  netAmount: number;
  netPrice: number;
  numberOfUsers: string;
  productId: number;
  productName: string;
  quantity: number;
  customerVATNumber: string;
  lineDescription: string;
  packetId: string;
  packetName: string;
  productNameDescription: string;
  productNameShortcut: string;
  toNumberOfUsers: number;
  toPacketId: string;
  toPacketName: string;
  toVariantId: number;
  toVariantName: string;
  toVersionId: number;
  toVersionNumber: string;
  variantDescription: string;
  packetDescription: string;
  eduLicenseDescription: string;
  variantId: number;
  variantName: string;
  versionId: number;
  versionNumber: string;
  agreementCode: string;
  agreementDesc: string;
  agreementUrl: string;
  unicate: number;
  maxDateOfExpiration?: string;
  remarks: string;
  numberOfPackets: number;
  requiredProducts: any;
  descriptionProduct: string;
  skipChangeLicenseDate?: boolean;
  dayToExpire?: number;
  licenseNumberEnc?: string;
}
