import {
  Component,
  OnInit,
  Injectable,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { ShopingCartComponent } from '../shoping-cart/shoping-cart.component';
import { ActivatedRoute, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss'],
  providers: [ShopingCartComponent],
})
export class OfferListComponent implements OnInit, OnDestroy {
  productList = [];
  productListCloud = [];
  myProductsList = [];
  view: string = 'tilesView';

  isLogIn: boolean = false;
  loadingVisible: boolean = false;

  isMobile = false;

  tabMenu = [
    { name: 'Licencje', productList: this.productList },
    { name: 'Usługi', productList: this.productListCloud },
  ];

  showModalBoxConfirm: boolean = false;
  isUnauthorized: boolean = true;
  showMoreVisible: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1366)
      document.body.style.background =
        "url('./assets/img/gray_triangle_right.png') no-repeat 700px 340px, url('./assets/img/gray_triangle_left.png') no-repeat -580px 940px";
    else
      document.body.style.background =
        "url('./assets/img/gray_triangle_right.png') no-repeat 100% 340px, url('./assets/img/gray_triangle_left.png') no-repeat -180px 940px";
  }

  constructor(
    private appServices: AppServices,
    private event: EventService,
    private _route: Router
  ) {
    if (window.innerWidth < 1366)
      document.body.style.background =
        "url('./assets/img/gray_triangle_right.png') no-repeat 700px 340px, url('./assets/img/gray_triangle_left.png') no-repeat -580px 940px";
    else
      document.body.style.background =
        "url('./assets/img/gray_triangle_right.png') no-repeat 100% 340px, url('./assets/img/gray_triangle_left.png') no-repeat -180px 940px";

    if (localStorage.getItem('viewProductList=wapro-erp'))
      this.view = localStorage.getItem('viewProductList=wapro-erp');

    // if(_route.url === '/panel/oferta'){
    //   this.event.showNavbar.next(true);
    //   this.isLogIn = true;
    //   this.isUnauthorized = false;
    // }
    // else{
    //   localStorage.removeItem('wapro-erp-token')
    // }

    if (window.innerWidth <= 750) {
      this.isMobile = true;
      this.view = 'tilesView';
    } else this.isMobile = false;

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 750) {
        this.isMobile = true;
        this.view = 'tilesView';
      } else this.isMobile = false;
    });

    this.event.askForUpdate.subscribe((response) => {
      if (response) this.showModalBoxConfirm = true;
    });

    if (
      window.localStorage.getItem('wapro-erp-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('wapro-erp-token')).exp * 1000
    ) {
      this.event.showNavbar.next(true);
      this.isLogIn = true;
      this.isUnauthorized = false;
    }
  }

  ngOnInit() {
    this.onShown();
    this.getProductList();
  }

  onShown() {
    this.loadingVisible = true;
  }

  onHidden() {
    this.loadingVisible = false;
  }

  ngOnDestroy() {
    document.body.style.background = 'none';
  }

  viewChanged = () => {
    localStorage.setItem('viewProductList=wapro-erp', this.view);
  };

  getProductList() {
    return this.appServices.getAuth('Products').subscribe(
      (response) => {
        this.productList = [];
        response.forEach((field) => {
          field.isBuy = false;
          field.productName = field.productName.replace('WAPRO ', '');
          field.productNameDescription =
            field.productNameDescription.toLowerCase();
          if (field.operatingSystem === 'Service')
            this.productListCloud.push(field);
          else this.productList.push(field);
        });

        // this.productList = response;
        // this.productList.forEach(field => {

        //   field.isBuy = false;
        //   field.productName = field.productName.replace("WAPRO ", "");
        //   field.productNameDescription = field.productNameDescription.toLowerCase();
        //   if(field.operatingSystem === "Service") this.productListCloud.push(field);
        // });

        if (this.isLogIn) this.getMyProductList();
        this.tabMenu[0].productList = this.productList;
        localStorage.setItem(
          'product-list-wapro-erp',
          this.event.encryptString(response)
        );
        this.onHidden();
      },
      (error) => {
        this.onHidden();
        this.event.showNotification(
          'error',
          'Nie udało się pobrać oferty produktowej'
        );
      }
    );
  }

  getMyProductList() {
    return this.appServices.postAuth('MyProducts', null).subscribe(
      (response) => {
        this.myProductsList = response;

        this.productList.forEach((field) => {
          for (let i = 0; i < this.myProductsList.length; i++) {
            if (this.myProductsList[i].productId === field.productId) {
              field.isBuy = true;
              break;
            }
          }
        });

        this.productListCloud.forEach((field) => {
          for (let i = 0; i < this.myProductsList.length; i++) {
            if (this.myProductsList[i].productId === field.productId) {
              field.isBuy = true;
              break;
            }
          }
        });

        this.isLogIn = true;
        this.event.showNavbar.next(true);
      },
      (error) => {
        this.onHidden();
        if (JSON.parse(error).error !== 401) {
          this.isLogIn = true;
          this.event.showNavbar.next(true);
        }
      }
    );
  }

  onSelectionChangedTab = (e) => {
    this.productList = e.addedItems[0].productList;
  };

  productToConfirm: any;
  buyConfirm() {}

  isConfirmBuy: boolean = false;
  onMdify = (isBuy, product) => {
    setTimeout(() => {
      product.selected = false;
    }, 0);
    if (isBuy) this._route.navigate(['moje-produkty', product.productId]);
    else {
      if (
        product.isBuy &&
        product.operatingSystem == 'Service' &&
        this.productToConfirm == null
      ) {
        product.selected = false;
        this.productToConfirm = product;
        this.isConfirmBuy = true;
        return;
      }

      if (this.productToConfirm != null) product = this.productToConfirm;
      product.licenseId = 0;
      product.licenseNumber = null;
      product.toNumberOfUsers = 1;
      product.numberOfUsers = 1;
      product.versionId = product.versions[0].versionId;
      product.versionNumber = product.versions[0].versionNumber;
      product.toVersionId = product.versions[0].versionId;
      product.toVersionNumber = product.versions[0].versionNumber;
      product.unicate = new Date().getTime();

      product.variants.sort(function (a, b) {
        return a.orderWWW < b.orderWWW ? -1 : a.orderWWW > b.orderWWW ? 1 : 0;
      });
      product.variantId = product.variants[0].variantId;
      product.variantName = product.variants[0].variantName;
      product.toVariantId = product.variants[0].variantId;
      product.toVariantName = product.variants[0].variantName;

      product.packetId = null;
      product.packetName = null;
      product.toPacketId = null;
      product.toPacketName = null;

      if (product.packets !== null) {
        product.packets.sort(function (a, b) {
          return a.orderWWW < b.orderWWW ? -1 : a.orderWWW > b.orderWWW ? 1 : 0;
        });
        product.packetId = product.packets[0].packetId;
        product.packetName = product.packets[0].packetName;
        product.toPacketId = product.packets[0].packetId;
        product.toPacketName = product.packets[0].packetName;
      }

      this.event.onModifyClick(product, this.isUnauthorized);
    }
  };

  getButtonText(item: any) {
    if (item.isBuy) return 'Modyfikuj';
    else if (item.operatingSystem === 'Service') return 'Kup usługę';
    else return 'Kup program';
  }
}
