import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { AppServices } from '../app-services.service';
import { EventService } from '../event.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { DatePipe } from '@angular/common';
import { Md5 } from 'ts-md5/dist/md5';

const helper = new JwtHelperService();

const md5 = new Md5();
const salt = '%CxR$%j$i9^2:9_0*2Q!230xs.+';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, OnDestroy {
  @ViewChild('dataGridDevexpres') dataGridDevexpres;

  orderList = [];
  filtrName = [
    { label: 'Przychodzące', value: 'Incoming' },
    { label: 'Własne', value: 'Own' },
    { label: 'Wysłane', value: 'Sent' },
    { label: 'Zapisane', value: 'Saved' },
    { label: 'Historia przychodzących', value: 'History' },
  ];

  filtrName2 = [
    { label: 'Nazwa', value: 'Company' },
    { label: 'NIP', value: 'VATNumber' },
    { label: 'Numer', value: 'OrderNumber' },
  ];

  filterCriteriaSave = [
    { label: 'Nazwa', value: 'Company' },
    { label: 'NIP', value: 'VATNumber' },
    { label: 'Numer', value: 'OrderNumber' },
    { label: 'Nazwa koszyka', value: 'BasketName' },
  ];

  filterCriteriaDefault = [
    { label: 'Nazwa', value: 'Company' },
    { label: 'NIP', value: 'VATNumber' },
    { label: 'Numer', value: 'OrderNumber' },
  ];

  filterCriteria: string = 'Incoming';
  filterColumn: string = 'OrderType';
  isShowMore: boolean = false;

  filterColumn2: string = 'Company';
  filterCriteria2: string = '';

  selectedRows = [];
  showModalBox: boolean = false;
  remarks: string = '';

  selectedtab = 1;
  showCheckBox: boolean = false;

  shortList = [];
  widthComments: number = 300;
  isSend: boolean = false;

  tabMenu = [
    { name: 'Własne', orderList: this.orderList, id: 0 },
    { name: 'Przychodzące', orderList: this.orderList, id: 1 },
    { name: 'Wysłane', orderList: this.orderList, id: 2 },
    { name: 'Zapisane', orderList: this.orderList, id: 3 },
    { name: 'Historia przychodzących', orderList: this.orderList, id: 4 },
  ];

  startToAdd = 0;
  UserRole = '';
  widthPopUp: number = 500;
  company = '';
  heightGrid: any = 'auto';
  isSent: boolean = false;
  showModalBoxConfirm: boolean = false;
  confirmMes = 'Czy chcesz anulować zaznaczone zamówienia?';
  arrayItem = { orderIds: [], positionIds: null };

  isMobile = false;

  constructor(
    private appServices: AppServices,
    private event: EventService,
    private _route: Router,
    public cd: ChangeDetectorRef
  ) {
    this.event.showNavbar.next(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 430) {
      this.widthComments = 100;
    }
  }

  ngOnInit(): void {
    this.UserRole = helper.decodeToken(
      localStorage.getItem('wapro-erp-token')
    ).UserRole;

    if (window.innerWidth < 576) {
      this.widthComments = 100;
    }

    this.UserRole === 'partner' || this.UserRole === 'subpartner'
      ? (this.company = 'Klient')
      : (this.company = 'Wysłane do');

    if (sessionStorage.getItem('FilterCriteriaOrderList')) {
      this.selectedtab = Number(
        sessionStorage.getItem('FilterCriteriaOrderList')
      );
      if (this.selectedtab === 0) this.filterCriteria = 'Own';
      if (this.selectedtab === 1) this.filterCriteria = 'Incoming';
      if (this.selectedtab === 2) {
        this.isSend = true;
        this.company = 'Wysłane do';
        this.filterCriteria = 'Sent';
      }
      if (this.selectedtab === 4) this.filterCriteria = 'History';

      if (this.selectedtab === 3) {
        this.filterCriteria = 'Saved';
        this.filterColumn2 = 'OrderNumber';
        this.filtrName2 = this.filterCriteriaSave;
        this.showCheckBox = true;
      }
    }

    if (this.UserRole === 'client') {
      this.tabMenu = [
        { name: 'Własne', orderList: this.orderList, id: 0 },
        { name: 'Zapisane', orderList: this.orderList, id: 3 },
      ];
      this.selectedtab = 0;
      this.filterCriteria = 'Own';
    }

    if (window.innerWidth <= 375) {
      this.widthPopUp = 335;
    }

    let filtercriteria2 = this.filterCriteria2;
    filtercriteria2 = filtercriteria2.replace(/ó/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ó/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ł/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ł/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ę/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ę/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ą/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ą/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ś/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ś/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ż/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ż/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ź/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ź/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ć/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ć/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ń/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ń/g, '%');

    this.appServices.headers = this.appServices.headers.append(
      'FilterCriteria',
      this.filterCriteria
    );
    this.appServices.headers = this.appServices.headers.append(
      'FilterColumn',
      this.filterColumn
    );

    this.appServices.headers = this.appServices.headers.append(
      'FilterCriteria2',
      filtercriteria2
    );
    this.appServices.headers = this.appServices.headers.append(
      'FilterColumn2',
      this.filterColumn2
    );
    if (this.filterCriteria === 'Incoming' || this.filterCriteria === 'Saved')
      this.showCheckBox = true;
    this.getOrderList();

    if (window.innerWidth < 1100) {
      this.isMobile = true;
    } else this.isMobile = false;
  }

  onSelectionChangedTab = (e) => {
    this.selectedRows = [];
    this.company = 'Klient';
    this.isSend = false;
    switch (e.addedItems[0].name) {
      case 'Przychodzące':
        this.filterColumn2 = 'Company';
        this.filterCriteria = 'Incoming';
        this.showCheckBox = true;
        this.selectedtab = 1;
        this.filtrName2 = this.filterCriteriaDefault;
        break;
      case 'Wysłane':
        this.filterColumn2 = 'Company';
        this.filtrName2 = this.filterCriteriaDefault;
        this.isSend = true;
        this.filterCriteria = 'Sent';
        this.showCheckBox = false;
        this.selectedtab = 2;
        this.company = 'Wysłane do';
        break;
      case 'Zapisane':
        this.filterColumn2 = 'OrderNumber';
        this.filtrName2 = this.filterCriteriaSave;
        this.filterCriteria = 'Saved';
        this.showCheckBox = true;
        this.selectedtab = 3;
        break;
      case 'Historia przychodzących':
        this.filterColumn2 = 'Company';
        this.filtrName2 = this.filterCriteriaDefault;
        this.filterCriteria = 'History';
        this.showCheckBox = false;
        this.selectedtab = 4;
        break;
      default:
        this.filterColumn2 = 'Company';
        this.filtrName2 = this.filterCriteriaDefault;
        this.filterCriteria = 'Own';
        this.showCheckBox = false;
        this.selectedtab = 0;
    }
    sessionStorage.setItem(
      'FilterCriteriaOrderList',
      this.selectedtab.toString()
    );
    this.appServices.headers = this.appServices.headers.set(
      'filterCriteria',
      this.filterCriteria
    );

    this.filterCriteria2 = '';
    this.appServices.headers = this.appServices.headers.set(
      'FilterCriteria2',
      ''
    );

    this.orderList = [];
    this.shortList = [];
    this.getOrderList();
  };

  onItemClick = (e) => {
    this.selectedRows = [];
    this.company = 'Klient';
    this.isSend = false;
    switch (e) {
      case 'Przychodzące':
        this.filterColumn2 = 'Company';
        this.filterCriteria = 'Incoming';
        this.showCheckBox = true;
        this.selectedtab = 1;
        this.filtrName2 = this.filterCriteriaDefault;
        break;
      case 'Wysłane':
        this.filterColumn2 = 'Company';
        this.filtrName2 = this.filterCriteriaDefault;
        this.isSend = true;
        this.filterCriteria = 'Sent';
        this.showCheckBox = false;
        this.selectedtab = 2;
        this.company = 'Wysłane do';
        break;
      case 'Zapisane':
        this.filterColumn2 = 'OrderNumber';
        this.filtrName2 = this.filterCriteriaSave;
        this.filterCriteria = 'Saved';
        this.showCheckBox = true;
        this.selectedtab = 3;
        break;
      case 'Historia przychodzących':
        this.filterColumn2 = 'Company';
        this.filtrName2 = this.filterCriteriaDefault;
        this.filterCriteria = 'History';
        this.showCheckBox = false;
        this.selectedtab = 4;
        break;
      default:
        this.filterColumn2 = 'Company';
        this.filtrName2 = this.filterCriteriaDefault;
        this.filterCriteria = 'Own';
        this.showCheckBox = false;
        this.selectedtab = 0;
    }
    sessionStorage.setItem(
      'FilterCriteriaOrderList',
      this.selectedtab.toString()
    );
    this.appServices.headers = this.appServices.headers.set(
      'filterCriteria',
      this.filterCriteria
    );

    this.filterCriteria2 = '';
    this.appServices.headers = this.appServices.headers.set(
      'FilterCriteria2',
      ''
    );

    this.orderList = [];
    this.shortList = [];
    this.getOrderList();
  };

  onSelect = (data) => {
    this.selectedRows = [];
    this.settextConfirm();
    this.selectedRows.push(data);
  };

  addSelectedToBasket = (getOrders?) => {
    let link = '';
    this.selectedRows.forEach((item) => {
      this.arrayItem.orderIds.push({ id: item.orderId });
      link +=
        link === '' ? `?orderIds=${item.orderId}` : `&orderIds=${item.orderId}`;
    });

    try {
      let basket = JSON.parse(
        this.event.decryptString(localStorage.getItem('basket-wapro-erp'))
      );
      let maxDateOfExpiration = false;
      basket.forEach((element) => {
        if (element.maxDateOfExpiration) {
          maxDateOfExpiration = true;
        }
      });

      if (maxDateOfExpiration) {
        this.event.showNotification(
          'error',
          'Nie można dodać zamówienia do bieżącego koszyka jeśli w koszyku są licencje z datą wyrównania'
        );
        return false;
      }
    } catch {}

    let addToBasket = [];
    this.appServices
      .getAuth(`orders/ordersCalculation${link}`)
      .subscribe((response) => {
        addToBasket = response;

        for (let i = 0; i < addToBasket.length; i++) {
          if (
            addToBasket[i].licenseId === -1 ||
            addToBasket[i].licenseId === 0
          ) {
            if (addToBasket[i].quantity == 1)
              addToBasket[i].unicate = addToBasket[i].positionId;
            else if (addToBasket[i].productId == addToBasket[i - 1].productId)
              addToBasket[i].unicate = addToBasket[i - 1].positionId;
          } else addToBasket[i].unicate = addToBasket[i].licenseId;
        }

        let actualProducts = JSON.parse(
          this.event.decryptString(
            localStorage.getItem('product-list-wapro-erp')
          )
        );

        let allIsMaxDateExpiration = null;
        let oneMacDateExpiration = false;
        addToBasket.forEach((field, index) => {
          if (
            field.maxDateOfExpiration &&
            new Date(field.maxDateOfExpiration).getTime() >=
              new Date().getTime()
          )
            allIsMaxDateExpiration = field.maxDateOfExpiration;
          for (let i = 0; i < actualProducts.length; i++) {
            if (actualProducts[i].productId === field.productId) {
              field.descriptionProduct = actualProducts[i].description;
              field.eduLicenseDescription =
                actualProducts[i].eduLicenseDescription;
              field.packetDescription = actualProducts[i].packetDescription;
              field.productNameDescription =
                actualProducts[i].productNameDescription;
              field.productNameShortcut = actualProducts[i].productNameShortcut;
              field.requiredProducts = actualProducts[i].requiredProducts;
              field.variantDescription = actualProducts[i].variantDescription;
              if (field.variantId === null) field.variantId = field.toVariantId;
              if (field.variantName === null)
                field.variantName = field.toVariantName;
              if (field.versionId === null) field.versionId = field.toVersionId;
              if (field.versionNumber === null)
                field.versionNumber = field.toVersionNumber;
            }
          }
        });

        addToBasket.forEach((field) => {
          if (field.maxDateOfExpiration != allIsMaxDateExpiration)
            oneMacDateExpiration = true;
        });

        if (oneMacDateExpiration) {
          this.event.showNotification(
            'error',
            'Nie można dodać zamówienia do bieżącego koszyka jeśli w koszyku są licencje z datą wyrównania'
          );
          return true;
        }
        this.appServices
          .postAuth(`orders/removeOrderPosition`, this.arrayItem)
          .subscribe((response) => {
            if (!response.status)
              this.event.showNotification('error', response.description);

            if (response.status) {
              if (getOrders) this.getOrderList();
              this.selectedRows = [];
            }
          });

        this.event.baskedShop.next(addToBasket);
      });
    return true;
  };

  goToOrder = (e) => {
    this.selectedRows = [];
    this.selectedRows.push(e);
    let isValid = this.addSelectedToBasket();
    this.selectedRows = [];
    if (isValid) this._route.navigate(['zamowienie']);
  };

  settextConfirm = () => {
    if (this.selectedRows.length > 1)
      this.confirmMes = 'Czy chcesz anulować zaznaczone zamówienia?';
    else this.confirmMes = 'Czy chcesz anulować zamówienie?';
  };

  ngOnDestroy() {
    this.appServices.headers = this.appServices.headers.delete('FilterColumn');
    this.appServices.headers =
      this.appServices.headers.delete('FilterCriteria');
    this.appServices.headers = this.appServices.headers.delete('FilterColumn2');
    this.appServices.headers =
      this.appServices.headers.delete('FilterCriteria2');
  }

  onSelectionChanged = (e) => {
    let selsected = e.selectedRowKeys;

    selsected.forEach((element, index) => {
      if (!element) {
        this.selectedRows.splice(index, 1);
      }
    });

    this.selectedRows = selsected;
  };

  getOrderList() {
    this.shortList = [];
    this.isShowMore = false;
    let filtercriteria2 = this.filterCriteria2;
    filtercriteria2 = filtercriteria2.replace(/ó/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ó/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ł/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ł/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ę/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ę/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ą/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ą/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ś/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ś/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ż/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ż/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ź/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ź/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ć/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ć/g, '%');

    filtercriteria2 = filtercriteria2.replace(/ń/g, '%');
    filtercriteria2 = filtercriteria2.replace(/Ń/g, '%');

    this.appServices.headers = this.appServices.headers.set(
      'FilterCriteria2',
      filtercriteria2
    );
    this.appServices.headers = this.appServices.headers.set(
      'FilterColumn2',
      this.filterColumn2
    );

    return this.appServices.getAuth('Orders').subscribe((response) => {
      this.orderList = response;
      // this.orderList.forEach(field => {
      //   field.orderNumber = field.orderNumber.replace(/\\(.)/g, '$1');
      // });

      if (this.orderList.length > 30) {
        this.isShowMore = true;
        for (let i = 0; i < 30; i++) {
          this.shortList.push(this.orderList[i]);
        }
      } else {
        this.shortList = this.orderList;
      }

      if (window.innerWidth < 1100) {
        this.heightGrid = this.shortList.length * 70 + 40;
      } else this.heightGrid = 'auto';
    });
  }

  cancelOrder = () => {
    this.selectedRows.forEach((data) => {
      this.appServices
        .postAuth(`orders/${data.orderId}/cancelOrder`, data.orderId)
        .subscribe(
          (response) => {
            if (response.status) {
              this.event.showNotification('success', response.description);
              data.orderState = 'anulowane';
              this.getOrderList();
            } else this.event.showNotification('error', response.description);
          },
          (error) => {
            this.event.showNotification(
              'error',
              'Wystąpił błąd podczas próby anulowania zamówienia'
            );
          }
        );
    });
    this.selectedRows = [];
  };

  onAdaptiveDetailRowPreparing = (event) => {
    if (window.innerWidth < 1100) {
      this.heightGrid = this.shortList.length * 70 + 40;
      this.shortList.forEach((el) => {
        if (event.component.isAdaptiveDetailRowExpanded(el)) {
          this.selectedtab === 1
            ? (this.heightGrid += 300)
            : (this.heightGrid += 220);
        }
      });
    }
  };

  ngModelChange = () => {
    if (this.filterCriteria2 === '') {
      this.getOrderList();
    }
  };

  unselectAll = () => {
    this.selectedRows = [];
  };

  addToSelected(orderId) {
    this.selectedRows = [];
    this.orderList.forEach((field) => {
      if (field.orderId === orderId) this.selectedRows.push(field);
    });
  }

  sendToRealize = () => {
    this.selectedRows.forEach((field) => {
      this.appServices
        .postAuth(`Orders/sendToRealize`, {
          orderId: field.orderId,
          remarks: this.remarks,
        })
        .subscribe((response) => {
          this.event.showNotification(
            'success',
            `Przesłano do dalszej realizacji zamówienie ${field.orderNumber}`
          );
          this.showModalBox = false;
          this.getOrderList();
        });
    });
    this.selectedRows = [];
    this.remarks = '';
  };

  showMore = () => {
    this.startToAdd = this.startToAdd + 30;
    let end = this.startToAdd + 30;

    if (end > this.orderList.length) {
      end = this.orderList.length;
      this.isShowMore = false;
    }
    for (let i = this.startToAdd; i < end; i++) {
      this.shortList.push(this.orderList[i]);
    }

    if (window.innerWidth < 1100) {
      this.heightGrid = this.shortList.length * 70 + 40;
    } else this.heightGrid = 'auto';
  };

  onRowClick = (e) => {
    if (!this.showModalBox) {
      sessionStorage.setItem(
        'detalis-order-wapro-erp',
        this.event.encryptString(e)
      );
      this._route.navigate(['zamowienie-info', e.orderId]);
    }
  };

  payOnline = (orderId) => {
    this.appServices
      .postAuth(
        `Orders/${orderId}/AddPayment`,
        JSON.stringify({ orderId: orderId })
      )
      .subscribe(
        (response) => {
          this.event.newPayment(response);
          // let parameters = this.createParameterString(response);
          // this.postForm(response.paymentServiceUrl, parameters, 'POST');
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).message);
        }
      );
  };

  createParameterString = (checkSum) => {
    let payment = '';
    payment += checkSum.transactionId;
    payment += checkSum.firstname;
    payment += checkSum.lastname;
    payment += checkSum.desc;
    payment += checkSum.lang;
    payment += checkSum.mail;
    payment += checkSum.accountId;
    payment += checkSum.returnUrl;
    payment += checkSum.notificationUrl;
    payment += checkSum.priceNet;
    payment += checkSum.priceGross;
    payment += checkSum.priceVat;
    payment += checkSum.sendToErp;

    let dataNow = new DatePipe('en-US').transform(new Date(), 'yyyyMMdd');

    let string = Md5.hashStr(payment);
    string = string + '/' + dataNow + '/' + salt;
    string = Md5.hashStr(string);

    checkSum.checkSum = string;

    return checkSum;
  };

  postForm(path, params, method) {
    method = method || 'post';

    var form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }
}
